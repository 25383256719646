import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { PROJECT_REPORT_MUTATION } from '../../apollo/mutations';

export function useReportProject(project, setSavingReport) {
  const [isReported, setIsReported] = useState(project.isReported);

  const [report] = useMutation(PROJECT_REPORT_MUTATION, {
    context: { clientName: 'authorized' }
  });

  const handleReportClick = (type, setReportModal) => {
    setSavingReport(true);
    report({
      variables: {
        id: parseInt(project.id, 10),
        type
      },
      optimisticResponse: {
        reportProject: {
          id: parseInt(project.id, 10),
          __typename: 'Project'
        }
      },
      onCompleted: () => {
        setReportModal(false);
        setIsReported(!isReported);
        setSavingReport(false);
      },
      onError: () => {
        setReportModal(false);
        setSavingReport(false);
      }
    });
  };

  return { isReported, handleReportClick };
}

// useReportProject.propTypes = {
//   project: PropTypes.shape({
//     id: projectPropTypes.id.isRequired,
//     isReported: projectPropTypes.isReported.isRequired
//   }).isRequired
// };
