import React, { useState, useEffect } from 'react';
import Banner from '../components/Banner';
import AvatarSlider from '../components/AvatarSlider';
import ProjectListing from '../components/ProjectListing';
import BottomFloatingActionBar from '../components/BottomFloatingActionBar';
import Button from '@digital-hig/button';
import Icon from '@digital-hig/icon';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { HOME_QUERY } from '../apollo/queryPages';
import { AvatarSliderWrapper } from './Pages.styled';
import { Helmet } from 'react-helmet-async';
import { useOpenCreateModal } from '../utils/hooks/useOpenCreateModal';
import { useSelector } from 'react-redux';

const HomePage = () => {
  const { t } = useTranslation();
  const { handleOpenCreateModal } = useOpenCreateModal();

  const { loading: loadingAuth } = useSelector((state) => state.auth);
  const username = useSelector((state) => state?.auth?.myProfile?.slug);

  const [pageData, setPageData] = useState({
    heroData: null,
    pushData: null,
    featuredMembersData: null
  });
  // Page data Query
  const { data: pageDataAPI } = useQuery(HOME_QUERY, {
    context: { clientName: 'public' },
    variables: {
      id: 1,
      lang: 'en'
    }
  });

  // Set page data on query completed
  useEffect(() => {
    if (pageDataAPI?.page) {
      const data = pageDataAPI.page;
      const featuredMembers = pageDataAPI.usersFeatured;
      setPageData({
        ...pageDataAPI,
        heroData: {
          type: 'hero',
          title: data.hero_title,
          subtitle: data.hero_description,
          avatarProps: {
            name: `${data.hero_project.author.display_name}`,
            profilePicture: data.hero_project.author.avatar_big,
            profileUrl: `/profile/${data.hero_project.author.slug}`,
            slug: data.hero_project.author.slug
          },
          image: {
            src: data.hero_project?.image?.path,
            alt: data.hero_project?.title,
            srcSet: data.hero_project?.image?.sizes
          },
          showSearchbar: true,
          autocompleteOptions: [
            '3DS Max',
            'AutoCAD LT',
            'AutoCAD',
            'BIM 360',
            'Factory Design Utilities',
            'Fusion 360',
            'Inventer CAM',
            'Inventor Nastran',
            'Inventor Nesting',
            'Inventor',
            'Maya',
            'Revit'
          ]
        },
        pushData: {
          type: 'banner',
          title: data.push_title,
          subtitle: data.push_description,
          avatarProps: {
            name: `${data.push_project?.author?.display_name}`,
            profilePicture: data.push_project?.author?.avatar_big,
            profileUrl: `/profile/${data.push_project?.author?.slug}`,
            slug: data.push_project?.author?.slug
          },
          image: {
            src: data.push_project?.image?.path,
            alt: data.push_project?.title,
            srcSet: data.push_project?.image.sizes
          },
          showSearchbar: false,
          actionProps: {
            href: data.push_link.url,
            label: data.push_link.label
          }
        },
        featuredMembersData: {
          title: t('home_featured_members'),
          sliderData: featuredMembers.map((member) => {
            const name = `${member.display_name}`;
            return {
              name: name,
              profilePicture: member.avatar_big,
              profilePictureAlt: name,
              profileUrl: `/profile/${member.slug}`,
              subtitle: member.job_title,
              id: member.id
            };
          })
        }
      });
    }
  }, [pageDataAPI]);

  return (
    <>
      <Helmet
        title={`${pageDataAPI?.page.seo?.title} | Autodesk Community Gallery`}
        meta={[
          {
            property: 'og:image',
            content:
              pageDataAPI?.page?.seo?.image ||
              'https://dsuj2mkiosyd2.cloudfront.net/seo/en/000/001/og_image_original.jpg'
          },
          {
            property: 'og:title',
            content: `${pageDataAPI?.page.seo?.og_title} | Autodesk Community Gallery`
          },
          { property: 'og:url', content: `https://www.autodesk.com/community/gallery` },
          {
            property: 'og:description',
            content: `${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`
          },
          {
            property: 'twitter:title',
            content: `${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`
          }
        ]}
        description={`${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`}
      />
      {pageData.heroData && <Banner showSearchbar {...pageData.heroData} />}
      {!!pageData.featuredMembersData && (
        <AvatarSliderWrapper>
          <AvatarSlider {...pageData.featuredMembersData} />
        </AvatarSliderWrapper>
      )}
      <ProjectListing
        name="homepage"
        pageType="homepage"
        banner={pageData.pushData && <Banner {...pageData.pushData} />}
        setToggleModal={handleOpenCreateModal}
        loadingAuth={loadingAuth}
        username={username}
      />
      <BottomFloatingActionBar>
        <Button
          className="filterbar--button"
          onClick={handleOpenCreateModal}
          startIcon={<Icon id="dhig--icons--utility--add" />}
        >
          {t('create_your_project_btn_label')}
        </Button>
      </BottomFloatingActionBar>
    </>
  );
};

export default HomePage;
