import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const ContentWrapper = styled.div`
  max-width: 1248px;
  margin: auto;
  width: 100%;
`;

export const AvatarSliderWrapper = styled(ContentWrapper)`
  padding: ${lightTheme.primitives.spacings['7']} 0px;
`;
