import React, { useState } from 'react';
import Banner from '../../components/Banner';
import ProjectListing from '../../components/ProjectListing';
import BottomFloatingActionBar from '../../components/BottomFloatingActionBar';
import Button from '@digital-hig/button';
import Icon from '@digital-hig/icon';
import { useQuery } from '@apollo/client';
import { CATEGORY_QUERY } from '../../apollo/queryPages';
import { getCategoryId } from '../../utils/getCategoryId';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useOpenCreateModal } from '../../utils/hooks/useOpenCreateModal';
import { useSelector } from 'react-redux';

const CategoryMediaPage = () => {
  const [pageData, setPageData] = useState({
    heroData: null
  });

  const { t } = useTranslation();
  const { handleOpenCreateModal } = useOpenCreateModal();
  const { isAuthenticated, loading: loadingAuth } = useSelector((state) => state.auth);
  const username = useSelector((state) => state?.auth?.myProfile?.slug);

  // Get Page Datas from URL pathname
  const { pathname } = useLocation();
  const { data: pageDataAPI } = useQuery(CATEGORY_QUERY, {
    context: { clientName: 'public' },
    variables: {
      id: getCategoryId(pathname),
      lang: 'en'
    },
    onCompleted: (pageDataAPI) => {
      const data = pageDataAPI.page;
      setPageData({
        heroData: {
          type: 'hero',
          title: data.hero_title,
          subtitle: data.hero_description,
          avatarProps: {
            name: `${data.hero_project.author.display_name}`,
            profilePicture: data.hero_project.author.avatar_big,
            profileUrl: `/profile/${data.hero_project.author.slug}`,
            slug: data.hero_project.author.slug
          },
          image: {
            src: data.hero_project?.image.path,
            alt: data.hero_project?.title,
            srcSet: data.hero_project?.image.sizes
          },
          showSearchbar: true
        }
      });
    }
  });

  return (
    <>
      <Helmet
        title={`${pageDataAPI?.page.seo?.title} | Autodesk Community Gallery`}
        meta={[
          {
            property: 'og:image',
            content:
              pageDataAPI?.page?.seo?.image ||
              'https://dsuj2mkiosyd2.cloudfront.net/seo/en/000/001/og_image_original.jpg'
          },
          {
            property: 'og:title',
            content: `${pageDataAPI?.page.seo?.og_title} | Autodesk Community Gallery`
          },
          {
            property: 'og:url',
            content: `https://www.autodesk.com/community/gallery/media-entertainment`
          },
          {
            property: 'og:description',
            content: `${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`
          },
          {
            property: 'twitter:title',
            content: `${pageDataAPI?.page.seo?.og_title} | Autodesk Community Gallery`
          }
        ]}
        description={`${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`}
      />
      {pageData.heroData && <Banner {...pageData.heroData} />}
      <ProjectListing
        name="media-entertainment"
        filters={true}
        pageType="media-entertainment"
        isSearch={false}
        setToggleModal={handleOpenCreateModal}
        loadingAuth={loadingAuth}
        username={username}
      />
      {isAuthenticated && (
        <BottomFloatingActionBar>
          <Button
            className="filterbar--button"
            onClick={handleOpenCreateModal}
            startIcon={<Icon id="dhig--icons--utility--add" />}
          >
            {t('create_your_project_btn_label')}
          </Button>
        </BottomFloatingActionBar>
      )}
    </>
  );
};

export default CategoryMediaPage;
