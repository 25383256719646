import React from 'react';
import PropTypes from 'prop-types';
import { TagListWrapper, Tags, LinkChip } from './TagList.styled';
import Typography from '@digital-hig/typography';
import Chip from '@mui/material/Chip';
import Icon from '@digital-hig/icon';

function TagList(props) {
  const { title, tagList, onDeleteClick, deletable, tagsWithLinks } = props;

  return (
    <TagListWrapper>
      {!!title && (
        <Typography component="h4" variant="headline-smallest">
          {title}
        </Typography>
      )}
      <Tags hasTitle={!!title}>
        {tagList.map((tag) => {
          if (tagsWithLinks && tag.url) {
            return (
              <LinkChip to={tag.url} key={tag.id}>
                <Chip
                  {...tag}
                  label={tag.label || tag.name}
                  deleteIcon={<Icon id="dhig--icons--utility--cross" />}
                  onDelete={deletable ? () => onDeleteClick(tag.id) : undefined}
                />
              </LinkChip>
            );
          } else {
            return (
              <Chip
                {...tag}
                key={tag.id}
                label={tag.label || tag.name}
                deleteIcon={<Icon id="dhig--icons--utility--cross" />}
                onDelete={deletable ? () => onDeleteClick(tag.id) : undefined}
              />
            );
          }
        })}
      </Tags>
    </TagListWrapper>
  );
}

TagList.propTypes = {
  title: PropTypes.string,
  tagList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  onDeleteClick: PropTypes.func,
  deletable: PropTypes.bool,
  tagsWithLinks: PropTypes.bool
};

TagList.defaultProps = {
  title: undefined,
  deletable: false,
  onDeleteClick: undefined,
  tagsWithLinks: true
};

export default TagList;
