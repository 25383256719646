import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import i18n from './app/i18n';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { store } from './app/store';
import ApolloProviderWithErrorHandler from './app/apollo/client';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { UserContextProvider } from './app/context/UserContext';
import { ModalContextProvider } from './app/context/ModalContext';

// import { PersistGate } from 'redux-persist/integration/react';
/***
 *
 * This is the client-side entry point.  The webpack bundle starts here and attempts to
 * hydrate the existing DOM with a react app injected into the DOM element with id="root"
 */
hydrateRoot(
  document.getElementById('root'),
  <UserContextProvider>
    <ModalContextProvider>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          {/* <PersistGate loading={null} persistor={persistor}> */}
          <ApolloProviderWithErrorHandler>
            <BrowserRouter basename="/community/gallery">
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </BrowserRouter>
          </ApolloProviderWithErrorHandler>
          {/* </PersistGate> */}
        </Provider>
      </I18nextProvider>
    </ModalContextProvider>
  </UserContextProvider>
);

// persistor.subscribe(() => {
//   /* Hydrate React components when persistor has synced with redux store */
//   const { bootstrapped } = persistor.getState();

//   if (bootstrapped) {
//     hydrate(<Main />, document.getElementById('root'));
//   }
// });
