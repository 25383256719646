import PropTypes from 'prop-types';
import Typography from '@digital-hig/typography';

import { StyledSlider, AvatarSliderWrapper } from './AvatarSlider.styled';
import Avatar from '../Avatar';
import { useIsMobile } from '../../utils';

function AvatarSlider(props) {
  const { title, sliderData } = props;
  const isMobile = useIsMobile();

  return (
    <div data-wat-link-section="community gallery featured member">
      <AvatarSliderWrapper isMobile={isMobile}>
        <Typography component="h3" variant={isMobile ? 'headline-smallest' : 'headline-medium'}>
          {title}
        </Typography>
        <StyledSlider showNav>
          {sliderData.map((item) => (
            <Avatar key={item.id} size="x-large" className="avatar-slider-item" {...item} />
          ))}
        </StyledSlider>
      </AvatarSliderWrapper>
    </div>
  );
}

AvatarSlider.propTypes = {
  title: PropTypes.string.isRequired,
  sliderData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      profilePicture: PropTypes.string,
      profilePictureAlt: PropTypes.string,
      profileUrl: PropTypes.string,
      subtitle: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  )
};

export default AvatarSlider;
