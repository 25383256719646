import styled, { css, keyframes } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

const infiniteLoadAnim = keyframes`
  0% {
    transform: translateX(110%)
  }
  50% {
    transform: translateX(-75%) scaleX(.5)
  }
  100% {
    transform: translateX(110%)
  }
`;

export const ListItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
  align-items: center;
`;

export const MediaLoader = styled.div`
  width: 80%;
  height: 3px;
  background-color: lightGrey;
  overflow: hidden;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: black;
    display: block;
    ${({ loadingPercentage, infiniteLoad }) =>
      !infiniteLoad
        ? css`
            transform-origin: center left;
            transform: scaleX(${loadingPercentage / 100});
          `
        : css`
            animation: ${infiniteLoadAnim} 1.5s linear infinite;
          `}
  }
`;

export const ImageWrapper = styled.div`
  height: 72px;
  width: 72px;
  border-radius: ${lightTheme.primitives.borderRadii.large};
  overflow: hidden;
  margin-right: ${lightTheme.primitives.spacings['6']};
  position: relative;
  flex: 0 0 auto;
  margin-left: ${({ hasCheckbox }) =>
    hasCheckbox ? `${lightTheme.primitives.spacings['3']}` : '0'};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
    width: 24px;
    height: 24px;
  }
`;

export const LeftColumn = styled.div`
  display: ${({ selectable }) => (selectable ? 'flex' : 'grid')};
  align-items: center;

  grid-template-columns: 72px 1fr;
  grid-gap: ${({ selectable }) => (selectable ? '8px' : '24px')};
`;

export const RightColumn = styled.div`
  display: flex;
`;

export const CenterColumn = styled.div`
  align-items: center;
`;

export const ContentWrapper = styled.div`
  max-width: 150px;
  overflow: hidden;
  > h5 {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: ${lightTheme.primitives.spacings['4']};
  button {
    appearance: none;
    font-size: 0;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    background: transparent;
    &.create_block_action_reorder {
      cursor: grab;
    }
    svg {
      width: 20px;
      height: 20px;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
`;

export const DownloadButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${lightTheme.primitives.spacings['2']};
  a {
    font-size: 0;
    height: 16px;
    width: 24px;

    svg {
      height: 16px;
      width: 24px;
    }
  }
`;
