import React, { useState, useEffect, useMemo, useContext } from 'react';
import Typography from '@digital-hig/typography';
import Banner from '../../components/Banner';
import MemoizedDetailHeader from './components/DetailHeader';
import ThumbnailGrid from '../../components/ThumbnailGrid';
import MemoizedDetailFooter from '../../components/DetailFooter';
import DownloadSection from '../../components/DownloadSection';
import BlockText from '../../components/CreateDetailBlocks/BlockText';
import MemoizedBlock3dViewer, {
  ConnectedBlock3dViewer
} from '../../components/CreateDetailBlocks/Block3dViewer';
import MemoizedBlockImage, {
  ConnectedBlockImage
} from '../../components/CreateDetailBlocks/BlockImage';
import MemoizedBlockEmbed, {
  ConnectedBlockEmbed
} from '../../components/CreateDetailBlocks/BlockEmbed';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import { PROJECT_QUERY } from '../../apollo/queryPages';
import { onDownloadFile } from '../../utils';
import useIsSsr from '../../utils/hooks/useIsSsr';
import { useExternalScript } from '../../utils/hooks/useExternalScript';
import {
  BlocksWrapper,
  DownloadButtonWrapper,
  RelatedContentWrapper,
  RelatedContentContainer,
  ProgressWrapper,
  SlidersWrapper
} from './DetailPage.styled';
import {
  fetchCreateDetailsSetStoreDataAction,
  emptyCreateStateAction
} from '../../store/actions/createActions';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DOWNLOAD_PROJECT, GET_CATEGORIES, GET_CONTEXTUAL_BANNER } from '../../apollo/queries';
import MemoizedBlockRaasEmbed, {
  ConnectedBlockRaasEmbed
} from '../../components/CreateDetailBlocks/BlockRaasEmbed';
import Progress from '@digital-hig/progress';
import { createBlockToViewModel } from '../../common/Create/utils';
import ContextualBanner from '../../components/ContextualBanner';

import { UserContext } from '../../context/UserContext';
import { ModalContext } from '../../context/ModalContext';
import SignInAlert from '../../components/SignInAlert';
import CreateModal from '../../components/CreateModal';
import FsLightbox from 'fslightbox-react';
import Unauthorized from '../../components/Unauthorized';

const DetailPage = () => {
  const { isBrowser } = useIsSsr();
  const { id, slug } = useParams();

  const { t } = useTranslation();
  const { state } = useContext(UserContext);
  const { isModalOpen, closeModal } = useContext(ModalContext);
  const showCreateModal = useSelector((state) => state.create.showModal);
  const [relatedProjects, setRelatedProjects] = useState([]);
  const dispatch = useDispatch();
  const createState = useSelector((state) => state.create);
  // const [toggleSlider, setToggleSlider] = useState(false);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
  }

  const viewerScript =
    'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js';
  const viewerState = useExternalScript(viewerScript);

  // Download a uniq asset
  const [downloadProject, { data: assetData }] = useLazyQuery(DOWNLOAD_PROJECT, {
    context: { clientName: 'authorized' }
  });
  const [projectData, setProjectData] = useState({ isDataSet: false });

  const { data: categoriesData } = useQuery(GET_CATEGORIES, {
    skip: !createState.isEdit,
    context: { clientName: 'public' }
  });
  // setSlideIndex(0);

  const addUrlToFilterData = (categories, type) => {
    return categories.map((category) => {
      let url = ``;
      if (!type) {
        if (category.id === '1') url = `/architecture-engineering-construction`;
        if (category.id === '4') url = `/product-design-manufacturing`;
        if (category.id === '3') url = `/media-entertainment`;
      } else if (type) {
        url = `/search?keywords=${category.name}`;
      }
      return {
        ...category,
        url: url
      };
    });
  };

  const handleDownload = (asset_id) => {
    const asset_id_array = [parseInt(asset_id)];
    downloadProject({
      variables: {
        id: parseInt(id),
        asset_ids: asset_id_array
      }
    });
  };

  const getDownloads = (assets = []) => {
    const downloadableAssets = [];

    assets.forEach((asset, i) => {
      if (asset.downloadable === true && (asset.type === '3D' || asset.type === 'file')) {
        downloadableAssets.push({
          type: asset.type,
          onSelect: () => {},
          imageUrl: asset.image?.path,
          id: asset.id,
          order: i,
          selectable: true,
          actions: [
            {
              type: 'text',
              label: '' // TODO REMOVE FAKER
            },
            {
              type: 'button',
              // onClick: () => onDownloadFile(asset.download_url, asset.display_name),
              onClick: () => handleDownload(asset.id),
              iconId: 'dhig--icons--actions--download',
              iconTitle: 'download',
              label: 'create_block_list_item_delete'
            }
          ],
          title: asset.display_name
        });
      }
    });
    return downloadableAssets;
  };

  // Page data Query
  const { error, data, loading, refetch } = useQuery(PROJECT_QUERY, {
    context: { clientName: 'authorized' },
    variables: {
      id: parseInt(id, 10),
      slug: slug,
      projectCount: 6
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.project) {
        setProjectData({
          isDataSet: true,
          isLiked: data.project.is_liked,
          isBookmarked: data.project.is_bookmarked,
          isReported: data.project.reported,
          isAuthor: data.project.is_author,
          canEdit: data.project.can_edit,
          campaign: data.project.campaign,
          status: data.project.status,
          id: data.project.id,
          authorId: data.project.author.id,
          username: data.project.author.slug,
          seo: {
            title: data.project.seo.title,
            description: data.project.seo.description,
            image: data.project.seo.image
          },
          heroData: {
            ...data.project.heroData,
            id: data.project.id,
            type: 'hero',
            title: data.project.title,
            description: data.project.description,
            date: new Date(data.project.published_at || data.project.created_at),
            avatarProps: {
              name: `${data.project.author.display_name}`,
              profilePicture: data.project.author.avatar_big,
              profileUrl: `/profile/${data.project.author.slug}`,
              subtitle: data.project.author.job_title,
              slug: data.project.author.slug
            },
            image: {
              src: data.project?.banner?.path,
              alt: data.project.title,
              cropData: {
                width: 904,
                height: 413,
                x: data.project?.banner?.crop_x || 0.5,
                y: data.project?.banner?.crop_y || 0.5,
                z: data.project?.banner?.crop_z || 1
              },
              srcSet: data.project?.banner?.sizes
            },
            showSearchbar: false
          },
          blocks: data.project.assets.map((el) => createBlockToViewModel(el, createState.isEdit)),
          footer: {
            avatarProps: {
              name: `${data.project.author.display_name}`,
              profilePicture: data.project.author.avatar_big,
              profileUrl: `/profile/${data.project.author.slug}`,
              subtitle: data.project.author.job_title
            },
            industries: addUrlToFilterData(data.project.industries),
            tags: addUrlToFilterData(data.project.tags, 'tags'),
            products: addUrlToFilterData(data.project.products, 'products')
          },
          downloads: getDownloads(data.project.assets)
        });
        if (data.project.can_edit) {
          // set redux state in case current user is able to edit it
          dispatch(fetchCreateDetailsSetStoreDataAction(data.project));
        }
      }
      if (data?.relatedProjects) {
        setRelatedProjects(
          data.relatedProjects.data.map((project) => ({
            project: { id: project.id, isLiked: project.is_liked },
            title: project.title,
            image: {
              src: project.image?.path,
              alt: project.title,
              srcSet: project.image?.sizes
            },
            author: {
              url: `/profile/${project.author?.slug}`,
              name: `${project.author?.display_name}`,
              image: {
                src: project.author?.avatar_big,
                alt: project.author?.display_name
              },
              slug: project.author?.slug
            },
            linkUrl: `/project/${project.id}/${project.slug}`
          }))
        );
      }
    }
  });

  const { data: contextualBannerData } = useQuery(GET_CONTEXTUAL_BANNER, {
    context: { clientName: 'authorized' },
    variables: {
      project_id: parseInt(id, 10)
    }
  });

  useEffect(() => {
    if (isBrowser) {
      if (assetData) {
        onDownloadFile(assetData.projectDownload[0].url, assetData.projectDownload[0].filename);
      }
    }
  }, [assetData]);

  useEffect(() => {
    if (createState.isEdit) {
      const categories = {};
      if (categoriesData) {
        categories.industries = categoriesData.industries
          .filter((i) => createState.industries.includes(parseInt(i.id)))
          .map((i) => ({ id: i.id, name: i.name }));
        const allTags = [].concat(...categoriesData.industries.map((c) => c.tags));
        categories.tags = allTags
          .filter((t) => createState.tags.includes(parseInt(t.id)))
          .map((t) => ({ id: t.id, name: t.name }));
        categories.products = categoriesData.products
          .filter((p) => createState.products.includes(parseInt(p.id)))
          .map((p) => ({ id: p.id, name: p.name }));
      }

      setProjectData({
        ...projectData,
        heroData: {
          ...projectData.heroData,
          title: createState.title,
          description: createState.description,
          image: {
            src: createState.cover.File,
            alt: createState.title,
            cropData: {
              ...createState.cover.banner
            }
          }
        },
        footer: {
          ...projectData.footer,
          ...categories
        },
        tags: createState.tags,
        products: createState.products,
        industries: createState.industries,
        blocks: createState.blocks
      });
    }
  }, [
    createState.isEdit,
    projectData.isDataSet,
    createState.title,
    createState.description,
    createState.tags,
    createState.products,
    createState.industries,
    createState.cover,
    createState.blocks,
    createState.blocks.length,
    createState.cover?.File
  ]);

  useEffect(() => {
    return () => {
      dispatch(emptyCreateStateAction());
    };
  }, []);

  const hasDisabledBlocks = (projectData?.blocks || [])
    .map((block) => block.isDisabled)
    .some((b) => b === true);

  // Memoize variables
  const memoizedProject = useMemo(() => {
    return { totalBlocks: projectData?.blocks?.length, ...projectData };
  }, [projectData]);
  const memoizedCreateState = useMemo(() => createState, [createState]);
  const memoizedRelatedProjects = useMemo(() => relatedProjects, [relatedProjects]);

  const memoizedSlides = useMemo(() => {
    let slidesArr = [];
    let slidesTypes = [];

    if (projectData?.blocks?.length && !createState.isEdit) {
      projectData?.blocks
        .sort((a, b) => a.order - b.order)
        .map((item) => {
          if (item.type === '3D') {
            slidesArr.push(
              <SlidersWrapper slideType="3dviewer">
                <MemoizedBlock3dViewer key={item.id} slug={memoizedProject.id} {...item} />
              </SlidersWrapper>
            );
            slidesTypes.push(null);
          }
          if (item.type === 'embed') {
            slidesArr.push(
              <SlidersWrapper slideType="embed">
                <MemoizedBlockEmbed key={item.id} embedUrl={item.embedUrl} />{' '}
              </SlidersWrapper>
            );
            slidesTypes.push('video');
          }
          if (item.type === 'raas_embed') {
            slidesArr.push(
              <SlidersWrapper slideType="raas">
                <MemoizedBlockRaasEmbed key={item.id} {...item} />{' '}
              </SlidersWrapper>
            );
            slidesTypes.push(null);
          }

          if (item.type === 'image') {
            if (item.addedFrom === undefined) {
              if (item.imageSizes === undefined) {
                slidesArr.push(item.image.path);
              } else {
                slidesArr.push(item.imageSizes[0].path);
              }
            }
          }
          slidesTypes.push('image');
        });
    }

    return { slides: slidesArr, types: slidesTypes };
  }, [projectData, createState.isEdit]);

  if (loading) {
    return (
      <ProgressWrapper>
        <Progress size="medium" />
      </ProgressWrapper>
    );
  }

  if (!state.loadingToken && error) {
    return <Unauthorized />;
  }

  return (
    data && (
      <>
        <Helmet
          title={`${data.project.seo.title} | Autodesk Community Gallery`}
          meta={[
            {
              property: 'og:site_name',
              content: 'Autodesk Community Gallery'
            },
            {
              property: 'og:image',
              content: data.project.seo.image
            },
            {
              property: 'og:url',
              content: `https://www.autodesk.com/community/gallery/project/${id}/${slug}`
            },
            {
              property: 'og:title',
              content: `${data.project.seo.title} | Autodesk Community Gallery`
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:description',
              content: `Come and see ${data.project.author.display_name}'s Project on the Autodesk Community Gallery`
            }
          ]}
          description={`Come and see ${data.project.author.display_name}'s Project on the Autodesk Community Gallery`}
        />
        {isModalOpen && <SignInAlert onClose={closeModal} />}
        {showCreateModal && <CreateModal />}

        {!loading && projectData.isDataSet && (
          <>
            <MemoizedDetailHeader
              refetchData={refetch}
              createState={memoizedCreateState}
              projectData={memoizedProject}
              hasDisabledBlocks={hasDisabledBlocks}
            />
            {memoizedProject.heroData && (
              <Banner
                isEdit={createState.isEdit}
                variant="detail"
                {...memoizedProject.heroData}
                actionProps={{ onClick: () => openLightboxOnSlide(1) }}
              />
            )}

            {memoizedProject.blocks && (
              <BlocksWrapper>
                {!createState.isEdit && (
                  <FsLightbox
                    toggler={lightboxController.toggler}
                    sources={memoizedSlides.slides}
                    type="image"
                    slide={lightboxController.slide}
                  />
                )}
                <BlockText text={memoizedProject.heroData.description} />
                {memoizedProject.blocks
                  .sort((a, b) => a.order - b.order)
                  .map((item, i) => {
                    if (item.type === '3D' && viewerState === 'ready') {
                      if (item?.addedFrom === 'from_lib') {
                        return <ConnectedBlock3dViewer key={item.id} {...item} />;
                      }
                      return (
                        <MemoizedBlock3dViewer key={item.id} slug={memoizedProject.id} {...item} />
                      );
                    }
                    if (item.type === 'embed') {
                      if (item?.addedFrom === 'from_lib') {
                        return <ConnectedBlockEmbed key={item.id} {...item} />;
                      }
                      return <MemoizedBlockEmbed key={item.id} embedUrl={item.embedUrl} />;
                    }
                    if (item.type === 'raas_embed') {
                      if (item?.addedFrom === 'from_lib') {
                        return <ConnectedBlockRaasEmbed key={item.id} {...item} />;
                      }
                      return <MemoizedBlockRaasEmbed key={item.id} {...item} />;
                    }
                    if (item.type === 'image') {
                      if (item?.addedFrom === 'from_lib') {
                        return (
                          <ConnectedBlockImage
                            key={item.id}
                            {...item}
                            isEdit={createState.isEdit}
                          />
                        );
                      }
                      return (
                        <MemoizedBlockImage
                          onOpenSlideShow={() => openLightboxOnSlide(i + 1)}
                          key={item.id}
                          {...item}
                          isSlideshow={false}
                          isEdit={createState.isEdit}
                        />
                      );
                    }
                  })}
                {memoizedProject.downloads?.length > 0 &&
                  !createState.isEdit &&
                  memoizedProject.status === 'published' && (
                    <DownloadButtonWrapper data-wat-loc="community gallery">
                      <DownloadSection
                        projectId={memoizedProject.id}
                        downloadableItems={memoizedProject.downloads}
                        creator={memoizedProject.display_name}
                        isAuthenticated={state.isAuthenticated}
                      />
                    </DownloadButtonWrapper>
                  )}
                {contextualBannerData?.banner && (
                  <ContextualBanner {...contextualBannerData.banner} />
                )}
              </BlocksWrapper>
            )}
            {memoizedProject.footer && <MemoizedDetailFooter {...memoizedProject.footer} />}
            {memoizedRelatedProjects.length > 0 && (
              <RelatedContentWrapper>
                <RelatedContentContainer>
                  <Typography
                    variant="headline-small"
                    component="h3"
                    sx={{
                      mb: '16px'
                    }}
                  >
                    {t('create_related_content_title')}
                  </Typography>
                  <ThumbnailGrid
                    isLoading={false}
                    name="related"
                    thumbnails={memoizedRelatedProjects}
                    gridType="relatedContent"
                    username={projectData.display_name}
                  />
                </RelatedContentContainer>
              </RelatedContentWrapper>
            )}
          </>
        )}
      </>
    )
  );
};

export default DetailPage;
