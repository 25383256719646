import { gql } from '@apollo/client';
import { THUMBNAIL_FRAGMENT } from './fragments';

export const LISTING_QUERY = gql`
  ${THUMBNAIL_FRAGMENT}
  query Projects($order: String, $filters: projectFiltersInput, $page: Int!, $limit: Int!) {
    projects(limit: $limit, page: $page, order: $order, filters: $filters) {
      total
      has_more_pages
      data {
        ...ThumbnailFragment
      }
    }
    industries {
      id
      name
      tags {
        id
        name
        industry {
          id
        }
      }
    }
    products {
      id
      name
    }
  }
`;

export const GET_CATEGORIES = gql`
  query Categories {
    industries {
      id
      name
      tags {
        id
        name
      }
    }
    products {
      id
      name
    }
  }
`;

export const GET_ASSET = gql`
  query GetAsset($id: Int) {
    asset(id: $id) {
      type
      text
      downloadable
      resource {
        size
        file_name
        encoded_urn
        has_viewer
        viewer_setting
      }
      embed {
        link_url
        link_title
        link_image
        link_description
      }
      image {
        display_name
        path
        sizes(formats: ["asset1300", "asset800", "asset600", "asset400"]) {
          path
          width
          height
          format
        }
      }
      created_at
    }
  }
`;

export const GET_ASSETS = gql`
  query GetAssets($limit: Int, $page: Int) {
    assets(limit: $limit, page: $page) {
      data {
        id
        type
        text
        downloadable
        embed {
          link_url
          link_title
          link_description
          link_image
        }
        image {
          path
          display_name
          sizes(formats: ["asset1300", "asset800", "asset600", "asset400"]) {
            path
            width
            height
            format
          }
        }
        resource {
          encoded_urn
          file_name
          has_viewer
          size
          viewer_setting
        }
        is_author
        can_edit
      }
      total
      has_more_pages
    }
  }
`;

export const GET_MY_PROFILE = gql`
  query GetMyProfile {
    myProfile {
      id
      slug
      role
      avatar_small
      avatar_big
      username
      display_name
      email
      first_name
      last_name
      about
      featured
      created_at
      updated_at
      deleted_at
      location
      country
      job_title
      industry
      company
    }
  }
`;

export const GET_PROFILE = gql`
  query user($slug: String) {
    user(slug: $slug) {
      id
      slug
      role
      avatar_small
      avatar_big
      username
      display_name
      email
      first_name
      last_name
      about
      featured
      created_at
      updated_at
      deleted_at
      location
      country
      job_title
      industry
      company
      khoros_id
    }
  }
`;

export const GET_MY_PROJECTS_TOTAL = gql`
  query getProjectsTotal($status: String!) {
    myProjects(filters: { status: $status }) {
      total
    }
  }
`;

export const GET_MY_BOOKMARKS = gql`
  ${THUMBNAIL_FRAGMENT}
  query getBookmarks($slug: String, $limit: Int, $page: Int) {
    user(slug: $slug) {
      bookmarks(limit: $limit, page: $page) {
        data {
          ...ThumbnailFragment
        }
        total
        last_page
        has_more_pages
      }
    }
  }
`;

export const GET_MY_PROJECTS = gql`
  ${THUMBNAIL_FRAGMENT}
  query myProjects($status: String!, $limit: Int, $page: Int) {
    myProjects(filters: { status: $status }, limit: $limit, page: $page) {
      data {
        ...ThumbnailFragment
      }
      total
      last_page
      has_more_pages
    }
  }
`;

export const GET_PUBLIC_PROJECTS = gql`
  ${THUMBNAIL_FRAGMENT}
  query projects($authors: [Int]!, $limit: Int, $page: Int) {
    projects(filters: { authors: $authors }, limit: $limit, page: $page) {
      data {
        ...ThumbnailFragment
      }
      total
      last_page
      has_more_pages
    }
  }
`;

export const GET_ACCESS_TOKEN = gql`
  query GetAccessToken {
    publicToken {
      access_token
      expires_in
    }
  }
`;

export const DOWNLOAD_PROJECT = gql`
  query DownloadProject($id: Int, $asset_ids: [Int]) {
    projectDownload(id: $id, asset_ids: $asset_ids) {
      filename
      url
      sha1
    }
  }
`;

export const GET_SEARCH_AUTOCOMPLETE_OPTIONS = gql`
  query GetSearchAutocompleteOptions($keywords: String) {
    searchAutocomplete(keywords: $keywords) {
      title
      slug
      description
    }
  }
`;

export const GET_SEARCH_PAGE_RESULTS = gql`
  ${THUMBNAIL_FRAGMENT}
  query GetSearchPageResults(
    $keywords: String!
    $order: String
    $filters: searchFiltersInput
    $page: Int!
    $limit: Int
  ) {
    search(keywords: $keywords, limit: $limit, page: $page, order_by: $order, filters: $filters) {
      total
      current_page
      has_more_pages
      data {
        ...ThumbnailFragment
      }
    }
    industries {
      id
      name
      tags {
        id
        name
        industry {
          id
        }
      }
    }
    products {
      id
      name
    }
  }
`;

export const ASSET = gql`
  query publicAsset($id: Int!, $project_id: Int!) {
    publicAsset(id: $id, project_id: $project_id) {
      id
      type
      downloadable
      resource {
        size
        file_name
        encoded_urn
        has_viewer
        viewer_setting
      }
    }
  }
`;

export const GET_CONTEXTUAL_BANNER = gql`
  query getContextualBanner($project_id: Int!) {
    banner(project_id: $project_id) {
      id
      title
      image_url
      description
      cta_link {
        label
        url
      }
    }
  }
`;
