import PropTypes from 'prop-types';
import { StyledImage } from './ImageWithSrcSet.styled';

const ImageWithSrcSet = ({ src, alt, srcSet, size, isBackground, lazy }) => {
  const mediaQueries = {
    mobile: '(max-width:576px)',
    tablet: '(min-width:9999px)',
    cardXl: size === 'xl' ? '(min-width:576px)' : '(min-width:9999px)',
    cardLg: '(min-width:9999px)',
    cardMd: size === 'md' ? '(min-width:576px)' : '(min-width:9999px)',
    cardSm: size === 'sm' ? '(min-width:576px)' : '(min-width:9999px)',
    cardXs: '(max-width:576px)',
    preview128: '(min-width:9999px)',
    preview72: '(min-width:9999px)'
  };

  return (
    <picture>
      {srcSet?.map((srcSet) => {
        return (
          <source key={srcSet.path} srcSet={srcSet.path} media={mediaQueries[srcSet.format]} />
        );
      })}
      <StyledImage
        src={src}
        alt={alt}
        loading={lazy ? lazy : undefined}
        isBackground={isBackground}
      />
    </picture>
  );
};

ImageWithSrcSet.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.string,
  lazy: PropTypes.bool,
  srcSet: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      format: PropTypes.string.isRequired
    })
  ),
  isBackground: PropTypes.bool
};

export default ImageWithSrcSet;
