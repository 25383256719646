import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const StepWrapper = styled.div`
  margin-top: ${lightTheme.primitives.spacings['4']};
  h5 {
    margin-bottom: ${lightTheme.primitives.spacings['4']};
    font-size: ${lightTheme.primitives.fontSizes['4']} !important;
  }
  .MuiFormLabel-root {
    font-family: ArtifaktElement, Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0px;
    color: #212121;
    margin: 0;
    margin-bottom: ${lightTheme.primitives.spacings['4']};
  }
  .slider-button-create-cover {
    @media screen and (max-width: 768px) {
      align-self: center;
    }
  }
  .cover-segment-control {
    width: 15%;
  }
  display: flex;
  flex-direction: column;
  gap: ${lightTheme.primitives.spacings['4']};
  .categories_multiselect {
    margin-bottom: ${lightTheme.primitives.spacings['4']};
    .MuiFormControl-root {
      height: auto;
      background-color: transparent;
      .MuiInputBase-root {
        padding-top: 0;
        padding-bottom: 0;
        max-width: 586px;
        height: auto;
        min-height: 40px;
      }
      label {
        margin-bottom: 0;
      }
      textarea {
        padding: 0;
      }
    }
  }
`;
