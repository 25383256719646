/* eslint-disable react/prop-types */
// TODO: find a way to add with react memo
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClientNotification from '../../components/ClientNotification';

const ClientNotificationProvider = React.memo((props) => {
  const { children, clientNotifications } = props;

  return (
    <>
      {clientNotifications.length >= 1 && (
        <>
          {clientNotifications.map((notif) => {
            return (
              <ClientNotification
                key={notif.id}
                type={notif?.type}
                text={notif?.text}
                extras={notif?.extras}
                autoDismiss={notif?.autoDismiss}
                closable={notif?.closable}
              />
            );
          })}
        </>
      )}
      {children}
    </>
  );
});

ClientNotificationProvider.propTypes = {
  children: PropTypes.node,
  clientNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      type: PropTypes.string,
      text: PropTypes.string,
      extras: PropTypes.arrayOf(PropTypes.shape({})),
      autoDismiss: PropTypes.bool,
      closable: PropTypes.bool,
      onClose: PropTypes.func
    })
  ),
  dismissClientNotification: PropTypes.func
};

const mapStateToProps = (state) => ({
  clientNotifications: state.clientNotifications
});

export default connect(mapStateToProps)(ClientNotificationProvider);
