import React from 'react';
import Button from '@digital-hig/button';
import { t } from 'i18next';
import { LinkWrapper } from './NoResultLinks.styled';

const NoResultLinks = () => {
  return (
    <>
      <LinkWrapper>
        <Button href="https://www.autodesk.com/community/gallery" variant="cardAction">
          {t('no_result_link_gallery')}
        </Button>
      </LinkWrapper>
      <LinkWrapper>
        <Button href="https://forums.autodesk.com/" variant="cardAction">
          {t('no_result_link_community')}
        </Button>
      </LinkWrapper>
      <LinkWrapper>
        <Button href="https://www.autodesk.com/" variant="cardAction">
          {t('no_result_link_autodesk')}
        </Button>
      </LinkWrapper>
    </>
  );
};

export default NoResultLinks;
