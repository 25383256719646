import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const BuildSectionWrapper = styled.div`
  @media screen and (min-width: ${lightTheme.primitives.grid.scale['5']}) {
    display: flex;
    gap: ${lightTheme.primitives.spacings['6']};
  }
`;

export const SectionWrapper = styled.div`
  @media screen and (min-width: ${lightTheme.primitives.grid.scale['5']}) {
    min-width: 300px;
    width: fit-content;
    display: flex;
    flex-direction: column;
  }
  .section-subtitle {
    margin-top: ${lightTheme.primitives.spacings['2']} !important;
  }
  .section-layout-content {
    margin-bottom: ${lightTheme.primitives.spacings['6']};
  }
  .embed-media-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${lightTheme.primitives.spacings['6']};
  }
  .embed-media-section-input-wrapper {
    display: flex;
    gap: ${lightTheme.primitives.spacings['6']};
  }
  .embed-media-input-error {
    color: ${lightTheme.primitives.colors.accent.clay};
    line-height: 1;
    font-size: 12px !important;
  }
  ${({ isAssets }) =>
    isAssets &&
    css`
      background-color: ${lightTheme.primitives.colors.tint.slate - 100};
      border-radius: ${lightTheme.primitives.borderRadii.large};
      @media screen and (min-width: ${lightTheme.primitives.grid.scale['5']}) {
        padding: ${lightTheme.primitives.spacings['4']};
      }
    `}
`;

export const TabsWrapper = styled.div`
  margin-top: ${lightTheme.primitives.spacings['6']};
  border-radius: ${lightTheme.primitives.borderRadii.large};
  overflow: hidden;
  border: 1px solid rgba(204, 204, 204, 0.5);
  .section-tab {
    display: flex;
    justify-content: center;
    gap: ${lightTheme.primitives.spacings['2']};
  }
`;

export const TabSection = styled.div`
  background: #ffffff;
  align-items: center;
  overflow-y: auto;

  padding: ${lightTheme.primitives.spacings['5']} 0px;
  box-shadow: inset 0px -1px 0px ${lightTheme.primitives.colors.detail['slate-dark']};
  min-height: 202px;
  max-height: 303px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['5']}) {
    padding: ${lightTheme.primitives.spacings['7']};
  }
  .empty-library-section {
    text-align: center;
    > p {
      margin-bottom: ${lightTheme.primitives.spacings['6']};
    }
  }
`;

export const EmptySectionContent = styled.div`
  // text-align: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  small {
    margin-top: ${lightTheme.primitives.spacings['4']};
  }
`;
