export const fr = {
  en: 'English',
  fr: 'Français',
  generic_delete_label: 'Supprimer',
  example_translation_string:
    "Bonjour, c'est un exemple de string, la langue en cours est {{language}}",
  client_notification_error_title: "Il y'a une erreur",
  client_notification_info_title: 'Information',
  client_notification_success_title: 'Bravo 🎉',
  client_notification_reportProject: "Thank you, we've recieved tour report",
  client_notification_UpdateProjectsStatus_success: 'Project status updated succesfully',
  thumbnail_like_label: 'Like',
  navigation_title: 'Galerie',
  navigation_item1: 'Architecture, ingénierie & construction',
  navigation_item2: 'Conception & fabrication de produits',
  navigation_item3: 'Médias & divertissement',
  navigation_item4: 'Mon profil',
  navigation_item5: `Page d'administration`,
  stepped_progress_bar_step: 'Étape {{value}}',
  file_upload_label_large: 'Déposez vos fichiers ici, ou',
  file_upload_button_label_files: 'Choisissez vos fichiers',
  file_upload_button_label_loading: 'Chargement des fichiers...',
  file_upload_drag_over: 'Déposez vos fichiers ici',
  file_upload_error_invalid_format_some:
    "Certains de vos fichiers n'ont pas pu être déposés. Raison: format invalide. (Accepté(s): {{extra}})",
  file_upload_error_invalid_format_all:
    "Vos fichiers n'ont pas pu être déposés. Raison: format invalide. (Accepté(s): {{extra}})",
  create_block_list_item_type_image: 'Image',
  create_block_list_item_type_embed: 'Média Intégré',
  create_block_list_item_type_3D: 'Objet 3D',
  loading_project_status_1: 'On y est presque {{name}}!',
  loading_project_status_2: 'Cela peut prendre quelques minutes',
  project_loaded_success_1: 'Felicitations!',
  project_loaded_success_2: 'Votre projet est prêt!',
  loading_project_status_percentage: 'Publication du projet',
  detail_footer_industries_list_title: 'Industries',
  detail_footer_products_list_title: 'Produits',
  detail_footer_tags_list_title: 'Tags',
  create_content_status_deleted: 'Supprimé',
  create_content_status_rejected: 'Rejetté',
  create_content_status_pending: 'En validation',
  create_content_status_draft: 'Brouillon',
  create_content_status_published: 'Publié',
  create_content_edit_project: 'Éditer le projet',
  create_content_like_project: "J'aime",
  create_content_liked_project: "Je n'aime plus",
  create_content_bookmark_project: 'Marque page',
  create_content_bookmarked_project: 'Enlever marque page',
  create_content_share_project: 'Partager',
  create_content_cancel_edit_project: 'Annuler',
  create_content_publish_project: 'Publier le projet',
  create_content_publish_edited_project: 'Publier les changements',
  create_content_save_draft_project: 'Sauvegarder le brouillon',
  create_content_delete_project: 'Supprimer',
  jan: 'Jan',
  feb: 'Fev',
  mar: 'Mar',
  apr: 'Avr',
  may: 'Mai',
  jun: 'Jui',
  jul: 'Juil',
  aug: 'Aou',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',
  actions_zoom_out: 'Réduire',
  actions_zoom_in: 'Agrandir',
  create_content_edit_project_details_label: 'Éditer les details',
  create_content_edit_project_build_label: 'Structure',
  pagination_gallery_label: '{{itemsCount}} de {{totalItems}} galleries',
  pagination_project_label: '{{itemsCount}} of {{totalItems}} projets',
  home_featured_members: 'Membres en vedette',
  clear_all_filters: 'Supprimer les filtres',
  filters_featured: 'Projets mis en avant',
  filters_most_relevant: 'Pertinence',
  filters_most_recent: 'Les plus récents',
  filters_trending: 'Tendance',
  create_your_project_btn_label: 'Créez votre projet',
  filters_industries_label: 'Industries',
  filters_industries_placeholder: 'Sélectionnez des industries...',
  filters_tags_label: 'Étiquettes',
  filters_tags_placeholder: 'Sélectionnez des étiquettes...',
  filters_products_label: 'Outils',
  filters_products_placeholder: 'Sélectionnez des outils...',
  search_results_for: 'Résultats pour',
  search_results_count: 'Affichage de {{count}} sur {{total}} résultats',
  search_no_results_count: 'Aucun résultat.',
  search_no_results_title: 'Aucun résultat à afficher.',
  search_no_results_description:
    'Essayez à nouveau votre recherche ou utilisez les liens suivants pour explorer nos produits et services.',
  file_upload_formats: 'Fichiers supportés',
  file_upload_max_size: "La taille max d'un fichier est {{size}}MB",
  file_upload_error_invalid_file_size:
    "Certains de vos fichiers n'ont pas pu être déposés. Raison: {{extra}}",
  create_block_list_downloadable_item_label: 'Autoriser le téléchargement du modèle',
  create_detail_download_terms:
    "La licence et les autres conditions de contribution et d'utilisation des ressources de l'Autodesk Community. Gallery se trouvent dans les conditions d'utilisation d'Autodesk.",
  create_detail_download_download_items_btn: 'Télécharger les fichiers',
  create_project_build_embed_media: 'Médias intégrés',
  create_project_build_my_assets: 'Ma bibliothèque',
  create_project_build_upload: 'Télécharger',
  create_related_content_title: "Explorer d'autres galeries",
  download_section_button_label: 'Télécharger le(s) modèle(s) {{size}}',
  block_list_selected_items: 'Elément(s) sélectionné(s) {{count}}',
  download_section_modal_title: 'Téléchargez les modèles de ce projet',
  library_select_file_types_label: "Type d'élément",
  library_file_type_all: 'Tous',
  library_file_type_embed: 'Intégré',
  library_file_type_image: 'Image',
  library_file_type_asset: 'Elément 3D',
  create_modal_title_new: 'Nouveau projet',
  create_modal_title_edit_draft: 'Editez Votre brouillon',
  create_modal_title_edit_published: 'Construisez votre projet',
  create_modal_title_edit_rejected: 'Construisez votre projet',
  create_cover_form_title: 'Créez une image de couverture',
  create_modal_cancel_button_label: 'Annuler',
  create_modal_prev_button_label: 'Retour',
  create_modal_next_button_label: 'Suivant',
  create_information_form_title_label: 'Titre',
  create_information_form_description_label: 'Description',
  create_information_form_title_line_1: 'Salut{{name}}',
  create_information_form_title_line_2: 'parles-nous de ton projet',
  create_categories_form_title: 'Choisissez vos catégories',
  create_modal_unsaved_title_cancel_label: 'Annuler',
  create_modal_unsaved_title_confirm_label: 'Confirmer',
  create_modal_unsaved_title_edit: 'Votre projet a des changements non-sauvés',
  create_modal_unsaved_content_edit:
    "Si vous choisissez de quitter l'édition, vous risquez de perdre les derniers changements faits. Êtes-vous sûr de vouloir quitter?",
  create_modal_unsaved_title_new: 'Projet non créé',
  create_modal_unsaved_content_new:
    "Vous n'avez pas fini de créer votre projet. Êtes-vous sûr de vouloir quitter?",
  create_categories_industry_form_label: 'Industrie',
  create_categories_products_form_label: 'Produits',
  create_categories_tags_form_label: 'Tags {{hintText}}',
  industries_architecture_engineering_construction_label: 'Architecture, Ingénierie & Construction',
  industries_product_design_manufacturing_label: 'Conception de produits & Fabrication',
  industries_media_entertainment_label: 'Médias et Divertissement',
  create_cover_thumbnail_title: 'Vignette',
  create_cover_banner_title: 'Bannière',
  project_loaded_error_1: "Il y'a eu une erreur",
  project_loaded_error_2: 'Plus de détails: {{error}}',
  file_upload_button_label_button: 'Ajouter',
  file_upload_button_label_large: 'Parcourir les fichiers',
  share_text_notification: "L'URL a été copiée dans votre presse-papiers.",
  client_notification_LikeProject_success: 'Vous avez aimé ce projet',
  client_notification_BookmarkProject_success: 'Vous avez enregistré ce project',
  file_upload_label_files: 'Glissez & déposez vos fichiers ici, ou',
  create_modal_publish_next_button_label: 'Publier',
  create_modal_edit_next_button_label: 'Appliquer les changements',
  create_modal_edit_details_next_button_label: 'Construisez votre projet',
  create_build_assets_section_title: 'Eléments',
  create_build_assets_section_subtitle:
    'Sélectionnez des éléments depuis votre bibliotheque ou téléchargez de nouveaux éléments et ajoutez-les à votre projet',
  create_build_layout_section_title: 'Mise en page',
  create_build_layout_section_subtitle:
    'Déplacez les élément pour avoir votre projet bien organisé.',
  create_build_assets_section_my_assets_empty_title: 'Votre bibliothèque est vide',
  create_build_assets_section_my_assets_empty_subtitle:
    'Vous pouvez télécharger directement vos éléments ici',
  create_build_layout_empty_title: 'Le projet ne contient aucun élément',
  create_build_layout_empty_subtitle: 'Commençons par ajouter des élément',
  create_build_layout_empty_new_project_title: 'Votre nouveau projet est prêt!',
  create_build_layout_empty_new_project_subtitle: 'Commençons par ajouter des élément',
  create_build_assets_embed_label: 'Vidéos intégrées et modèles depuis {{accepted}}',
  create_build_embed_submit_label: 'Ajouter',
  create_build_invalid_embed_error: 'Lien vers la resource invalide',
  create_step_project_info: 'Informations de base',
  create_step_project_categories: 'Catégories',
  create_step_project_cover: 'Image de couverture',
  create_publish_success_draft: 'Votre projet a été sauvé comme brouillon',
  create_publish_success_publish: 'Projet mis à jour avec succès',
  create_publish_deleted_project:
    'Ce projet a été supprimé ou rejetté par un administrateur, il sera définitivement supprimé dans peu de temps.',
  create_publish_success_pending:
    'Votre projet est en cours de vérification, cela peut prendre du temps (ou pas).',
  create_delete_project_confirm_title: 'Supprimer le projet ?',
  create_delete_project_confirm_description: 'Êtes-vous sûr de vouloir supprimer ce projet ?',
  create_publish_loading: 'Votre projet se met à jour, veuillez attendre...',
  create_blocks_finalize_asset: 'Votre élément est en cours de téléversement',
  pagination_assets_label: '{{itemsCount}} de {{totalItems}} éléments',
  error_404_error: 'Erreur 404',
  error_404_title: "Nous n'arrivons pas à trouver cette page.",
  error_404_description:
    'Il y a beaucoup de choses à explorer ici, et nous voulons vous aider à les trouver. Veuillez réessayer votre recherche ou utiliser les liens ci-dessous pour voir nos produits et services les plus populaires.',
  no_result_link_gallery: 'Aller à la Galerie Communautaire',
  no_result_link_community: 'Aller à la Communauté',
  no_result_link_autodesk: 'Aller sur Autodesk.com',
  modal_filters: 'Filtres',
  modal_filters_label: 'Filtres',
  modal_filters_title: 'Filtres',
  asset_loading_in_back_short: 'Cet élément est en cours de finalisation',
  asset_loading_in_back_long:
    'Votre élément a été importé avec succès, il est en cours de finalisation sur nos serveurs, essayez de revenir plus tard',
  generic_optionnal_field: '(optionnel)',
  profile_tab_published: 'Projets {{count}}',
  profile_tab_pending: 'Projets en attente{{count}}',
  profile_tab_draft: 'Mes brouillons{{count}}',
  profile_tab_bookmarks: 'Mes marques-page{{count}}',
  profile_tab_deleted: 'Supprimmés récemment{{count}}',
  download_section_button_label_not_auth: 'Connectez-vous pour télécharger',
  file_upload_mobile_no_image: 'Ajouter une image',
  file_upload_mobile_image: "Éditer l'image"
};
