import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { StepWrapper } from './DetailsSteps.styled';
import TextInput from '@digital-hig/text-input';
import FormControl from '@digital-hig/form-control';
import { t } from 'i18next';
import Typography from '@digital-hig/typography';
import debounce from 'lodash.debounce';

function Details(props) {
  const { onChange, isEdit, data, error } = props;
  const { title, description, userName } = data;
  const debouncedHandleChange = useMemo(() => debounce((v) => onChange(v), 300), []);

  function handleChange(key, event) {
    if (event.target.value.length >= 1) {
      debouncedHandleChange({ key, ...event });
    } else {
      onChange({ key, ...event });
    }
  }

  function removeHTMLTags(inputText) {
    const regex = /<[^>]+>/g;
    return inputText?.replace(regex, '');
  }

  return (
    <StepWrapper>
      {!isEdit && (
        <Typography component="h5" variant="headline-medium">
          {t('create_information_form_title_line_1', { name: ` ${userName},` || ',' })}
          <br />
          {t('create_information_form_title_line_2')}
        </Typography>
      )}
      <FormControl
        InputLabelProps={{
          shrink: true
        }}
        required
        fullWidth
        onChange={(e) => handleChange('title', e)}
        label={t('create_information_form_title_label')}
      >
        <TextInput required value={title} error={error.allFormFilledOnce && error.title} />
      </FormControl>
      <FormControl
        InputLabelProps={{
          shrink: true
        }}
        fullWidth
        required
        onChange={(e) => handleChange('description', e)}
        label={t('create_information_form_description_label')}
      >
        <TextInput
          inputProps={{
            maxLength: 200
          }}
          value={removeHTMLTags(description)}
          required
          multiline
          rows={4}
          error={error.allFormFilledOnce && error.description}
        />
      </FormControl>
    </StepWrapper>
  );
}

Details.propTypes = {
  onChange: PropTypes.func,
  isEdit: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    userName: PropTypes.string
  }),
  error: PropTypes.shape({
    description: PropTypes.bool,
    title: PropTypes.bool,
    allFormFilledOnce: PropTypes.bool
  })
};

Details.defaultProps = {
  error: {
    description: false,
    title: false,
    allFormFilledOnce: false
  },
  onChange: () => {},
  isEdit: false,
  data: {
    userName: '',
    title: '',
    description: ''
  }
};

export default Details;
