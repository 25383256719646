import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const ProjectStatusWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 306px;
  height: 100%;
  ${({ isError }) =>
    isError &&
    css`
      box-shadow: inset 0 0 0 1px ${lightTheme.primitives.colors.tint['clay-550']};
    `}
`;
