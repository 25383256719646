import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DetailFooterWrapper, DetailFooterWrapperInner } from './DetailFooter.styled';
import Avatar from '../Avatar';
import { useTranslation } from 'react-i18next';
import TagList from '../TagList';

function DetailFooter({ avatarProps, industries, products, tags }) {
  const { t } = useTranslation();

  return (
    <DetailFooterWrapper>
      <DetailFooterWrapperInner>
        <Avatar {...avatarProps} size="x-large" className="avatar" />
        {!!(industries.length >= 1) && (
          <TagList
            size="medium"
            tagList={industries}
            title={t('detail_footer_industries_list_title')}
          />
        )}
        {!!(products.length >= 1) && (
          <TagList
            size="medium"
            tagList={products}
            title={t('detail_footer_products_list_title')}
          />
        )}
        {!!(tags.length >= 1) && (
          <TagList size="medium" tagList={tags} title={t('detail_footer_tags_list_title')} />
        )}
      </DetailFooterWrapperInner>
    </DetailFooterWrapper>
  );
}

DetailFooter.propTypes = {
  avatarProps: PropTypes.shape({
    name: PropTypes.string,
    profilePicture: PropTypes.string.isRequired,
    profilePictureAlt: PropTypes.string,
    profileUrl: PropTypes.string
  }).isRequired,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  )
};

DetailFooter.defaultProps = {
  industries: [],
  products: [],
  tags: []
};

const MemoizedDetailFooter = memo(DetailFooter);

export default MemoizedDetailFooter;
