import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@digital-hig/typography';
import { useNavigate } from 'react-router-dom';
import Avatar from '../Avatar';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import SvgCtaArrowRight from '@digital-hig/icon/lib/build/icons/utility/cta-arrow-right';
import SvgAdd from '@digital-hig/icon/lib/build/icons/utility/add';
import Icon from '@digital-hig/icon';

import {
  StyledAutocomplete,
  BannerWrapper,
  ImageWrapper,
  BannerInner,
  BannerContentWrapper,
  BannerTitleWrapper,
  GalleryButtonWrapper
} from './Banner.styled';
import { dateToShortFormat, useIsMobile } from '../../utils';
import ImageCrop from '../ImageCrop';
import { cropDataPropTypes } from '../../common/types';
import { useLazyQuery } from '@apollo/client';
import { GET_SEARCH_AUTOCOMPLETE_OPTIONS } from '../../apollo/queries';
import debounce from 'lodash.debounce';
import ImageWithSrcSet from '../ImageWithSrcSet';
import SvgIcon from '@digital-hig/svg-icon';
import { Link } from 'react-router-dom';
function Banner({
  isEdit,
  image,
  title,
  subtitle,
  type = 'hero',
  showSearchbar,
  date,
  avatarProps,
  actionProps,
  variant,
  setToggleModal
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const onSearch = (value) => {
    navigate(`/search?keywords=${value}`);
  };

  const [imageSrc, setImageSrc] = useState();
  useEffect(() => {
    if (image?.src) {
      if (typeof image.src === 'string') {
        setImageSrc(image.src);
      }
      if (typeof image.src === 'object') {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const convertedFile = reader.result;
          setImageSrc(convertedFile);
        };
        reader.readAsDataURL(image.src);
      }
    }
  }, [image?.src]);

  // Get autocomplete options
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  const [getSearchAutocompleteOptions, { data }] = useLazyQuery(GET_SEARCH_AUTOCOMPLETE_OPTIONS, {
    context: { clientName: 'public' }
  });

  const debouncedGetSearchAutocompleteOptions = useMemo(
    () => debounce(getSearchAutocompleteOptions, 500),
    []
  );

  useEffect(() => {
    if (data) {
      const options = data.searchAutocomplete.map((item) => item.title);
      setAutocompleteOptions(options);
    }
  }, [data]);

  return (
    <BannerWrapper type={type} data-wat-loc="community gallery" className="dhig-theme--dark">
      <BannerInner type={type} variant={variant}>
        <BannerContentWrapper type={type}>
          <BannerTitleWrapper>
            <Typography component="h1" variant="headline-largest" className="banner-title">
              {title}
            </Typography>

            {!!subtitle && (
              <Typography component="p" variant="body-copy-medium">
                {subtitle}
              </Typography>
            )}
            {!!date && (
              <Typography
                component="p"
                variant="short-copy-medium"
                className="banner-date"
                sx={{ mb: 6 }}
              >
                {dateToShortFormat(date, t)}
              </Typography>
            )}
          </BannerTitleWrapper>
          {showSearchbar && (
            <StyledAutocomplete
              options={autocompleteOptions}
              onKeyUp={(e) => {
                const { value } = e.target;
                if (value.length > 0) {
                  if (e.key === 'Enter') {
                    onSearch(value);
                  }
                  debouncedGetSearchAutocompleteOptions({
                    variables: { keywords: value }
                  });
                }
              }}
              onChange={(e, value) => {
                onSearch(value);
              }}
            />
          )}

          {type === 'banner' && (
            <Button
              size="small"
              href={actionProps.href}
              rel="noopener"
              target="_blank"
              onClick={actionProps.onClick}
              data-wat-val={actionProps.label}
              startIcon={
                <SvgIcon size="medium">
                  <SvgCtaArrowRight />
                </SvgIcon>
              }
              variant="text"
            >
              {actionProps.label}
            </Button>
          )}

          {type === 'campaign' && (
            <div style={{ display: 'flex', gap: '16px' }}>
              <Button
                size="medium"
                variant="outlined"
                onClick={() => setToggleModal()}
                startIcon={
                  <SvgIcon size="medium">
                    <SvgAdd />
                  </SvgIcon>
                }
                data-wat-val="create"
              >
                Create a project
              </Button>
              <Link to={actionProps.href}>
                <Button
                  size="medium"
                  href={actionProps.href}
                  rel="noopener"
                  target="_blank"
                  onClick={actionProps.onClick}
                  data-wat-val={actionProps.label}
                >
                  {actionProps.label}
                </Button>
              </Link>
            </div>
          )}

          {typeof avatarProps === 'object' && type === 'hero' && (
            <Avatar
              {...avatarProps}
              size={variant === 'detail' ? 'large' : 'small'}
              className="hero-banner-avatar"
            />
          )}
        </BannerContentWrapper>
        {typeof avatarProps === 'object' && (type === 'big' || type === 'banner') && (
          <Avatar {...avatarProps} size="small" className="big-banner-avatar" />
        )}
        {variant === 'detail' && !isEdit && (
          <GalleryButtonWrapper>
            <Button
              startIcon={<Icon size="medium" id="dhig--icons--miscellaneous--photos"></Icon>}
              size="dense"
              variant="outlined"
              onClick={actionProps?.onClick}
            >
              Slideshow
            </Button>
          </GalleryButtonWrapper>
        )}
        <ImageWrapper type={type}>
          {isEdit ? (
            <ImageCrop image={imageSrc} cropData={image?.cropData} />
          ) : (
            <ImageWithSrcSet
              src={imageSrc}
              alt={image?.alt}
              srcSet={image?.srcSet}
              size={isMobile ? '100vw' : '904px'}
              isBackground
              lazy={false}
            />
          )}
        </ImageWrapper>
      </BannerInner>
    </BannerWrapper>
  );
}

Banner.propTypes = {
  isEdit: PropTypes.bool,
  classes: PropTypes.object,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    cropData: PropTypes.shape(cropDataPropTypes),
    srcSet: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        format: PropTypes.string.isRequired
      })
    )
  }),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  type: PropTypes.oneOf(['hero', 'big', 'banner']),
  variant: PropTypes.oneOf(['landing', 'detail', 'campaign']),
  showSearchbar: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  avatarProps: PropTypes.shape({
    name: PropTypes.string,
    profilePicture: PropTypes.string.isRequired,
    profilePictureAlt: PropTypes.string,
    profileUrl: PropTypes.string
  }),
  actionProps: PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string
  }),
  setToggleModal: PropTypes.func
};

export default Banner;
