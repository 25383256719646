export const dateToShortFormat = (date = new Date(), translationCb = (e) => e) => {
  const newDate = new Date(date);

  let monthNames = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec'
  ];

  let day = newDate.getDate();

  let monthIndex = newDate.getMonth();
  let monthNameShort = monthNames[monthIndex];

  let year = newDate.getFullYear();

  return `${day} ${translationCb(monthNameShort)} ${year}`;
};
