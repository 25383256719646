import React from 'react';
import PropTypes from 'prop-types';
import { SectionWrapper } from './BuildSections.styled';
import Typography from '@digital-hig/typography';
import { t } from 'i18next';
import { createPropTypes } from '../../../types/create';
import CreateBlockList from '../../../../components/CreateBlockList';

function Layout({ blocks, isNewProject, onReorder, onDeleteBlock, onSelectDownload }) {
  return (
    <SectionWrapper type="layout">
      <div className="section-layout-content">
        <Typography component="h4" variant="headline-small">
          {t('create_build_layout_section_title')}
        </Typography>
        {blocks.length > 0 ? (
          <Typography
            component="p"
            className="section-subtitle"
            variant="smallprint"
            style={{ inlineSize: '300px', overflowWrap: 'break-word' }}
          >
            {t('create_build_layout_section_subtitle')}
          </Typography>
        ) : (
          <>
            <Typography component="small" variant="short-copy-small">
              {t(`create_build_layout_empty${isNewProject ? '_new_project' : ''}_subtitle`)}
            </Typography>
          </>
        )}
      </div>
      {blocks.length >= 1 && (
        <CreateBlockList
          onSelectDownload={onSelectDownload}
          blockList={blocks.map((block) => {
            return {
              ...block,
              actions: [
                {
                  type: 'button',
                  onClick: () => onDeleteBlock(block.id),
                  iconId: 'dhig--icons--actions--trash',
                  iconTitle: 'delete',
                  label: 'create_block_list_item_delete'
                }
              ]
            };
          })}
          onReorder={onReorder}
          allowReorder
        />
      )}
    </SectionWrapper>
  );
}

Layout.propTypes = {
  blocks: createPropTypes.blocks,
  isNewProject: PropTypes.bool,
  onReorder: PropTypes.func,
  onDeleteBlock: PropTypes.func,
  onSelectBlock: PropTypes.func,
  onSelectDownload: PropTypes.func,
  onSetLayoutTab: PropTypes.func
};

Layout.defaultProps = {
  blocks: [],
  isNewProject: false,
  onReorder: () => {},
  onDeleteBlock: () => {},
  onSelectBlock: () => {},
  onSelectDownload: () => {}
};

export default Layout;
