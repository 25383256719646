import React, { useState, useEffect, useRef, useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';
import SignInAlert from '../SignInAlert';
import FilterBar from '../FilterBar';
import Pagination from '../Pagination';
import ThumbnailGrid from '../ThumbnailGrid';
import PropTypes from 'prop-types';
import { generateUniqueId, useIsMobile } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_SEARCH_PAGE_RESULTS, LISTING_QUERY } from '../../apollo/queries';
import Typography from '@digital-hig/typography';
import CreateModal from '../../components/CreateModal';
import { useSelector } from 'react-redux';
import { document } from 'global';
import {
  FilterBarWrapper,
  GridContainer,
  TopGridWrapper,
  BottomGridWrapper,
  PaginationWrapper,
  SearchResultWrapper,
  NoResultWrapper,
  Separator
} from './ProjectListing.styled';
import { useSearchParams } from 'react-router-dom';
import NoResultLinks from '../NoResultLinks';
import { trackSearch } from '../../utils/trackingScripts';
const CATEGORIES_PAGES = [
  'architecture-engineering-construction',
  'product-design-manufacturing',
  'media-entertainment'
];

function getIndustryFilter(name) {
  if (name === CATEGORIES_PAGES[0]) {
    return { industries: [1] };
  }
  if (name === CATEGORIES_PAGES[1]) {
    return { industries: [4] };
  }
  if (name === CATEGORIES_PAGES[2]) {
    return { industries: [3] };
  }
}

function scrollTop() {
  document.documentElement.scrollTo({
    left: 0,
    top: 0,
    behavior: 'smooth'
  });
}

function ProjectListing({ banner, pageType, setToggleModal, name, username }) {
  /* In this page, for each filter there is :
  - a useState for the content of the filter (only if it needs to be displayed in the FilterBar component) (CF)
  - a useState for the state of selected filters (SF)
  - a url searchParam
  
  Onchange inside the FilterBar component => the 'active' variable in CF is updated => the url searchParams is 
  updated => the SF is updated
  */
  const { isModalOpen, closeModal } = useContext(ModalContext);
  const showCreateModal = useSelector((state) => state.create.showModal);
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const [noResult, setNoResult] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [isCategoryPage, setIsCategoryPage] = useState(false);

  useEffect(() => {
    if (CATEGORIES_PAGES.includes(name)) {
      setIsCategoryPage(true);
    }
  }, [name]);

  // Gets search params from url
  const [searchParams, setSearchParams] = useSearchParams();

  // Page filter
  const LISTING_ITEM_PER_PAGE_INIT = pageType === 'search' ? 9 : 11;
  const LISTING_ITEM_PER_PAGE = 9;
  const [paginationData, setPaginationData] = useState({
    totalPageCount: 0,
    currentPage: parseInt(searchParams.get('page')) || 1,
    itemsPerPage: LISTING_ITEM_PER_PAGE_INIT,
    totalItems: 0,
    paginationType: pageType === 'homepage' ? 'project' : 'gallery'
  });

  const onChangePage = (page) => {
    searchParams.set('page', page);
    setSearchParams([...searchParams.entries()]);
  };

  // Keywords filter
  const [keywordsFilter, setKeywordsFilter] = useState(null);

  // Order filter
  const listingOrderOptions = [
    {
      label: t('filters_featured'),
      id: generateUniqueId(),
      slug: 'featured'
    },
    {
      label: t('filters_trending'),
      id: generateUniqueId(),
      slug: 'trending'
    },
    {
      label: t('filters_most_recent'),
      id: generateUniqueId(),
      slug: 'recent'
    }
  ];

  const searchOrderOptions = [
    {
      label: t('filters_most_relevant'),
      id: generateUniqueId(),
      slug: 'relevance'
    },
    {
      label: t('filters_most_recent'),
      id: generateUniqueId(),
      slug: 'recent'
    }
  ];

  const [orderFilter, setOrderFilter] = useState({
    type: 'slider',
    icon: 'dhig--icons--actions--filter',
    active: 0,
    filterType: 'order',
    options: pageType === 'search' ? searchOrderOptions : listingOrderOptions
  });

  const [selectedOrderFilter, setSelectedOrderFilter] = useState(
    pageType === 'search' ? 'relevance' : 'featured'
  );

  // Dropdown filters (Filter by Product, Tag, Category)
  const initialFilters = [
    ...(!isCategoryPage
      ? [
          {
            type: 'dropdown',
            label: t('filters_industries_label'),
            active: parseInt(searchParams.get('industries'), 10) || 0,
            filterCategory: 'industries',
            options: [{ label: t('filters_industries_placeholder'), value: 0 }]
          }
        ]
      : []),
    {
      type: 'dropdown',
      label: t('filters_products_label'),
      active: parseInt(searchParams.get('products'), 10) || 0,
      filterCategory: 'products',
      options: [{ label: t('filters_products_placeholder'), value: 0 }]
    },
    {
      type: 'dropdown',
      label: t('filters_tags_label'),
      active: parseInt(searchParams.get('tags'), 10) || 0,
      filterCategory: 'tags',
      options: [{ label: t('filters_tags_placeholder'), value: 0 }],
      hideFilter: true
    }
  ];

  const [dropdownFilters, setDropdownFilters] = useState({
    label: 'Filter',
    filterType: 'filters',
    type: 'subsection',
    icon: 'dhig--icons--actions--filter',
    isDataSet: false,
    filters: initialFilters
  });

  const [selectedDropdownFilters, setSelectedDropdownFilters] = useState({
    industries: [],
    products: [],
    tags: []
  });

  // Create project button (only on Homepage and Category pages)
  const filtersActionsData = {
    icon: 'dhig--icons--utility--add',
    label: t('create_your_project_btn_label'),
    onClick: setToggleModal
  };

  // Populate dropdowns filters
  const populateDropdownFilters = (industries, products, tags) => {
    const dropdownFiltersClone = { ...dropdownFilters };
    dropdownFiltersClone.filters.forEach((filter) => {
      if (industries && filter.filterCategory === 'industries') {
        industries.forEach((element) => {
          filter.options.push({ label: element.name, value: parseInt(element.id, 10) });
        });
      }
      if (products && filter.filterCategory === 'products') {
        products.forEach((element) => {
          filter.options.push({ label: element.name, value: parseInt(element.id, 10) });
        });
      }
      if (tags && filter.filterCategory === 'tags') {
        filter.options = initialFilters?.find((el) => el.filterCategory === 'tags').options;
        filter.hideFilter = false;
        tags.forEach((element) => {
          filter.options?.push({ label: element?.name, value: parseInt(element.id, 10) });
        });
      } else if (!tags && filter.filterCategory === 'tags') {
        filter.options = initialFilters?.find((el) => el.filterCategory === 'tags').options;
        filter.hideFilter = true;
      }
    });
    dropdownFiltersClone.isDataSet = true;

    setDropdownFilters(dropdownFiltersClone);
  };

  // Create state for FilterBar
  const [filterBarFilters, setFilterBarFilters] = useState([]);

  const [featuredFilter, setFeaturedFilter] = useState(pageType === 'search' ? false : true);

  // Update url search params when filter is selected in filterBar (Order, Products, Tags, Categories)
  const onFilterChange = (updatedFilters) => {
    updatedFilters.forEach((filter) => {
      if (filter.filterType === 'order' && filter.options[filter.active]?.slug !== 'featured') {
        searchParams.set('order', filter.options[filter.active]?.slug);
        searchParams.delete('featured');
      }
      if (filter.filterType === 'order' && filter.options[filter.active]?.slug === 'featured') {
        searchParams.set('featured', true);
        searchParams.delete('order');
      }
      if (filter.filterType == 'filters') {
        filter.filters?.forEach((subFilter) => {
          if (subFilter.active >= 1) {
            searchParams.set(subFilter.filterCategory, subFilter.active);
          } else searchParams.delete(subFilter.filterCategory);
        });
      }
    });
    searchParams.set('page', 1);
    setSearchParams([...searchParams.entries()]);
  };

  useEffect(() => {
    pageType === 'search'
      ? setFilterBarFilters([dropdownFilters, !noResult && orderFilter])
      : setFilterBarFilters([!noResult && orderFilter]);
  }, [dropdownFilters, orderFilter, noResult]);

  // Update filtering when url search param changes
  useEffect(() => {
    // Pagination
    const page = parseInt(searchParams.get('page'));
    const perPage = page && page > 1 ? LISTING_ITEM_PER_PAGE : LISTING_ITEM_PER_PAGE_INIT;
    setPaginationData({
      ...paginationData,
      currentPage: page ? page : 1,
      itemsPerPage: perPage
    });

    // Keywords Filter
    const keywords = searchParams.get('keywords');
    keywords && setKeywordsFilter(keywords);

    // Featured Filter
    const featured = Boolean(searchParams.get('featured'));
    setFeaturedFilter(featured);

    // Order Filter
    const order = searchParams.get('order');
    if (order === null && !featured) {
      setSelectedOrderFilter(pageType === 'search' ? 'relevance' : 'featured');
      setFeaturedFilter(true);
    }
    if (order) {
      setSelectedOrderFilter(order);
      setFeaturedFilter(false);
    }

    setOrderFilter({
      ...orderFilter,
      active:
        featured || order === null ? 0 : orderFilter.options.findIndex((el) => el.slug === order)
    });

    // Dropdown filters (Filter by Product, Tag, Category)
    const industries = parseInt(searchParams.get('industries'), 10);
    const products = parseInt(searchParams.get('products'), 10);
    const tags = parseInt(searchParams.get('tags'), 10);

    setSelectedDropdownFilters({
      ...(industries !== null && industries >= 1 && !isCategoryPage
        ? { industries: [industries] }
        : {}),
      ...(isCategoryPage && getIndustryFilter(name)),
      ...(products !== null && products >= 1 && { products: [products] }),
      ...(tags !== null && tags >= 1 && { tags: [tags] })
    });
  }, [searchParams, name, isCategoryPage]);

  // Listing Query
  const { loading: loadingListingData, data: listingDataAPI } = useQuery(LISTING_QUERY, {
    context: { clientName: 'public' },
    variables: {
      ...(!featuredFilter && { order: selectedOrderFilter }),
      limit: paginationData.itemsPerPage,
      page: paginationData.currentPage,
      filters: {
        ...(featuredFilter && { featured: featuredFilter }),
        ...(pageType === 'campaign' && { campaign: 'designed&madeinfusion' }),
        ...selectedDropdownFilters
      }
    },
    onCompleted: scrollTop,
    skip: pageType === 'search'
  });

  // Get Search Results
  const { loading: loadingSearchData, data: searchDataAPI } = useQuery(GET_SEARCH_PAGE_RESULTS, {
    context: { clientName: 'public' },
    variables: {
      keywords: keywordsFilter,
      limit: paginationData.itemsPerPage,
      page: paginationData.currentPage,
      order: selectedOrderFilter,
      filters: {
        ...selectedDropdownFilters
      }
    },
    onCompleted: scrollTop,
    skip: pageType !== 'search' || !searchParams.get('keywords') || !keywordsFilter
  });

  const isLoading = loadingListingData || loadingSearchData;

  // Set dummy datas if loading
  const dummyObject = {
    title: '',
    project: {
      id: '',
      isLiked: false
    },
    image: {
      src: '',
      alt: '',
      srcSet: []
    },
    author: {
      name: '',
      image: {
        src: '',
        alt: ''
      }
    },
    linkUrl: ''
  };

  const dummyData = [];
  const numberOfDummies =
    paginationData.currentPage > 1 ? LISTING_ITEM_PER_PAGE : LISTING_ITEM_PER_PAGE_INIT;
  for (let i = 0; i < numberOfDummies; i++) {
    dummyObject.id = i;
    dummyData.push(dummyObject);
  }

  useEffect(() => {
    setListingData(dummyData);
  }, [isLoading]);

  // Set listing data on query completed
  useEffect(() => {
    // Good to know : we use Search query here as it is way faster than Listing query (cause it uses elasticsearch), but it does not allow all the same filters. Maybe the two requests will be merged in the future.
    const projectData = searchDataAPI?.search || listingDataAPI?.projects;
    if (projectData) {
      const projects = projectData.data.map((project) => {
        const has3dViewer = () => {
          return project.assets.some((asset) => asset.resource && asset.resource.has_viewer);
        };

        return {
          project: {
            id: project.id,
            isLiked: project.is_liked
          },
          has3dViewer: has3dViewer(),
          title: project.title,
          image: {
            src: project.image?.path,
            alt: project.title,
            srcSet: project.image?.sizes
          },
          author: {
            slug: project.author?.slug,
            url: `/profile/${project.author?.slug}`,
            name: `${project.author?.display_name}`,
            image: {
              src: project.author?.avatar_big,
              alt: project.author?.display_name
            }
          },
          linkUrl: `/project/${project.id}/${project.slug}`
        };
      });
      setListingData(projects);

      setPaginationData({
        ...paginationData,
        totalPageCount: Math.ceil(projectData.total / LISTING_ITEM_PER_PAGE),
        totalItems: projectData.total
      });

      if (listingDataAPI || searchDataAPI) {
        const industriesData = listingDataAPI?.industries || searchDataAPI?.industries;
        const industyBasedTags = (listingDataAPI?.industries || searchDataAPI?.industries)?.find(
          (el) => parseInt(el.id, 10) === parseInt(selectedDropdownFilters?.industries?.[0], 10)
        )?.tags;
        const productsData = listingDataAPI?.products || searchDataAPI?.products;

        if (!dropdownFilters.isDataSet) {
          populateDropdownFilters(industriesData, productsData, industyBasedTags);
        } else {
          populateDropdownFilters(false, false, industyBasedTags);
        }
      }

      projectData.total === 0 ? setNoResult(true) : setNoResult(false);
    }
  }, [searchDataAPI, listingDataAPI, selectedDropdownFilters]);

  useEffect(() => {
    if (pageType === 'search') {
      if (!loadingSearchData) {
        const page = parseInt(searchParams.get('page'));
        trackSearch(keywordsFilter, selectedDropdownFilters, paginationData.totalItems, page);
      }
    }
  }, [loadingSearchData]);

  // Scroll to results
  const scrollHere = useRef(null);
  useEffect(() => {
    //scrollHere.current?.scrollIntoView({ behavior: 'smooth', top: 0 });
  }, [searchParams]);

  return (
    <>
      {isModalOpen && <SignInAlert onClose={closeModal} />}
      {showCreateModal && (
        <CreateModal {...(pageType === 'campaign' && { campaign: 'designed&madeinfusion' })} />
      )}
      {(searchParams.get('keywords') || pageType !== 'search') && (
        <div ref={scrollHere}>
          <GridContainer>
            {pageType == 'search' && (
              <SearchResultWrapper>
                <Typography
                  component="h2"
                  variant={isMobile ? 'headline-largest' : 'headline-large'}
                >
                  {`${t('search_results_for')} "${keywordsFilter}"`}
                </Typography>
                <Typography component="p" variant="body-copy-large">
                  {isLoading
                    ? 'Loading results'
                    : t('search_results_count', {
                        count:
                          paginationData.totalItems < paginationData.itemsPerPage
                            ? paginationData.totalItems
                            : paginationData.itemsPerPage,
                        total: paginationData.totalItems
                      })}
                </Typography>
              </SearchResultWrapper>
            )}
            <FilterBarWrapper>
              <FilterBar
                filters={filterBarFilters}
                setFilters={setFilterBarFilters}
                actionButton={
                  isCategoryPage || pageType == 'homepage' || pageType === 'campaign'
                    ? filtersActionsData
                    : {}
                }
                onChange={onFilterChange}
                pageType={pageType}
              />
            </FilterBarWrapper>
            {isLoading || !noResult ? (
              <>
                {pageType !== 'search' && paginationData.currentPage == 1 && (
                  <div data-wat-link-section="community gallery thumbnail">
                    <TopGridWrapper hasPaddingBottom={banner}>
                      <ThumbnailGrid
                        name={name}
                        isLoading={isLoading}
                        thumbnails={listingData.slice(0, 5)}
                        gridType="featured"
                        username={username}
                      />
                    </TopGridWrapper>
                    {banner && banner}
                  </div>
                )}
                <BottomGridWrapper hasPaddingTop={banner}>
                  <ThumbnailGrid
                    name={name}
                    isLoading={isLoading}
                    thumbnails={
                      pageType === 'search' || paginationData.currentPage > 1
                        ? listingData.slice(0, paginationData.itemsPerPage)
                        : listingData.slice(5, 11)
                    }
                    gridType="listing"
                    username={username}
                  />
                </BottomGridWrapper>
              </>
            ) : (
              <NoResultWrapper>
                <Separator></Separator>
                <Typography component="p" variant="body-copy-large">
                  {t('search_no_results_title')}
                </Typography>
                <Typography
                  component="p"
                  variant="body-copy-large"
                  sx={{
                    mb: '6',
                    mt: '3'
                  }}
                >
                  {t('search_no_results_description')}
                </Typography>

                <NoResultLinks />
              </NoResultWrapper>
            )}
          </GridContainer>
          {paginationData.totalPageCount > 1 && (
            <PaginationWrapper>
              <Pagination {...paginationData} onChangePage={onChangePage} />
            </PaginationWrapper>
          )}
        </div>
      )}
    </>
  );
}

ProjectListing.propTypes = {
  pageType: PropTypes.string,
  banner: PropTypes.object,
  setToggleModal: PropTypes.func,
  name: PropTypes.string,

  username: PropTypes.string
};

export default ProjectListing;
