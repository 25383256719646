import React from 'react';
import Grid from '@digital-hig/grid';
import Typography from '@digital-hig/typography';
import PageContainer from '../PageContainer';

const TermsAndConditions = () => {
  return (
    <PageContainer>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography
            component="h1"
            variant="headline-big"
            sx={{ mb: 4, fontWeight: 800 }}
            className="wd-mv-"
          >
            Terms and Conditions
          </Typography>
          <Typography component="p" variant="body-copy-medium" sx={{ mb: 3 }} className="wd-mv-">
            In addition to the Autodesk Terms of Use (currently located at{' '}
            <a href="http://usa.autodesk.com/adsk/servlet/item?siteID=123112&id=16910341">
              http://usa.autodesk.com/adsk/servlet/item?siteID=123112&id=16910341
            </a>{' '}
            or any successor URL), the following Special Gallery Web Terms apply to the Community
            Gallery Site.
          </Typography>
          <Typography component="p" variant="body-copy-medium" sx={{ mb: 4 }} className="wd-mv-">
            By accessing or using the Community Gallery, you understand and agree that you will be
            subject to, have read and agree to be bound by these additional terms. Capitalized words
            not defined in these Community Gallery Terms have the meanings given to them in the
            Autodesk Terms of Use.
          </Typography>
          <Typography component="p" variant="body-copy-medium" sx={{ mb: 4 }} className="wd-mv-">
            Use of Model Documents
          </Typography>
          <Typography component="p" variant="body-copy-medium" sx={{ mb: 4 }} className="wd-mv-">
            You may download and use model Documents expressly made available for download by other
            users for commercial purposes provided such use is only for the purpose of creating,
            within the ordinary course of your business, designs incorporating such models (and not
            for the purpose of creating or maintaining sites for the cataloging or access of models)
            and further provided that you retain all copyright and other proprietary notices in the
            original downloaded models. Documents specified above do not include the design or
            layout of this website or any other Autodesk owned, operated, licensed, or controlled
            website.
          </Typography>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default TermsAndConditions;
