import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const ContentWrapper = styled.div`
  max-width: 1248px;
  margin: auto;
  background-color: #fff;
  width: 100%;
  padding-bottom: ${lightTheme.primitives.spacings['7']};
  padding-top: ${lightTheme.primitives.spacings['7']};
  padding-left: ${lightTheme.primitives.spacings['6']};
  padding-right: ${lightTheme.primitives.spacings['6']};
`;

export const PageDescription = styled.div`
  margin-bottom: ${lightTheme.primitives.spacings['8']};
  padding-left: ${lightTheme.primitives.spacings['7']};
  padding-right: ${lightTheme.primitives.spacings['7']};
`;
