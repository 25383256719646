import { gql } from '@apollo/client';
import {
  PAGE_HERO_FRAGMENT,
  PAGE_PUSH_FRAGMENT,
  PAGE_SEO_FRAGMENT,
  THUMBNAIL_FRAGMENT
} from './fragments';

export const HOME_QUERY = gql`
  ${PAGE_HERO_FRAGMENT}
  ${PAGE_PUSH_FRAGMENT}
  ${PAGE_SEO_FRAGMENT}
  query page($id: Int!, $lang: String!) {
    page(id: $id, lang: $lang) {
      id
      ...PageHeroFragment
      ...PagePushFragment
      ...PageSeoFragment
    }
    usersFeatured(limit: 8) {
      id
      slug
      avatar_big
      username
      email
      display_name
      first_name
      last_name
      job_title
    }
  }
`;

export const SEARCH_QUERY = gql`
  ${PAGE_HERO_FRAGMENT}
  ${PAGE_SEO_FRAGMENT}
  query page($id: Int!, $lang: String!) {
    page(id: $id, lang: $lang) {
      id
      ...PageHeroFragment
      ...PageSeoFragment
    }
  }
`;

export const CATEGORY_QUERY = gql`
  ${PAGE_HERO_FRAGMENT}
  ${PAGE_SEO_FRAGMENT}
  query page($id: Int!, $lang: String!) {
    page(id: $id, lang: $lang) {
      id
      ...PageHeroFragment
      ...PageSeoFragment
    }
  }
`;

export const PROJECT_QUERY = gql`
  ${THUMBNAIL_FRAGMENT}
  query project($slug: String!, $id: Int!, $projectCount: Int) {
    project(id: $id) {
      id
      slug
      can_edit
      is_author
      is_bookmarked
      reported
      is_liked
      like_count
      author {
        id
        slug
        avatar_big
        username
        display_name
        first_name
        last_name
        job_title
      }
      campaign {
        id
        name
      }
      title
      description
      industries {
        id
        name
      }
      tags {
        id
        name
      }
      products {
        id
        name
      }
      banner: image(format: "pageHero") {
        path
        width
        height
        crop_x
        crop_y
        crop_z
        sizes(formats: ["mobile"]) {
          path
          width
          height
          format
        }
      }
      original: image(format: "original") {
        path
      }
      cover: image {
        path
        width
        height
        crop_x
        crop_y
        crop_z
      }
      assets {
        id
        type
        order
        downloadable
        associated_at
        display_name
        download_url
        raas_embed
        resource {
          encoded_urn
          file_name
          has_viewer
          size
          viewer_setting
        }
        embed {
          link_url
          link_title
          link_description
          link_image
        }
        image {
          path
          width
          height
          format
          sizes(formats: ["asset1300", "asset800", "asset600", "asset400"]) {
            path
            width
            height
            format
          }
        }

        author {
          id
          id
          avatar_big
          username
        }
      }
      created_at
      seo {
        title
        description
        image
      }
      published_at
      status
    }
    relatedProjects(limit: $projectCount, page: 1, slug: $slug) {
      data {
        ...ThumbnailFragment
      }
    }
  }
`;
