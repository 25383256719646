import React, { useState, useEffect } from 'react';
import MemoizedBlock3dViewer from '../../components/CreateDetailBlocks/Block3dViewer';
import { useQuery } from '@apollo/client';
import { ProgressWrapper } from '../DetailPage/DetailPage.styled';
import { useParams } from 'react-router-dom';
import Progress from '@digital-hig/progress';
import { ASSET } from '../../apollo/queries';

const ShareAsset = () => {
  const { id, assetId } = useParams();
  const [assetData, setAssetData] = useState({
    urn: null,
    slug: null,
    hasViewer: null,
    viewerSetting: null,
    isDataSet: false
  });

  const { data, loading } = useQuery(ASSET, {
    context: { clientName: 'authorized' },
    variables: { project_id: parseInt(id, 10), id: parseInt(assetId, 10) },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (data) {
      const asset = data?.publicAsset?.resource;
      setAssetData({
        urn: asset.encoded_urn,
        slug: id,
        hasViewer: asset.has_viewer,
        viewerSetting: asset.viewer_setting,
        isDataSet: true
      });
    }
  }, [data]);

  if (loading) {
    return (
      <ProgressWrapper>
        <Progress size="medium" />
      </ProgressWrapper>
    );
  }

  // if (error) return <p>Error: {error}</p>;

  return <>{data && assetData.isDataSet && <MemoizedBlock3dViewer {...assetData} />}</>;
};

export default ShareAsset;
