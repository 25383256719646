import React from 'react';
import PropTypes from 'prop-types';
import { ProjectStatusWrapper } from './ProjectStatus.styled';
import { useTranslation } from 'react-i18next';
import Typography from '@digital-hig/typography';
import Icon from '@digital-hig/icon';
import Progress from '@digital-hig/progress';

function ProjectStatus(props) {
  const { name, loadingPercentage, loaded, error } = props;

  const { t } = useTranslation();

  return (
    <ProjectStatusWrapper isError={error.isError}>
      <Typography variant="headline-smaller" component="h5">
        {!error.isError
          ? t(
              loaded && loadingPercentage === 100
                ? 'project_loaded_success_1'
                : 'loading_project_status_1',
              {
                name
              }
            )
          : t('project_loaded_error_1')}
      </Typography>
      <Typography variant="headline-smaller" component="h5" style={{ marginBottom: '24px' }}>
        {!error.isError &&
          t(
            loaded && loadingPercentage === 100
              ? 'project_loaded_success_2'
              : 'loading_project_status_2'
          )}
        {error.isError && error.error && t('project_loaded_error_2', { error: error.error })}
      </Typography>
      {(loaded && loadingPercentage === 100) || error.isError ? (
        <Icon
          size="large"
          id={!error.isError ? 'dhig--display-icons--complete' : 'dhig--display-icons--error'}
        />
      ) : (
        <Progress label="Loading project" size="large" />
      )}
    </ProjectStatusWrapper>
  );
}
ProjectStatus.propTypes = {
  name: PropTypes.string,
  loadingPercentage: PropTypes.number,
  loaded: PropTypes.bool,
  error: PropTypes.shape({
    isError: PropTypes.bool,
    error: PropTypes.string
  })
};

ProjectStatus.defaultProps = {
  name: undefined,
  loadingPercentage: 0,
  loaded: false,
  error: {
    isError: false,
    error: undefined
  }
};

export default ProjectStatus;
