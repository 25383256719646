import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const DetailFooterWrapperInner = styled.div`
  display: grid;
  gap: ${lightTheme.primitives.spacings['6']};
  grid-template-columns: repeat(4, 2fr);
  max-width: 1248px;
  margin: 0 auto;
  padding: 0 1rem;
  align-items: flex-start;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 2fr);
  }

  @media screen and (max-width: 728px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: flex-start;

    .avatar {
      display: none;
    }
  }
`;

export const DetailFooterWrapper = styled.div`
  background-color: ${lightTheme.tokens.colors['background-panel'].color};
  padding: ${lightTheme.primitives.spacings['9']};

  @media screen and (max-width: 728px) {
    padding: ${lightTheme.primitives.spacings['6']};
  }
`;
