import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FilterBarWrapper,
  FilterSection,
  SubSection,
  StyledSelect,
  SingleButton,
  ActionButton
} from './FilterBar.styled';
import Icon from '@digital-hig/icon';
import FormControl from '@digital-hig/form-control';
import { generateUniqueId, useIsMobile } from '../../utils';
import Modal from '@digital-hig/modal';
import { useTranslation } from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function RenderFilter({ filter, stateCallBack, handleResetFilters, isFiltered }) {
  const { label, type, active, icon, options } = filter;
  const itemId = generateUniqueId();
  const { t } = useTranslation();
  switch (type) {
    case 'subsection':
      return (
        <div>
          <SingleButton
            data-wat-val={active ? label + ' show' : label + ' hide'}
            variant="text"
            startIcon={icon && <Icon id={icon} />}
            className={active ? 'active-filter' : ''}
            onClick={() => {
              stateCallBack(!active);
            }}
          >
            {label}
          </SingleButton>
          {isFiltered && (
            <SingleButton
              data-wat-val={label}
              variant="text"
              startIcon={<Icon id="dhig--icons--utility--cross" />}
              onClick={() => {
                handleResetFilters();
              }}
            >
              {t('clear_all_filters')}
            </SingleButton>
          )}
        </div>
      );
    case 'single':
      return (
        <SingleButton
          data-wat-val={label}
          variant="outlined"
          startIcon={icon && <Icon id={icon} />}
          className={active ? 'active-filter' : ''}
          onClick={() => {
            stateCallBack(!active);
          }}
        >
          {label}
        </SingleButton>
      );
    case 'slider':
      return (
        <ToggleButtonGroup
          exclusive
          onChange={(e) => {
            stateCallBack(parseInt(e.target.value, 10));
          }}
          size="medium"
          value={active}
        >
          {options?.map((option, i) => {
            return (
              <ToggleButton value={i} key={option?.slug}>
                {option?.label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      );
    case 'dropdown':
      return (
        <FormControl id={itemId} label={label}>
          <StyledSelect
            id={itemId}
            onChange={(e) => stateCallBack(parseInt(e.target.value, 10))}
            value={active || 0}
            width="280px"
            variant="outlined"
            options={options.map((option) => ({
              children: option.label,
              value: option.value
            }))}
          />
        </FormControl>
      );
    default:
      return null;
  }
}

function FilterBar({ onChange, filters, setFilters, actionButton, pageType }) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [showSubsection, setShowSubsection] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    setIsFiltered(false);
    filters.forEach((filter) => {
      if (typeof filter.active === 'number' && filter.active !== 0) setIsFiltered(true);
      filter.filters?.forEach((subfilter) => {
        if (subfilter.active !== 0) setIsFiltered(true);
      });
    });
  }, [filters]);

  // Update active value in filter state
  function handleActiveState(value, index, subIndex) {
    const newState = [...filters];

    subIndex !== undefined
      ? (newState[index].filters[subIndex].active = value)
      : (newState[index].active = value);

    setFilters(newState);
    onChange(newState);
  }

  // Reset Filters
  function handleResetFilters() {
    const newState = [...filters];
    newState.map((filter) => {
      if (filter.active !== undefined) filter.active = 0;
      filter.filters?.map((subfilter) => (subfilter.active = 0));
    });
    setFilters(newState);
    onChange(newState);
  }

  return (
    <>
      <FilterBarWrapper>
        <FilterSection pageType={pageType} data-wat-link-section="community gallery filter">
          {filters.map((filter, i) => (
            <RenderFilter
              key={filter.label + filter.type + i}
              handleResetFilters={handleResetFilters}
              isFiltered={isFiltered}
              filter={{
                ...filter,
                active: filter.type === 'subsection' ? showSubsection : filter.active
              }}
              stateCallBack={(e) => {
                if (filter.type === 'subsection') {
                  setShowSubsection(e);
                } else {
                  handleActiveState(e, i);
                }
              }}
            />
          ))}
        </FilterSection>
        {Boolean(Object.keys(actionButton).length >= 1) && (
          <ActionButton
            data-wat-loc="community gallery"
            data-wat-val="create your project"
            className="filterbar--button"
            onClick={actionButton.onClick}
            href={actionButton.href}
            startIcon={<Icon id={actionButton.icon} />}
            disabled={actionButton.disabled}
          >
            {actionButton.label}
          </ActionButton>
        )}
      </FilterBarWrapper>
      {showSubsection && !isMobile && (
        <SubSection>
          {filters.map((filterSubSection, i) => {
            if (filterSubSection.type === 'subsection') {
              return (
                <FilterSection key={'subsection_item' + i}>
                  {filterSubSection.filters.map((filter, j) => (
                    <RenderFilter
                      data-wat-val={actionButton.label}
                      key={filter.label + filter.type + 'subsection_item' + j}
                      filter={filter}
                      stateCallBack={(e) => handleActiveState(e, i, j)}
                    />
                  ))}
                </FilterSection>
              );
            }
          })}
        </SubSection>
      )}

      {isMobile && (
        <Modal
          aria-describedby={t('modal_filters')}
          aria-labelledby={t('modal_filters_label')}
          backdropVariant="default"
          closeButtonDisplay
          maxWidth="xl"
          styleVariant="default"
          title={t('modal_filters_title')}
          type="modal"
          open={showSubsection}
          handleClose={() => setShowSubsection(false)}
          content={filters.map((filterSubSection, i) => {
            if (filterSubSection.type === 'subsection') {
              return (
                <FilterSection key={'subsection_item' + i}>
                  {filterSubSection.filters.map((filter, j) => (
                    <RenderFilter
                      key={filter.label + filter.type + 'subsection_item' + j}
                      filter={filter}
                      stateCallBack={(e) => handleActiveState(e, i, j)}
                    />
                  ))}
                </FilterSection>
              );
            }
          })}
        />
      )}
    </>
  );
}

const filtersShape = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['subsection', 'single', 'dropdown', 'slider']),
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  icon: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
      ]),
      id: PropTypes.string
    })
  )
};

FilterBar.propTypes = {
  onChange: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      ...filtersShape,
      filters: PropTypes.arrayOf(PropTypes.shape(filtersShape))
    })
  ).isRequired,
  setFilters: PropTypes.func,
  actionButton: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    href: PropTypes.string,
    disabled: PropTypes.bool
  }),
  pageType: PropTypes.string
};

RenderFilter.propTypes = {
  ...filtersShape,
  stateCallBack: () => {}
};

FilterBar.defaultProps = {
  onChange: () => {}
};

export default FilterBar;
