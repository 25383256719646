export const getCategoryId = (pathname) => {
  switch (pathname) {
    case '/architecture-engineering-construction':
      return 3;

    case '/product-design-manufacturing':
      return 4;

    case '/media-entertainment':
      return 5;

    default:
      break;
  }
};
