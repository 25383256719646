import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import { Link } from 'react-router-dom';

const wrapperStyle = css`
  display: inline-flex;
  align-items: center;
  position: relative;

  h6,
  p {
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const AvatarWrapper = styled.div`
  ${wrapperStyle}
`;

export const AvatarWrapperWithLink = styled(Link)`
  ${wrapperStyle}
  border: none;
  color: #000000;
  text-decoration: none !important;
  img {
    transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`;

export const ImageWrapper = styled.div`
  margin-right: ${lightTheme.primitives.spacings['2']};
  overflow: hidden;
  border-radius: 100%;
  width: 100%;
  mask-image: radial-gradient(white, black);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: 32px;
          max-width: 32px;
          min-width: 32px;
        `;
      case 'medium':
        return css`
          height: 48px;
          max-width: 48px;
          min-width: 48px;
        `;
      case 'large':
        return css`
          height: 64px;
          max-width: 64px;
          min-width: 64px;
          border: 2px solid #ffffff;
          box-shadow: 0px 0px 50px 3px rgba(0, 0, 0, 0.25);
        `;
      case 'x-large':
        return css`
          height: 72px;
          max-width: 72px;
          min-width: 72px;
          @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
            max-width: 48px;
            min-width: 48px;
            height: 48px;
            margin-bottom: ${lightTheme.primitives.spacings['2']};
          }
        `;
    }
  }}
`;
