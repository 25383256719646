import React from 'react';
import PropTypes from 'prop-types';
import ProjectStatus from '../../../components/ProjectStatus';
import { Details, Categories, Cover } from './detailsSteps';

const ProjectDetails = React.memo(function ProjectDetails(props) {
  const {
    currentCreateDetailsStep,
    onChange,
    title,
    description,
    tags,
    industries,
    products,
    cover,
    coverCropData,
    images,
    isEdit,
    creatorData,
    allFormFilledOnce,
    publishStatusData,
    campaign
  } = props;

  switch (currentCreateDetailsStep) {
    case 0:
      return (
        <Details
          isEdit={isEdit}
          onChange={onChange}
          data={{ title, description, userName: creatorData.userName }}
          error={{
            title: !title && title === '',
            description: !description && description === '',
            allFormFilledOnce
          }}
        />
      );
    case 1:
      return (
        <Categories
          onChange={onChange}
          isEdit={isEdit}
          data={{ tags, industries, products }}
          campaign={campaign}
          error={{
            industries: industries.filter((el) => el.selected).length <= 0,
            products: products.filter((el) => el.selected).length <= 0,
            allFormFilledOnce
          }}
        />
      );
    case 2:
      return (
        <Cover
          onChange={onChange}
          isEdit={isEdit}
          data={{
            cover,
            images,
            title,
            coverCropData,
            creatorData: {
              name: `${creatorData.userName}`,
              subtitle: creatorData.title,
              profilePicture: creatorData.profilePicture
            }
          }}
        />
      );
    case 3:
      return (
        <ProjectStatus
          loaded={publishStatusData.loaded}
          loadingPercentage={publishStatusData.loadingPercentage}
          name={`${creatorData.userName}`}
          error={publishStatusData.error}
        />
      );
    default:
      return null;
  }
});

ProjectDetails.propTypes = {
  currentCreateDetailsStep: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  campaign: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      selected: PropTypes.bool
    })
  ),
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      selected: PropTypes.bool
    })
  ),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      selected: PropTypes.bool
    })
  ),
  cover: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
    // PropTypes.instanceOf(File),
    // PropTypes.arrayOf(PropTypes.instanceOf(File))
  ]),
  images: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
    // PropTypes.instanceOf(File),
    // PropTypes.arrayOf(PropTypes.instanceOf(File))
  ]),
  coverCropData: PropTypes.shape({
    thumbnail: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
      z: PropTypes.number
    }),
    banner: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
      z: PropTypes.number
    })
  }),
  publishStatusData: PropTypes.shape({
    loaded: PropTypes.bool,
    loadingPercentage: PropTypes.number,
    error: PropTypes.shape({
      isError: PropTypes.bool,
      error: PropTypes.string
    })
  }),
  creatorData: PropTypes.shape({
    userName: PropTypes.string,
    profilePicture: PropTypes.string,
    title: PropTypes.string
  }),
  isEdit: PropTypes.bool,
  allFormFilledOnce: PropTypes.bool
};

ProjectDetails.defaultProps = {
  onChange: () => {},
  title: undefined,
  description: undefined,
  tags: [],
  industries: [],
  products: [],
  campaign: '',
  cover: undefined,
  images: [],
  isEdit: false,
  allFormFilledOnce: false,
  publishStatusData: {
    loaded: false,
    loadingPercentage: 0,
    error: {
      isError: false,
      error: undefined
    }
  }
};

export default ProjectDetails;
