import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const OptionsButtonWrapper = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  z-index: 1;

  .MuiButton-outlinedSizeSmall {
    border-radius: 0.25rem 0px 0px 0.25rem;
  }

  ${({ inverted }) =>
    inverted &&
    css`
      filter: invert(1);
    `};
  ${({ withoutOptions }) =>
    !withoutOptions &&
    css`
      > button,
      > a {
        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;
      }
    `};
`;

export const OptionsWrapper = styled.div`
  .MuiButton-outlinedSizeSmall {
    border-radius: 0px 0.25rem 0.25rem 0px;
    border-left: none;
    padding: 0px 0.75rem;
    min-width: 0 !important;
  }
  > button {
    display: block;
    border: none;
    cursor: pointer;
    height: 100%;
    position: relative;
    padding: 0 ${lightTheme.primitives.spacings['3']};
    border-radius: ${lightTheme.primitives.borderRadii.medium};
    background-color: none !important;
    svg {
      color: ${lightTheme.primitives.colors.primary.white};
      stroke: ${lightTheme.primitives.colors.primary.white};
      fill: ${lightTheme.primitives.colors.primary.white};
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -1px;
      top: -1px;
      width: 1px;
      height: calc(100% + 2px);
      background-color: white;
    }
    border-bottom-left-radius: 0%;
    border-top-left-radius: 0%;
  }
  .outlined {
    background-color: transparent;
    color: ${lightTheme.primitives.colors.primary.black};
    border: 1px solid ${lightTheme.primitives.colors.primary.black};
    svg {
      color: ${lightTheme.primitives.colors.primary.black};
      stroke: ${lightTheme.primitives.colors.primary.black};
      fill: ${lightTheme.primitives.colors.primary.black};
    }
    &::before {
      background-color: ${lightTheme.primitives.colors.primary.black};
    }
  }
`;

export const Options = styled.div`
  ${({ inverted }) =>
    inverted &&
    css`
      filter: invert(1);
    `};
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: ${lightTheme.primitives.spacings['1']};
  padding: 8px 12px;
  border-radius: ${lightTheme.primitives.borderRadii.medium};
  box-shadow: ${lightTheme.tokens.effects['elevation-0-popover'].boxShadow};
  background: black;
  right: 0;
  flex-wrap: nowrap;
  ${({ isMobile }) =>
    isMobile &&
    css`
      top: -300%;
      margin-top: 0;
    `};

  .MuiButton-textSizeSmall,
  .MuiButton-textSizeSmall:hover {
    width: max-content;
    padding: 0.5rem 0.75rem;
    margin: 0;
  }
  > button {
    padding: 0;
    width: fit-content;

    &:focus,
    &:active:focus,
    &:hover {
      padding: 0;
      box-shadow: none;
      opacity: 0.8;
    }
  }
`;
