import React, { useEffect, useState, useContext } from 'react';
import Pagination from '../Pagination';
import ThumbnailGrid from '../ThumbnailGrid';
import PropTypes from 'prop-types';
import Button from '@digital-hig/button';
import Tabs from '@digital-hig/tabs/lib/tabs';
import { useTranslation } from 'react-i18next';
import Icon from '@digital-hig/icon';
import { ModalContext } from '../../context/ModalContext';
import SignInAlert from '../SignInAlert';
import { useSelector } from 'react-redux';
import CreateModal from '../CreateModal';
import MyProfileCard from '../../components/MyProfileCard';
import Alert from '@mui/material/Alert';
import { AlertIcon } from '@digital-hig/alert';
import { document } from 'global';
import { useNavigate } from 'react-router-dom';
import SvgIcon from '@digital-hig/svg-icon';
import SvgExternal from '@digital-hig/icon/lib/build/icons/navigation/external';
import {
  GridContainer,
  TopGridWrapper,
  PaginationWrapper,
  DescriptionWrapper,
  CreateProjectCardWrapper,
  CreateProjectButtonWrapper
} from '../ProjectListing/ProjectListing.styled';

import {
  GET_MY_PROJECTS_TOTAL,
  GET_PUBLIC_PROJECTS,
  GET_MY_BOOKMARKS,
  GET_MY_PROJECTS
} from '../../apollo/queries';
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { toggleModalAction } from '../../store/actions/createActions';
import { ProgressWrapper } from '../../pages/ProfileLite.styled';
import Progress from '@digital-hig/progress';

const ITEMS_PER_PAGE = 6;

const tabIds = {
  published: 0,
  pending: 1,
  draft: 2,
  deleted: 3,
  bookmarks: 4
};

function projectsToViewModel(projects = []) {
  const projectsArr = projects.map((project) => {
    const has3dViewer = () => {
      return project.assets.some((asset) => asset.resource && asset.resource.has_viewer);
    };

    return {
      project: {
        id: project.id,
        isLiked: project.is_liked
      },
      has3dViewer: has3dViewer(),
      title: project.title,
      image: {
        src: project.image?.path,
        alt: project.title,
        srcSet: project.image?.sizes
      },
      author: {
        slug: project.author?.slug,
        url: `/profile/${project.author?.slug}`,
        name: `${project.author?.display_name}`,
        image: {
          src: project.author?.avatar_big,
          alt: project.author?.display_name
        }
      },
      linkUrl: `/project/${project.id}/${project.slug}`
    };
  });

  return projectsArr;
}

function scrollTop() {
  document.documentElement.scrollTo({
    left: 0,
    top: 0,
    behavior: 'smooth'
  });
}

const ProfileListing = ({ ownProfile, id, slug }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isModalOpen, closeModal } = useContext(ModalContext);
  const [currentTab, setCurrentTab] = useState(0);
  const showCreateModal = useSelector((state) => state.create.showModal);
  const [getMyProjects, myProjectsData] = useLazyQuery(GET_MY_PROJECTS, {
    context: { clientName: 'authorized' }
  });
  const [getBookmarks, bookmarksData] = useLazyQuery(GET_MY_BOOKMARKS, {
    context: { clientName: 'authorized' }
  });
  const [getPublicProjects, publicProjectsData] = useLazyQuery(GET_PUBLIC_PROJECTS, {
    context: { clientName: 'public' }
  });
  const [getDraftCountData, draftCountData] = useLazyQuery(GET_MY_PROJECTS_TOTAL, {
    context: { clientName: 'authorized' }
  });
  const [getPendingCountData, pendingCountData] = useLazyQuery(GET_MY_PROJECTS_TOTAL, {
    context: { clientName: 'authorized' }
  });
  const [getDeletedCountData, deletedCountData] = useLazyQuery(GET_MY_PROJECTS_TOTAL, {
    context: { clientName: 'authorized' }
  });
  const [listingData, setListingData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: ITEMS_PER_PAGE
  });

  useEffect(() => {
    getPublicProjects({
      variables: {
        authors: [parseInt(id, 10)],
        page: paginationData.currentPage,
        limit: ITEMS_PER_PAGE
      },
      fetchPolicy: 'cache-and-network'
    });
    if (ownProfile) {
      getBookmarks({
        variables: {
          slug,
          page: paginationData.currentPage,
          limit: ITEMS_PER_PAGE
        },
        fetchPolicy: 'cache-and-network'
      });
      getPendingCountData({
        variables: {
          status: 'pending'
        },
        fetchPolicy: 'cache-and-network'
      });
      getDraftCountData({
        variables: {
          status: 'draft'
        },
        fetchPolicy: 'cache-and-network'
      });
      getDeletedCountData({
        variables: {
          status: 'deleted'
        },
        fetchPolicy: 'cache-and-network'
      });
    }
  }, [ownProfile, slug, id]);

  const [tabs, setTabs] = useState([
    {
      'aria-controls': 'tabpanel-1-quiet',
      id: 0,
      currentPage: 1,
      label: t(`profile_tab_published`, { count: '' }),
      'data-wat-val': 'Projects',
      slug: 'published'
    }
  ]);

  useEffect(() => {
    if (
      publicProjectsData.called &&
      !ownProfile &&
      publicProjectsData?.data?.projects?.total === 0
    ) {
      return navigate(`/404`);
    }
  }, [publicProjectsData]);

  useEffect(() => {
    if (
      publicProjectsData.called &&
      draftCountData.called &&
      pendingCountData.called &&
      deletedCountData.called &&
      bookmarksData.called
    ) {
      const listingCounts = {
        published: publicProjectsData?.data?.projects?.total,
        draft: draftCountData?.data?.myProjects?.total,
        pending: pendingCountData?.data?.myProjects?.total,
        deleted: deletedCountData?.data?.myProjects?.total,
        bookmarks: bookmarksData?.data?.user?.bookmarks?.total
      };
      const newTabs = Object.keys(listingCounts)
        .map((key) => {
          if (
            (listingCounts[key] !== undefined && listingCounts[key] >= 1) ||
            key === 'published'
          ) {
            return {
              'aria-controls': 'tabpanel-1-quiet',
              id: tabIds[key],
              slug: key,
              label: t(`profile_tab_${key}`, {
                count: listingCounts[key] > 0 ? `(${listingCounts[key]})` : ''
              }),
              'data-wat-val': t(`profile_public_project_tab_${key}`, {
                count: listingCounts[key] > 0 ? `(${listingCounts[key]})` : ''
              })
            };
          }
        })
        .filter((el) => el !== undefined);

      setTabs(newTabs);
    }
  }, [publicProjectsData, draftCountData, pendingCountData, deletedCountData, bookmarksData]);

  useEffect(() => {
    if (tabs[currentTab]?.id > 0 && tabs[currentTab]?.id < 4) {
      getMyProjects({
        variables: {
          page: 1,
          limit: ITEMS_PER_PAGE,
          status: Object.keys(tabIds).find((key) => tabIds[key] === tabs[currentTab].id)
        },
        context: { clientName: 'authorized' },
        fetchPolicy: 'cache-and-network'
      });
    }
    if (tabs[currentTab]?.id === 0) {
      setListingData(projectsToViewModel(publicProjectsData?.data?.projects?.data));
      setPaginationData({
        currentPage: paginationData.currentPage,
        totalPageCount: publicProjectsData?.data?.projects?.last_page,
        totalItems: publicProjectsData?.data?.projects?.last_page,
        itemsPerPage: ITEMS_PER_PAGE
      });
    }
    if (tabs[currentTab]?.id === 4) {
      setListingData(projectsToViewModel(bookmarksData?.data?.user?.bookmarks?.data));
      setPaginationData({
        currentPage: paginationData.currentPage,
        totalPageCount: bookmarksData?.data?.user?.bookmarks?.last_page,
        totalItems: bookmarksData?.data?.user?.bookmarks?.total,
        itemsPerPage: ITEMS_PER_PAGE
      });
    }
  }, [publicProjectsData, currentTab, id]);

  useEffect(() => {
    if (myProjectsData?.data?.myProjects && tabs[currentTab]?.id > 0 && tabs[currentTab]?.id < 4) {
      setListingData(projectsToViewModel(myProjectsData?.data?.myProjects?.data));
      setPaginationData({
        currentPage: paginationData.currentPage,
        totalPageCount: myProjectsData?.data?.myProjects?.last_page,
        totalItems: myProjectsData?.data?.myProjects?.total,
        itemsPerPage: ITEMS_PER_PAGE
      });
    }
  }, [myProjectsData, currentTab]);

  function handlePageChange(oldPage, newPage) {
    const currentStatus = Object.keys(tabIds).find((key) => tabIds[key] === tabs[currentTab].id);
    if (oldPage !== newPage) {
      setPaginationData({
        ...paginationData,
        currentPage: newPage
      });
      switch (currentStatus) {
        case 'deleted':
        case 'pending':
        case 'draft':
          getMyProjects({
            variables: {
              status: currentStatus,
              page: newPage,
              limit: ITEMS_PER_PAGE
            },
            onCompleted: scrollTop,
            fetchPolicy: 'cache-and-network'
          });
          break;
        case 'published':
          getPublicProjects({
            variables: {
              authors: [parseInt(id, 10)],
              page: newPage,
              limit: ITEMS_PER_PAGE
            },
            onCompleted: scrollTop,
            fetchPolicy: 'cache-and-network'
          });
          break;
        case 'bookmarks':
          getBookmarks({
            variables: {
              slug,
              page: newPage,
              limit: ITEMS_PER_PAGE
            },
            onCompleted: scrollTop,
            fetchPolicy: 'cache-and-network'
          });
          break;
      }
    }
  }

  return (
    <>
      {ownProfile ? (
        <CreateProjectButtonWrapper>
          <Button
            data-wat-val="create your project"
            className="filterbar--button create-project--button"
            onClick={() => dispatch(toggleModalAction())}
            startIcon={<Icon id="dhig--icons--utility--add" />}
          >
            {t('create_your_project_btn_label')}
          </Button>
        </CreateProjectButtonWrapper>
      ) : null}
      <Tabs
        data-wat-link-section="community gallery profile"
        ariaLabel="DHIG light quiet tabs"
        tabProperties={tabs}
        styleVariant="quiet"
        value={currentTab}
        onChange={(e, v) => {
          setCurrentTab(v);
          setPaginationData({ ...paginationData, currentPage: 1 });
        }}
      />
      {showCreateModal && <CreateModal />}

      {ownProfile && (
        <DescriptionWrapper isPending={tabs[currentTab]?.slug === 'pending'}>
          {tabs[currentTab]?.slug === 'pending' && (
            <Alert
              severity="info"
              icon={<AlertIcon severity="info" variant="filled" alt="Pending approval" />}
              variant="filled"
            >
              Projects waiting for approval.
            </Alert>
          )}
          <Button
            data-wat-val="my renderings"
            startIcon={
              <SvgIcon fontSize="medium">
                <SvgExternal />
              </SvgIcon>
            }
            variant="text"
          >
            <a
              href="https://rendering-beta.360.autodesk.com/myrenderings"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: '#000000' }}
            >
              My renderings
            </a>
          </Button>
        </DescriptionWrapper>
      )}
      {listingData.length === 0 &&
      publicProjectsData.called &&
      !publicProjectsData.loading &&
      currentTab === 0 ? (
        <CreateProjectCardWrapper>
          <MyProfileCard handleOpenCreateModal={() => dispatch(toggleModalAction())} />
        </CreateProjectCardWrapper>
      ) : null}

      {isModalOpen && <SignInAlert onClose={closeModal} />}
      <GridContainer>
        {publicProjectsData?.loading && currentTab === 0 && (
          <ProgressWrapper>
            <Progress size="medium" />
          </ProgressWrapper>
        )}
        <TopGridWrapper hasPaddingBottom hasPaddingTop={!ownProfile}>
          <ThumbnailGrid
            name={name}
            isLoading={
              myProjectsData?.loading || publicProjectsData?.loading || bookmarksData?.loading
            }
            thumbnails={listingData}
            gridType="listing"
            username={slug}
            isProfile
            gridColumns={4}
          />
        </TopGridWrapper>
      </GridContainer>
      {paginationData.totalPageCount > 1 && (
        <PaginationWrapper>
          <Pagination
            {...paginationData}
            currentPage={paginationData?.currentPage}
            onChangePage={(p) => handlePageChange(paginationData.currentPage, p)}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

ProfileListing.propTypes = {
  ownProfile: PropTypes.bool,
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired
};

export default ProfileListing;
