import React from 'react';
import PropTypes from 'prop-types';
import { ThumbnailPreviewWrapper } from './ThumbnailPreview.styled';
import Avatar from '../Avatar';
import Typography from '@digital-hig/typography';

function ThumbnailPreview(props) {
  const { avatarProps, image, title, showUserInfo, children, variant } = props;

  return (
    <ThumbnailPreviewWrapper isBanner={variant === 'banner'}>
      {children}
      {!!image && <img className="thumbnail-preview-image" src={image} alt={title} />}
      {showUserInfo && (
        <>
          <Typography component="h5" variant="headline-small" className="thumbnail-preview-title">
            {title}
          </Typography>
          <Avatar {...avatarProps} size="small" />
        </>
      )}
    </ThumbnailPreviewWrapper>
  );
}

ThumbnailPreview.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  variant: PropTypes.oneOf(['thumbnail', 'banner']),
  avatarProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    profilePicture: PropTypes.string.isRequired
  }),
  image: PropTypes.string,
  showUserInfo: PropTypes.bool
};

ThumbnailPreview.defaultProps = {
  image: undefined,
  showUserInfo: true
};

export default ThumbnailPreview;
