/* eslint-disable no-unused-vars */
import React from 'react';
import Router from './router';
import ThemeMui from '@digital-hig/theme-mui';
import { GlobalStyles } from './GlobalStyles.styled';
import { initAutodeskHeader } from './app/utils/initAutodeskHeader';
import { getEnvironmentConstant } from './app/utils/getEnvironmentConstant';
import { PKCE_ENV } from './app/common/constants';
import window from 'global/window';
import { getEnv } from './app/utils/getEnv';

function App() {
  const env = getEnv();
  function initAutodeskFooter() {
    var footerConfig = {
      locale: 'en-US',
      environment: getEnvironmentConstant({ env, key: PKCE_ENV }),
      hideMegaFooter: false
    };

    if (window.adsk && window.adsk.components && window.adsk.components.universalFooter) {
      window.adsk.components.universalFooter.render('lo-uf-container', footerConfig);
    }
  }

  const checkSignInRedirect = async () => {
    try {
      const STORAGE_KEY = 'autodesk_oxygen_redir_url';
      let redirUrl = await JSON.parse(window.sessionStorage.getItem(STORAGE_KEY));

      if (redirUrl) {
        window.sessionStorage.removeItem(STORAGE_KEY);
        window.parent.location.href = redirUrl;
      }
    } catch (e) {
      console.log(e);
    }
  };

  checkSignInRedirect();
  initAutodeskHeader();
  initAutodeskFooter();

  return (
    <ThemeMui>
      <GlobalStyles />
      <Router />
    </ThemeMui>
  );
}

export default App;
