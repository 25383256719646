import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const Image = styled.img`
  max-width: 100%;
  max-height: 40rem;
`;

export const LinkWrapper = styled.div`
  display: flex;
  margin-bottom: ${lightTheme.primitives.spacings['2']};
`;
