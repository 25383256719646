import React from 'react';
import PropTypes from 'prop-types';
import Thumbnail from '../Thumbnail';
import { GridWrapper } from './ThumbnailGrid.styled';

function thumbnailType(index, gridType) {
  if (gridType === 'featured') {
    return index == 0 ? 'xl' : 'sm';
  } else if (gridType === 'relatedContent') {
    return 'sm';
  } else {
    return 'md';
  }
}

const ThumbnailGrid = ({
  gridType,
  isLoading,
  thumbnails,
  name,
  username,
  isProfile,
  gridColumns = 3
}) => {
  return (
    <div data-wat-link-section="community gallery thumbnail">
      <GridWrapper gridType={gridType} gridColumns={gridColumns}>
        {thumbnails.map((item, index) => {
          return (
            <Thumbnail
              key={index + '-thumbnail-grid-' + name}
              {...item}
              type={thumbnailType(index, gridType)}
              isLoading={isLoading}
              ownProject={item.author.slug === username}
              isProfile={isProfile}
              has3dViewer={item.has3dViewer}
            />
          );
        })}
      </GridWrapper>
    </div>
  );
};

ThumbnailGrid.propTypes = {
  gridType: PropTypes.oneOf(['featured', 'relatedContent', 'listing']),
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  thumbnails: PropTypes.array.isRequired,
  username: PropTypes.string,
  isProfile: PropTypes.bool,
  has3DModel: PropTypes.bool,
  gridColumns: PropTypes.number
};

ThumbnailGrid.defaultProps = {
  username: undefined,
  isProfile: false
};

export default ThumbnailGrid;
