import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const ListWrapper = styled.div`
  border-radius: ${lightTheme.primitives.borderRadii.large};
  background-color: white;
  width: 100%;
  max-height: 303px;
  overflow: auto;
  box-shadow: rgba(204, 204, 204, 0.5) 0px 0px 0px 1px inset,
    rgb(102, 102, 102) 0px -1px 0px 0px inset;
`;

export const BulkActionsWrapper = styled.div`
  background: #000000;
  padding: ${lightTheme.primitives.spacings['3']};
  p {
    color: #ffffff;
  }
  display: flex;
  justify-content: space-between;
  gap: ${lightTheme.primitives.spacings['4']};
`;

export const BulkCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${lightTheme.primitives.spacings['4']};
  .MuiCheckbox-root {
    filter: invert(1);
  }
`;

export const Block = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: ${lightTheme.primitives.spacings['3']} ${lightTheme.primitives.spacings['6']}
    ${lightTheme.primitives.spacings['3']} ${lightTheme.primitives.spacings['3']};
  position: relative;
  transition: box-shadow 0.1s ease-in-out;
  box-shadow: ${lightTheme.tokens.effects['divider-0-top.boxShadow']};

  ${({ isDragging }) =>
    isDragging &&
    css`
      border-radius: ${lightTheme.primitives.borderRadii.large};
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 0px 50px 1px rgba(0, 0, 0, 0.1);
      background-color: white;
    `}
`;
