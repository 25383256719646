// import window from 'global/window';
import { ENVIRONMENT_CONSTANTS } from '../common/constants';

export const getEnvironmentConstant = ({ env, key }) => {
  if (ENVIRONMENT_CONSTANTS[env] && ENVIRONMENT_CONSTANTS[env][key]) {
    const defaultValue = ENVIRONMENT_CONSTANTS[env][key];

    return defaultValue;
  }

  return null;
};
