// CORE ACTIONS
export const SET_LANGUAGE = 'SET_LANGUAGE';

// CLIENT NOTIFICATIONS ACTIONS
export const ADD_CLIENT_NOTIFICATION = 'ADD_CLIENT_NOTIFICATION';
export const DISMISS_CLIENT_NOTIFICATION = 'DISMISS_CLIENT_NOTIFICATION';

// CREATE ACTIONS
export const CREATE_EMPTY_STATE = 'CREATE_EMPTY_STATE';
export const CREATE_CLEAR_BLOCKS = 'CREATE_CLEAR_BLOCKS';
export const CREATE_UPDATE_DATA = 'CREATE_UPDATE_DATA';
export const CREATE_TOGGLE_MODAL = 'CREATE_TOGGLE_MODAL';
export const CREATE_TOGGLE_EDIT_MODE = 'CREATE_TOGGLE_EDIT_MODE';
export const CREATE_PUBLISH = 'CREATE_PUBLISH';
export const CREATE_ADD_BLOCK = 'CREATE_ADD_BLOCK';
export const CREATE_REORDER_BLOCK = 'CREATE_REORDER_BLOCK';
export const CREATE_ALLOW_DOWNLOAD_ASSET = 'CREATE_ALLOW_DOWNLOAD_ASSET';
export const CREATE_EDIT_BLOCK = 'CREATE_EDIT_BLOCK';
export const CREATE_DELETE_BLOCK = 'CREATE_DELETE_BLOCK';
export const CREATE_FORM_VALIDATION_CHANGE = 'CREATE_FORM_VALIDATION_CHANGE';
export const CREATE_DETAILS_FORM_CURRENT_STEP_CHANGE = 'CREATE_DETAILS_FORM_CURRENT_STEP_CHANGE';
export const CREATE_CHANGE_PROJECT_STEP_TYPE = 'CREATE_CHANGE_PROJECT_STEP_TYPE';
export const CREATE_CHANGE_MODAL_TYPE = 'CREATE_CHANGE_MODAL_TYPE';
export const FETCH_CREATE_DETAILS_SET_STORE_DATA = 'FETCH_CREATE_DETAILS_SET_STORE_DATA';

// AUTH ACTIONS
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const SET_AUTH_USER_INFO = 'SET_AUTH_USER_INFO';
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';

export default {
  SET_LANGUAGE,
  ADD_CLIENT_NOTIFICATION,
  DISMISS_CLIENT_NOTIFICATION,
  CREATE_PUBLISH,
  CREATE_EMPTY_STATE,
  CREATE_CLEAR_BLOCKS,
  CREATE_TOGGLE_MODAL,
  CREATE_ADD_BLOCK,
  CREATE_REORDER_BLOCK,
  CREATE_ALLOW_DOWNLOAD_ASSET,
  CREATE_EDIT_BLOCK,
  CREATE_UPDATE_DATA,
  CREATE_FORM_VALIDATION_CHANGE,
  CREATE_DETAILS_FORM_CURRENT_STEP_CHANGE,
  CREATE_DELETE_BLOCK,
  SET_AUTH_STATUS,
  SET_AUTH_USER_INFO,
  AUTH_LOG_OUT,
  CREATE_CHANGE_PROJECT_STEP_TYPE,
  CREATE_CHANGE_MODAL_TYPE,
  FETCH_CREATE_DETAILS_SET_STORE_DATA,
  CREATE_TOGGLE_EDIT_MODE
};
