import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  StyledReactPlayer,
  BlockSketchFabWrapper,
  BlockScreencastWrapper,
  BlockVideoWrapper
} from './Blocks.styled';
import { withBlockDataFetch } from '../../hocs/withBlockDataFetch';

const getEmbedType = (url) => {
  if (url?.includes('sketchfab')) {
    return 'sketchfab';
  } else if (url?.includes('youtube') || url?.includes('youtu.be') || url?.includes('vimeo')) {
    return 'reactplayer';
  } else if (url?.includes('screencast')) {
    return 'screencast';
  } else {
    return 'unsupported';
  }
};

function BlockEmbed(props) {
  const { embedUrl } = props;
  const embedType = getEmbedType(embedUrl);

  if (!embedUrl) {
    return null;
  }

  return (
    <>
      {embedType === 'sketchfab' && (
        <BlockSketchFabWrapper>
          <iframe
            frameBorder="0"
            allowFullScreen
            webkitallowfullscreen
            mozallowfullscreen
            allow="autoplay; fullscreen; xr-spatial-tracking"
            width="100%"
            src={`${embedUrl}/embed`}
          ></iframe>
        </BlockSketchFabWrapper>
      )}
      {embedType === 'screencast' && (
        <BlockScreencastWrapper>
          <iframe
            frameBorder="0"
            allowFullScreen
            webkitallowfullscreen
            mozallowfullscreen
            allow="autoplay; fullscreen; xr-spatial-tracking"
            src={embedUrl}
          ></iframe>
        </BlockScreencastWrapper>
      )}
      {embedType === 'reactplayer' && (
        <BlockVideoWrapper>
          <StyledReactPlayer
            url={embedUrl}
            width="100%"
            height="100%"
            controls
            onError={(error) => {
              console.error('eror', error);
            }}
          />
        </BlockVideoWrapper>
      )}
    </>
  );
}

BlockEmbed.propTypes = {
  embedUrl: PropTypes.string
};

export const ConnectedBlockEmbed = memo(withBlockDataFetch(BlockEmbed));

const MemoizedBlockEmbed = memo(BlockEmbed);

export default MemoizedBlockEmbed;
