import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const PageContainerWrapper = styled.div`
  max-width: 1248px;
  margin: auto;
  width: 100%;
  margin-top: calc(3rem + 52px);
  margin-bottom: 3rem;
  padding: 0 2rem;
  .dhig-text-right {
    display: none;
  }

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['4']}) {
    padding: 0 3rem;

    .dhig-text-right {
      display: block;
    }
  }
  @media screen and (min-width: ${lightTheme.primitives.grid.scale['7']}) {
    padding: 0 1rem;
  }
`;
