import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@digital-hig/typography';
import Checkbox from '@digital-hig/checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  ListItemWrapper,
  ImageWrapper,
  LeftColumn,
  RightColumn,
  ActionWrapper,
  MediaLoader,
  ContentWrapper,
  Overlay,
  CenterColumn
} from './CreateBlockListItem.styled';
import Icon from '@digital-hig/icon';
import { assetType } from '../../common/constants';
import Skeleton from '@mui/material/Skeleton';

function iconForType(type) {
  switch (type) {
    case 'image':
      return 'dhig--icons--miscellaneous--file-image';
    case 'embed':
      return 'dhig--icons--actions--link';
    case 'raas_embed':
      return 'dhig--icons--actions--link';
    case '3D':
      return 'dhig--icons--miscellaneous--products-and-services';
    case 'file':
      return 'dhig--icons--miscellaneous--file-document';
    default:
      return '';
  }
}

function CreateBlockListItem({
  actions,
  type,
  onSelect,
  imageUrl,
  imageAlt,
  loading,
  loadingPercentage,
  selectable,
  allowDownload,
  downloadable,
  onAllowDownloadSelect,
  isDisabled,
  title,
  allowReorder,
  selected,
  draggableProps,
  dragHandleProps
}) {
  const { t } = useTranslation();

  const hasImage = !!imageUrl && imageUrl !== '';

  function titleType() {
    if (type === 'image') {
      return 'Image';
    } else if (type === '3D' || type === 'raas_embed') {
      return '3D Model';
    } else if (type === 'embed') {
      return 'Media';
    } else {
      return 'PDF';
    }
  }

  return (
    <ListItemWrapper {...(allowReorder ? draggableProps : {})} isDisabled={isDisabled && !loading}>
      <LeftColumn selectable={selectable}>
        {selectable && (
          <Checkbox size="small" disabled={isDisabled} onChange={onSelect} checked={selected} />
        )}
        <ImageWrapper hasCheckbox={selectable}>
          {loading && <Skeleton width="72px" height="72px" variant="rectangular" />}
          {hasImage && <img src={imageUrl} alt={imageAlt} />}
          {!loading && (
            <Overlay>
              <Icon id={iconForType(type)} title={`bloc ${type}`} />
            </Overlay>
          )}
        </ImageWrapper>
        <ContentWrapper>
          {!selectable && (
            <Typography variant="headline-smallest" component="h5">
              {titleType()}
            </Typography>
          )}

          {loading && (
            <MediaLoader
              loadingPercentage={loadingPercentage}
              infiniteLoad={loadingPercentage >= 100 && loading}
              style={{ marginTop: '8px' }}
            />
          )}
          {!!(allowDownload && !loading) && (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  disabled={isDisabled}
                  checked={downloadable}
                  onChange={onAllowDownloadSelect}
                />
              }
              label={t('create_block_list_downloadable_item_label')}
            />
          )}
        </ContentWrapper>
      </LeftColumn>
      {selectable && (
        <CenterColumn>
          <Typography variant="headline-smallest" component="h5">
            {title}
          </Typography>
        </CenterColumn>
      )}

      <RightColumn>
        <ActionWrapper>
          {actions.map((action) => {
            if (action.type !== 'button') {
              return (
                <Typography key={action.label} as="p" tabularNumeric variant="body-copy-small">
                  {t(action.label)}
                </Typography>
              );
            } else {
              return (
                <button
                  key={action.label}
                  onClick={action.onClick}
                  disabled={isDisabled || loading}
                >
                  <Icon id={action.iconId} title={t(action.iconTitle)} />
                  {t(action.label)}
                </button>
              );
            }
          })}
          {allowReorder && (
            <button
              className="create_block_action_reorder"
              disabled={isDisabled || loading}
              {...dragHandleProps}
            >
              <Icon id="dhig--icons--navigation--waffle" title="reorder" />
              {t('create_block_list_item_reorder')}
            </button>
          )}
        </ActionWrapper>
      </RightColumn>
    </ListItemWrapper>
  );
}

CreateBlockListItem.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['text', 'button']),
      onClick: PropTypes.func,
      iconId: PropTypes.string,
      iconTitle: PropTypes.string,
      label: PropTypes.string
    })
  ),
  type: PropTypes.oneOf(assetType).isRequired,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  allowDownload: PropTypes.bool,
  downloadable: PropTypes.bool,
  loading: PropTypes.bool,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  isDisabled: PropTypes.bool,
  allowReorder: PropTypes.bool,
  loadingPercentage: PropTypes.number,
  onAllowDownloadSelect: PropTypes.func,
  draggableProps: PropTypes.shape({}),
  dragHandleProps: PropTypes.shape({})
};

CreateBlockListItem.defaultProps = {
  actions: [],
  onSelect: () => {},
  onAllowDownloadSelect: () => {},
  draggableProps: {},
  dragHandleProps: {},
  selectable: false,
  selected: false,
  allowDownload: false,
  downloadable: false,
  loading: false,
  imageUrl: undefined,
  imageAlt: undefined,
  loadingPercentage: 0,
  isDisabled: false,
  allowReorder: false
};

export default CreateBlockListItem;
