import React, { useState } from 'react';
import Typography from '@digital-hig/typography';
import Snackbar from '@material-ui/core/Snackbar';
// import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { AlertIcon } from '@digital-hig/alert';
// import { Container } from '@material-ui/core';
import Fade from '@mui/material/Fade';
import IconButton from '@digital-hig/icon-button';

export default function ErrorAlert({ error, severity, onClose }) {
  const [state] = useState({
    Transition: Fade
  });

  return (
    <div className="dhig-theme--dark">
      <Snackbar
        open={true}
        autoHideDuration={4000}
        TransitionComponent={state.Transition}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        message={
          <div style={{ display: 'grid', gridTemplateColumns: '16px auto', gap: '1rem' }}>
            <AlertIcon severity={severity} />
            <div style={{ display: 'flex' }}>
              <Typography variant="short-copy-small">{error}</Typography>
            </div>
          </div>
        }
        action={
          <IconButton
            layout="hug"
            size="dense"
            variant="text"
            iconId="dhig--icons--utility--cross"
            onClick={onClose}
          ></IconButton>
        }
      />
    </div>
  );
}

ErrorAlert.propTypes = {
  onClose: PropTypes.func,
  error: PropTypes.string,
  severity: PropTypes.string
};
