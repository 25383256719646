import { getEnv } from './getEnv';
import { FORGE_AUTH_API, FORGE_CLIENT_ID, PKCE_ENV } from '../common/constants';
import { getEnvironmentConstant } from './getEnvironmentConstant';
import window from 'global/window';

let authClient;

const env = getEnv();

export const initializeClient = async () => {
  if (typeof window !== 'undefined') {
    try {
      const module = await import('@adsk/identity-web-sdk');
      const AuthClient = module.AuthClient;

      authClient = new AuthClient({
        client_id: getEnvironmentConstant({ env, key: FORGE_CLIENT_ID }),
        redirect_uri: `${getEnvironmentConstant({
          env,
          key: FORGE_AUTH_API
        })}`,
        redirect_uri_iframe: `${getEnvironmentConstant({
          env,
          key: FORGE_AUTH_API
        })}/auth`,
        env: getEnvironmentConstant({ env, key: PKCE_ENV }),
        scope: 'data:read',
        useRefreshTokens: true,
        cacheLocation: 'sessionstorage'
      });
      const token = await authClient.getAccessTokenSilently();
      return token;
    } catch (error) {
      console.error('Error loading AuthClient:', error);
    }
  } else {
    console.error('AuthClient cannot be initialized in a non-browser environment.');
  }
};

(async () => {
  await initializeClient();
})();

export const setLoginRedirects = (redirectUrl) => {
  // Set current URL in session storage to be redirected to after login.

  try {
    window.sessionStorage.setItem(
      'autodesk_oxygen_redir_url',
      redirectUrl ? JSON.stringify(redirectUrl) : JSON.stringify(document.location.href)
    );
    // Set current URL in local storage to be redirected to after verify of new acct.
    const lrsObject = JSON.parse(window.localStorage.getItem('galleryAuthRedirect')) || {};
    lrsObject.redirUrl = redirectUrl || document.location.href;
    window.localStorage.setItem('galleryAuthRedirect', JSON.stringify(lrsObject));
  } catch (e) {
    console.log(e);
  }
};

export const loginWithRedirect = async (redirectUrl) => {
  console.log(redirectUrl);
  // setLoginRedirects(redirectUrl.pathname);
  await initializeClient();
  await authClient.loginWithRedirect({
    redirectTarget: window.parent.location,
    redirectMethod: 'replace'
  });
};

export const logoutWithRedirect = () => {
  initializeClient();
  try {
    authClient.logout({
      post_logout_redirect_uri: `${getEnvironmentConstant({
        env,
        key: FORGE_AUTH_API
      })}`
    });
  } catch (e) {
    console.log(e);
  }
};

export const getAccessTokenSilently = async () => {
  const token = await initializeClient();
  return token;
};

export const logout = () => {
  if (!authClient) {
    initializeClient();
  }
  try {
    authClient.logout();
  } catch (e) {
    console.log(e);
  }
};

export default {
  loginWithRedirect,
  logout,
  logoutWithRedirect,
  getAccessTokenSilently
};
