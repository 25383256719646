export function asyncActionCreator(actionType) {
  return {
    init: (payload) => {
      return { type: `${actionType}_INIT`, payload };
    },
    success: (payload) => {
      return { type: `${actionType}_SUCCESS`, payload };
    },
    error: (payload) => {
      return { type: `${actionType}_ERROR`, payload };
    }
  };
}
