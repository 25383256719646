import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const ThumbnailPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);
  border-radius: ${lightTheme.primitives.borderRadii.large};
  box-shadow: inset 0 0 2px 0 #ffffff;
  overflow: hidden;
  min-width: 300px;
  min-height: 418px;
  width: 100%;
  height: 100%;
  position: relative;
  padding: ${lightTheme.primitives.spacings['5']} ${lightTheme.primitives.spacings['4']};
  justify-content: flex-end;
  h5,
  p {
    color: #ffffff !important;
    z-index: 1;
  }
  .thumbnail-preview-title {
    margin-bottom: ${lightTheme.primitives.spacings['2']} !important;
  }
  .thumbnail-preview-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
  }
  ${({ isBanner }) =>
    isBanner &&
    css`
      @media screen and (max-width: 768px) {
        min-height: unset;
      }
    `}
`;
