import PropTypes from 'prop-types';

export const projectPropTypes = {
  isAuthor: PropTypes.bool,
  canEdit: PropTypes.bool,
  status: PropTypes.oneOf(['draft', 'published', 'pending', 'deleted']),
  id: PropTypes.string,
  likeCount: PropTypes.number,
  isLiked: PropTypes.bool,
  slug: PropTypes.string,
  bookmarkCount: PropTypes.number,
  isBookmarked: PropTypes.bool,
  heroData: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    avatarProps: PropTypes.shape({
      name: PropTypes.string,
      profilePicture: PropTypes.string,
      profileUrl: PropTypes.string,
      subtitle: PropTypes.string
    }),
    imageUrl: PropTypes.string,
    imageAlt: PropTypes.string,
    showSearchbar: PropTypes.bool
  }),
  blocks: PropTypes.array,
  footer: PropTypes.shape({
    avatarProps: PropTypes.shape({
      name: PropTypes.string,
      profilePicture: PropTypes.string,
      profileUrl: PropTypes.string,
      subtitle: PropTypes.string
    }),
    industries: PropTypes.array,
    tags: PropTypes.array,
    products: PropTypes.array
  }),
  downloads: PropTypes.array
};
