import React from 'react';
import Grid from '@digital-hig/grid';
import Divider from '@digital-hig/divider';
import Typography from '@digital-hig/typography';
import { t } from 'i18next';
import { Image } from './Error404.styled';
import PageContainer from './../PageContainer';
import NoResultLinks from '../NoResultLinks';
import { STATIC_PATH } from '../../common/constants';
import { getEnv } from '../../utils/getEnv';
import { getEnvironmentConstant } from '../../utils/getEnvironmentConstant';

const Error404 = () => {
  const env = getEnv();
  const imgPath = getEnvironmentConstant({ env, key: STATIC_PATH });

  return (
    <PageContainer>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography component="h5" variant="headline-small" sx={{ mb: 4 }} className="wd-mv-">
            {t('error_404_error')}
          </Typography>
          <Divider />
          <Typography
            component="h1"
            variant="headline-large"
            sx={{ mb: 2, mt: 8 }}
            className="wd-mv-"
          >
            {t('error_404_title')}
          </Typography>
          <Typography component="p" variant="body-copy-medium" sx={{ mb: 8 }} className="wd-mv-">
            {t('error_404_description')}
          </Typography>
          <NoResultLinks />
        </Grid>
        <Grid item xs={12} md={6} className="dhig-text-right">
          <Image src={imgPath + '/static/images/404.jpg'} alt="" />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Error404;
