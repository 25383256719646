import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const BottomFloatingActionBarWrapper = styled.div`
  background-color: #fff;
  padding: ${lightTheme.primitives.spacings['6']};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 40;
  display: none;
  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    display: flex;
  }
`;
