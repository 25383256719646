import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { BlockSketchFabWrapper } from './Blocks.styled';
import { withBlockDataFetch } from '../../hocs/withBlockDataFetch';

function BlockRaasEmbed({ raasEmbed }) {
  return (
    <BlockSketchFabWrapper>
      <iframe
        frameBorder="0"
        allowFullScreen={true}
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allow="autoplay; fullscreen; xr-spatial-tracking"
        src={raasEmbed}
      ></iframe>
    </BlockSketchFabWrapper>
  );
}

BlockRaasEmbed.propTypes = {
  raasEmbed: PropTypes.string.isRequired
};

export const ConnectedBlockRaasEmbed = memo(withBlockDataFetch(BlockRaasEmbed));

const MemoizedBlockRaasEmbed = memo(BlockRaasEmbed);

export default MemoizedBlockRaasEmbed;
