import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SteppedProgressBarWrapper, StepWrapper } from './SteppedProgressBar.styled';
import Typography from '@digital-hig/typography';
import Icon from '@digital-hig/icon';
import { useTranslation } from 'react-i18next';

function SteppedProgressBar({
  createDetailsSteps,
  currentCreateDetailsStep,
  onStepClick,
  showStepsLabels,
  showStepsValue,
  style
}) {
  const [currentStepSate, setCurrentStepState] = useState(currentCreateDetailsStep);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentStepState(currentCreateDetailsStep);
  }, [currentCreateDetailsStep]);

  function handleClick(e) {
    setCurrentStepState(parseInt(e.currentTarget.value, 10));
    onStepClick(e);
  }

  return (
    <SteppedProgressBarWrapper style={style}>
      <ul>
        {createDetailsSteps.map((step, i) => {
          const isDisabled =
            !(currentStepSate >= i) &&
            createDetailsSteps.filter((s, j) => j < i).some((s) => !s?.validated || s.isError);

          return (
            <StepWrapper
              key={step.label}
              hasNextStep={i !== createDetailsSteps.length - 1 && createDetailsSteps.length > 1}
              isCurrentStep={step.value === currentStepSate}
              isCompleted={step.value < currentStepSate}
              isDisabled={isDisabled}
              validated={step.validated}
              isNextDisabled={!step.validated}
              isError={step.isError}
            >
              <button
                value={step.value}
                onClick={handleClick}
                id={step.label + step.value + i}
                disabled={isDisabled}
              >
                {!!(typeof step.validated === 'boolean' && step.validated && !step.isError) && (
                  <Icon id="dhig--display-icons--complete" />
                )}
                {!!(typeof step.isError === 'boolean' && step.isError) && (
                  <Icon id="dhig--display-icons--error" />
                )}
                {t('stepped_progress_bar_step', { value: step.value + 1 })}
                {showStepsValue &&
                  typeof step.validated === 'boolean' &&
                  !(step.validated || step.isError) && (
                    <Typography component="p">{step.value + 1}</Typography>
                  )}
              </button>
              {showStepsLabels && (
                <Typography
                  className="step_progress_label"
                  variant={
                    step.validated && step.value < currentStepSate
                      ? 'headline-smaller'
                      : 'body-copy-medium'
                  }
                  component="label"
                  htmlFor={step.label + step.value + i}
                  disabled={isDisabled}
                >
                  {step.label}
                </Typography>
              )}
            </StepWrapper>
          );
        })}
      </ul>
    </SteppedProgressBarWrapper>
  );
}

SteppedProgressBar.propTypes = {
  createDetailsSteps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      validated: PropTypes.bool,
      isError: PropTypes.bool
    })
  ).isRequired,
  showStepsLabels: PropTypes.bool,
  showStepsValue: PropTypes.bool,
  currentCreateDetailsStep: PropTypes.number,
  onStepClick: PropTypes.func,
  style: PropTypes.object
};

SteppedProgressBar.defaultProps = {
  showStepsLabels: false,
  alignment: 'start',
  showStepsValue: false,
  currentCreateDetailsStep: 0,
  onStepClick: () => {},
  style: {}
};

export default SteppedProgressBar;
