import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { OptionsButtonWrapper, OptionsWrapper, Options } from './OptionsButton.styled';
import Button from '@digital-hig/button';
import Icon from '@digital-hig/icon';
import { useClickOutside } from '../../utils';
import SvgMore from '@digital-hig/icon/lib/build/icons/navigation/more';
import { SvgIcon } from '@material-ui/core';
function OptionsButton(props) {
  const { label, onClick, href, icon, disabled, otherActions, inverted, isMobile } = props;

  const optionsRef = useRef();

  const [showOptions, setShowOptions] = useState(false);
  useClickOutside(optionsRef, () => setShowOptions(false));

  return (
    <OptionsButtonWrapper withoutOptions={!!(otherActions.length < 1)} inverted={inverted}>
      <Button
        onClick={onClick}
        href={href}
        startIcon={!!icon && <Icon id={icon} />}
        disabled={disabled}
        size="dense"
        variant={'outlined'}
      >
        {label}
      </Button>
      {!!(otherActions.length >= 1) && (
        <OptionsWrapper ref={optionsRef}>
          <Button onClick={() => setShowOptions(!showOptions)} size="dense" variant={'outlined'}>
            <SvgIcon>
              <SvgMore></SvgMore>
            </SvgIcon>
          </Button>
          {showOptions && (
            <Options inverted={inverted} isMobile={isMobile}>
              {otherActions.map((action) => (
                <Button
                  key={action.label}
                  {...action}
                  onClick={(e) => {
                    action.onClick(e);
                    setShowOptions(false);
                  }}
                  variant="text"
                  size="dense"
                  startIcon={!!action.icon && <Icon id={action.icon} />}
                >
                  {action.label}
                </Button>
              ))}
            </Options>
          )}
        </OptionsWrapper>
      )}
    </OptionsButtonWrapper>
  );
}

OptionsButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  href: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  inverted: PropTypes.bool,
  isMobile: PropTypes.bool,
  otherActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      href: PropTypes.string,
      icon: PropTypes.string
    })
  ),
  variant: PropTypes.oneOf(['contained', 'outlined'])
};

OptionsButton.defaultProps = {
  label: undefined,
  onClick: undefined,
  href: undefined,
  icon: undefined,
  disabled: false,
  variant: 'contained',
  otherActions: [],
  inverted: false
};

export default OptionsButton;
