import { AUTH_LOG_OUT, SET_AUTH_STATUS, SET_AUTH_USER_INFO } from './actionTypes';

export const setAuthStatusAction = (payload) => {
  return {
    type: SET_AUTH_STATUS,
    payload
  };
};

export const setAuthUserInfoAction = (payload) => {
  return {
    type: SET_AUTH_USER_INFO,
    payload
  };
};

export const authLogOutAction = () => ({
  type: AUTH_LOG_OUT
});
