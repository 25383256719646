import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { SectionWrapper, StyledAlert } from './DownloadSection.styled';
import Modal from '@digital-hig/modal';
import Button from '@digital-hig/button/lib/button';
import { t } from 'i18next';
import CreateBlockList from '../CreateBlockList';
import { AlertIcon } from '@digital-hig/alert';
import { useLazyQuery } from '@apollo/client';
import { DOWNLOAD_PROJECT } from '../../apollo/queries';
import JSZip from 'jszip';
import Progress from '@digital-hig/progress';
import { onDownloadFile, useIsSsr } from '../../utils';
import { assetType } from '../../common/constants';
import { UserContext } from '../../context/UserContext';
import { ModalContext } from '../../context/ModalContext';
// import { useDownloadProject } from '../../utils/hooks/useDownloadProject';

function DownloadSection({ projectId, downloadableItems, totalSizeMb, creator }) {
  // const { handleClick } = useDownloadProject();
  const { openModal } = useContext(ModalContext);
  const { state } = useContext(UserContext);
  const { isBrowser } = useIsSsr();
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    if (!state.isAuthenticated) {
      openModal();
    } else {
      setShowModal(true);
    }
  };

  const [downloadProject, { data }] = useLazyQuery(DOWNLOAD_PROJECT, {
    context: { clientName: 'authorized' }
  });

  var zip = new JSZip();

  const handleDownload = ({ selectedItems }) => {
    setShowModal(true);
    setIsLoading(true);
    const asset_ids = selectedItems.map((item) => parseInt(item));
    downloadProject({
      variables: {
        id: parseInt(projectId),
        asset_ids: asset_ids
      }
    });
  };

  const requestURL = ({ url, filename }) => {
    return new Promise((resolve) => {
      // Get file content from url and zip it
      var httpRequest = new XMLHttpRequest();
      httpRequest.open('GET', url, true);
      httpRequest.responseType = 'blob';
      httpRequest.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      httpRequest.onload = function () {
        if (httpRequest.readyState === 4 && httpRequest.status === 200) {
          const blob = new Blob([httpRequest.response]);
          const fileName = filename;
          zip.file(fileName, blob);
          resolve();
        }
      };
      httpRequest.send();
    });
  };

  useEffect(() => {
    if (data) {
      Promise.all(
        data.projectDownload.map((file) => {
          return requestURL(file);
        })
      )
        .then(() => {
          zip
            .generateAsync({
              type: 'blob'
            })
            .then((blob) => {
              if (!isBrowser) throw 'Cannot access window element in SSR';
              const url = window.URL.createObjectURL(new Blob([blob]));
              onDownloadFile(url, 'download.zip');
            })
            .catch((error) => console.error(error));
          setIsLoading(false);
        })
        .catch((error) => console.error(error));
    }
  }, [data]);

  return (
    <>
      {/* {openModal && <SignInAlert onClose={closeModal} />} */}
      <Button onClick={handleClick} data-wat-val={`profile: download model(s) ${creator}`}>
        {t('download_section_button_label', {
          size: totalSizeMb ? `(${totalSizeMb}Mb)` : ''
        })}
      </Button>
      <Modal
        backdropVariant="default"
        closeButtonDisplay
        maxWidth="sm"
        styleVariant="default"
        title={t('download_section_modal_title')}
        type="modal"
        open={showModal}
        handleClose={() => setShowModal(false)}
        content={
          <>
            {showAlert && (
              <StyledAlert
                icon={<AlertIcon alt="info icon" severity="info" variant="filled" />}
                severity="info"
                variant="filled"
                onClose={() => setShowAlert(false)}
                style={{
                  marginBottom: '20px'
                }}
              >
                {t('create_detail_download_terms')}
              </StyledAlert>
            )}
            <SectionWrapper>
              <CreateBlockList
                blockList={downloadableItems}
                bulkActions={[
                  {
                    label: isLoading ? (
                      <Progress />
                    ) : (
                      t('create_detail_download_download_items_btn')
                    ),
                    onClick: handleDownload
                  }
                ]}
              />
            </SectionWrapper>
          </>
        }
      />
    </>
  );
}

DownloadSection.propTypes = {
  projectId: PropTypes.string.isRequired,
  creator: PropTypes.string.isRequired,
  downloadableItems: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(assetType),
      title: PropTypes.string.isRequired,
      onSelect: PropTypes.func,
      selectable: PropTypes.bool,
      imageUrl: PropTypes.string,
      imageAlt: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['text', 'button']),
          onClick: PropTypes.func,
          iconId: PropTypes.string,
          iconTitle: PropTypes.string,
          label: PropTypes.string
        })
      )
    })
  ),
  totalSizeMb: PropTypes.number
};

export default DownloadSection;
