import coreReducer, { initialState as coreReducerInitialState } from './coreReducer';
import createReducer, { initialState as createReducerInitialState } from './createReducer';
import authReducer, { initialState as authReducerInitialState } from './authReducer';
import notificationReducer, {
  initialState as notificationReducerInitialState
} from './clientNotificationsReducer';

export default {
  core: coreReducer,
  create: createReducer,
  clientNotifications: notificationReducer,
  auth: authReducer
};

export const initialStates = {
  core: coreReducerInitialState,
  create: createReducerInitialState,
  clientNotifications: notificationReducerInitialState,
  auth: authReducerInitialState
};
