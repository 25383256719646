import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const ImageCropWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  overflow: hidden;
  position: relative;
  height: 100%;
  ${({ loading }) =>
    loading &&
    css`
      opacity: 0.6;
    `}
  canvas {
    background-color: ${lightTheme.primitives.colors.primary.black};
    height: 100% !important;
    ${({ isBanner }) =>
      isBanner &&
      css`
        @media screen and (max-width: 768px) {
          width: 363px !important;
          height: 229px !important;
        }
      `}
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ChildrenWrapper = styled.div`
  position: absolute;
  width: ${({ width }) => (!isNaN(width) ? width + 'px' : '100%')};
  height: ${({ height }) => (!isNaN(height) ? height + 'px' : '100%')};
  // top: ${({ top }) => (!isNaN(top) ? top + 'px' : '0')};
  pointer-events: none;
  ${({ isBanner }) =>
    isBanner &&
    css`
      @media screen and (max-width: 768px) {
        height: 167px;
        width: 348px;
      }
    `}
`;

export const ZoomSliderWrapperInner = styled.div`
  // padding: 0 ${lightTheme.primitives.spacings['4']};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // width: ${({ width }) => (!isNaN(width) ? width + 'px' : '100%')};
  svg {
    color: #ffffff !important;
  }
  input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    background: #ffffff;
    height: 1px;
    align-self: center;
    cursor: pointer;
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #ffffff;
    border-radius: 100%;
    width: 12px;
    height: 12px;
  }

  input[type='range']:focus {
    outline: none;
    background: #ffffff;
    height: 2px;
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;
    height: 1px;
    background: #ffffff;
  }
`;

export const ZoomSliderWrapper = styled.div`
  //width: 100%;
  display: flex;
  justify-content: center;
  background: #0000008c;
  position: absolute;
  top: 0;
  right: 0;
`;
