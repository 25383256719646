import {
  CREATE_EMPTY_STATE,
  CREATE_CLEAR_BLOCKS,
  CREATE_TOGGLE_MODAL,
  CREATE_PUBLISH,
  CREATE_ADD_BLOCK,
  CREATE_REORDER_BLOCK,
  CREATE_EDIT_BLOCK,
  CREATE_DELETE_BLOCK,
  CREATE_UPDATE_DATA,
  CREATE_FORM_VALIDATION_CHANGE,
  CREATE_DETAILS_FORM_CURRENT_STEP_CHANGE,
  CREATE_CHANGE_PROJECT_STEP_TYPE,
  CREATE_CHANGE_MODAL_TYPE,
  FETCH_CREATE_DETAILS_SET_STORE_DATA,
  CREATE_TOGGLE_EDIT_MODE,
  CREATE_ALLOW_DOWNLOAD_ASSET
} from '../actions/actionTypes';

export const initialState = {
  showModal: false,
  id: null,
  slug: undefined,
  createType: 'artwork',
  title: '',
  description: '',
  blocks: [],
  status: 'draft',
  cover: undefined,
  tags: [],
  industries: [1],
  products: [],
  unsavedActions: false,
  isEdit: false,
  currentCreateDetailsStep: 0,
  createDetailsSteps: ['info', 'categories', 'cover'].map((step, i) => ({
    label: `create_step_project_${step}`,
    value: i,
    validated: false,
    isError: false,
    allFormFilledOnce: false
  })),
  formComponentType: 'project_details',
  loading: false,
  publishStatusData: {
    loaded: false,
    loadingPercentage: 0,
    error: {
      isError: false,
      error: undefined
    }
  }
};

export default function createReducer(prevState = initialState, action) {
  const { payload, type } = action;

  function changeDownloadable(value) {
    let blocks = [...prevState.blocks];
    for (var i in blocks) {
      if (parseInt(blocks[i].id, 10) === value) {
        blocks[i].downloadable = !blocks[i].downloadable;
        break;
      }
    }
    return blocks;
  }

  switch (type) {
    case CREATE_TOGGLE_MODAL:
      return {
        ...prevState,
        showModal: !prevState.showModal
      };

    case CREATE_TOGGLE_EDIT_MODE:
      return {
        ...prevState,
        isEdit: !prevState.isEdit,
        ...payload
      };

    case CREATE_CHANGE_PROJECT_STEP_TYPE:
      return {
        ...prevState,
        type: payload
      };

    case CREATE_CHANGE_MODAL_TYPE:
      return {
        ...prevState,
        formComponentType: payload,
        ...(!prevState.showModal ? { showModal: true } : {})
      };

    case CREATE_EMPTY_STATE:
      return initialState;

    case CREATE_CLEAR_BLOCKS:
      return {
        ...prevState,
        blocks: []
      };

    case CREATE_FORM_VALIDATION_CHANGE: {
      let newSteps = prevState.createDetailsSteps.map((step) => {
        if (step.label === payload.label) {
          return payload;
        } else {
          return step;
        }
      });

      return {
        ...prevState,
        createDetailsSteps: newSteps
      };
    }

    case CREATE_DETAILS_FORM_CURRENT_STEP_CHANGE: {
      const isDirection = payload === 'prev' || payload === 'next';
      let newCurrentStep = 0;
      if (isDirection) {
        if (payload === 'next') {
          newCurrentStep = prevState.currentCreateDetailsStep + 1;
        } else if (payload === 'prev' && prevState.currentCreateDetailsStep > 0) {
          newCurrentStep = prevState.currentCreateDetailsStep - 1;
        }
      }

      return {
        ...prevState,
        currentCreateDetailsStep:
          !isDirection && typeof payload === 'number' ? payload : newCurrentStep
      };
    }

    case CREATE_UPDATE_DATA: {
      const isIndustryChange = Object.keys(payload)[0] === 'industries';
      return {
        ...prevState,
        ...payload,
        ...(isIndustryChange && { tags: [] }),
        ...(prevState.isEdit
          ? {
              unsavedActions: true
            }
          : {})
      };
    }

    case CREATE_ALLOW_DOWNLOAD_ASSET: {
      let newBlocks = changeDownloadable(parseInt(payload, 10));
      return {
        ...prevState,
        unsavedActions: true,
        blocks: newBlocks
      };
    }

    case `${CREATE_PUBLISH}_INIT`:
      return {
        ...prevState,
        loading: true,
        publishStatusData: {
          loaded: false,
          loadingPercentage: payload.progress,
          error: {
            isError: false,
            error: undefined
          }
        }
      };

    case `${CREATE_PUBLISH}_ERROR`:
      return {
        ...prevState,
        loading: false,
        publishStatusData: {
          loaded: true,
          loadingPercentage: 100,
          error: {
            isError: true,
            error: payload.error
          }
        }
      };

    case `${CREATE_PUBLISH}_SUCCESS`:
      return {
        ...prevState,
        currentCreateDetailsStep: 0,
        loading: false,
        slug: payload.slug,
        showModal: false,
        isNew: payload.isNew,
        publishStatusData: {
          loaded: true,
          loadingPercentage: 100,
          error: initialState.publishStatusData.error
        }
      };

    case CREATE_ADD_BLOCK:
      if (payload.addFrom === 'from_lib') {
        return {
          ...prevState,
          unsavedActions: true,
          blocks: [
            ...prevState.blocks,
            ...payload.payload
              .filter((id) => !prevState.blocks.map((el) => el.id).includes(id))
              .map((id) => ({
                id,
                order: prevState.blocks.length,
                addedFrom: payload.addFrom,
                type: payload.type
              }))
          ]
        };
      } else {
        return {
          ...prevState,
          unsavedActions: true,
          blocks: [...prevState.blocks, { order: prevState.blocks.length, ...payload.payload }]
        };
      }

    case CREATE_DELETE_BLOCK: {
      const newBlocks = prevState.blocks.filter((block) => block.id !== payload);
      return {
        ...prevState,
        unsavedActions: true,
        blocks: newBlocks
      };
    }

    case CREATE_REORDER_BLOCK: {
      return {
        ...prevState,
        blocks: payload.map((el, i) => ({ ...el, order: i })),
        unsavedActions: true
      };
    }

    case CREATE_EDIT_BLOCK: {
      const blockIndex = prevState.blocks.findIndex((el) => el.id === payload.id);
      prevState.blocks[blockIndex] = {
        ...payload,
        order: prevState.blocks[blockIndex]?.order,
        id: payload.newId
      };
      delete prevState.blocks[blockIndex].newId;

      return {
        ...prevState,
        unsavedActions: true
      };
    }

    case FETCH_CREATE_DETAILS_SET_STORE_DATA:
      return {
        ...prevState,
        ...payload
      };

    default:
      return prevState;
  }
}
