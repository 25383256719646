import styled from 'styled-components';

export const StyledImage = styled.img`
  position: ${({ isBackground }) => (isBackground ? 'absolute' : 'relative')};
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
