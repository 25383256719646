/* eslint-disable react/prop-types */
// reason: some properties are from the react-beautiful-dnd and don't need to be defined
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  ListWrapper,
  Block,
  BulkActionsWrapper,
  BulkCheckboxWrapper
} from './CreateBlockList.styled';
import CreateBlockListItem from '../CreateBlockListItem';
import CreateBlockListItemConnected from '../CreateBlockListItem/withBlockDataFetchHOC';
import Typography from '@digital-hig/typography';
import { t } from 'i18next';
import Checkbox from '@digital-hig/checkbox';
import OptionsButton from '../OptionsButton';
import { assetType } from '../../common/constants';

function CreateBlockList({ blockList, onReorder, allowReorder, onSelectDownload, bulkActions }) {
  const [blockListState, setBlockListState] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const bottomRef = useRef(null);

  useEffect(() => {
    setBlockListState(blockList);
  }, [blockList]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(blockList, result.source.index, result.destination.index);
    setBlockListState(items);
    onReorder(items);
  };

  function handleSelectBlock(e, block) {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, block.id]);
    } else {
      const newSelectedList = [...selectedItems].filter((item) => item !== block.id);
      setSelectedItems(newSelectedList);
    }
  }

  const hasLoadingItems =
    blockListState.length >= 1 ? blockListState.some((el) => el?.loading) : false;

  function delay(milliseconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }
  useEffect(() => {
    async function init() {
      await delay(600);
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    init();
  }, [blockList.length]);

  const BlockComp = ({ provided, snapshot, block }) => {
    const blockProps = {
      ...block,
      isDisabled: hasLoadingItems,
      dragHandleProps: provided?.dragHandleProps,
      allowReorder: allowReorder,
      onAllowDownloadSelect: () => onSelectDownload(block.id),
      onSelect: block.selectable ? (e) => handleSelectBlock(e, block) : () => {},
      selected: selectedItems.includes(block.id)
    };
    return (
      <Block
        ref={provided?.innerRef}
        isDragging={snapshot?.isDragging}
        {...(allowReorder ? provided?.draggableProps : {})}
      >
        {block.addedFrom === 'from_lib' ? (
          <CreateBlockListItemConnected {...blockProps} />
        ) : (
          <CreateBlockListItem {...blockProps} />
        )}
      </Block>
    );
  };

  function onBulkSelectChange() {
    if (selectedItems.length < blockList.length) {
      setSelectedItems(blockList.map((el) => el.id));
    } else if (selectedItems.length === blockList.length) {
      setSelectedItems([]);
    }
  }

  return (
    <ListWrapper>
      {!!(selectedItems.length >= 1) && (
        <BulkActionsWrapper>
          <BulkCheckboxWrapper>
            <Checkbox
              checked={selectedItems.length === blockList.length}
              indeterminate={selectedItems.length >= 1 && selectedItems.length < blockList.length}
              onChange={onBulkSelectChange}
              size="small"
            />
            <Typography as="p" variant="short-copy-medium">
              {t('block_list_selected_items', {
                count: selectedItems.length
              })}
            </Typography>
          </BulkCheckboxWrapper>
          {!!(bulkActions.length >= 1) && (
            <OptionsButton
              label={bulkActions[0].label}
              icon={bulkActions[0].icon}
              onClick={(e) => bulkActions[0].onClick({ ...e, selectedItems })}
              variant="outlined"
              inverted
              otherActions={[...bulkActions]
                .filter((el, i) => i > 0)
                .map((el) => ({ ...el, onClick: (e) => el.onClick({ ...e, selectedItems }) }))}
            />
          )}
        </BulkActionsWrapper>
      )}
      {allowReorder ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...(allowReorder ? provided.droppableProps : {})} ref={provided.innerRef}>
                {blockListState.map((block, i) => {
                  return (
                    <Draggable
                      isDragDisabled={hasLoadingItems}
                      key={block.id}
                      draggableId={block.id}
                      index={i}
                    >
                      {(provided, snapshot) => {
                        return <BlockComp provided={provided} snapshot={snapshot} block={block} />;
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        blockListState.map((block) => <BlockComp key={block.id} block={block} />)
      )}
      <div ref={bottomRef} />
    </ListWrapper>
  );
}

CreateBlockList.propTypes = {
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      onClick: PropTypes.func,
      href: PropTypes.string,
      icon: PropTypes.string
    })
  ),
  blockList: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(assetType).isRequired,
      title: PropTypes.string,
      onSelect: PropTypes.func,
      selectable: PropTypes.bool,
      allowDownload: PropTypes.bool,
      downloadable: PropTypes.bool,
      loading: PropTypes.bool,
      imageUrl: PropTypes.string,
      imageAlt: PropTypes.string,
      loadingPercentage: PropTypes.number,
      isDisabled: PropTypes.bool,
      allowReorder: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['text', 'button']),
          onClick: PropTypes.func,
          iconId: PropTypes.string,
          iconTitle: PropTypes.string,
          label: PropTypes.string
        })
      )
    })
  ).isRequired,
  onReorder: PropTypes.func,
  onSelectDownload: PropTypes.func,
  allowReorder: PropTypes.bool
};

CreateBlockList.defaultProps = {
  onReorder: () => {},
  onSelectDownload: () => {},
  allowReorder: false,
  bulkActions: []
};

export default CreateBlockList;
