import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .css-1g2aded-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: transparent;
    border: 1.5px solid black;
  }
`;
