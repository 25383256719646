import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import ContentWrapper from '../ContentWrapper';
import Slider from '../Slider';

export const AvatarSliderWrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  padding: 0 2rem;

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['4']}) {
    padding: 0 3rem;
  }
  @media screen and (min-width: ${lightTheme.primitives.grid.scale['7']}) {
    padding: 0 1rem;
  }

  h3 {
    position: relative;
    display: block;
    margin-right: ${lightTheme.primitives.spacings['4']};
    flex: 0.2 1 0;
  }

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    flex-direction: column;
    align-items: flex-start;

    h3 {
      flex-basis: 100%;
      margin-bottom: ${lightTheme.primitives.spacings['6']};
      margin-right: 0;
    }
  }

  .avatar-slider-item {
    p {
      color: #000;
    }
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;

      h6 {
        overflow: visible;
        text-overflow: unset;
        line-clamp: unset;
        -webkit-line-clamp: unset;
        line-height: 1.2em !important;
        margin-top: ${lightTheme.primitives.spacings['1']};
      }
      p {
        display: none;
      }

      .avatar--image-wrapper {
        margin: 0;
      }
    }
  }
`;

export const StyledSlider = styled(Slider)`
  flex: 0.8 1 0;
  width: 80%;

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    width: 100%;
  }
`;
