import React from 'react';
import PropTypes from 'prop-types';
import { AvatarWrapper, AvatarWrapperWithLink, ImageWrapper } from './Avatar.styled';
import Typography from '@digital-hig/typography';

function Avatar({
  name,
  profileUrl,
  profilePicture,
  profilePictureAlt,
  subtitle,
  size,
  target,
  className,
  slug
}) {
  const Content = () => (
    <>
      <ImageWrapper size={size} className="avatar--image-wrapper">
        <img
          src={profilePicture || 'https://images.profile-stg.autodesk.com/default/user_X120.png'}
          alt={name || profilePictureAlt}
        />
      </ImageWrapper>
      {!!(name || subtitle) && (
        <div className="avatar--content-wrapper">
          {!!name && (
            <Typography
              component="h6"
              variant={size === 'x-large' ? 'headline-smallest' : 'body-copy-small'}
            >
              {name}
            </Typography>
          )}
          {!!subtitle && (
            <Typography component="p" variant="smallprint" color="ink-on-background">
              {subtitle}
            </Typography>
          )}
        </div>
      )}
    </>
  );

  return profileUrl ? (
    <AvatarWrapperWithLink
      to={profileUrl}
      target={target}
      className={className}
      data-wat-val={`profile: ${slug}`}
      underline="none"
    >
      <Content />
    </AvatarWrapperWithLink>
  ) : (
    <AvatarWrapper className={className}>
      <Content />
    </AvatarWrapper>
  );
}

Avatar.propTypes = {
  name: PropTypes.string,
  profileUrl: PropTypes.string,
  profilePicture: PropTypes.string,
  profilePictureAlt: PropTypes.string,
  subtitle: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'x-large']),
  target: PropTypes.string,
  className: PropTypes.string,
  slug: PropTypes.string
};

Avatar.defaultProps = {
  name: undefined,
  profileUrl: undefined,
  profilePicture: undefined,
  profilePictureAlt: undefined,
  subtitle: undefined,
  size: 'medium',
  target: '',
  className: undefined,
  slug: undefined
};

export default Avatar;
