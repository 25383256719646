import { ADD_CLIENT_NOTIFICATION, DISMISS_CLIENT_NOTIFICATION } from './actionTypes';

export const addClientNotificationAction = (payload) => {
  return {
    type: ADD_CLIENT_NOTIFICATION,
    payload
  };
};

export const dismissClientNotificationAction = (payload) => {
  return {
    type: DISMISS_CLIENT_NOTIFICATION,
    payload
  };
};
