import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@digital-hig/typography';
import { PaginationWrapper } from './Pagination.styled';
import { Pagination } from '@mui/material';
import { useIsMobile } from '../../utils';

const PaginationComp = ({
  totalPageCount,
  currentPage,
  itemsPerPage,
  onChangePage,
  totalItems,
  paginationType = 'project'
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const getCurrentMaxVisibleItems = () => {
    let maxVisibleItems = currentPage * itemsPerPage;
    if (maxVisibleItems > totalPageCount * itemsPerPage) {
      maxVisibleItems = totalPageCount * itemsPerPage;
    }
    if (maxVisibleItems > totalItems) {
      maxVisibleItems = totalItems;
    }
    return maxVisibleItems;
  };

  const getCurrentMinVisibleItems = () => {
    let minVisibleItems = currentPage * itemsPerPage - itemsPerPage;
    if (minVisibleItems == 0) {
      minVisibleItems = 1;
    }
    return minVisibleItems;
  };

  const onClickChangePage = (e, page) => {
    e.preventDefault();
    // navigate(`/?page=${page}`);
    onChangePage(page);
  };

  return (
    <PaginationWrapper>
      <Pagination
        count={totalPageCount}
        page={currentPage}
        shape="rounded"
        onChange={onClickChangePage}
        size={isMobile ? 'small' : 'medium'}
        boundaryCount={1}
        siblingCount={isMobile ? 0 : 1}
      />
      <div>
        <Typography variant="smallprint" component="span">
          {t(`pagination_${paginationType}_label`, {
            itemsCount: `${getCurrentMinVisibleItems()} - ${getCurrentMaxVisibleItems()}`,
            totalItems
          })}
        </Typography>
      </div>
    </PaginationWrapper>
  );
};

PaginationComp.propTypes = {
  totalPageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  paginationType: PropTypes.string,
  totalItems: PropTypes.number.isRequired
};

export default PaginationComp;
