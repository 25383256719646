import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import CreatePage from './app/pages/CreatePage';
import DetailPage from './app/pages/DetailPage';
import HomePage from './app/pages/HomePage';
import SearchPage from './app/pages/SearchPage';
import CategoryArchitecturePage from './app/pages/CategoryPages/CategoryArchitecturePage';
import CategoryProductPage from './app/pages/CategoryPages/CategoryProductPage';
import CategoryMediaPage from './app/pages/CategoryPages/CategoryMediaPage';
import Error404Page from './app/pages/Error404Page';
import CommunityProfileLite from './app/pages/CommunityProfileLite';
import { trackPageView } from './app/utils/trackingScripts';
import ScrollToTop from './app/utils/ScrollToTop';
import ShareAsset from './app/pages/ShareAsset/ShareAsset';
import { useLocation } from 'react-router-dom';
import Layout from './app/components/Layout';
import TermsAndConditionsPage from './app/pages/Terms&ConditionsPage';
import DesignedAndMadeInFusionListingPage from './app/pages/Campaigns/Design&MadeinFusionListingPage';
import DesignedAndMadeInFusion from './app/pages/Campaigns/DesignedAndMadeInFusion';

export default function Router() {
  const location = useLocation();

  useEffect(() => {
    trackPageView();
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="community/gallery/auth" />
          <Route path="create" element={<CreatePage />} />
          <Route path="search" element={<SearchPage />} />
          <Route path="project/:id/:slug" element={<DetailPage />} />
          <Route path="project/:id/assets/:assetId/embed" element={<ShareAsset />} />
          <Route
            path="architecture-engineering-construction"
            element={<CategoryArchitecturePage />}
          />
          <Route
            path="designed-and-made-in-fusion/projects"
            element={<DesignedAndMadeInFusionListingPage />}
          />
          <Route path="designed-and-made-in-fusion" element={<DesignedAndMadeInFusion />} />
          <Route path="product-design-manufacturing" element={<CategoryProductPage />} />
          <Route path="media-entertainment" element={<CategoryMediaPage />} />
          <Route path="profile/:id" element={<CommunityProfileLite />} />
          <Route path="/tc" element={<TermsAndConditionsPage />} />
          <Route path="*" element={<Error404Page />} />
        </Route>
      </Routes>
    </>
  );
}
