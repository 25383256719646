import React, { useEffect, useState, useContext } from 'react';
import Grid from '@digital-hig/grid';
import { Avatar } from '@mui/material';
import { Box } from '@mui/material';
import { BoxContainer, GridContainer, BottomContainer } from './ProfileLite.styled';
import Icon from '@digital-hig/icon';
import Typography from '@digital-hig/typography';
import { useQuery } from '@apollo/client';
import { GET_PROFILE } from '../apollo/queries';
import CommunityProfilePage from './CommunityProfilePage';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserContext } from '../context/UserContext';
import { Helmet } from 'react-helmet-async';
import { getEnv } from '../utils/getEnv';
import { getEnvironmentConstant } from '../utils/getEnvironmentConstant';
import { PROFILE_URL, STATIC_PATH, FORUMS_PROFILE_URL } from '../common/constants';
import Button from '@digital-hig/button/lib/button';

const CommunityProfileLite = () => {
  const env = getEnv();
  const imgBasePath = getEnvironmentConstant({ env, key: STATIC_PATH });
  const heroImg = imgBasePath + '/static/images/brandImage.png';
  const myProfile = useSelector((state) => state.auth);
  const [profileData, setProfiletData] = useState({ isDataSet: false });
  const [isMyProfile, setIsMyProfile] = useState(false);
  const { id } = useParams();
  const { state } = useContext(UserContext);

  const { data } = useQuery(GET_PROFILE, {
    context: { clientName: 'public' },
    variables: { slug: id },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setProfiletData({ data, isDataSet: true });
    }
  });
  const { khoros_id } = data?.user || {};

  let profileUrl = khoros_id
    ? `${getEnvironmentConstant({ env, key: FORUMS_PROFILE_URL })}/${khoros_id}`
    : '';

  if (!profileUrl && state.isAuthenticated && myProfile.myProfile.slug === id) {
    profileUrl = getEnvironmentConstant({ env, key: PROFILE_URL });
  }

  useEffect(() => {
    if (state.isAuthenticated && myProfile.myProfile.slug === id) {
      setIsMyProfile(true);
    }
  }, [myProfile, id]);

  return (
    <>
      <Helmet
        title={`${data?.user?.display_name} | Autodesk Community Gallery`}
        meta={[
          {
            property: 'og:image',
            content: 'https://dsuj2mkiosyd2.cloudfront.net/seo/en/000/001/og_image_original.jpg'
          },
          {
            property: 'og:title',
            content: `Autodesk Community Gallery`
          },
          {
            property: 'og:url',
            content: `https://www.autodesk.com/community/gallery/profile/${data?.user?.slug}`
          },
          {
            property: 'og:description',
            content: `Come and see ${data?.user?.display_name}'s Profile on the Autodesk Community Gallery`
          },
          {
            property: 'twitter:title',
            content: `Autodesk Community Gallery`
          }
        ]}
        description={`Come and see ${data?.user?.display_name}'s Profile on the Autodesk Community Gallery`}
      />
      <BoxContainer>
        <GridContainer>
          <Grid item className="info-section">
            <Avatar
              className="avatar-section"
              alt={`${profileData?.data?.user?.display_name}`}
              src={
                profileData?.data?.user?.avatar_big
                  ? profileData?.data?.user?.avatar_big
                  : profileData?.data?.user?.avatar_small
              }
            />
          </Grid>
          <Grid className="profile-meta">
            <Typography variant="headline-small" className="wrap-name" color="text-on-fill-primary">
              {profileData?.data?.user?.display_name}
            </Typography>
            {profileUrl && (
              <Button
                href={profileUrl}
                variant="contained"
                className="edit-profile"
                target="_blank"
                rel="noreferrer"
                startIcon={
                  <Icon
                    className="icon-style"
                    id="dhig--icons--actions--profile"
                    size={{ height: 20, width: 20 }}
                  />
                }
              >
                My profile
              </Button>
            )}
          </Grid>
          <Grid item xs={8} className="pattern">
            <img src={heroImg} alt="brand-image" className="brandImage" />
          </Grid>
        </GridContainer>
      </BoxContainer>

      <BottomContainer>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} className="bottomBox">
            <Grid item xs={12}>
              {profileData?.isDataSet && (
                <CommunityProfilePage
                  id={profileData?.data?.user?.id}
                  slug={profileData?.data?.user?.slug}
                  profileData={profileData?.data}
                  isMyProfile={isMyProfile}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </BottomContainer>
    </>
  );
};
export default CommunityProfileLite;
