import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const Wrapper = styled.div`
  padding: 0 ${lightTheme.primitives.spacings['9']};

  @media screen and (max-width: 900px) {
    padding: 0 ${lightTheme.primitives.spacings['6']};
  }
`;
