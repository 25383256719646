import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import ContentWrapper from '../ContentWrapper';

const featuredStyle = css`
  grid-template-columns: 587fr repeat(2, 336fr);
  grid-template-rows: repeat(2, 418fr);
  position: relative;

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: unset;
  }
  @media screen and (max-width: ${lightTheme.primitives.grid.scale['2']}) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: unset;
  }

  .thumbnail:nth-of-type(1) {
    grid-area: 1 / 1 / 3 / 2;
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
      grid-area: 1 / 1 / 2 / 3;
      justify-self: center;
      width: 500px;
      min-width: 500px;
    }
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['2']}) {
      grid-area: unset;
      width: 100%;
      min-width: 100%;
    }
  }
  .thumbnail:nth-of-type(2) {
    grid-area: 1 / 2 / 2 / 3;
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
      grid-area: 2 / 1 / 3 / 2;
    }
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['2']}) {
      grid-area: unset;
    }
  }
  .thumbnail:nth-of-type(3) {
    grid-area: 1 / 3 / 2 / 4;
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
      grid-area: 2 / 2 / 3 / 3;
    }
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['2']}) {
      grid-area: unset;
    }
  }
  .thumbnail:nth-of-type(4) {
    grid-area: 2 / 2 / 3 / 3;
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
      grid-area: 3 / 1 / 4 / 2;
    }
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['2']}) {
      grid-area: unset;
    }
  }
  .thumbnail:nth-of-type(5) {
    grid-area: 2 / 3 / 3 / 4;
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
      grid-area: 3 / 2 / 4 / 3;
    }
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['2']}) {
      grid-area: unset;
    }
  }
`;

const normalStyle = (gridColumns) => css`
  grid-template-columns: repeat(${gridColumns}, 1fr);
  grid-template-rows: 1fr;

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['6']}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['2']}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const GridWrapper = styled(ContentWrapper)`
  display: grid;
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  padding: ${({ gridType }) => (gridType === 'relatedContent' ? '0' : '0 1.5rem')};

  ${({ gridColumns }) =>
    gridColumns &&
    css`
      grid-template-columns: repeat(${gridColumns}, 1fr);
    `}

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['7']}) {
    padding: ${({ gridType }) => (gridType === 'relatedContent' ? '0 1rem' : '0')};
  }

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }

  ${({ gridType, gridColumns }) => {
    switch (gridType) {
      case 'featured':
        return featuredStyle;
      default:
        return normalStyle(gridColumns);
    }
  }}
`;
