import styled from 'styled-components';

export const CreateFlowWrapper = styled.div`
  display: 'flex';
  flex-direction: column;
  justify-content: center;
  min-width: 512px;
  @media screen and (max-width: 767px) {
    min-width: auto;
  }
`;
