import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import Typography from '@digital-hig/typography';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AlertIcon } from '@digital-hig/alert';
import IconButton from '@digital-hig/icon-button';

function ClientNotification(props) {
  const {
    text,
    type,
    onClose
    // actions,
    // extras
  } = props;

  const { t } = useTranslation();

  function handleClose() {
    onClose();
    setOpen(false);
  }

  const [open, setOpen] = useState(true);

  const [state] = useState({
    Transition: Fade
  });

  return (
    <Box
      sx={{
        '& .MuiSnackbarContent-root': {
          '& .MuiSnackbarContent-action': {
            '& .MuiSvgIcon-root': {
              flexGrow: '1',
              order: '3'
            },
            '& .MuiTypography-root': {
              flexGrow: '3',
              order: '4',
              width: '100%'
            }
          },
          '& .MuiSnackbarContent-message': {
            '& .MuiSvgIcon-root': {
              flexGrow: '1',
              order: '1'
            },
            '& .MuiTypography-root': {
              flexGrow: '2',
              order: '2'
            },
            display: 'flex',
            gap: '8px'
          },
          backgroundColor: 'white',
          color: 'black'
        }
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        TransitionComponent={state.Transition}
        action={
          <IconButton
            layout="hug"
            size="dense"
            variant="text"
            iconId="dhig--icons--utility--cross"
            onClick={handleClose}
          ></IconButton>
        }
        message={
          <>
            <AlertIcon severity={type} />
            <Typography variant="short-copy-small">{t(text)}</Typography>
          </>
        }
      />
    </Box>
  );
}

ClientNotification.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'success', 'info']),
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  extras: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      colorTheme: PropTypes.string,
      internalUrl: PropTypes.string,
      externalUrl: PropTypes.string,
      actionType: PropTypes.oneOf(['onclick', 'externalLink', 'internalLink']),
      onClick: PropTypes.func,
      svgIcon: PropTypes.string,
      svgLocation: PropTypes.oneOf(['before', 'after'])
    })
  )
};

ClientNotification.defaultProps = {
  type: 'info',
  autoDismiss: false,
  onAutoDismiss: () => {},
  autoDismissTimeoutMs: 5000,
  closable: false,
  extras: {},
  onClose: () => {},
  actions: []
};

export default ClientNotification;
