import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StepWrapper } from './DetailsSteps.styled';
import Typography from '@digital-hig/typography';
import { t } from 'i18next';
import FormControl from '@digital-hig/form-control';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@digital-hig/radio';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import Chip from '@mui/material/Chip';
import {
  createUpdateData,
  createDetailsFormValidation
} from '../../../../store/actions/createActions';
import { useDispatch, useSelector } from 'react-redux';

function Categories({ isEdit, onChange, data, error, campaign }) {
  const [industries, setIndustries] = useState(data.industries);
  const createState = useSelector((state) => state.create);

  const { createDetailsSteps } = createState;

  const dispatch = useDispatch();

  useEffect(() => {
    setIndustries(data.industries);
  }, [data.industries]);

  useEffect(() => {
    if (campaign == 'designed&madeinfusion') {
      dispatch(createUpdateData({ ['products']: [9] }));
      // console.log(currentCreateDetailsStep);
      dispatch(
        createDetailsFormValidation({
          ...createDetailsSteps[1],
          validated: true,
          allFormFilledOnce: true
        })
      );
    }
  }, [campaign]);

  return (
    <StepWrapper>
      {!isEdit && (
        <Typography component="h5" variant="headline-medium">
          {t('create_categories_form_title')}
        </Typography>
      )}
      <FormControl
        InputLabelProps={{ component: 'legend' }}
        label={t('create_categories_industry_form_label')}
        onChange={(e) =>
          onChange({
            key: 'industries',
            value: [parseInt(e.target.value, 10)]
          })
        }
      >
        <RadioGroup>
          {industries.map((industry) => {
            return (
              <FormControlLabel
                key={industry.label}
                control={<Radio />}
                value={industry.value}
                label={t(industry.label)}
                checked={industry.selected}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      {['products', 'tags'].map((cat) => {
        if (data[cat]?.length >= 1) {
          return (
            <Autocomplete
              multiple
              className="categories_multiselect"
              key={`categories_multiselect_${cat}`}
              id={`categories_multiselect_${cat}`}
              options={data[cat]
                .filter((el) => !el.selected)
                .map((e) => ({ ...e, label: t(e.label) }))}
              getOptionLabel={(e) => t(e.label)}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  return (
                    <Chip
                      key={data[cat] + index}
                      label={option.label}
                      {...getTagProps({ index })}
                      disabled={campaign == 'designed&madeinfusion' && option.value === 9}
                    />
                  );
                })
              }
              value={data[cat].filter((el) => el.selected)}
              onChange={(e, v) => {
                return onChange({
                  key: cat,
                  value: v.map((el) => el.value)
                });
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    error={error.allFormFilledOnce && error[cat]}
                    variant="outlined"
                    label={t(`create_categories_${cat}_form_label`, {
                      hintText: cat === 'tags' ? t(`generic_optionnal_field`) : ''
                    })}
                    multiline
                  />
                );
              }}
            />
          );
        }
      })}
    </StepWrapper>
  );
}

Categories.propTypes = {
  onChange: PropTypes.func,
  isEdit: PropTypes.bool,
  campaign: PropTypes.string,
  data: PropTypes.shape({
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        selected: PropTypes.bool
      })
    ),
    industries: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        selected: PropTypes.bool
      })
    ),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        selected: PropTypes.bool
      })
    )
  }).isRequired,
  error: PropTypes.shape({
    tags: PropTypes.bool,
    products: PropTypes.bool,
    industries: PropTypes.bool,
    allFormFilledOnce: PropTypes.bool
  })
};
Categories.defaultProps = {
  onChange: () => {},
  isEdit: false,
  campaign: '',
  data: {
    tags: [],
    industries: [],
    products: []
  },
  error: {
    tags: false,
    products: false,
    industries: false
  }
};

export default Categories;
