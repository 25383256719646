import React, { useEffect, useState } from 'react';
import Banner from '../../components/Banner';
import ProjectListing from '../../components/ProjectListing';
import BottomFloatingActionBar from '../../components/BottomFloatingActionBar';
import Button from '@digital-hig/button';
import Icon from '@digital-hig/icon';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useOpenCreateModal } from '../../utils/hooks/useOpenCreateModal';
import { useSelector } from 'react-redux';
import { HOME_QUERY } from '../../apollo/queryPages';
import { useQuery } from '@apollo/client';

const DesignedAndMadeInFusionListingPage = () => {
  const { t } = useTranslation();
  const { handleOpenCreateModal } = useOpenCreateModal();

  const { loading: loadingAuth } = useSelector((state) => state.auth);
  const username = useSelector((state) => state?.auth?.myProfile?.slug);

  const { data: pageDataAPI } = useQuery(HOME_QUERY, {
    context: { clientName: 'public' },
    variables: {
      id: 6,
      lang: 'en'
    }
  });

  const [pageData, setPageData] = useState({
    heroData: null
  });

  useEffect(() => {
    if (pageDataAPI?.page) {
      const data = pageDataAPI.page;
      setPageData({
        ...pageDataAPI,
        heroData: {
          type: 'hero',
          title: data.hero_title,
          subtitle: data.hero_description,
          avatarProps: {
            name: `${data.hero_project?.author.display_name}`,
            profilePicture: data.hero_project?.author.avatar_big,
            profileUrl: `/profile/${data.hero_project?.author.slug}`,
            slug: data.hero_project?.author.slug
          },
          showSearchbar: false,
          image: {
            src: data.hero_project?.image?.path,
            alt: data.hero_project?.title,
            srcSet: data.hero_project?.image?.sizes
          }
        }
      });
    }
  }, [pageDataAPI]);

  return (
    <>
      <Helmet
        title={`${pageDataAPI?.page.seo?.title} | Autodesk Community Gallery`}
        meta={[
          {
            property: 'og:image',
            content:
              pageDataAPI?.page?.seo?.image ||
              'https://dsuj2mkiosyd2.cloudfront.net/seo/en/000/001/og_image_original.jpg'
          },
          {
            property: 'og:title',
            content: `${pageDataAPI?.page.seo?.og_title} | Autodesk Community Gallery`
          },
          { property: 'og:url', content: `https://www.autodesk.com/community/gallery` },
          {
            property: 'og:description',
            content: `${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`
          },
          {
            property: 'twitter:title',
            content: `${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`
          }
        ]}
        description={`${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`}
      />
      {pageData.heroData && <Banner showSearchbar {...pageData.heroData} />}
      <ProjectListing
        name="homepage"
        pageType="campaign"
        setToggleModal={handleOpenCreateModal}
        loadingAuth={loadingAuth}
        username={username}
      />
      <BottomFloatingActionBar>
        <Button
          className="filterbar--button"
          onClick={handleOpenCreateModal}
          startIcon={<Icon id="dhig--icons--utility--add" />}
        >
          {t('create_your_project_btn_label')}
        </Button>
      </BottomFloatingActionBar>
    </>
  );
};

export default DesignedAndMadeInFusionListingPage;
