import PropTypes from 'prop-types';
import { ContextualBannerWrapper } from './ContextualBanner.styled';
import Grid from '@digital-hig/grid';
import Typography from '@digital-hig/typography';
import Button from '@digital-hig/button';
import { getEnvironmentConstant } from '../../utils/getEnvironmentConstant';
import { STATIC_PATH } from '../../common/constants';
import { getEnv } from '../../utils/getEnv';

const ContextualBanner = ({ title, description, image_url, image_alt, cta_link = null }) => {
  const env = getEnv();
  const imgPath = getEnvironmentConstant({ env, key: STATIC_PATH });

  return (
    <ContextualBannerWrapper className="contextual-banner">
      <div className="contextual-banner__media">
        <img className="contextual-banner__image" src={image_url} alt={image_alt} />
        <div className="contextual-banner__logo">
          <img src={`${imgPath}/static/images/logo.svg`} alt="Autodesk Logo" />
        </div>
      </div>
      <Grid className="contextual-banner__content">
        <Grid className="headline-text">
          <Typography color="ink-on-fill-primary" variant="headline-large">
            {title}
          </Typography>
          {description && (
            <Typography color="ink-on-fill-primary" variant="body-copy-medium">
              {description}
            </Typography>
          )}
          {cta_link && (
            <Button className="headline-text__cta" href={cta_link.url} variant="outlined">
              {cta_link.label}
            </Button>
          )}
        </Grid>
      </Grid>
    </ContextualBannerWrapper>
  );
};

ContextualBanner.propTypes = {
  title: PropTypes.string.isRequired,
  image_url: PropTypes.string.isRequired,
  image_alt: PropTypes.string.isRequired,
  description: PropTypes.string,
  cta_link: PropTypes.object
};

export default ContextualBanner;
