import React, { useEffect, useState, useContext } from 'react';
import { setAuthUserInfoAction } from '../../store/actions/authActions';
import { useTranslation } from 'react-i18next';
import Typography from '@digital-hig/typography';
import IconButton from '@digital-hig/icon-button';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { GET_MY_PROFILE } from '../../apollo/queries';
import { useSelector } from 'react-redux';
import SvgIcon from '@mui/material/SvgIcon';
import Button from '@digital-hig/button';
import { HelpSolid } from '@digital-hig/icon/lib/build/icons/utility';
import { Profile } from '@digital-hig/icon/lib/build/icons/actions';
import {
  NavigationWrapper,
  NavigationTitle,
  NavigationContainer,
  NavigationNav,
  NavigationItem,
  NavigationItems,
  NavigationLink,
  NavigationItemIcon
} from './Navigation.styled';

const Navigation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state } = useContext(UserContext);
  const [windowDimensions, setWindowDimensions] = useState({});
  const [isNavOpen, setIsNavOpen] = useState(false);
  const myProfile = useSelector((state) => state.auth?.myProfile);
  const linksDatas = [
    {
      label: 'navigation_item1',
      url: `/architecture-engineering-construction`,
      icon: false
    },
    {
      label: 'navigation_item2',
      url: `/product-design-manufacturing`,
      icon: false
    },
    {
      label: 'navigation_item3',
      url: `/media-entertainment`,
      icon: false
    },
    ...(state.isAuthenticated
      ? [
          {
            label: 'navigation_item4',
            url: `/profile/${myProfile?.slug}`,
            icon: true
          }
        ]
      : [])
  ];
  const location = useLocation();

  const [getMyProfile] = useLazyQuery(GET_MY_PROFILE, {
    skip: !state.isAuthenticated,
    context: { clientName: 'authorized' },
    onCompleted: (res) => dispatch(setAuthUserInfoAction({ myProfile: res.myProfile }))
  });
  useEffect(() => {
    if (state.isAuthenticated) {
      getMyProfile();
    }
  }, [getMyProfile, state.isAuthenticated, myProfile]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onNavToggleMobile = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    setIsNavOpen(false);
  }, [location]);

  return (
    <NavigationWrapper data-wat-link-section="community gallery navigation">
      <NavigationContainer>
        <NavigationTitle to="/" data-wat-val={t('navigation_title')}>
          <Typography
            variant="body-copy-medium"
            component="span"
            style={{ textTransform: 'uppercase' }}
          >
            {t('navigation_title')}
          </Typography>
        </NavigationTitle>
        {windowDimensions.width < 1024 && (
          <IconButton
            layout="hug"
            size="defaultSize"
            variant="text"
            iconId="dhig--icons--utility--caret-down"
            onClick={onNavToggleMobile}
          ></IconButton>
        )}
        <NavigationNav isNavOpen={isNavOpen}>
          <NavigationItems>
            {linksDatas.map((link) => (
              <NavigationItem key={link.url + link.label}>
                <NavigationLink to={link.url} state={link?.state} data-wat-val={t(link.label)}>
                  {link.icon && (
                    <NavigationItemIcon>
                      <SvgIcon>
                        <Profile />
                      </SvgIcon>
                    </NavigationItemIcon>
                  )}
                  {t(link.label)}
                </NavigationLink>
              </NavigationItem>
            ))}
          </NavigationItems>
        </NavigationNav>

        <NavigationItem>
          <Button
            startIcon={
              <SvgIcon fontSize="large">
                <HelpSolid />
              </SvgIcon>
            }
            size="dense"
            variant="text"
            href="https://forums.autodesk.com/t5/report-a-website-issue/ct-p/report-a-website-issue"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </Button>
        </NavigationItem>
      </NavigationContainer>
    </NavigationWrapper>
  );
};

export default Navigation;
