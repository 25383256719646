import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import Progress from '@digital-hig/progress';

export const LoadingAssetWrapper = styled.div`
  width: 100%;
  margin: auto;
  margin-top: ${lightTheme.primitives.spacings['4']};
  padding: ${lightTheme.primitives.spacings['9']};
  aspect-ratio: 1.8;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${lightTheme.tokens.colors['background-panel-distinct'].color};

  ${({ shape }) =>
    shape === 'circle' &&
    css`
      align-items: center;
    `}

  & p + p:not([class*="wd-mt-"]), p {
    margin-top: ${lightTheme.primitives.spacings['0']} !important;
  }
`;

export const Loader = styled(Progress)`
  width: 100%;
`;
