import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FileWrapper,
  LibraryWrapper,
  FileCover,
  AssetLoadingWrapper,
  ProgressWrapper
} from './FileLibrary.styled';
import IconButton from '@digital-hig/icon-button';
import Typography from '@digital-hig/typography';
import Icon from '@digital-hig/icon';
import Select from '@digital-hig/select';
import FormControl from '@digital-hig/form-control';
import { t } from 'i18next';
import Progress from '@digital-hig/progress';
import { assetType } from '../../common/constants';
import { InView } from 'react-intersection-observer';

const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);
function FileLibrary(props) {
  const {
    files,
    selected,
    // onDelete,
    selectedType,
    onTypeChange,
    // onPageChange,
    paginationData,
    fetchMore,
    onAddToSelection,
    onRemoveFromSelection,
    assetsLoaded,
    onSetLayoutTab
  } = props;

  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    if (!equals(selected, selectedFiles)) {
      setSelectedFiles(selected);
    }
  }, [selected]);

  function handleAddToSelection(id, type) {
    let newSelected = [...selectedFiles];
    if (!selectedFiles.includes(id)) {
      newSelected = [...new Set([...selectedFiles, id])];
      setSelectedFiles(newSelected);
      onAddToSelection(newSelected, type);
      onSetLayoutTab();
    }
  }

  function handleRemoveFromSelection(id) {
    let newSelected = [...selectedFiles];
    if (selectedFiles.includes(id)) {
      newSelected = newSelected.filter((el) => el !== id);
      setSelectedFiles([...new Set(newSelected)]);
      onRemoveFromSelection(id);
    }
  }

  return (
    <>
      {!!onTypeChange && (
        <FormControl id="file-type-select" label={t('library_select_file_types_label')}>
          <Select
            id="file-type-select"
            onChange={(e) => onTypeChange(parseInt(e.target.value, 10))}
            value={selectedType || 0}
            width="196px"
            options={[...assetType, 'all'].map((option, i) => ({
              children: t(`library_file_type_${option}`),
              value: i
            }))}
            style={{
              marginBottom: '24px'
            }}
          />
        </FormControl>
      )}
      <LibraryWrapper>
        {files.map((file) => {
          return (
            <FileWrapper key={file.id}>
              <FileCover>
                {file.imageUrl && <img src={file.imageUrl} alt={file.imageAlt} />}
                {!file.imageUrl && (
                  <AssetLoadingWrapper>
                    <Icon id="dhig--icons--miscellaneous--products-and-services" size="large" />
                    {file.type === '3D' && !file.hasViewer && (
                      <>
                        <Typography component="p" variant="smallprint">
                          {t('asset_loading_in_back_short')}
                        </Typography>
                        <Progress shape="bar" size="medium" />
                      </>
                    )}
                  </AssetLoadingWrapper>
                )}
                <div className="file-actions-wrapper">
                  {/* <IconButton
                    variant="text"
                    onClick={() => onDelete(file.id)}
                    iconId="dhig--icons--actions--trash"
                    size="dense"
                    aria-label={t('actions-delete')}
                  /> */}
                  <IconButton
                    variant="text"
                    onClick={() => {
                      if (selectedFiles.includes(file.id)) {
                        handleRemoveFromSelection(file.id);
                      } else {
                        handleAddToSelection(file.id, file.type);
                      }
                    }}
                    iconId={
                      selectedFiles.includes(file.id)
                        ? 'dhig--icons--utility--complete'
                        : 'dhig--icons--utility--add'
                    }
                    size="dense"
                    aria-label={t('actions-select')}
                  />
                </div>
              </FileCover>
              <Typography component="p" variant="smallprint" className="asset-title">
                {file.title}
              </Typography>
            </FileWrapper>
          );
        })}

        {files && (
          <InView
            onChange={async (inView) => {
              const nextPage = paginationData.currentPage + 1;
              if (inView && paginationData.hasMorePages) {
                await fetchMore({
                  variables: {
                    page: nextPage
                  }
                });
              }
            }}
          />
        )}
      </LibraryWrapper>
      {!assetsLoaded && (
        <ProgressWrapper>
          <Progress size="medium" />
        </ProgressWrapper>
      )}
    </>
  );
}

FileLibrary.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
      imageAlt: PropTypes.string,
      type: PropTypes.oneOf(assetType).isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  paginationData: PropTypes.shape({
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
    totalPageCount: PropTypes.number,
    hasMorePages: PropTypes.bool
  }),
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onAddToSelection: PropTypes.func,
  onRemoveFromSelection: PropTypes.func,
  onDelete: PropTypes.func,
  onTypeChange: PropTypes.func,
  onPageChange: PropTypes.func,
  selectedType: PropTypes.number,
  fetchMore: PropTypes.func,
  assetsLoaded: PropTypes.bool,
  onSetLayoutTab: PropTypes.func
};

FileLibrary.defaultProps = {
  selected: [],
  paginationData: {},
  onAddToSelection: () => {},
  onRemoveFromSelection: () => {},
  onDelete: () => {},
  onPageChange: () => {},
  onTypeChange: undefined,
  selectedType: 0,
  onSetLayoutTab: () => {}
};

export default FileLibrary;
