import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

const loggerMiddleware = createLogger();

const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem(key, value) {
      return Promise.resolve(value);
    },
    removeItem() {
      return Promise.resolve();
    }
  };
};

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const persistConfig = {
  key: 'core', // the key we want to keep stored in the local storage
  storage, // storage target (web local storage)
  whitelist: ['core', 'auth']
};

const persistedReducer = persistReducer(persistConfig, combineReducers(reducers));

const allMiddleWares = [
  thunkMiddleware,
  // we don't want to log redux actions on prod
  process.env.NODE_ENV === 'production' ? null : loggerMiddleware
].filter((x) => x);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: allMiddleWares
});

export const persistor = persistStore(store);
