import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './ContentWrapper.styled';

function ContentWrapper({ className, children }) {
  return <Wrapper className={className}>{children}</Wrapper>;
}

ContentWrapper.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default ContentWrapper;
