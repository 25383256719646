import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import ReactPlayer from 'react-player';

export const BlockTextWrapper = styled.div`
  max-width: 800px;
  margin: auto;
  margin-bottom: ${lightTheme.primitives.spacings['8']};
`;

export const Block3dViewerWrapper = styled.div`
  width: 100%;
  margin-bottom: ${lightTheme.primitives.spacings['7']};
  position: relative;
  aspect-ratio: 1.8;
  border: 1px solid ${lightTheme['primitives.colors.tint.slate-200']};
  background: ${lightTheme['primitives.colors.tint.slate-200']};
`;

export const BlockSketchFabWrapper = styled.div`
  width: 100%;
  margin-bottom: ${lightTheme.primitives.spacings['7']};
  font-size: 0;

  iframe {
    width: 100%;
    aspect-ratio: 1.8;
  }
`;

export const BlockScreencastWrapper = styled.div`
  width: 100%;
  margin-bottom: ${lightTheme.primitives.spacings['7']};
  font-size: 0;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1.3;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

export const BlockVideoWrapper = styled.div`
  width: 100%;
  margin-bottom: ${lightTheme.primitives.spacings['7']};
  font-size: 0;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  aspect-ratio: 1.8;
`;

export const BlockImageWrapper = styled.div`
  width: 100%;
  margin-bottom: ${lightTheme.primitives.spacings['7']};
  font-size: 0;
  text-align: center;
  cursor: ${(props) => (props.isEdit ? 'inherit' : 'pointer')};

  img {
    display: block;
    max-width: 100%;
    margin: auto;
    height: auto;
  }
`;
