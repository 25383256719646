/* eslint-disable no-undef */
import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Block3dViewerWrapper } from './Blocks.styled';
import { GET_ACCESS_TOKEN } from '../../apollo/queries';
import { useQuery } from '@apollo/client';
import LoadingAsset from '../LoadingAsset';
import { withBlockDataFetch } from '../../hocs/withBlockDataFetch';
import { useTranslation } from 'react-i18next';
import { getEnv } from '../../utils/getEnv';
import { getEnvironmentConstant } from '../../utils/getEnvironmentConstant';
import { VIEWER_ENV } from '../../common/constants';
import { Helmet } from 'react-helmet-async';
import window from 'global/window';

const env = getEnv();
function Block3dViewer({ urn, slug, hasViewer, viewerSetting, isDisabled }) {
  const forgeViewer = useRef();
  const { t } = useTranslation();

  const { loading, data } = useQuery(GET_ACCESS_TOKEN, {
    context: { clientName: 'public' },
    variables: {
      slug: slug
    }
  });

  useEffect(() => {
    if (window && hasViewer && viewerSetting && data !== undefined && Autodesk !== undefined) {
      var viewer;
      var options = {
        env: getEnvironmentConstant({ env, key: VIEWER_ENV }),
        api: 'derivativeV2', // for models uploaded to EMEA change this option to 'streamingV2_EU'
        getAccessToken: (onTokenReady) => {
          var token = data.publicToken.access_token;
          var timeInSeconds = data.publicToken.expires_in;
          onTokenReady(token, timeInSeconds);
        }
      };
      var config3d = {
        loaderExtensions: { svf: 'Autodesk.MemoryLimited' }
      };

      Autodesk?.Viewing.Initializer(options, () => {
        var htmlDiv = forgeViewer.current;

        viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv, config3d);
        var startedCode = viewer.start();
        if (startedCode > 0) {
          console.error('Failed to create a Viewer: WebGL not supported.');
          return;
        }
        const onDocumentLoadSuccess = (viewerDocument) => {
          var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
          viewer.loadDocumentNode(viewerDocument, defaultModel);
        };
        const onDocumentLoadFailure = (error, code) => {
          console.error(error, code);
        };
        var documentId = `urn:${urn}`;
        Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure, {
          'x-ads-acm-namespace': viewerSetting
        });
      });
    }
  }, [hasViewer, urn, data, viewerSetting]);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css"
        />
      </Helmet>
      {loading || !hasViewer ? (
        <LoadingAsset
          label={
            hasViewer !== undefined && hasViewer === false && !isDisabled
              ? t('asset_loading_in_back_long')
              : t('create_blocks_finalize_asset')
          }
          shape="bar"
        />
      ) : (
        <Block3dViewerWrapper ref={forgeViewer} />
      )}
    </>
  );
}

Block3dViewer.propTypes = {
  urn: PropTypes.string,
  slug: PropTypes.string,
  hasViewer: PropTypes.bool,
  viewerSetting: PropTypes.string,
  isDisabled: PropTypes.bool
};

export const ConnectedBlock3dViewer = withBlockDataFetch(Block3dViewer);

const MemoizedBlock3dViewer = memo(Block3dViewer);

export default MemoizedBlock3dViewer;
