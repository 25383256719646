import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SteppedProgressBar from '../../components/SteppedProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { CreateFlowWrapper } from './Create.styled';
import {
  createUpdateData,
  createFormCurrentStepChange,
  createDetailsFormValidation,
  createDeleteBlockAction,
  createReorderBlockAction,
  createAllowDownloadAssetAction
} from '../../store/actions/createActions';
import ProjectDetails from './ProjectDetails';
import ProjectBuild from './ProjectBuild/withUserAssetsHOC';
import Actions from './Actions';
import { initialState as initialCreateState } from '../../store/reducers/createReducer';
import Tabs from '@digital-hig/tabs/lib/tabs';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';
import { GET_CATEGORIES } from '../../apollo/queries';

function categoriesToViewModel(catObj, selectedCategs, campaign) {
  let newCatObj = {};
  Object.keys(catObj).map((cat) => {
    newCatObj[cat] = catObj[cat].map((el) => ({
      label: el.name,
      value: parseInt(el.id, 10),
      selected:
        (campaign == 'designed&madeinfusion' && el.id == 9) ||
        selectedCategs?.[cat]?.includes(parseInt(el.id, 10))
    }));
    if (cat === 'industries') {
      const selectedIndustry = selectedCategs[cat][0];
      const industryIndex = catObj[cat].findIndex((el) => parseInt(el.id, 10) === selectedIndustry);
      newCatObj.tags = catObj[cat][industryIndex]?.tags.map((el) => ({
        label: el.name,
        value: parseInt(el.id, 10),
        selected: selectedCategs?.tags?.includes(parseInt(el.id, 10))
      }));
    }
  });
  return newCatObj;
}

function creatorDataToViewModel(data) {
  return {
    userName: data.display_name,
    title: data.job_title,
    profilePicture: data.avatar_small
  };
}

function Create(props) {
  const { inModal, campaign } = props;

  const dispatch = useDispatch();
  const createState = useSelector((state) => state.create);
  const creatorData = useSelector((state) => state.auth.myProfile);

  const {
    currentCreateDetailsStep,
    createDetailsSteps,
    isEdit,
    title,
    description,
    tags,
    products,
    industries,
    showActionButtons,
    formComponentType,
    publishStatusData,
    cover
  } = createState;

  const { data } = useQuery(GET_CATEGORIES, {
    context: { clientName: 'public' },
    fetchPolicy: 'cache-first'
  });
  const [categories, setCategories] = useState({});

  useEffect(() => {
    if (data) {
      setCategories(categoriesToViewModel(data, { tags, products, industries }, campaign));
    }
  }, [data, tags, products, industries]);

  function handleStepClick(e) {
    const newStep = e?.currentTarget?.value ? parseInt(e.currentTarget.value, 10) : e;
    if (newStep !== currentCreateDetailsStep) {
      dispatch(createFormCurrentStepChange(newStep));
    }
  }

  function handleFormChange(formData) {
    const { key, target, value } = formData;
    dispatch(createUpdateData({ [key]: target?.value || value }));
  }

  useEffect(() => {
    switch (currentCreateDetailsStep) {
      case 0:
        if (title || description) {
          dispatch(
            createDetailsFormValidation({
              ...createDetailsSteps[currentCreateDetailsStep],
              validated: Boolean(title && title !== '' && description && description !== ''),
              allFormFilledOnce:
                title && title !== '' && description && description !== ''
                  ? true
                  : createDetailsSteps[currentCreateDetailsStep].allFormFilledOnce,
              isError:
                createDetailsSteps[currentCreateDetailsStep].allFormFilledOnce &&
                (!title || title === '' || !description || description === '')
            })
          );
        }
        break;
      case 1:
        if (industries.length >= 1 || products.length >= 1) {
          dispatch(
            createDetailsFormValidation({
              ...createDetailsSteps[currentCreateDetailsStep],
              validated: industries.length >= 1 && products.length >= 1,
              allFormFilledOnce:
                industries.length >= 1 && products.length >= 1
                  ? true
                  : createDetailsSteps[currentCreateDetailsStep].allFormFilledOnce,
              isError:
                createDetailsSteps[currentCreateDetailsStep].allFormFilledOnce &&
                !(industries.length >= 1 && products.length >= 1)
            })
          );
        }
        break;
      case 2:
        if (cover) {
          dispatch(
            createDetailsFormValidation({
              ...createDetailsSteps[currentCreateDetailsStep],
              validated: cover !== undefined && cover.File !== undefined,
              allFormFilledOnce:
                cover !== undefined && cover.File !== undefined
                  ? true
                  : createDetailsSteps[currentCreateDetailsStep].allFormFilledOnce,
              isError:
                createDetailsSteps[currentCreateDetailsStep].allFormFilledOnce &&
                !(cover !== undefined && cover.File !== undefined)
            })
          );
        }
        break;
      default:
        return () => {};
    }
  }, [title, description, tags, products, industries, cover]);

  return (
    <CreateFlowWrapper>
      {!!(
        !isEdit &&
        currentCreateDetailsStep < createDetailsSteps.length &&
        formComponentType === 'project_details'
      ) && (
        <SteppedProgressBar
          style={{ width: '80%' }}
          currentCreateDetailsStep={currentCreateDetailsStep}
          createDetailsSteps={createDetailsSteps}
          showCreateFormValue
          onStepClick={handleStepClick}
          showStepsValue
        />
      )}
      {isEdit && formComponentType === 'project_details' && (
        <Tabs
          ariaLabel="DHIG tabs"
          tabProperties={createDetailsSteps.map((step, i) => ({
            'aria-controls': `tabpanel-${i}-default`,
            id: step.value.toString(),
            label: t(step.label)
          }))}
          styleVariant="quiet"
          value={currentCreateDetailsStep}
          onChange={(e, v) => handleStepClick(v)}
        />
      )}
      {formComponentType === 'project_details' && (
        <ProjectDetails
          currentCreateDetailsStep={currentCreateDetailsStep}
          allFormFilledOnce={createDetailsSteps?.[currentCreateDetailsStep]?.allFormFilledOnce}
          creatorData={creatorDataToViewModel(creatorData)}
          tags={categories.tags}
          products={categories.products}
          industries={categories.industries}
          campaign={campaign}
          onChange={handleFormChange}
          title={title}
          cover={cover?.File}
          coverCropData={cover}
          description={description}
          publishStatusData={publishStatusData}
          isEdit={isEdit}
        />
      )}
      {formComponentType === 'project_build' && (
        <ProjectBuild
          blocks={createState.blocks}
          onDeleteBlock={(id) => dispatch(createDeleteBlockAction(id))}
          onReorderBlocks={(newOrder) => dispatch(createReorderBlockAction(newOrder))}
          onSelectDownload={(id) => dispatch(createAllowDownloadAssetAction(id))}
        />
      )}
      {!inModal && showActionButtons && (
        <Actions
          showPrevButton={currentCreateDetailsStep >= 1}
          nextButtonDisabled={!createDetailsSteps[currentCreateDetailsStep].validated}
        />
      )}
    </CreateFlowWrapper>
  );
}

Create.propTypes = {
  inModal: PropTypes.bool,
  showActionButtons: PropTypes.bool,
  campaign: PropTypes.string
};

Create.defaultProps = {
  ...initialCreateState,
  showActionButtons: false
};

export default Create;
