import { gql } from '@apollo/client';

export const PAGE_HERO_FRAGMENT = gql`
  fragment PageHeroFragment on Page {
    hero_title
    hero_description
    hero_project {
      id
      title
      image(format: "pageHero") {
        path
        width
        height
        sizes(formats: ["mobile"]) {
          path
          width
          height
          format
        }
      }
      author {
        id
        slug
        avatar_big
        username
        display_name
        first_name
        last_name
        job_title
      }
    }
  }
`;

export const PAGE_PUSH_FRAGMENT = gql`
  fragment PagePushFragment on Page {
    push_title
    push_description
    push_link {
      url
      label
    }
    push_project {
      id
      title
      image(format: "pageHero") {
        path
        width
        height
        format
        sizes(formats: ["mobile"]) {
          path
          width
          height
          format
        }
      }
      author {
        id
        slug
        avatar_big
        username
        display_name
        first_name
        last_name
        job_title
      }
    }
  }
`;

export const PAGE_SEO_FRAGMENT = gql`
  fragment PageSeoFragment on Page {
    seo {
      title
      description
      image
      og_title
      og_description
    }
  }
`;

export const THUMBNAIL_FRAGMENT = gql`
  fragment ThumbnailFragment on Project {
    slug
    id
    title
    featured
    status
    is_liked
    is_author
    assets {
      resource {
        has_viewer
      }
    }
    image(format: "projectThumbnail") {
      path
      width
      height
      format
      sizes(
        formats: [
          "tablet"
          "cardXl"
          "cardLg"
          "cardMd"
          "cardSm"
          "cardXs"
          "preview128"
          "preview72"
        ]
      ) {
        path
        width
        height
        format
      }
    }
    author {
      id
      slug
      avatar_big
      username
      display_name
      first_name
      last_name
      job_title
    }
  }
`;

export const ASSET_FRAGMENT = gql`
  fragment AssetFragment on Asset {
    id
    type
    path
    image {
      path
      display_name
    }
    text
    embed {
      link_url
      link_title
      link_image
      link_description
    }
    author {
      id
      username
      display_name
      email
      avatar_small
      avatar_big
    }
    projects {
      id
    }
    created_at
  }
`;
