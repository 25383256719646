import {
  CREATE_PUBLISH,
  CREATE_ADD_BLOCK,
  CREATE_DELETE_BLOCK,
  CREATE_EDIT_BLOCK,
  CREATE_REORDER_BLOCK,
  CREATE_ALLOW_DOWNLOAD_ASSET,
  CREATE_TOGGLE_MODAL,
  CREATE_UPDATE_DATA,
  CREATE_FORM_VALIDATION_CHANGE,
  CREATE_DETAILS_FORM_CURRENT_STEP_CHANGE,
  CREATE_CHANGE_MODAL_TYPE,
  CREATE_EMPTY_STATE,
  CREATE_CLEAR_BLOCKS,
  FETCH_CREATE_DETAILS_SET_STORE_DATA,
  CREATE_TOGGLE_EDIT_MODE
} from './actionTypes';
import { asyncActionCreator } from '../../utils';
import { createDataToViewModel, createViewToDataModel } from '../../common/Create/utils';

export const toggleModalAction = () => ({ type: CREATE_TOGGLE_MODAL });
export const toggleEditModeAction = (payload) => ({
  type: CREATE_TOGGLE_EDIT_MODE,
  ...(payload ? { payload } : {})
});
export const emptyCreateStateAction = () => ({ type: CREATE_EMPTY_STATE });
export const emptyCreateBlocksStateAction = () => ({ type: CREATE_CLEAR_BLOCKS });

const createPublishAsyncActions = asyncActionCreator(CREATE_PUBLISH);

export const publishCreateActions = (payload, callback, navigate) => (dispatch) => {
  const payloadSizeKb = parseInt((payload.cover.File.size / 1024).toFixed(), 10);
  const globalAvgSpeedKbps = 7600; // source Ookla
  const estimateSecsForPayload = payloadSizeKb / globalAvgSpeedKbps;

  let interval;
  let counter = 0;

  interval = setInterval(() => {
    counter += 1;
    if (counter <= 100) {
      dispatch(createPublishAsyncActions.init({ progress: counter }));
    } else {
      clearInterval(interval);
    }
  }, estimateSecsForPayload / 1000);

  return callback({
    variables: createViewToDataModel(payload),
    onError: (error) => {
      clearInterval(interval);
      return dispatch(createPublishAsyncActions.error({ error }));
    },
    onCompleted: (data) => {
      clearInterval(interval);
      navigate(`/project/${data.CreateProject.id}/${data.CreateProject.slug}`);

      return dispatch(
        createPublishAsyncActions.success({
          slug: `${data.CreateProject.id}/${data.CreateProject.slug}`,
          isNew: true
        })
      );
    }
  });
};

export const createUpdateData = (payload) => {
  return {
    type: CREATE_UPDATE_DATA,
    payload
  };
};

export const createAllowDownloadAssetAction = (payload) => {
  return {
    type: CREATE_ALLOW_DOWNLOAD_ASSET,
    payload
  };
};

export const createDetailsFormValidation = (payload) => {
  return {
    type: CREATE_FORM_VALIDATION_CHANGE,
    payload
  };
};

export const createFormCurrentStepChange = (payload) => {
  return {
    type: CREATE_DETAILS_FORM_CURRENT_STEP_CHANGE,
    payload
  };
};

export const createChangeModalTypeAction = (payload) => {
  return {
    type: CREATE_CHANGE_MODAL_TYPE,
    payload
  };
};

export const createAddBlockAction = (payload, addFrom, type) => ({
  type: CREATE_ADD_BLOCK,
  payload: {
    payload,
    addFrom,
    type
  }
});

export const createReorderBlockAction = (payload) => ({
  type: CREATE_REORDER_BLOCK,
  payload
});

export const createEditBlockAction = (payload) => ({
  type: CREATE_EDIT_BLOCK,
  payload
});

export const createDeleteBlockAction = (payload) => ({
  type: CREATE_DELETE_BLOCK,
  payload
});

export const fetchCreateDetailsSetStoreDataAction = (payload) => {
  return {
    type: FETCH_CREATE_DETAILS_SET_STORE_DATA,
    payload: createDataToViewModel(payload)
  };
};
