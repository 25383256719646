import window from 'global/window';
import PropTypes from 'prop-types';

export function trackPageView() {
  window.__analyticsChangeContext = window.__analyticsChangeContext || [];
  window.__analyticsChangeContext.push({
    eventData: {
      eventName: 'page_view'
    }
  });
}

export function trackCreateStep0() {
  window.__analyticsChangeContext = window.__analyticsChangeContext || [];
  window.__analyticsChangeContext.push({
    eventData: {
      eventName: 'flow_step',
      values: {
        componentName: 'community gallery: create project',
        componentStep: 'initiate'
      }
    }
  });
}

export function trackCreateStep1() {
  window.__analyticsChangeContext = window.__analyticsChangeContext || [];
  window.__analyticsChangeContext.push({
    eventData: {
      eventName: 'flow_step',
      values: {
        componentName: 'community gallery: create project',
        componentStep: 'details'
      }
    }
  });
}
export function trackCreateStep2() {
  window.__analyticsChangeContext = window.__analyticsChangeContext || [];
  window.__analyticsChangeContext.push({
    eventData: {
      eventName: 'flow_step',
      values: {
        componentName: 'community gallery: create project',
        componentStep: 'cover image'
      }
    }
  });
}

export function trackCreateStep3() {
  window.__analyticsChangeContext = window.__analyticsChangeContext || [];
  window.__analyticsChangeContext.push({
    eventData: {
      eventName: 'flow_step',
      values: {
        componentName: 'community gallery: create project',
        componentStep: 'complete'
      }
    }
  });
}

export function trackCreateStep4() {
  window.__analyticsChangeContext = window.__analyticsChangeContext || [];
  window.__analyticsChangeContext.push({
    eventData: {
      eventName: 'flow_step',
      values: {
        componentName: 'community gallery: build project',
        componentStep: 'preview'
      }
    }
  });
}

export function trackSearch(keyword, filters, results, page) {
  let activeFilters = [];
  Object.keys(filters).map((cat) => {
    const newValue = {
      filterCategory: cat,
      filterName: filters[cat].filter((item) => item)[0]
    };
    activeFilters.push(newValue);
  });

  function sanitisePageNumber() {
    if (!isNaN(page)) {
      return page;
    }
    return 1;
  }

  window.__analyticsChangeContext = window.__analyticsChangeContext || [];
  window.__analyticsChangeContext.push({
    eventData: {
      eventName: 'internal_search',
      values: {
        ...(activeFilters.length && { activeFilters }),
        searchTerm: keyword, //Example: "revit cloud",
        searchType: 'gallery', //static value,
        searchResultsCount: results, //number of results
        searchResultsPage: sanitisePageNumber() //The result page we are on{}
      }
    }
  });
}

//Don't remove we need to clarify and work on this one later

// export function trackSearchFiltering(keyword, filters) {
//   let activeFilters = [];
//   Object.keys(filters).map((cat) => {
//     const newValue = {
//       filterCategory: cat,
//       filterName: filters[cat].filter((item) => item)[0]
//     };
//     activeFilters.push(newValue);
//   });
//   window.__analyticsChangeContext = window.__analyticsChangeContext || [];
//   window.__analyticsChangeContext.push({
//     eventData: {
//       eventName: 'search_refinement',
//       values: {
//         activeFilters,
//         searchTerm: keyword //only pass if a keyword was typed before the filtering
//       }
//     }
//   });
// }

trackSearch.propTypes = {
  keywords: PropTypes.string.isRequired,
  filters: PropTypes.array,
  results: PropTypes.string
};
