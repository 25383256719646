import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ImageSelectButton,
  ImagesWrapper,
  ThumbnailCropWrapper,
  UploadWrapper
} from './ThumbnailCrop.styled';
import ImageCrop from '../ImageCrop';
import ThumbnailPreview from '../ThumbnailPreview';
import FileUpload from '../FileUpload';
import Icon from '@digital-hig/icon';
// import Alert from '@mui/material/Alert';
// import { AlertIcon } from '@digital-hig/alert';
import { t } from 'i18next';
import debounce from 'lodash.debounce';
import { cropDataPropTypes } from '../../common/types';
import { useIsMobile } from '../../utils';

const MAX_SIZE_KB = 10000;
const ACCEPTED_FILES = 'image/jpeg, image/png, image/jpg';

function ThumbnailCrop(props) {
  const { thumbnailProps, image, isLoading, onCropChange, variant, coverCropData } = props;

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [images, setImages] = useState([]);
  const isMobile = useIsMobile();

  const [cropData, setCropData] = useState({});

  useEffect(() => {
    if (variant === 'banner') {
      setCropData({
        ...cropData,
        [variant]: {
          ...cropData?.[variant],
          width: 904,
          height: 413
        }
      });
    } else if (variant === 'thumbnail') {
      setCropData({
        ...cropData,
        [variant]: {
          ...cropData?.[variant],
          width: 300,
          height: 450
        }
      });
    }
  }, [variant]);

  useEffect(() => {
    setCropData(coverCropData);
  }, []);

  useEffect(() => {
    if (image) {
      setImages([...images, image]);
    }
  }, []);

  function handleChange(e) {
    const newCropData = {
      ...cropData,
      [variant]: { ...cropData?.[variant], ...e }
    };
    onCropChange({ ...newCropData, File: images[selectedImageIndex] });
    setCropData(newCropData);
  }

  const debouncedHandleChange = useMemo(
    () => debounce(handleChange, 500),
    [variant, images, selectedImageIndex]
  );

  function handleImageUpload(e) {
    const newCropData = {
      ...cropData,
      [variant]: {
        ...cropData?.[variant],
        x: 0.5,
        y: 0.5,
        z: 1
      }
    };

    setSelectedImageIndex(images.length);
    setImages([...images, ...e]);
    setCropData(newCropData);
    onCropChange({ ...newCropData, File: e[0] });
  }

  function handleIndexChange(index) {
    setSelectedImageIndex(index);
    const newCropData = {
      ...cropData,
      [variant]: {
        ...cropData?.[variant],
        x: 0.5,
        y: 0.5,
        z: 1
      }
    };
    setCropData(newCropData);
    onCropChange({ ...newCropData, File: images[index] });
  }

  return (
    <>
      {/* <NewAlert isError={errorState.isError} error={errorState.error} setState={setErrorState} /> */}
      <ThumbnailCropWrapper hasImage={isMobile && images?.length > 0}>
        <ImageCrop
          cropData={cropData?.[variant]}
          image={images[selectedImageIndex]}
          border={variant === 'thumbnail' ? [64, 24] : [40, 32]}
          showZoomSlider={images?.[selectedImageIndex] !== undefined}
          isLoading={isLoading}
          onChange={debouncedHandleChange}
          variant={variant}
        >
          <ThumbnailPreview
            {...thumbnailProps}
            showUserInfo={variant === 'thumbnail'}
            variant={variant}
          >
            {isMobile && (
              <FileUpload
                accept={ACCEPTED_FILES}
                variant="button"
                maxSizeKb={MAX_SIZE_KB}
                isLoading={isLoading}
                className="thumbnail-crop-upload-mobile"
                showBorder={false}
                content={
                  images?.length > 0
                    ? t('file_upload_mobile_image')
                    : t('file_upload_mobile_no_image')
                }
                iconId={
                  images?.length > 0
                    ? 'dhig--icons--actions--edit'
                    : 'dhig--icons--miscellaneous--file-image'
                }
                multiple={false}
                onChange={handleImageUpload}
              />
            )}
          </ThumbnailPreview>
        </ImageCrop>
        {!isMobile && (
          <UploadWrapper>
            <ImagesWrapper>
              {images.map((img, i) => {
                return (
                  <ImageSelectButton
                    key={typeof img === 'string' ? img : img.name}
                    value={i}
                    onClick={() => handleIndexChange(i)}
                    active={i === selectedImageIndex}
                    disabled={isLoading}
                  >
                    <Icon id="dhig--icons--utility--complete" />
                    <img src={typeof img === 'string' ? img : URL.createObjectURL(img)} />
                  </ImageSelectButton>
                );
              })}
            </ImagesWrapper>
            <FileUpload
              accept={ACCEPTED_FILES}
              variant="narrow"
              maxSizeKb={MAX_SIZE_KB}
              isLoading={isLoading}
              className="thumbnail-crop-upload"
              showBorder={false}
              onChange={handleImageUpload}
            />
          </UploadWrapper>
        )}
      </ThumbnailCropWrapper>
    </>
  );
}

ThumbnailCrop.propTypes = {
  variant: PropTypes.oneOf(['thumbnail', 'banner']),
  thumbnailProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    avatarProps: PropTypes.shape({
      name: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      profilePicture: PropTypes.string.isRequired
    }),
    image: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
    // PropTypes.instanceOf(File)
    // PropTypes.arrayOf(PropTypes.instanceOf(File))
  ]),
  onCropChange: PropTypes.func,
  coverCropData: PropTypes.shape({
    thumbnail: PropTypes.shape(cropDataPropTypes),
    banner: PropTypes.shape(cropDataPropTypes)
  })
};

// NewAlert.propTypes = {
//   isError: PropTypes.bool,
//   error: PropTypes.string,
//   setState: PropTypes.func
// };

ThumbnailCrop.defaultProps = {
  variant: 'thumbnail',
  isLoading: false,
  image: undefined,
  onCropChange: () => {}
};

export default ThumbnailCrop;
