export const en = {
  en: 'English',
  fr: 'Français',
  generic_delete_label: 'Delete',
  example_translation_string:
    'Hello this is an example string the current language is {{language}}',
  client_notification_error_title: 'There is an error',
  client_notification_info_title: 'Notice',
  client_notification_success_title: 'Congratulations 🎉',
  client_notification_reportProject_success: "Thank you, we've recieved your report",
  thumbnail_like_label: 'Like',
  navigation_title: 'Gallery',
  navigation_item1: 'Architecture, Engineering & Construction',
  navigation_item2: 'Product Design & Manufacturing',
  navigation_item3: 'Media & Entertainment',
  navigation_item4: 'My projects',
  navigation_item5: 'Admin Page',
  stepped_progress_bar_step: 'Step {{value}}',
  file_upload_label_large: 'Drag files here, or',
  file_upload_button_label_files: 'Select files',
  file_upload_button_label_loading: 'Loading files...',
  file_upload_drag_over: 'Drop your files here',
  file_upload_error_invalid_format_some: 'Uploaded failed. <br>Unsupported file format',
  file_upload_error_invalid_format_all: 'Uploaded failed. <br>Unsupported file format',
  create_block_list_item_type_image: 'Image',
  create_block_list_item_type_embed: 'Embedded media',
  create_block_list_item_type_3D: '3D Object',
  loading_project_status_1: 'Almost there {{name}}!',
  loading_project_status_2: 'This might take a few moments',
  project_loaded_success_1: 'Good job!',
  loading_project_status_percentage: 'Building project',
  detail_footer_industries_list_title: 'Industries',
  detail_footer_products_list_title: 'Products',
  detail_footer_tags_list_title: 'Tags',
  create_content_status_draft: 'Draft',
  create_content_status_deleted: 'Deleted',
  create_content_status_pending: 'In validation',
  create_content_status_published: 'Published',
  create_content_status_rejected: 'Rejected',
  create_content_edit_project: 'Edit project',
  create_content_like_project: 'Like',
  create_content_liked_project: 'Liked',
  create_content_bookmark_project: 'Bookmark',
  create_content_bookmarked_project: 'Bookmarked',
  project_loaded_success_2: 'Your project is ready!',
  create_content_share_project: 'Share',
  create_content_cancel_edit_project: 'Cancel',
  create_content_publish_project: 'Submit',
  create_content_publish_edited_project: 'Update project',
  create_content_save_draft_project: 'Save draft',
  create_content_delete_project: 'Delete',
  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  may: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',
  actions_zoom_out: 'Zoom-out',
  actions_zoom_in: 'Zoom-in',
  create_content_edit_project_details_label: 'Edit details',
  create_content_edit_project_build_label: 'Build',
  pagination_gallery_label: '{{itemsCount}} of {{totalItems}} galleries',
  pagination_project_label: '{{itemsCount}} of {{totalItems}} projects',
  home_featured_members: 'Featured Members',
  clear_all_filters: 'Clear all',
  filters_featured: 'Featured projects',
  filters_most_relevant: 'Relevance',
  filters_most_recent: 'Most Recent',
  filters_trending: 'Trending',
  create_your_project_btn_label: 'Create your project',
  filters_industries_label: 'Industries',
  filters_industries_placeholder: 'Select industries...',
  filters_products_label: 'Tools',
  filters_products_placeholder: 'Select tools...',
  filters_tags_label: 'Tags',
  filters_tags_placeholder: 'Select tags...',
  search_results_for: 'Results for',
  search_results_count: 'Showing {{count}} of {{total}} results',
  search_no_results_count: 'No results.',
  search_no_results_title: 'No results to display.',
  create_delete_project_confirm_title: 'Delete project?',
  create_delete_project_confirm_description: 'Are you sure you want to delete this project ?',
  search_no_results_description:
    'Try your search again or use these links to explore our products and services.',
  file_upload_formats: 'Files supported',
  file_upload_max_size: 'Max file size is {{size}}MB',
  file_upload_error_invalid_file_size: 'Uploaded failed. <br>The maximum file size is {{extra}}MB ',
  create_block_list_downloadable_item_label: 'Allow download model',
  create_detail_download_terms:
    'The license and other terms for contributing and using assets in the Autodesk Community. Gallery are found in the Autodesk Terms of Use.',
  create_detail_download_download_items_btn: 'Download files',
  create_related_content_title: 'Explore more projects',
  download_section_button_label: 'Download model(s) {{size}}',
  block_list_selected_items: '{{count}} item(s) selected',
  download_section_modal_title: 'Download this project’s models',
  library_select_file_types_label: 'Asset type',
  library_file_type_all: 'All',
  library_file_type_embed: 'Embed',
  library_file_type_image: 'Image',
  library_file_type_asset: '3D asset',
  create_modal_title_new: 'New project',
  create_modal_title_edit_draft: 'Build your project',
  create_modal_title_edit_published: 'Build your project',
  create_modal_title_edit_rejected: 'Build your project',
  create_modal_cancel_button_label: 'Cancel',
  create_modal_reset_button_label: 'Reset',
  create_modal_prev_button_label: 'Back',
  create_modal_next_button_label: 'Next',
  create_information_form_title_label: 'Title',
  create_information_form_description_label: 'Description',
  create_information_form_title_line_1: 'Hey {{name}}',
  create_information_form_title_line_2: 'Tell us about your project',
  create_categories_form_title: 'Select your categories',
  create_cover_form_title: 'Create a cover image',
  create_modal_unsaved_title_cancel_label: 'Cancel',
  create_modal_unsaved_title_confirm_label: 'Confirm',
  create_modal_unsaved_title_edit: 'Your project has unsaved changes',
  create_modal_unsaved_content_edit:
    "If you choose to leave the project's edition, you might loose unsaved changes. Are you sure you want to leave?",
  create_modal_unsaved_title_new: 'Project not created',
  create_modal_unsaved_content_new:
    "You haven't completed the project creation. Are you sure you want to leave?",
  create_categories_industry_form_label: 'Industry',
  create_categories_products_form_label: 'Products',
  create_categories_tags_form_label: 'Tags {{hintText}}',
  industries_architecture_engineering_construction_label:
    'Architecture, Engineering & Construction',
  industries_product_design_manufacturing_label: 'Product Design & Manufacturing',
  industries_media_entertainment_label: 'Media & Entertainment',
  create_cover_thumbnail_title: 'Thumbnail',
  create_cover_banner_title: 'Banner',
  project_loaded_error_1: 'There was an error',
  project_loaded_error_2: 'more details: {{error}}',
  file_upload_button_label_button: 'Add',
  file_upload_button_label_large: 'Browse files',
  share_text_notification: 'Copied to your clipboard',
  client_notification_LikeProject_success: 'You have liked this project',
  client_notification_BookmarkProject_success: 'You have bookmarked this project',
  client_notification_UpdateProjectsStatus_success: 'Project status updated succesfully',
  file_upload_label_files: 'Drag & dop files here, or',
  create_modal_publish_next_button_label: 'Continue',
  create_modal_edit_next_button_label: 'Preview',
  create_build_assets_section_title: 'Assets',
  create_build_assets_section_subtitle:
    'Select assets from your library or upload new ones and add them to your project',
  create_build_layout_section_title: 'Page Layout',
  create_build_layout_section_subtitle:
    'Drag and drop your assets into position to get your project nicely organized.',
  create_project_build_upload: 'Upload',
  create_project_build_embed_media: 'Embed media',
  create_project_build_my_assets: 'My library',
  create_build_assets_section_my_assets_empty_title: 'Your library is empty',
  create_build_assets_section_my_assets_empty_subtitle: 'You can directly upload your assets here',
  create_build_layout_empty_title: 'Your project is empty',
  create_build_layout_empty_subtitle: "Let's start by adding some assets",
  create_build_layout_empty_new_project_title: 'Your new project is ready!',
  create_build_layout_empty_new_project_subtitle: "Let's start by adding some assets",
  create_modal_edit_details_next_button_label: 'Build your project',
  create_build_assets_embed_label: 'Embed videos and models from {{accepted}}',
  create_build_embed_submit_label: 'Add',
  create_build_invalid_embed_error: 'Invalid resource URL',
  create_step_project_info: 'Basic information',
  create_step_project_categories: 'Categories',
  create_step_project_cover: 'Cover image',
  create_publish_success_draft: 'Project saved to your drafts',
  create_publish_success_publish: 'Project updated succesfully',
  create_publish_deleted_project:
    'This project has been deleted or rejected by an admin, it will be permanently deleted soon',
  create_publish_success_pending:
    "Your project was submitted for publishing and it's waiting for approval.",
  create_publish_loading: 'Your project is being updated, please wait...',
  pagination_assets_label: '{{itemsCount}} of {{totalItems}} assets',
  create_blocks_finalize_asset: 'Processing 3D model...',
  error_404_error: '404 error',
  error_404_title: "We can't seem to find that page.",
  error_404_description:
    "There's a lot to explore here, and we want to help you find it. Please try your search again or use the links below to see our most popular products and services.",
  no_result_link_gallery: 'Go to Community Gallery',
  no_result_link_community: 'Go to Community',
  no_result_link_autodesk: 'Go to Autodesk.com',
  modal_filters: 'Filters',
  modal_filters_label: 'Filters',
  modal_filters_title: 'Filters',
  asset_loading_in_back_short: 'This asset is being finalized',
  asset_loading_in_back_long: 'Processing 3D asset',
  generic_optionnal_field: '(optional)',
  profile_tab_published: 'Projects {{count}}',
  profile_tab_pending: 'Pending {{count}}',
  profile_tab_draft: 'My drafts {{count}}',
  profile_tab_bookmarks: 'My bookmarks {{count}}',
  profile_tab_deleted: 'Deleted/Rejected {{count}}',
  download_section_button_label_not_auth: 'Log-in to download models',
  file_upload_mobile_no_image: 'Add image',
  file_upload_mobile_image: 'Select new image',
  create_detail_reject_button: 'Reject',
  create_detail_approve_button: 'Approve',
  create_detail_reject_modal_button: 'Reject only',
  create_detail_reject_ban_modal_button: 'Reject and ban user',
  create_detail_reject_modal_description:
    ' Do you want to reject this content and ban this user from creating more content?'
};
