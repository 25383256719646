import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const BlocksWrapper = styled.div`
  max-width: 1248px;
  margin: auto;
  background-color: #fff;
  width: 100%;
  padding-bottom: ${lightTheme.primitives.spacings['7']};
  padding-top: ${lightTheme.primitives.spacings['7']};
  padding-left: ${lightTheme.primitives.spacings['6']};
  padding-right: ${lightTheme.primitives.spacings['6']};

  .contextual-banner {
    margin-top: 32px;
  }
`;

export const SlidersWrapper = styled.div`
  display: flex;
  justify-content: 'center';
  ${({ slideType }) => {
    switch (slideType) {
      case '3dviewer':
        return css`
          width: 1198px;
          height: 665px;
        `;
      case 'embed':
        return css`
          width: 1198px;
          height: 665px;
        `;
      case 'raas':
        return css`
          width: 1198px;
          height: 665px;
        `;
      case 'image':
        return css`
          width: 1198px;
          height: 665px;
        `;
    }
  }}
`;

export const DownloadButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${lightTheme.primitives.spacings['7']};
  padding-top: ${lightTheme.primitives.spacings['7']};
  padding-left: ${lightTheme.primitives.spacings['6']};
  padding-right: ${lightTheme.primitives.spacings['6']};
`;

export const RelatedContentContainer = styled.div`
  max-width: 1248px;
  margin: auto;
  padding: 0px ${lightTheme.primitives.spacings['5']};

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['5']}) {
    width: 100%;
    padding: 0px;
  }
`;

export const RelatedContentWrapper = styled.div`
  background-color: ${lightTheme.tokens.colors['background-panel'].color};
  padding: ${lightTheme.primitives.spacings['9']} 0 ${lightTheme.primitives.spacings['9']} 0;
  border-top: 1px solid #e5e5e5;

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['7']}) {
    padding: ${lightTheme.primitives.spacings['9']} 0 ${lightTheme.primitives.spacings['9']} 0;

    h3 {
      padding: 0 ${lightTheme.primitives.spacings['4']};
    }
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${lightTheme.primitives.spacings['9']} 0;
`;
