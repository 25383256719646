import window from 'global';
import { PRELOADED_STATE_KEY } from '../common/constants';

export const getEnv = () => {
  const hostEnv = window?.location?.hostname;

  let preloadedState = {};
  // Grab the state from a global variable injected into the server-generated HTML

  preloadedState = window[PRELOADED_STATE_KEY];
  const defaultEnvData = { ENVIRONMENT: 'dev' };

  const env = preloadedState?.envData.ENVIRONMENT || defaultEnvData.ENVIRONMENT;

  if (hostEnv?.includes('localhost')) {
    return 'local';
  } else {
    return env;
  }
};
