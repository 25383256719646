import { document } from 'global';
export function onDownloadFile(url, filename) {
  // Create a link to the zipped files in the DOM, virtually click on it, and then delete it
  const link = document.createElement('a');
  link.href = url;
  if (filename?.split('.')?.pop() === 'pdf') {
    link.target = '_blank';
  }
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}
