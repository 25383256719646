import PropTypes from 'prop-types';
import {
  DELETE_PROJECT,
  CREATE_UPDATE_PROJECT,
  ADMIN_UPDATE_PROJECT_STATUS,
  UPDATE_USER_18PLUS
} from '../../../apollo/mutations';
import { PROJECT_QUERY } from '../../../apollo/queryPages';
import { onCopyUrlToClipboard } from '../../../utils';
import { createStatePropTypes, projectPropTypes } from '../../../common/types';
import { useSelector, useDispatch } from 'react-redux';
import {
  createChangeModalTypeAction,
  emptyCreateStateAction,
  toggleEditModeAction
} from '../../../store/actions/createActions';
import Header from '../../../components/Header';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { useLikeProject } from '../../../utils/hooks/useLikeProject';
import { useBookmarkProject } from '../../../utils/hooks/useBookmarkProject';
import { memo, useEffect, useState } from 'react';
import { t } from 'i18next';
import Modal from '@digital-hig/modal';
import Button from '@digital-hig/button/lib/button';
import window from 'global/window';
import Snackbar from '@material-ui/core/Snackbar';
import { AlertIcon } from '@digital-hig/alert';
import Fade from '@mui/material/Fade';
import Typography from '@digital-hig/typography';
import RejectActionsModal from '../../../components/Header/RejectActionsModal';
import { useReportProject } from '../../../utils/hooks/useReportProject';
import { trackCreateStep4 } from '../../../utils/trackingScripts';

function publishDataToViewModel(data, status, campaign, campaign_comment, socialLinks) {
  return {
    id: parseInt(data.id, 10),
    input: {
      social_links: socialLinks,
      campaign_comment: campaign_comment,
      description: data.description,
      title: data.title,
      image: {
        pageHeroCrop: {
          x: data.cover.banner.x,
          y: data.cover.banner.y,
          z: data.cover.banner.z
        },
        projectThumbnailCrop: {
          x: data.cover.thumbnail.x,
          y: data.cover.thumbnail.y,
          z: data.cover.thumbnail.z
        },
        ...(typeof data.cover.File !== 'string' && data.cover.File
          ? {
              image: data.cover.File
            }
          : {})
      },
      products: data.products,
      industries: data.industries,
      tags: data.tags
    },
    assets: data.blocks.map((el) => ({
      id: parseInt(el.id, 10),
      order: el.order,
      downloadable: el.downloadable
    })),
    status: status,
    campaign: campaign
  };
}

const initialAlertState = { showAlert: false };

function DetailHeader({ createState, projectData, refetchData, hasDisabledBlocks }) {
  const [state] = useState({
    Transition: Fade
  });
  const [showModal, setShowModal] = useState(false);

  let location = window.location.href;

  const dispatch = useDispatch();

  const [deleteProject, deleteProjectData] = useMutation(DELETE_PROJECT, {
    context: { clientName: 'authorized' }
  });
  const [updateProject, updateProjectData] = useMutation(CREATE_UPDATE_PROJECT, {
    context: { clientName: 'authorized' }
  });
  const [updateProjectStatus] = useMutation(ADMIN_UPDATE_PROJECT_STATUS, {
    context: { clientName: 'cms' }
  });
  const [updateUser18Plus] = useMutation(UPDATE_USER_18PLUS, {
    context: { clientName: 'authorized' }
  });
  const [openCopyAlert, setOpenCopyAlert] = useState(false);
  const [alertData, setAlertData] = useState(initialAlertState);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [savingReport, setSavingReport] = useState(false);

  const create = useSelector((state) => state.create);

  const navigate = useNavigate();

  useEffect(() => {
    if (createState.isNew) {
      trackCreateStep4();
      dispatch(toggleEditModeAction());
      handleEditProjectClick('project_build');
    }
  }, [createState.isNew]);

  useEffect(() => {
    if (hasDisabledBlocks) {
      setAlertData({
        showAlert: true,
        severity: 'info',
        content: t('create_blocks_finalize_asset')
      });
    } else {
      setAlertData(initialAlertState);
    }
    if (projectData.status === 'pending') {
      setAlertData({
        showAlert: true,
        severity: 'info',
        content: t('create_publish_success_pending')
      });
    } else if (projectData.status === 'deleted') {
      setAlertData({
        showAlert: true,
        severity: 'error',
        content: t('create_publish_deleted_project')
      });
    } else {
      setAlertData(initialAlertState);
    }
  }, [hasDisabledBlocks, projectData.status]);
  // Publish project handling
  function handlePublishClick(type, campaign, user18Plus, campaign_comment, socialLinks) {
    setAlertData({
      showAlert: true,
      severity: 'info',
      content: t('create_publish_loading')
    });
    updateProject({
      variables: publishDataToViewModel(create, type, campaign, campaign_comment, socialLinks),
      onCompleted: (res) => {
        setAlertData({
          showAlert: true,
          severity: res.UpdateProject.status === 'pending' ? 'info' : 'success',
          content: t(`create_publish_success_${type}`)
        });
        dispatch(toggleEditModeAction());
        dispatch(emptyCreateStateAction());
      },
      refetchQueries: [
        {
          PROJECT_QUERY,
          variables: {
            id: parseInt(projectData.id),
            slug: projectData.slug
          }
        }
      ]
    });
    if (user18Plus) {
      updateUser18Plus({
        variables: {
          plus18: user18Plus
        }
      });
    }
  }

  const handleApproveClick = () => {
    updateProjectStatus({
      variables: {
        ids: [parseInt(create.id, 10)],
        action: 'approve'
      },
      awaitRefetchQueries: true,
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        refetchData();
      }
    });
  };

  // Delete project handling
  function handleDeleteClick() {
    if (!createState.unsavedActions) {
      deleteProject({
        variables: {
          id: parseInt(create.id, 10)
        },
        onCompleted: () => {
          dispatch(emptyCreateStateAction());
          navigate('/');
        }
      });
    } else {
      setShowUnsavedChangesModal(true);
    }
  }

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    if (createState.unsavedActions) {
      window.addEventListener('beforeunload', alertUser);
      return () => {
        window.removeEventListener('beforeunload', alertUser);
      };
    }
  }, [createState.unsavedActions]);

  const { isLiked, handleLikeClick } = useLikeProject({
    id: projectData.id,
    isLiked: projectData.isLiked
  });
  const { isBookmarked, handleBookmarkClick } = useBookmarkProject({
    id: projectData.id,
    isBookmarked: projectData.isBookmarked
  });

  const { isReported, handleReportClick } = useReportProject(
    {
      id: projectData.id,
      isReported: projectData.isReported
    },
    setSavingReport,
    refetchData
  );

  // Edit project handling
  function handleEditProjectClick(modalType) {
    dispatch(createChangeModalTypeAction(modalType));
  }

  // Edit handling
  function handleEditClick() {
    dispatch(toggleEditModeAction());
  }

  // Cancel project handling
  function handleCancelClick() {
    if (!createState.unsavedActions) {
      dispatch(toggleEditModeAction());
      refetchData();
    } else {
      setShowUnsavedChangesModal(true);
    }
  }

  const handleShareClick = () => {
    onCopyUrlToClipboard(location)
      .then(() => {
        setOpenCopyAlert(true);
        setTimeout(() => {
          setOpenCopyAlert(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        aria-describedby={t('create_unsaved_changes_modal_aria_description')}
        aria-labelledby={t('create_unsaved_changes_modal_aria_label')}
        backdropVariant="default"
        maxWidth="sm"
        styleVariant="default"
        title={t(`create_modal_unsaved_title_${createState.isNew ? 'new' : 'edit'}`)}
        type="alert"
        iconName="alert"
        open={showUnsavedChangesModal}
        content={t(`create_modal_unsaved_content_${createState.isNew ? 'new' : 'edit'}`)}
        actions={
          <>
            <Button
              onClick={() => {
                setShowUnsavedChangesModal(false);
              }}
              variant="text"
            >
              {t('create_modal_unsaved_title_cancel_label')}
            </Button>
            <Button
              onClick={() => {
                dispatch(toggleEditModeAction());
                dispatch(emptyCreateStateAction());
                setShowUnsavedChangesModal(false);
                refetchData();
              }}
            >
              {t('create_modal_unsaved_title_confirm_label')}
            </Button>
          </>
        }
      />
      {openCopyAlert && (
        <div className="dhig-theme--light">
          <Snackbar
            open={true}
            autoHideDuration={3000}
            TransitionComponent={state.Transition}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            message={
              <div style={{ display: 'grid', gridTemplateColumns: '16px auto', gap: '1rem' }}>
                <AlertIcon severity="success" />
                <div style={{ display: 'flex' }}>
                  <Typography variant="short-copy-small">{t('share_text_notification')}</Typography>
                </div>
              </div>
            }
            // action={
            //   <IconButton
            //     layout="hug"
            //     size="dense"
            //     variant="text"
            //     iconId="dhig--icons--utility--cross"
            //     onClick={onClose}
            //   ></IconButton>
            // }
          />
        </div>
      )}
      <Header
        handlePublishClick={handlePublishClick}
        handleDeleteClick={handleDeleteClick}
        handleEditProjectClick={handleEditProjectClick}
        handleEditClick={handleEditClick}
        handleCancelClick={handleCancelClick}
        handleShareClick={handleShareClick}
        projectData={projectData}
        isEdit={createState.isEdit}
        isLiked={isLiked}
        isBookmarked={isBookmarked}
        isReported={isReported}
        handleLikeClick={handleLikeClick}
        handleBookmarkClick={handleBookmarkClick}
        handleApproveClick={handleApproveClick}
        handleReportClick={handleReportClick}
        savingReport={savingReport}
        unsavedActions={createState.unsavedActions}
        isLoading={deleteProjectData.loading || updateProjectData.loading || hasDisabledBlocks}
        alertData={alertData}
        setShowModal={setShowModal}
      />
      <RejectActionsModal
        projectId={parseInt(projectData?.id)}
        userId={parseInt(projectData?.authorId)}
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
        handleRefetchData={refetchData}
      />
    </>
  );
}

DetailHeader.propTypes = {
  createState: PropTypes.shape({
    unsavedActions: createStatePropTypes.unsavedActions,
    isNew: createStatePropTypes.isNew,
    isEdit: createStatePropTypes.isEdit
  }),
  hasDisabledBlocks: PropTypes.bool,
  refetchData: PropTypes.func.isRequired,
  projectData: PropTypes.shape({
    totalBlocks: PropTypes.number,
    status: projectPropTypes.status,
    id: projectPropTypes.id,
    authorId: projectPropTypes.id,
    slug: projectPropTypes.slug,
    isAuthor: projectPropTypes.isAuthor,
    canEdit: projectPropTypes.canEdit,
    isLiked: projectPropTypes.isLiked,
    isBookmarked: projectPropTypes.isBookmarked,
    isReported: projectPropTypes.isReported,
    likeCount: projectPropTypes.likeCount,
    bookmarkCount: projectPropTypes.bookmarkCount
  })
};

const MemoizedDetailHeader = memo(DetailHeader);

export default MemoizedDetailHeader;
