import React from 'react';
import PropTypes from 'prop-types';
import { BottomFloatingActionBarWrapper } from './BottomFloatingActionBar.styled';

function BottomFloatingActionBar({ children }) {
  return <BottomFloatingActionBarWrapper>{children}</BottomFloatingActionBarWrapper>;
}

BottomFloatingActionBar.propTypes = {
  children: PropTypes.node.isRequired
};

export default BottomFloatingActionBar;
