import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const ContentWrapper = styled.div`
  max-width: 1248px;
  margin: auto;
  width: 100%;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: ${({ isPending }) => (isPending ? 'space-between' : 'flex-end')};
  align-items: center;
  margin: 1rem 0;
  padding-left: 1rem;
`;
export const FilterBarWrapper = styled(ContentWrapper)`
  padding: ${lightTheme.primitives.spacings['9']} 0px ${lightTheme.primitives.spacings['7']} 0px;

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    padding: ${lightTheme.primitives.spacings['8']} ${lightTheme.primitives.spacings['6']}
      ${lightTheme.primitives.spacings['6']} ${lightTheme.primitives.spacings['6']};
  }
`;

export const PaginationWrapper = styled.div`
  padding: 0 ${lightTheme.primitives.spacings['5']} ${lightTheme.primitives.spacings['8']};
  background-color: ${lightTheme.primitives.colors.primary.white};
`;

export const BottomGridWrapper = styled(ContentWrapper)`
  padding: 0 0px ${lightTheme.primitives.spacings['9']} 0px;

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    padding: 0 0 ${lightTheme.primitives.spacings['6']} 0;
  }

  ${({ hasPaddingTop }) =>
    hasPaddingTop &&
    css`
      padding-top: ${lightTheme.primitives.spacings['9']};

      @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
        padding-top: ${lightTheme.primitives.spacings['6']};
      }
    `};
`;

export const GridContainer = styled.div`
  background-color: ${lightTheme.primitives.colors.primary.white};
`;

export const TopGridWrapper = styled(ContentWrapper)`
  padding: 0 0 ${lightTheme.primitives.spacings['6']} 0;

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    padding: 0 0 ${lightTheme.primitives.spacings['6']} 0;
  }

  ${({ hasPaddingBottom }) =>
    hasPaddingBottom &&
    css`
      padding-bottom: ${lightTheme.primitives.spacings['9']};

      @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
        padding-bottom: ${lightTheme.primitives.spacings['6']};
      }
    `};

  ${({ hasPaddingTop }) =>
    hasPaddingTop &&
    css`
      padding-top: ${lightTheme.primitives.spacings['9']};

      @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
        padding-top: ${lightTheme.primitives.spacings['6']};
      }
    `};
`;

export const SearchFilterBarWrapper = styled(ContentWrapper)`
  padding: ${lightTheme.primitives.spacings['6']} ${lightTheme.primitives.spacings['5']}
    ${lightTheme.primitives.spacings['7']};
`;

export const NoResultWrapper = styled(ContentWrapper)`
  padding: 0 ${lightTheme.primitives.spacings['9']} ${lightTheme.primitives.spacings['9']}
    ${lightTheme.primitives.spacings['9']};

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    padding: 0 ${lightTheme.primitives.spacings['6']} ${lightTheme.primitives.spacings['6']}
      ${lightTheme.primitives.spacings['6']};
  }
`;

export const SearchResultWrapper = styled(ContentWrapper)`
  padding: ${lightTheme.primitives.spacings['9']} ${lightTheme.primitives.spacings['4']} 0
    ${lightTheme.primitives.spacings['4']};

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['7']}) {
    padding: ${lightTheme.primitives.spacings['9']} ${lightTheme.primitives.spacings['8']} 0
      ${lightTheme.primitives.spacings['8']};
  }

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    padding: ${lightTheme.primitives.spacings['8']} ${lightTheme.primitives.spacings['6']} 0
      ${lightTheme.primitives.spacings['6']};

    p {
      margin-top: ${lightTheme.primitives.spacings['4']};
    }
  }
`;

export const Separator = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: ${lightTheme.primitives.colors.tint['slate-400']};
  margin-bottom: ${lightTheme.primitives.spacings['5']};
`;

export const CreateProjectCardWrapper = styled.div`
  padding: 0 16px;

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['7']}) {
    padding: 0;
  }
`;

export const CreateProjectButtonWrapper = styled.div`
  padding: 12px 16px 4px;
  text-align: right;

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['7']}) {
    padding: 12px 0 4px;
  }
`;
