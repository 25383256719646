import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const SteppedProgressBarWrapper = styled.nav`
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  ul {
    width: 100%;
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
`;

export const StepWrapper = styled.li`
  display: block;
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  justify-content: center;
  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: calc(50% + 1px);
    height: 3px;
    justify-self: center;
    transition: all 0.2s ease-in-out;
  }
  &::after {
    right: 0;
  }
  &::before {
    left: 0;
  }
  &:first-of-type,
  &:last-child {
    flex-grow: 0;
  }
  button {
    z-index: 10;
    pointer-events: visible;
    transition: all 0.2s ease-in-out;
    appearance: none;
    width: ${lightTheme.primitives.spacings['7']};
    height: ${lightTheme.primitives.spacings['7']};
    margin: 1px 0;
    padding: 0;
    border: none;
    border-radius: 100%;
    font-size: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    svg {
      align-self: center;
    }
    background-color: ${lightTheme.primitives.colors.tint['slate-200']};
    &:not(:disabled) {
      cursor: pointer;
    }
  }
  label {
    display: block;
    position: relative;
    cursor: pointer;
    color: black !important;
    margin-top: 6px;
  }
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          button: {
            background-color: white !important;
          }
          p,
          label {
            cursor: unset;
            opacity: 0.4;
          }
          &::before,
          &::after {
            background-color: ${lightTheme.primitives.colors.tint['slate-200']};
            height: 1px;
          }
        `
      : css`
          &::before,
          &::after {
            background-color: ${lightTheme.tokens.colors['fill-success'].rgb};
          }
        `}
  ${({ isNextDisabled, isDisabled }) =>
    isNextDisabled &&
    !isDisabled &&
    css`
      button {
        background-color: white;
        border: 1px solid ${lightTheme.tokens.colors['fill-success'].rgb};
        &:hover {
          border-width: 3px;
        }
      }
      &::after {
        height: 1px;
        top: calc(1rem - 0.5px);
        background-color: ${lightTheme.primitives.colors.tint['slate-200']};
      }
    `}
  ${({ isCurrentStep, validated }) => {
    if (isCurrentStep) {
      if (!validated) {
        return css`
          button {
            border: 2px solid black;
            background: white;
          }
        `;
      } else {
        return css`
          button {
            border: 2px solid ${lightTheme.tokens.colors['fill-success'].rgb};
            background: white;
          }
        `;
      }
    }
  }}
  ${({ isError }) =>
    isError &&
    css`
      &::before,
      &::after {
        height: 1px;
        background-color: red;
      }
      button {
        border-width: 0;
        background: white;
        &:hover {
          border-width: 0;
        }
      }
    `}
`;
