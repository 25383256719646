import React from 'react';
import PropTypes from 'prop-types';
import { LoadingAssetWrapper, Loader } from './LoadingAsset.styled';

function LoadingAsset({ shape = 'circle', label = '', loadingPercentage }) {
  return (
    <LoadingAssetWrapper shape={shape}>
      <Loader
        size="medium"
        type={!loadingPercentage ? 'indeterminate' : 'determinate'}
        value={loadingPercentage}
        shape={shape}
        displayPercentage
        label={label}
      />
    </LoadingAssetWrapper>
  );
}

LoadingAsset.propTypes = {
  shape: PropTypes.string,
  label: PropTypes.string,
  loadingPercentage: PropTypes.number
};

export default LoadingAsset;
