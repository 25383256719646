import { useDispatch } from 'react-redux';
import { toggleModalAction } from '../../store/actions/createActions';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { ModalContext } from '../../context/ModalContext';
export function useOpenCreateModal() {
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);
  const { state } = useContext(UserContext);
  const handleOpenCreateModal = () => {
    if (!state.isAuthenticated) {
      openModal();
    } else {
      dispatch(toggleModalAction());
    }
  };

  return { handleOpenCreateModal };
}
