import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const ContextualBannerWrapper = styled.div`
  position: relative;
  min-height: 250px;
  background: #000;

  .contextual-banner__media {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:before {
      content: '';
      position: absolute;
      height: calc(100% - 45px);
      width: 100%;
      box-shadow: inset 0 0 80px 80px rgba(0, 0, 0, 0.9);

      @media screen and (min-width: ${lightTheme.primitives.grid.scale['4']}) {
        box-shadow: none;
        background: linear-gradient(90deg, rgb(0, 0, 0) 20%, transparent 100%);
      }
    }
  }

  .contextual-banner__logo {
    height: 45px;
    text-align: right;
    padding: 8px 16px;
  }

  .contextual-banner__image {
    height: calc(100% - 45px);
    width: 100%;
    object-fit: cover;
  }

  .contextual-banner__content {
    position: relative;
    z-index: 1;
    max-width: 60%;
  }

  .headline-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 24px 16px 60px;
    gap: 4px;
    height: 100%;

    @media screen and (min-width: ${lightTheme.primitives.grid.scale['3']}) {
      padding: 24px 48px 45px;
    }

    .headline-text__cta {
      color: #fff;
      border-color: #fff;
      margin-top: 4px;
    }
  }
`;
