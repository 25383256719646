import React from 'react';
import PropTypes from 'prop-types';
import { TagWrapper, TagWrapperWithLink, DeleteButton } from './Tag.styled';
// import Typography from '@digital-hig/typography';
import { useTranslation } from 'react-i18next';

function Tag(props) {
  const { label, url, size, onDeleteClick, deletable, className } = props;

  const { t } = useTranslation();

  const Content = () => (
    <>
      {!!(typeof onDeleteClick === 'function' && deletable) && (
        <DeleteButton onClick={onDeleteClick}>{t('generic_delete_label')}</DeleteButton>
      )}
      <span>{label}</span>
    </>
  );

  return url ? (
    <TagWrapperWithLink size={size} className={className} to={url} target="_blank">
      <Content />
    </TagWrapperWithLink>
  ) : (
    <TagWrapper size={size} className={className}>
      <Content />
    </TagWrapper>
  );
}

Tag.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string,
  onDeleteClick: PropTypes.func,
  deletable: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

Tag.defaultProps = {
  label: undefined,
  url: undefined,
  className: undefined,
  deletable: false,
  onDeleteClick: undefined,
  size: 'small'
};

export default Tag;
