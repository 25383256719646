import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ProjectBuild from '.';
import { CREATE_ASSET, DELETE_ASSETS, FINALISE_ASSET } from '../../../apollo/mutations';
import { GET_ASSETS } from '../../../apollo/queries';
import {
  createAddBlockAction,
  createDeleteBlockAction,
  createEditBlockAction
} from '../../../store/actions/createActions';
import { generateUniqueId } from '../../../utils';

const MAX_ASSETS = 12;

function assetsDataToViewModel(array) {
  return array.map((asset) => {
    let newAsset = {
      type: asset.type,
      id: asset.id
    };

    switch (asset.type) {
      case 'embed':
        newAsset = {
          ...newAsset,
          title: asset.embed?.link_title,
          imageUrl: asset.embed?.link_image,
          imageAlt: asset.embed?.link_title
        };
        break;

      case 'image':
        newAsset = {
          ...newAsset,
          title: asset.image?.display_name,
          imageUrl: asset.image?.path,
          imageAlt: asset.image?.display_name
        };
        break;

      case '3D':
        newAsset = {
          ...newAsset,
          title: asset.resource?.file_name,
          imageUrl: asset.image?.path,
          imageAlt: asset.resource?.file_name,
          hasViewer: asset.resource?.has_viewer
        };
        break;

      default:
        return newAsset;
    }

    return newAsset;
  });
}

function assetDataToBlockViewModel(asset) {
  if (asset.type === 'embed') {
    return {
      ...asset,
      title: asset.embed?.link_title,
      embedUrl: asset.embed?.link_url,
      imageUrl: asset.embed?.link_image
    };
  } else if (asset.type === 'image') {
    return {
      ...asset,
      title: asset.image?.display_name,
      imageUrl: asset.image?.path,
      imageAlt: asset.image?.display_name
    };
  } else if (asset.type === '3D') {
    return {
      ...asset,
      title: asset.resource?.file_name,
      imageUrl: asset.image?.path,
      imageAlt: asset.resource?.file_name,
      hasViewer: asset.resource?.has_viewer,
      urn: asset.resource?.encoded_urn
    };
  }
}

const withUserAssetsHOC = (Comp) => (props) => {
  // reason: these aren't actually props passed to the component
  // eslint-disable-next-line react/prop-types
  const { addBlock, editBlock, deleteBlock, ...rest } = props;
  const [userAssets, setUserAssets] = useState([]);
  const [error, setError] = useState({});
  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    itemsPerPage: MAX_ASSETS,
    totalPageCount: 1,
    hasMorePages: false
  });

  const [getAssets, { loading, error: errorAssets, data: assetsData, fetchMore }] = useLazyQuery(
    GET_ASSETS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      context: { clientName: 'authorized' }
    }
  );

  if (errorAssets) {
    console.log(errorAssets);
  }

  const onLoadMore = () => {
    const nextPage = paginationState.currentPage + 1;
    fetchMore({
      variables: {
        page: nextPage
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        return Object.assign({}, previousResult, {
          assets: {
            ...previousResult.assets,
            data: [...previousResult.assets.data, ...fetchMoreResult.assets.data],
            has_more_pages: fetchMoreResult.assets.has_more_pages
          }
        });
      }
    });
    setPaginationState({
      ...paginationState,
      currentPage: nextPage
    });
  };

  const [addAsset, addAssetRes] = useMutation(CREATE_ASSET, {
    context: { clientName: 'authorized' }
  });
  const [finaliseAsset] = useMutation(FINALISE_ASSET, { context: { clientName: 'authorized' } });
  const [deleteAsset] = useMutation(DELETE_ASSETS, { context: { clientName: 'authorized' } });

  useEffect(() => {
    getAssets({
      variables: {
        limit: MAX_ASSETS,
        page: paginationState.currentPage
      }
    });
  }, [getAssets]);

  useEffect(() => {
    if (assetsData) {
      const assets = assetsData?.assets?.data;
      setUserAssets(assetsDataToViewModel(assets));
      setPaginationState({
        ...paginationState,
        hasMorePages: assetsData?.assets.has_more_pages,
        totalItems: assetsData?.assets.total,
        totalPageCount: Math.ceil(assetsData?.assets.total / MAX_ASSETS)
      });
    }
  }, [assetsData]);

  function handlePageChange(page) {
    setPaginationState({
      ...paginationState,
      currentPage: parseInt(page, 10)
    });
  }

  const handleAddAsset = async (assetObject, errorValidation) => {
    if (errorValidation && errorValidation.isError) setError(errorValidation);
    const assetArray = [].concat(assetObject);
    const tempId = generateUniqueId();
    let mutationBody = {
      refetchQueries: [
        {
          query: GET_ASSETS,
          variables: {
            limit: MAX_ASSETS,
            page: paginationState.currentPage
          }
        }
      ],
      onCompleted: (res) => {
        editBlock(
          assetDataToBlockViewModel({
            ...res.CreateAsset,
            id: tempId,
            newId: res.CreateAsset.id,
            type: res.CreateAsset.type,
            loading: false
          })
        );
      }
    };

    const addBlockBody = {
      id: tempId,
      loading: true,
      loadingPercentage: 100,
      isDisabled: true
    };

    for (const asset of assetArray) {
      if (typeof asset === 'string') {
        addBlock(
          {
            ...addBlockBody,
            type: 'embed',
            title: asset,
            embed: {
              link_url: asset
            }
          },
          'embed_url'
        );

        await addAsset({
          ...mutationBody,
          variables: {
            input: {
              type: 'embed',
              embed: asset
            }
          }
        });
      } else if (
        typeof asset === 'object' &&
        asset.type.includes('image') &&
        !asset.type.includes('dwg')
      ) {
        addBlock(
          {
            ...addBlockBody,
            type: 'image',
            imageUrl: asset,
            title: asset.name
          },
          'file_upload'
        );

        await addAsset({
          ...mutationBody,
          variables: {
            input: {
              type: 'image',
              image: asset
            }
          }
        });
      } else if (
        (typeof asset === 'object' && asset.type === '') ||
        asset.type.includes('dwg') ||
        asset.type.includes('model') ||
        asset.type.includes('application/x-tgif')
      ) {
        await addAsset({
          ...mutationBody,
          variables: {
            input: {
              type: '3D',
              file_name: asset.name
            }
          },
          onCompleted: (res) => {
            addBlock(
              {
                ...addBlockBody,
                type: '3D',
                title: asset.name
              },
              'file_upload'
            );
            const requestOptions = {
              method: 'PUT',
              headers: { 'Content-Type': 'application/octet-stream' },
              body: asset
            };

            fetch(res.CreateAsset.resource.upload_url, requestOptions)
              .catch(console.log) // TODO: error handling
              .then((response) => {
                if (response.status === 200) {
                  finaliseAsset({
                    variables: {
                      id: parseInt(res.CreateAsset.id, 10)
                    },
                    context: { clientName: 'authorized' },
                    onCompleted: (aRes) => {
                      editBlock(
                        assetDataToBlockViewModel({
                          ...aRes.FinaliseAsset,
                          id: tempId,
                          newId: aRes.FinaliseAsset.id,
                          type: aRes.FinaliseAsset.type,
                          allowDownload: aRes.FinaliseAsset.has_viewer,
                          loading: false
                        })
                      );
                    }
                  });
                }
              });
          }
        });
      }
    }
  };

  function handleDeleteAsset(id) {
    const nextPage = !paginationState.hasMorePages
      ? Math.ceil((paginationState.totalItems - 1) / MAX_ASSETS)
      : paginationState.currentPage;

    setPaginationState({
      ...paginationState,
      currentPage: nextPage > 0 ? nextPage : 1
    });
    deleteAsset({
      variables: { ids: [parseInt(id)] },
      context: { clientName: 'authorized' },
      onCompleted: (rest) => {
        if (rest?.blocks.map((block) => block.id).includes(id)) {
          deleteBlock(id);
        }
      },
      refetchQueries: [
        {
          query: GET_ASSETS,
          variables: {
            page: nextPage > 0 ? nextPage : 1,
            limit: MAX_ASSETS
          }
        }
      ]
    });
  }

  return (
    <Comp
      {...rest}
      fetchMore={onLoadMore}
      assetsLoaded={!loading}
      assetsLoading={addAssetRes.loading}
      fileUploadError={error}
      onFileUpload={handleAddAsset}
      onAddEmbed={handleAddAsset}
      onDeleteAssetFromLib={handleDeleteAsset}
      onAddAssetFromLib={(selection, type) => addBlock(selection, 'from_lib', type)}
      onRemoveAssetFromBlocks={deleteBlock}
      userAssets={userAssets}
      onPageChange={handlePageChange}
      paginationData={paginationState}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  addBlock: (payload, addFrom, type) => dispatch(createAddBlockAction(payload, addFrom, type)),
  editBlock: (payload) => dispatch(createEditBlockAction(payload)),
  deleteBlock: (payload) => dispatch(createDeleteBlockAction(payload))
});

export default connect(null, mapDispatchToProps)(withUserAssetsHOC(ProjectBuild));
