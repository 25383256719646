import React, { useState, useContext } from 'react';
import Banner from '../../components/Banner';
import Card from '@digital-hig/card';
import { ContentWrapper, PageDescription } from './campaigns.styled';
import CreateModal from '../../components/CreateModal';
import { useSelector } from 'react-redux';
import { ModalContext } from '../../context/ModalContext';
import SignInAlert from '../../components/SignInAlert';
import { STATIC_PATH } from '../../common/constants';
import { getEnv } from '../../utils/getEnv';
import { getEnvironmentConstant } from '../../utils/getEnvironmentConstant';
import { CardContent, CardMedia, CardActions } from '@digital-hig/card';
import Typography from '@digital-hig/typography';
import Grid from '@mui/material/Grid';
import { useOpenCreateModal } from '../../utils/hooks/useOpenCreateModal';

const DesignedAndMadeInFusion = () => {
  const { isModalOpen, closeModal } = useContext(ModalContext);
  const showCreateModal = useSelector((state) => state.create.showModal);
  const { handleOpenCreateModal } = useOpenCreateModal();
  const env = getEnv();
  const imgBasePath = getEnvironmentConstant({ env, key: STATIC_PATH });

  const heroImg = imgBasePath + '/static/images/designed-made-in-fusion-hero.jpg';
  const card1 = imgBasePath + '/static/images/designed-made-in-fusion-create.jpg';
  const card2 = imgBasePath + '/static/images/designed-made-in-fusion-submit.jpg';
  const card3 = imgBasePath + '/static/images/designed-made-in-fusion-explore.jpg';

  const [pageData] = useState({
    heroData: {
      type: 'campaign',
      title: 'Designed and made in Autodesk Fusion 360',
      subtitle:
        'Welcome to our Fusion 360 Student Gallery, where you can explore the amazing projects created by talented students using Fusion 360.',
      avatarProps: {
        name: `Bryan Hidalgo`,
        profilePicture: 'https://images.profile-stg.autodesk.com/default/user_X160.png',
        profileUrl: `/profile/com-000015847071`,
        slug: 'com-000015847071'
      },
      image: {
        src: heroImg,
        alt: '',
        srcSet: []
      },
      showSearchbar: false,
      actionProps: {
        href: '/designed-and-made-in-fusion/projects?order=recent&page=1',
        label: 'Explore',
        external: false
      }
    }
  });

  return (
    <>
      {/* <Helmet
        title={`${pageDataAPI?.page.seo?.title} | Autodesk Community Gallery`}
        meta={[
          {
            property: 'og:image',
            content:
              pageDataAPI?.page?.seo?.image ||
              'https://dsuj2mkiosyd2.cloudfront.net/seo/en/000/001/og_image_original.jpg'
          },
          {
            property: 'og:title',
            content: `${pageDataAPI?.page.seo?.og_title} | Autodesk Community Gallery`
          },
          {
            property: 'og:url',
            content: `https://www.autodesk.com/community/gallery/architecture-engineering-construction`
          },
          {
            property: 'og:description',
            content: `${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`
          },
          {
            property: 'twitter:title',
            content: `${pageDataAPI?.page.seo?.og_title} | Autodesk Community Gallery`
          }
        ]}
        description={`${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`}
      />*/}
      {isModalOpen && <SignInAlert onClose={closeModal} />}
      {showCreateModal && <CreateModal campaign={'designed&madeinfusion'} />}
      {pageData.heroData && (
        <Banner {...pageData.heroData} setToggleModal={handleOpenCreateModal} />
      )}

      <ContentWrapper>
        <PageDescription>
          <Typography
            sx={{
              mb: 8
            }}
          >
            From engineering and product design to art and architecture, Autodesk students use
            creativity and skills to bring their ideas to life. Get inspired by their work, explore
            recommended projects{' '}
            <a
              href="https://www.autodesk.com/campaigns/education/2023-back-to-school-educators"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{' '}
            or pick your own and submit it for a chance to be featured on our Autodesk Education
            social handles.
          </Typography>
          <Typography
            sx={{
              mb: 3,
              textAlign: 'center'
            }}
            variant="headline-small"
          >
            What’s the coolest project you can design and make in Fusion 360?
          </Typography>
        </PageDescription>
        <Grid container spacing={6}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardMedia alt="Alt text" component="img" image={card1} />
              <CardContent>
                <Typography
                  sx={{
                    mb: 3
                  }}
                  variant="headline-medium"
                >
                  1. Create a project
                </Typography>
                <Typography>
                  Sign up / sign in, then select the “create a project” button.
                </Typography>
              </CardContent>
              <CardActions className="dhig-mt-6">
                {/* <CardActionButton>Action</CardActionButton> */}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardMedia alt="Alt text" component="img" image={card2} />
              <CardContent>
                <Typography
                  sx={{
                    mb: 3
                  }}
                  variant="headline-medium"
                >
                  2. Submit
                </Typography>
                <Typography>
                  Give your project a title, a description, and select your industry – upload and
                  submit.
                </Typography>
              </CardContent>
              <CardActions className="dhig-mt-6">
                {/* <CardActionButton>Action</CardActionButton> */}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardMedia alt="Alt text" component="img" image={card3} />
              <CardContent>
                <Typography
                  sx={{
                    mb: 3
                  }}
                  variant="headline-medium"
                >
                  3. Explore
                </Typography>
                <Typography>
                  Filter your project view to explore other projects and get inspired.
                </Typography>
              </CardContent>
              <CardActions className="dhig-mt-6">
                {/* <CardActionButton>Action</CardActionButton> */}
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </ContentWrapper>
    </>
  );
};

export default DesignedAndMadeInFusion;
