import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import BottomFloatingActionBar from '../components/BottomFloatingActionBar';
import Button from '@digital-hig/button';
import Icon from '@digital-hig/icon';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ProfileListing from '../components/ProfileListing';
import { useOpenCreateModal } from '../utils/hooks/useOpenCreateModal';
import { UserContext } from '../context/UserContext';

const CommunityProfile = ({ profileData, isMyProfile }) => {
  const { t } = useTranslation();
  const { handleOpenCreateModal } = useOpenCreateModal();
  const { state } = useContext(UserContext);

  return (
    <>
      <Box mt="10px" data-wat-loc="community gallery">
        <>
          <ProfileListing
            ownProfile={isMyProfile}
            id={profileData?.user?.id}
            slug={profileData?.user?.slug}
          />
        </>
      </Box>
      {state.isAuthenticated && (
        <BottomFloatingActionBar>
          <Button
            className="filterbar--button"
            onClick={handleOpenCreateModal}
            startIcon={<Icon id="dhig--icons--utility--add" />}
          >
            {t('create_your_project_btn_label')}
          </Button>
        </BottomFloatingActionBar>
      )}
    </>
  );
};

CommunityProfile.propTypes = {
  setToggleModal: PropTypes.func,
  id: PropTypes.string,
  slug: PropTypes.string,
  profileData: PropTypes.object,
  isMyProfile: PropTypes.bool
};

export default CommunityProfile;
