import PropTypes from 'prop-types';
import { assetType } from '../constants';

export const createPropTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  slug: PropTypes.string,
  createType: PropTypes.oneOf(['artwork']),
  title: PropTypes.string,
  description: PropTypes.string,
  cover: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  status: PropTypes.oneOf(['draft', 'published']),
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(assetType).isRequired,
      title: PropTypes.string.isRequired,
      allowDownload: PropTypes.bool,
      downloadable: PropTypes.bool,
      imageUrl: PropTypes.string,
      imageAlt: PropTypes.string,
      fileSize: PropTypes.bool
    })
  ),
  tags: PropTypes.arrayOf(PropTypes.number),
  industries: PropTypes.arrayOf(PropTypes.number),
  products: PropTypes.arrayOf(PropTypes.number)
};

export const createStatePropTypes = {
  ...createPropTypes,
  showModal: PropTypes.bool,
  unsavedActions: PropTypes.bool,
  isEdit: PropTypes.bool,
  currentCreateDetailsStep: PropTypes.number,
  creatorData: PropTypes.shape({
    userName: PropTypes.string,
    profilePicture: PropTypes.string,
    title: PropTypes.string
  }),
  publishStatusData: PropTypes.shape({
    loaded: PropTypes.bool,
    loadingPercentage: PropTypes.number,
    error: PropTypes.shape({
      isError: PropTypes.bool,
      error: PropTypes.string
    })
  }),
  createDetailsSteps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      validated: PropTypes.bool.isRequired,
      isError: PropTypes.bool.isRequired,
      allFormFilledOnce: PropTypes.bool.isRequired
    })
  )
};
