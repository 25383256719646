import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle` 
  html {
    font-size: 16px !important;
    font-family: 'ArtifaktElement';
  }
  .router-wrapper {
    @media screen and (max-width: 768px){
     margin-top: 0;
    }
  }

  body{
    margin:0;
    background-color: #fff;
  }

  p, div, img, video{
    box-sizing: border-box;
  }

  .uh-container-wrapper .uh-container-section-wrapper{
    width:100% !important;
  }

  .inverted_white_loader {
    &.DhigProgress--background {      
      color:  rgba(255, 255, 255, 0.16) !important
    }
    &.DhigProgress--foreground {
      color: white !important
    }
  }

  a[href^=tel]{ color:#000; text-decoration:none;}
`;
