import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { GET_ASSET } from '../apollo/queries';
import { createBlockToViewModel } from '../common/Create/utils';

export function withBlockDataFetch(Comp) {
  function Block(props) {
    const { data, loading } = useQuery(GET_ASSET, {
      context: { clientName: 'authorized' },
      variables: { id: parseInt(props.id, 10) },
      fetchPolicy: 'cache-first'
    });
    const [blockData, setBlockData] = useState({});

    useEffect(() => {
      if (data) {
        setBlockData(createBlockToViewModel(data.asset, props.isEdit));
      }
    }, [data]);

    return <Comp {...blockData} {...props} loadingPercentage={100} loading={loading} />;
  }

  Block.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isEdit: PropTypes.bool
  };

  return Block;
}
