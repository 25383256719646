import React from 'react';
import Navigation from './Navigation';
import { Outlet, matchPath, useLocation } from 'react-router-dom';

const Layout = () => {
  const { pathname } = useLocation();
  const is3dViewer = matchPath('project/:id/assets/:assetId/embed', pathname);

  return (
    <>
      {!is3dViewer && <Navigation />}
      <div className={!is3dViewer && 'router-wrapper'}>
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
