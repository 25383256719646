import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import Autocomplete from '@digital-hig/autocomplete';
import ContentWrapper from '../ContentWrapper';
import Button from '@digital-hig/button';

export const BannerWrapper = styled.div`
  width: 100%;
  background-color: #000000;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  height: 100%;
  position: relative;

  input {
    color: white;
  }
  svg {
    color: white;
  }

  .banner-action-button {
    color: white;
    svg {
      color: white;
    }
  }

  .banner-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  ${({ type }) => {
    switch (type) {
      case 'campaign':
        return css`
          height: 600px;
        `;
      case 'hero':
        return css`
          .hero-banner-avatar {
            margin-top: ${lightTheme.primitives.spacings['9']};
          }
        `;
      case 'banner':
        return css`
          .big-banner-avatar {
            @media screen and (min-width: ${lightTheme.primitives.grid.scale['4']}) {
              position: absolute !important;
              z-index: 2;
              left: ${lightTheme.primitives.spacings['6']};
              bottom: ${lightTheme.primitives.spacings['7']};
              h5,
              p {
                color: #ffffff !important;
              }
            }
          }
        `;
    }
  }}
  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    min-height: unset;
    max-height: unset;
    padding: 0;

    .hero-banner-avatar,
    .big-banner-avatar {
      width: fit-content;
      margin-top: ${lightTheme.primitives.spacings['9']};
    }
  }
`;

export const BannerTitleWrapper = styled.div`
  margin-bottom: ${lightTheme.primitives.spacings['8']};
`;

export const BannerInner = styled(ContentWrapper)`
  max-width: 1248px;
  margin: 0 auto;
  display: flex;
  justify-content: ${({ type }) => (type === 'banner' ? 'flex-end' : 'flex-start')};
  align-items: center;
  min-height: 413px;
  padding: 0 1rem;
  gap: ${lightTheme.primitives.spacings['6']};
  padding-bottom: ${lightTheme.primitives.spacings['7']};
  padding-top: ${({ type }) =>
    type === 'banner' ? lightTheme.primitives.spacings['6'] : lightTheme.primitives.spacings['9']};
  z-index: 1;
  position: relative;
  width: 100%;
  ${({ variant }) =>
    variant === 'landing' &&
    css`
      padding-top: ${lightTheme.primitives.spacings['8']};
      padding-bottom: ${lightTheme.primitives.spacings['8']};
    `}
  ${({ variant }) =>
    variant === 'detail' &&
    css`
      padding-top: ${lightTheme.primitives.spacings['9']};
      padding-bottom: ${lightTheme.primitives.spacings['9']};
    `}
    
    @media screen and (max-width: ${lightTheme.primitives.grid.scale['7']}) {
    padding: 0 3rem;
    padding-top: ${lightTheme.primitives.spacings['8']};
    padding-bottom: ${lightTheme.primitives.spacings['8']};
  }
  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    flex-direction: column;
    justify-content: flex-end;
    gap: unset;
    align-items: flex-start;
    padding: 0 2rem;
    padding-top: ${lightTheme.primitives.spacings['8']};
    padding-bottom: ${lightTheme.primitives.spacings['8']};
  }
`;

export const BannerContentWrapper = styled.div`
  width: 50%;
  z-index: 2;
  h1,
  h5,
  p {
    color: #ffffff !important;
  }
  h1 {
    margin-bottom: ${lightTheme.primitives.spacings['2']};
  }
  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    width: 100%;
  }

  .banner-date {
    text-transform: uppercase;
    margin-top: ${lightTheme.primitives.spacings['2']};
  }
  ${({ type }) => {
    switch (type) {
      case 'hero':
        return css`
          h1 {
            font-size: ${lightTheme.primitives.fontSizes['8']} !important;
          }
        `;
      case 'banner':
        return css`
          h1 {
            font-size: ${lightTheme.primitives.fontSizes['7']} !important;
          }
        `;
    }
  }}
`;

export const ImageWrapper = styled.div`
  justify-self: flex-end;
  height: 100%;
  width: 50%;
  position: absolute;
  display: block;
  right: ${({ type }) => (type === 'hero' || type === 'campaign' ? 0 : 'unset')};
  left: ${({ type }) => (type === 'banner' ? 0 : 'unset')};
  z-index: 0;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.9;
  }
  canvas {
    object-fit: cover;
    width: 100% !important;
    opacity: 0.9;
  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 101%;
    height: 120%;

    box-shadow: inset 0px 0px 80px 80px #000000;
    mix-blend-mode: darken;
  }
  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    width: 100%;
    height: 90%;
    img {
      opacity: 0.6;
    }
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  background: rgba(255, 255, 255, 0.12);
  margin-top: 1.5rem;
  width: 422px;
  max-width: 100%;
  border-radius: 4px;
`;

export const CreateButton = styled(Button)`
  // font-family: ${lightTheme.primitives.fontFamilies.default} !important;
  // font-weight: 600 !important;
  // font-size: ${lightTheme.primitives.fontSizes['2']} !important;
  // padding: ${lightTheme.primitives.spacings['2']} ${lightTheme.primitives.spacings[
    '4'
  ]} !important;
  border: 1px solid #fff;
  color: white;
`;

export const GalleryButtonWrapper = styled.div`
  position: absolute;
  right: 100px;
  bottom: 32px;
  z-index: 9;
`;
