import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { PROJECT_BOOKMARK_MUTATION } from '../../apollo/mutations';
import { projectPropTypes } from '../../common/types';
import PropTypes from 'prop-types';
import { UserContext } from '../../context/UserContext';
import { ModalContext } from '../../context/ModalContext';

export function useBookmarkProject(project) {
  const [isBookmarked, setIsBookmarked] = useState(project.isBookmarked);
  const [bookmark] = useMutation(PROJECT_BOOKMARK_MUTATION, {
    context: { clientName: 'authorized' }
  });
  const { openModal } = useContext(ModalContext);
  const { state } = useContext(UserContext);

  const handleBookmarkClick = () => {
    if (!state.isAuthenticated) {
      openModal();
    } else {
      bookmark({
        variables: {
          id: parseInt(project.id, 10),
          action: isBookmarked ? 'remove' : 'add'
        }
      });

      setIsBookmarked(!isBookmarked);
    }
  };

  return { isBookmarked, handleBookmarkClick };
}

useBookmarkProject.propTypes = {
  project: PropTypes.shape({
    id: projectPropTypes.id.isRequired,
    isBookmarked: projectPropTypes.isBookmarked.isRequired
  }).isRequired
};
