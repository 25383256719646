import styled from 'styled-components';
import darkTheme from '@digital-hig/theme-data/lib/build/json/dark/design-tokens.json';
import ImageWithSrcSet from '../ImageWithSrcSet';
import Icon from '@digital-hig/icon';

export const ThumbnailWrapper = styled.div`
  display: flex;
  position: relative;
  border-radius: ${darkTheme.primitives.borderRadii.large};
  overflow: hidden;
  aspect-ratio: ${(props) => (props.type == 'sm' ? 0.8 : 0.681)};
  span,
  h2 {
    color: ${darkTheme.primitives.colors.primary.white};
  }

  svg {
    color: ${darkTheme.primitives.colors.primary.white};
  }
`;

export const ThumbnailHeader = styled.div`
  display: grid;
  z-index: 30;
  position: absolute;
  // top: ${darkTheme.primitives.spacings['6']};
  // right: ${darkTheme.primitives.spacings['6']};
  width: 100%;
  justify-content: space-between;
  padding: 1.5rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'left right';
`;
export const ThumbnailHeaderLeft = styled.div`
  grid-area: left;
`;
export const ThumbnailHeaderRight = styled.div`
  grid-area: right;
  display: flex;
  justify-content: right;
`;

export const ThumbnailFooter = styled.div`
  display: block;
  z-index: 3;
  position: relative;
  .avatar--content-wrapper {
    h5,
    p {
      color: white;
    }
  }
`;

export const ThumbnailButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.4s ease, opacity 0.4s ease;

  svg {
    position: relative;
    top: -2px;
    transition: transform 0.4s ease;
  }

  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

export const ThumbnailBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);
  border-radius: ${darkTheme.primitives.borderRadii.large};
`;

export const ThumbnailImage = styled(ImageWithSrcSet)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  border-radius: ${darkTheme.primitives.borderRadii.large};
  will-change: transform;
  transform: scale(1);
  transition: transform 0.2s ease-out;
`;

export const ThumbnailContent = styled.div`
  display: inline-flex;
  width: 100%;
`;

export const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-right: 4px;

  path {
    fill: ${({ $liked }) => ($liked ? 'white' : 'none')};
  }
`;

export const ThumbnailContentWrapper = styled.div`
  a {
    text-decoration: none;
    color: #ffffff;
  }
  .thumbnail-real-link {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
  }
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${darkTheme.primitives.spacings['6']};
  border-radius: ${darkTheme.primitives.borderRadii.large};
  aspect-ratio: ${(props) => (props.type == 'sm' ? 0.8 : 0.681)};
  overflow: hidden;
  width: 100%;
  &:hover {
    img {
      transform: scale(1.05);
      transition: transform 0.8s ease-out;
    }
  }
`;
