import styled from 'styled-components';

export const ProfileCardWrapper = styled.div`
  .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .cardTypography {
    text-align: center;
  }
  .cardComponent {
    background-color: #f9f9f9;
    width: 308px;
    height: 405px;
    border: 1px dashed #bfbfbf;
    box-shadow: none;
  }
  .cardButton {
    justify-content: center;
    align-items: center;
  }
  .cardImg {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;
