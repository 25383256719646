import styled from 'styled-components';
import Avatar from '../Avatar';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 0.5rem;
`;
