import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@digital-hig/button/lib/button';
import { useSelector } from 'react-redux';
// import { trackCreateStep0 } from '../../utils/trackingScripts';
function Actions(props) {
  const {
    handleCancelClick,
    showCancelButton,
    showPrevButton,
    changeModalType,
    nextButtonDisabled,
    setCreateDetailsFormCurrentStep,
    isEdit,
    currentStep,
    stepsCount,
    publishCreate,
    slug,
    // triggerTrack,
    handleClearBlocks,
    formComponentType
  } = props;

  const blocks = useSelector((state) => state.create?.blocks);

  function nextButtonCopy() {
    if (isEdit) {
      return '_edit';
    } else if (currentStep === stepsCount) {
      return '_publish';
    } else if (currentStep > stepsCount) {
      return '_edit_details';
    }
    return '';
  }

  function handleNextButtonClick() {
    if (isEdit) {
      //trackCreateStep0();
      return handleCancelClick();
    }

    if (nextButtonCopy() === '' || nextButtonCopy() === '_publish') {
      // triggerTrack();
      setCreateDetailsFormCurrentStep('next');
    }
    if (nextButtonCopy() === '_publish') {
      publishCreate();
    } else if (nextButtonCopy() === '_edit_details' && slug) {
      changeModalType('project_build');
      setCreateDetailsFormCurrentStep(0);
    }
  }

  function handleCancel() {
    if (isEdit) {
      return handleClearBlocks();
    } else {
      return handleCancelClick();
    }
  }

  function disableNextButton() {
    if (!isEdit) {
      if (currentStep > stepsCount) {
        return slug === undefined;
      } else {
        return nextButtonDisabled;
      }
    } else {
      return false;
    }
  }

  return (
    <>
      {showCancelButton && !formComponentType === 'project_build' && (
        <Button
          variant="text"
          onClick={handleCancel}
          style={{ textTransform: 'none' }}
          disabled={formComponentType === 'project_build' && blocks.length === 0}
        >
          {formComponentType === 'project_build'
            ? t('create_modal_reset_button_label')
            : t('create_modal_cancel_button_label')}
        </Button>
      )}

      {showPrevButton && !isEdit && (
        <Button variant="outlined" onClick={() => setCreateDetailsFormCurrentStep('prev')}>
          {t('create_modal_prev_button_label')}
        </Button>
      )}

      <Button disabled={disableNextButton()} onClick={handleNextButtonClick}>
        {t(`create_modal${nextButtonCopy()}_next_button_label`)}
      </Button>
    </>
  );
}

Actions.propTypes = {
  handleCancelClick: PropTypes.func.isRequired,
  handleClearBlocks: PropTypes.func,
  setCreateDetailsFormCurrentStep: PropTypes.func.isRequired,
  // triggerTrack: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool,
  showPrevButton: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  changeModalType: PropTypes.func,
  isEdit: PropTypes.bool,
  currentStep: PropTypes.number,
  stepsCount: PropTypes.number,
  publishCreate: PropTypes.func,
  slug: PropTypes.string,
  formComponentType: PropTypes.string
};

Actions.defaultProps = {
  showCancelButton: true,
  showPrevButton: false,
  nextButtonDisabled: true,
  toggleEditMode: () => {},
  changeModalType: () => {},
  publishCreate: () => {},
  isEdit: false,
  currentStep: null,
  stepsCount: null,
  slug: undefined
};

export default Actions;
