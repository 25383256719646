import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

const spacing4 = lightTheme.primitives.spacings['4'];
const spacing3 = lightTheme.primitives.spacings['3'];
const spacing2 = lightTheme.primitives.spacings['2'];

export const LibraryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${spacing2} ${spacing2};
  @media screen and (min-width: ${lightTheme.primitives.grid.scale['5']}) {
    grid-template-columns: repeat(4, 1fr);
    gap: ${spacing4} ${spacing3};
  }
  flex-wrap: wrap;
  align-self: center;
  ${({ showPagination }) =>
    showPagination &&
    css`
      margin-bottom: ${lightTheme.primitives.spacings['6']};
    `}
`;

export const AssetLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing2};
  justify-content: center;
  padding: ${spacing4};
  z-index: 3;
  position: absolute;
  pointer-events: none;

  svg,
  p {
    color: white;
  }
`;

export const FileWrapper = styled.div`
  width: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .asset-title {
    max-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    width: 100%;
  }
`;

export const FileCover = styled.div`
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  width: 100px;
  height: 100px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border-radius: ${lightTheme.primitives.borderRadii.large};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .file-actions-wrapper {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 999;
    button {
      transition: opacity 0.3s ${lightTheme.primitives.transitions.easing.smooth};
      cursor: pointer;
    }
    svg {
      color: white;
    }
  }
  .file-actions-selected-icon {
    position: absolute;
    right: 10px;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.3s ${lightTheme.primitives.transitions.easing.smooth};
    bottom: ${lightTheme.primitives.spacings['2']};
  }

  :focus,
  :hover {
    .file-actions-selected-icon {
      opacity: 0;
    }
    .file-actions-wrapper {
      button {
        opacity: 1 !important;
      }
    }
  }
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${lightTheme.primitives.spacings['6']} 0;
`;
