import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BlockImageWrapper } from './Blocks.styled';
import { withBlockDataFetch } from '../../hocs/withBlockDataFetch';
function BlockImage(props) {
  const { imageUrl, imageAlt, height, width, imageSizes = [], onOpenSlideShow, isEdit, id } = props;

  const srcSet = imageSizes?.map((image) => `${image.path} ${image.width}w`).join(', ');

  return (
    <BlockImageWrapper
      id={'image-' + id}
      divHeight={height}
      isEdit={isEdit}
      {...(!isEdit && { onClick: () => onOpenSlideShow() })}
    >
      <LazyLoadImage
        srcSet={srcSet}
        src={imageUrl}
        alt={imageAlt}
        height={height > 0 ? height : 'auto'}
        width={width > 0 ? width : 'auto'}
        sizes="(max-width: 600px) 400px, (max-width: 900px) 800px, 1300px"
      />
    </BlockImageWrapper>
  );
}

BlockImage.propTypes = {
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  imageSizes: PropTypes.array.isRequired,
  onOpenSlideShow: PropTypes.func,
  isEdit: PropTypes.bool
};

export const ConnectedBlockImage = memo(withBlockDataFetch(BlockImage));

const MemoizedBlockImage = memo(BlockImage);

export default MemoizedBlockImage;
