import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@digital-hig/typography';
import ThumbsUp from '@digital-hig/icon/lib/build/icons/actions/thumbs-up';
import ThumbsUpFilled from '@digital-hig/icon/lib/build/icons/actions/thumbs-up-filled';
import Skeleton from '@mui/material/Skeleton';

import {
  ThumbnailWrapper,
  ThumbnailHeader,
  ThumbnailFooter,
  ThumbnailImage,
  ThumbnailBackdrop,
  ThumbnailButton,
  ThumbnailContent,
  ThumbnailContentWrapper,
  ThumbnailHeaderLeft,
  ThumbnailHeaderRight,
  StyledIcon
} from './Thumbnail.styled';
import Avatar from '../Avatar';
import { useLikeProject } from '../../utils/hooks/useLikeProject';
import { projectPropTypes } from '../../common/types';
import { Link } from 'react-router-dom';

function getTitleVariant(type) {
  let variant = '';
  switch (type) {
    case 'xl':
      variant = 'headline-large';
      break;
    case 'lg':
      variant = 'headline-medium';
      break;
    case 'md':
      variant = 'headline-small';
      break;
    case 'sm':
      variant = 'headline-small';
      break;
    default:
      break;
  }
  return variant;
}

const Thumbnail = ({
  project,
  type,
  linkUrl,
  image,
  author,
  title,
  isLoading,
  ownProject,
  isProfile,
  has3dViewer
}) => {
  const { t } = useTranslation();
  const { isLiked, handleLikeClick } = useLikeProject(project);

  const isMyProjectOnProfile = ownProject && isProfile;

  return (
    <ThumbnailWrapper className="thumbnail" type={type}>
      <ThumbnailContent>
        {!isLoading ? (
          <ThumbnailHeader>
            {has3dViewer && (
              <ThumbnailHeaderLeft>
                <StyledIcon
                  id={`dhig--icons--miscellaneous--products-and-services`}
                  size="medium"
                  alt="Includes 3D model"
                ></StyledIcon>
              </ThumbnailHeaderLeft>
            )}
            {!ownProject && (
              <ThumbnailHeaderRight>
                <ThumbnailButton
                  onClick={handleLikeClick}
                  disabled={isLoading}
                  data-wat-val={isLiked ? 'liked' : 'unlike'}
                >
                  <Typography variant="short-copy-small" component="span">
                    <StyledIcon
                      id={`dhig--icons--actions--thumbs-up${isLiked ? '-filled' : ''}`}
                      $liked={isLiked}
                      size="medium"
                    >
                      {isLiked ? <ThumbsUpFilled /> : <ThumbsUp />}
                    </StyledIcon>
                    {t(`create_content_like${isLiked ? 'd' : ''}_project`)}
                  </Typography>
                </ThumbnailButton>
              </ThumbnailHeaderRight>
            )}
          </ThumbnailHeader>
        ) : (
          <>
            {!ownProject && (
              <ThumbnailHeader>
                <Skeleton width="60px" height="32px" />
              </ThumbnailHeader>
            )}
            <Skeleton
              style={{
                position: 'absolute'
              }}
              variant="rectangle"
              width="100%"
              height="100%"
            />
          </>
        )}
        <ThumbnailContentWrapper type={type}>
          {!isLoading && (
            <>
              <Link to={linkUrl} data-wat-val={linkUrl} className="thumbnail-real-link" />
              {image.src && (
                <ThumbnailImage
                  src={image.src}
                  alt={image.alt}
                  srcSet={image.srcSet}
                  size={type}
                  isBackground
                  lazy={true}
                />
              )}
              <ThumbnailBackdrop />
            </>
          )}

          <ThumbnailFooter>
            <>
              {!isLoading ? (
                <>
                  <Typography
                    variant={getTitleVariant(type)}
                    component="h2"
                    color="ink-on-fill-primary"
                    sx={{
                      mb: '4'
                    }}
                    style={{ marginBottom: '8px' }}
                  >
                    <Link to={linkUrl}>{title}</Link>
                  </Typography>
                  {!isMyProjectOnProfile && (
                    <Avatar
                      name={author.name}
                      profilePicture={author.image.src}
                      size="small"
                      profileUrl={author.url}
                      profilePictureAlt={author.image.alt}
                    />
                  )}
                </>
              ) : (
                <Skeleton variant="rectangle" width="100%" height="24px" />
              )}
            </>
            {isLoading && !isMyProjectOnProfile && (
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '4px' }}>
                <Skeleton variant="circular" width="30px" height="30px" />
                <Skeleton variant="rectangle" width="100px" height="16px" />
              </div>
            )}
          </ThumbnailFooter>
        </ThumbnailContentWrapper>
      </ThumbnailContent>
    </ThumbnailWrapper>
  );
};

Thumbnail.propTypes = {
  project: PropTypes.shape({
    id: projectPropTypes.id.isRequired,
    isLiked: projectPropTypes.isLiked
  }).isRequired,
  type: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    srcSet: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        format: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  author: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string.isRequired,
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  linkUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  ownProject: PropTypes.bool,
  isProfile: PropTypes.bool,
  has3dViewer: PropTypes.bool
};

Thumbnail.defaultProps = {
  type: 'xl'
};

export default Thumbnail;
