import React from 'react';
import PropTypes from 'prop-types';
import { PageContainerWrapper } from './PageContainer.styled';

function PageContainer({ children }) {
  return <PageContainerWrapper>{children}</PageContainerWrapper>;
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageContainer;
