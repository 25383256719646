const modExports = {};

modExports.LOCAL_ENV_URL = 'localhost.autodesk.com';
modExports.DEV_ENV_URL = 'app.community-gallery-development.autodesk.com';
modExports.STAGE_ENV_URL = 'app.community-gallery-stage.autodesk.com';
modExports.PREF_ENV = 'app.community-gallery.autodesk.com';
modExports.PROD_ENV_URL = 'autodesk.com/community/gallery';

modExports.LOCAL_ENV = 'local';
modExports.DEV_ENV = 'dev';
modExports.STAGE_ENV = 'stg';
modExports.PREF_ENV = 'pref';
modExports.PROD_ENV = 'prod';

modExports.PRELOADED_STATE_KEY = '__PRELOADED_STATE__';
modExports.API_GRAPHQL_URL = 'API_GRAPHQL_URL';
modExports.FORGE_CLIENT_ID = 'FORGE_CLIENT_ID';
modExports.FORGE_AUTH_API = 'FORGE_AUTH_API';
modExports.MAINTENANCE_MODE = 'MAINTENANCE_MODE';
modExports.ENVIRONMENT = 'ENVIRONMENT';
modExports.PKCE_ENV = 'PKCE_ENV';
modExports.VIEWER_ENV = 'VIEWER_ENV';
modExports.AWS_ENV_VARS = 'AWS_ENV_VARS';
modExports.CANONICAL_URL = 'CANONICAL_URL';
modExports.PROFILE_URL = 'PROFILE_URL';
modExports.STATIC_PATH = 'STATIC_PATH';
modExports.FORUMS_PROFILE_URL = 'FORUMS_PROFILE_URL';

modExports.VALID_AWS_ENVS = [
  modExports.DEV_ENV,
  modExports.STAGE_ENV,
  modExports.PREF_ENV,
  modExports.PROD_ENV
];

modExports.ROUTER_BASENAME = '/community/gallery';

modExports.AWS_ENV_VARS = {
  ENVIRONMENT: {
    default: modExports.DEV_ENV,
    validator: (v) => modExports.VALID_AWS_ENVS.includes(v)
  }
};

modExports.ENVIRONMENT_CONSTANTS = {
  local: {
    ENVIRONMENT: modExports.DEV_ENV,
    API_GRAPHQL_URL: 'https://gallery-dev-api.autodesk.com/graphql',
    FORGE_CLIENT_ID: 'XvscUGtvNiIeBGwa76gLDPzYnAU5fUf9',
    FORGE_AUTH_API: 'https://localhost.autodesk.com:3000/community/gallery',
    STATIC_PATH: 'https://localhost.autodesk.com:3000/community/gallery',
    CANONICAL_URL: 'https://localhost.autodesk.com:3000/community/gallery',
    PKCE_ENV: 'stg',
    VIEWER_ENV: 'AutodeskStaging2',
    PROFILE_URL: 'https://profile-stg.autodesk.com/personalinfo',
    FORUMS_PROFILE_URL: 'https://forums-dev.autodesk.com/t5/user/viewprofilepage/user-id'
  },
  dev: {
    ENVIRONMENT: modExports.DEV_ENV,
    API_GRAPHQL_URL: 'https://gallery-dev-api.autodesk.com/graphql',
    FORGE_CLIENT_ID: 'XvscUGtvNiIeBGwa76gLDPzYnAU5fUf9',
    FORGE_AUTH_API: 'https://app.community-gallery-development.autodesk.com/community/gallery',
    STATIC_PATH: 'https://app.community-gallery-development.autodesk.com',
    CANONICAL_URL: 'https://app.community-gallery-development.autodesk.com/community/gallery',
    PKCE_ENV: 'stg',
    VIEWER_ENV: 'AutodeskStaging2',
    PROFILE_URL: 'https://profile-stg.autodesk.com/personalinfo',
    FORUMS_PROFILE_URL: 'https://forums-dev.autodesk.com/t5/user/viewprofilepage/user-id'
  },
  stg: {
    ENVIRONMENT: modExports.STAGE_ENV,
    API_GRAPHQL_URL: 'https://gallery-stg-api.autodesk.com/graphql',
    FORGE_CLIENT_ID: 'XvscUGtvNiIeBGwa76gLDPzYnAU5fUf9',
    FORGE_AUTH_API: 'https://www-pt.autodesk.com/community/gallery',
    STATIC_PATH: 'https://app.community-gallery-stage.autodesk.com',
    CANONICAL_URL: 'https://www-pt.autodesk.com/community/gallery',
    PKCE_ENV: 'stg',
    VIEWER_ENV: 'AutodeskStaging2',
    PROFILE_URL: 'https://profile-stg.autodesk.com/personalinfo',
    FORUMS_PROFILE_URL: 'https://forums-stg.autodesk.com/t5/user/viewprofilepage/user-id'
  },
  pref: {
    ENVIRONMENT: modExports.PROD_ENV,
    API_GRAPHQL_URL: 'https://gallery-api.autodesk.com/graphql',
    FORGE_CLIENT_ID: 'vWgEAzcYFHsX8jIl8ZS2tLARLtf3qEXe',
    FORGE_AUTH_API: 'https://app.community-gallery.autodesk.com/community/gallery',
    STATIC_PATH: 'https://app.community-gallery.autodesk.com',
    CANONICAL_URL: 'https://www.autodesk.com/community/gallery',
    PKCE_ENV: 'prd',
    VIEWER_ENV: 'AutodeskProduction2',
    PROFILE_URL: 'https://profile.autodesk.com/',
    FORUMS_PROFILE_URL: 'https://forums.autodesk.com/t5/user/viewprofilepage/user-id'
  },
  prod: {
    ENVIRONMENT: modExports.PROD_ENV,
    API_GRAPHQL_URL: 'https://gallery-api.autodesk.com/graphql',
    FORGE_CLIENT_ID: 'vWgEAzcYFHsX8jIl8ZS2tLARLtf3qEXe',
    FORGE_AUTH_API: 'https://www.autodesk.com/community/gallery',
    STATIC_PATH: 'https://app.community-gallery.autodesk.com',
    CANONICAL_URL: 'https://www.autodesk.com/community/gallery',
    PKCE_ENV: 'prd',
    VIEWER_ENV: 'AutodeskProduction2',
    PROFILE_URL: 'https://profile.autodesk.com/',
    FORUMS_PROFILE_URL: 'https://forums.autodesk.com/t5/user/viewprofilepage/user-id'
  }
};
// Add ENVIRONMENT_CONSTANTS to AWS_ENV_VARS to allow for overrides
Object.keys(modExports.ENVIRONMENT_CONSTANTS.dev).forEach((key) => {
  modExports.AWS_ENV_VARS[key] = modExports.AWS_ENV_VARS[key] || {};
});

modExports.assetType = ['3D', 'image', 'embed', 'raas_embed', 'file'];

module.exports = modExports;
