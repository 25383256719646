import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { PROJECT_LIKE_MUTATION } from '../../apollo/mutations';
import PropTypes from 'prop-types';
import { projectPropTypes } from '../../common/types';
import { UserContext } from '../../context/UserContext';
import { ModalContext } from '../../context/ModalContext';

export function useLikeProject(project) {
  const [isLiked, setIsLiked] = useState(null);
  const { openModal } = useContext(ModalContext);
  const { state } = useContext(UserContext);

  useEffect(() => {
    setIsLiked(project?.isLiked);
  }, [project?.isLiked]);

  const [likeMutationFunction] = useMutation(PROJECT_LIKE_MUTATION, {
    context: { clientName: 'authorized' }
  });

  const handleLikeClick = () => {
    if (!state.isAuthenticated) {
      openModal();
    } else {
      likeMutationFunction({
        variables: {
          id: parseInt(project.id, 10),
          action: isLiked ? 'remove' : 'add'
        }
      });

      setIsLiked(!isLiked);
    }
  };

  return { isLiked, handleLikeClick };
}

useLikeProject.propTypes = {
  project: PropTypes.shape({
    id: projectPropTypes.id.isRequired,
    isLiked: projectPropTypes.isLiked
  }).isRequired
};
