import styled from 'styled-components';

export const SigninButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: ArtifaktElement, Arial, sans-serif;
  color: #000000;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.43;
  text-decoration-color: rgb(0, 0, 0);
  text-decoration-thickness: 0.0625em;
  text-underline-offset: 0.125em;
`;
