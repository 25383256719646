import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import darkTheme from '@digital-hig/theme-data/lib/build/json/dark/design-tokens.json';

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 56px;
  width: 100%;
  padding: ${lightTheme.primitives.spacings['5']};
  box-sizing: border-box;
  height: 74px;
  background-color: ${lightTheme.primitives.colors.primary.white};
  box-shadow: ${darkTheme.tokens.effects['divider-0-bottom-on-fill-primary'].boxShadow};
  transition: all ease 0.3s;
  z-index: 10;

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['5']}) {
    top: 98px;
  }

  ${({ isEdit }) =>
    isEdit &&
    css`
      background-color: ${lightTheme.primitives.colors.primary.black};
      border-bottom: 1px solid ${lightTheme.primitives.colors.tint['slate-600']};
      border-top: 1px solid ${lightTheme.primitives.colors.tint['slate-600']};
    `}
`;

export const ReportOptionsWrapper = styled.div`
  width: 586px;
`;

export const PublishWrapper = styled.div`
  display: flex;
  gap: 8px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      position: fixed;
      bottom: 0;
      right: 0;
      padding: 28px;
      width: 100%;
      justify-content: flex-end;
      background-color: ${lightTheme.primitives.colors.primary.black};
    `}
`;

export const AlertWrapper = styled.div`
  width: 100%;
  min-height: 42px;
  background-color: ${(props) => {
    switch (props.color) {
      case 'success':
        return lightTheme.primitives.colors.accent.plant;
      // break;
      case 'info':
        return lightTheme.primitives.colors.accent.iris;

      case 'pending':
        return lightTheme.primitives.colors.accent.iris;
      // break;

      case 'draft':
        return lightTheme.primitives.colors.accent.gold;
      // break;

      case 'deleted':
        return lightTheme.primitives.colors.accent.clay;

      case 'error':
        return lightTheme.primitives.colors.accent.clay;
      // break;

      default:
        return;
    }
  }};
`;
export const AlertInner = styled.div`
  width: 100%;
  max-width: 1248px;
  margin: 0 auto;
`;

export const TnCModalWrapper = styled.div`
  display: grid;
  .svg_icon_margin {
    margin-bottom: 10px;
  }
`;

export const MediaListActions = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  .action-icons {
    cursor: pointer;
    height: 20px !important;
    width: 20px !important;
  }
`;

export const SocialMediaRow = styled.div`
  box-shadow: 0px -1px 0px 0px #000000 inset, 0px 0px 0px 1px rgba(0, 0, 0, 0.3) inset;
  padding: 8px;

  display: flex;
  gap: 8px;
  align-items: center;

  ${({ error }) =>
    error &&
    css`
      align-items: flex-start;
    `}

  .social_media_select {
    width: 143px;
    height: 40px;
  }
  .social_media_input {
    width: 260px;
  }
  .media_icon {
    height: 24px;
    width: 24px;
    padding: 0 8px;
    align-self: center;
  }
`;

export const SocialMediaWrapper = styled.div`
  margin-bottom: ${lightTheme.primitives.spacings['2']};
`;

export const AddbuttonWrapper = styled.div`
  .add_button {
    margin: 10px;
  }
  .checkmark_action {
    height: 20px;
    width: 20px;
    padding: 0 8px;
    cursor: pointer;
  }
`;

export const MediaListWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  .terms_and_conditions {
    font-weight: 400;
    line-height: 17.5px;
  }
  .checkbox_title {
    font-size: 16px;
    font-weight: 400;
  }
  .media_label {
    font-weight: 700;
    // padding: 0 8px;
    align-self: center;
    min-width: 95px;
    margin-left: 0px;
  }
  .url_label {
    width: 270px;
    overflow: auto;
    margin-right: 8px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const HeaderWrapperInner = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-areas: 'edit label more';
  ${({ isEdit }) =>
    isEdit &&
    css`
      @media screen and (min-width: ${lightTheme.primitives.grid.scale['5']}) {
        grid-template-columns: 1fr 2fr 1fr;
        grid-template-areas: 'edit label more';
      }
    `}

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['5']}) {
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas: 'edit label more';
  }

  ${({ rightAndStatus }) =>
    rightAndStatus &&
    css`
      justify-content: flex-end;
    `}
  max-width: 1248px;
  margin: 0 auto;
  @media screen and (max-width: 728px) {
    /* flex-direction: column; */
    gap: ${lightTheme.primitives.spacings['2']};
  }
  .create_content_tag {
    margin: 0 auto;
  }
  .create_content_status_draft {
    background-color: ${lightTheme.tokens.colors['fill-warning'].rgb};
  }
  .create_content_status_published {
    background-color: ${lightTheme.tokens.colors['fill-success'].rgb};
  }
  .create_content_status_pending {
    background-color: ${lightTheme.primitives.colors.accent.iris};
    span {
      color: #ffffff;
  }
  .create_content_status_deleted {
    background-color: ${lightTheme.tokens.colors['fill-error'].rgb};
    span {
      color: #ffffff;
    }
  }
  .create_content_status_rejected {
    background-color: ${lightTheme.tokens.colors['fill-error'].rgb};
    span {
      color: #ffffff;
    }
  }
`;

export const EditActionsWrapper = styled.div`
  display: flex;
  grid-area: edit;
  gap: ${lightTheme.primitives.spacings['2']};

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    button {
      min-width: 32px !important;
      box-shadow: none !important;
    }

    .MuiButton-startIcon {
      margin-right: 0;
    }
  }
`;
export const LabelWrapper = styled.div`
  grid-area: label;
  margin: 0 auto;
`;

export const MoreActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-area: more;
  gap: ${lightTheme.primitives.spacings['2']};

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    button {
      min-width: 32px !important;
      box-shadow: none !important;
    }

    .MuiButton-startIcon {
      margin-right: 0;
    }
  }
`;

export const ButtonLabel = styled.span`
  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px !important;
  ${({ showCount }) =>
    showCount &&
    css`
      svg {
        margin-left: ${lightTheme.primitives.spacings['2']};
      }
    `}
`;

export const TermsAndConditionsWrapper = styled.div`
  background-color: ${lightTheme.tokens.colors['background-panel-distinct'].rgb};
  padding: ${lightTheme.primitives.spacings['6']};
  border-radius: ${lightTheme.primitives.borderRadii['medium']};
`;

export const CampaignWrapper = styled.div`
  margin-bottom: ${lightTheme.primitives.spacings['6']};
  .about_project_input {
    width: 100%;
    margin-top: 10px;
  }
`;
