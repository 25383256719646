import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const NavigationWrapper = styled.div`
  background-color: #f9f9f9;
  z-index: 99;
  width: 100%;
  min-height: 50px;
  @media screen and (max-width: 768px) {
    top: 57px;
  }
`;

export const NavigationTitle = styled(Link)`
  display: inline-block;
  position: relative;
  padding: 0.8em 26px 0.6em;
  border-bottom: 1px solid #000000;
  text-decoration: none;

  span {
    line-height: 1em !important;
    font-weight: 600 !important ;
  }
`;

export const NavigationContainer = styled.div`
  font-size: 1rem;
  max-width: 1248px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (max-width: 1024px) {
    justify-content: space-between;
    position: relative;
    text-align: right;
    align-items: center;
  }
`;

export const NavigationNav = styled.nav`
  display: flex;
  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: #f9f9f9;
    display: none;
    z-index: 99;
    ${({ isNavOpen }) =>
      isNavOpen &&
      css`
        display: block;
      `};
  }
`;

export const NavigationItems = styled.ul`
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-inline-start: 0;
`;

export const NavigationItem = styled.li`
  list-style-type: none;

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    width: 100%;
  }
`;
export const NavigationItemIcon = styled.span`
  margin-right: 4px;
`;

export const NavigationLink = styled(Link)`
  border-bottom: none;
  font-size: 14px;
  font-weight: 600;
  padding: 18px 1rem 14px;
  line-height: 1em;
  display: inline-block;
  text-decoration: none;
  color: #000;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.09);
    border: none !important;
  }
`;
