import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SectionWrapper, TabSection, TabsWrapper } from './BuildSections.styled';
import { t } from 'i18next';
import FileUpload from '../../../../components/FileUpload';
import FileLibrary from '../../../../components/FileLibrary';
import Typography from '@digital-hig/typography';
import Tabs from '@digital-hig/tabs/lib/tabs';
import Icon from '@digital-hig/icon';
import TextInput from '@digital-hig/text-input';
import Button from '@digital-hig/button/lib/button';
import { sketchFabUrl, vimeoUrl, wistiaUrl, youtubeUrl } from '../../../../utils/regExps';
import { createPropTypes } from '../../../types/create';
import { assetType } from '../../../constants';
import Progress from '@digital-hig/progress';
import { useIsMobile } from '../../../../utils';

const typesIds = {
  upload: 'dhig--icons--actions--upload',
  embed_media: 'dhig--icons--actions--link',
  my_assets: 'dhig--icons--miscellaneous--folder-open'
};

const acceptedEmbedTypes = ['youTube', 'vimeo', 'wistia', 'sketchfab'];

function validateEmbedUrl(url) {
  if (url) {
    const isYoutube = url.match(youtubeUrl);
    const isSketchFab = url.match(sketchFabUrl);
    const isWistia = url.match(wistiaUrl);
    const isVimeo = url.match(vimeoUrl);

    if (isYoutube || isSketchFab || isWistia || isVimeo) return true;
  }
  return false;
}

function Assets({
  fileUploadError,
  onFileUpload,
  userAssets,
  assetsLoaded,
  assetsLoading,
  onAddEmbed,
  onAddAssetFromLib,
  onDeleteAssetFromLib,
  onRemoveAssetFromBlocks,
  blocks,
  onPageChange,
  paginationData,
  fetchMore,
  onSetLayoutTab
}) {
  const isMobile = useIsMobile();
  const [currentTab, setCurrentTab] = useState(0);
  const [embed, setEmbed] = useState();
  const [embedError, setEmbedError] = useState(false);

  const TABS = Object.keys(typesIds).map((el, i) => ({
    'aria-controls': `tabpanel-${i}-default`,
    id: `${i}`,
    label: (
      <div className="section-tab">
        {!isMobile && <Icon size="medium" id={typesIds[el]} />}
        {t(`create_project_build_${el}`)}
      </div>
    )
  }));

  function fileUpload() {
    return (
      <FileUpload
        variant="large"
        showBorder
        isError={fileUploadError.isError}
        error={fileUploadError.error}
        type="large"
        onChange={onFileUpload}
        onSetLayoutTab={onSetLayoutTab}
        accept="image/jpeg, image/jpg, image/png, image/gif, .f3d, .ipt, .f3z, .3dm, .3ds, .asm, .catpart, .catproduct, .cgr, .collaboration, .dae, .ddx, .ddz, .dgk, .dwf, .dwfx, image/vnd.dwg, .dwg, .dwt, .fbrd, .fbx, .fsch, .gbxml, .glb, .gltf, .idw, .ifc, .iges, model/iges, .igs, .ipt, .iwm, .jt, .max, .neu, .nwc, .nwd, .obj, application/x-tgif, .osb, .par, .pmlprjz, .prt, .psm, .psmodel, .sab, .sat, .sch, .skp, .sldasm, .sldprt, .smb, .smt, .step, .stl, model/stl, .stp, .vpb, .vue, .wire, .x_b, .x_t"
        maxSizeKb={100000}
        showError
        multiple
      />
    );
  }

  function handleAddEmbed() {
    const isValid = validateEmbedUrl(embed);
    if (isValid) {
      onSetLayoutTab();
      onAddEmbed(embed);
      setEmbed();
      setEmbedError(false);
    } else {
      setEmbedError(true);
    }
  }

  return (
    <SectionWrapper isAssets>
      <div>
        <Typography component="h4" variant="headline-small">
          {t('create_build_assets_section_title')}
        </Typography>
        <Typography component="p" className="section-subtitle" variant="smallprint">
          {t('create_build_assets_section_subtitle')}
        </Typography>
      </div>
      <TabsWrapper>
        <Tabs
          value={currentTab}
          ariaLabel="DHIG tabs"
          tabProperties={TABS}
          onChange={(e, v) => setCurrentTab(v)}
        />
        <TabSection>
          {!!(currentTab === 0) && fileUpload()}
          {!!(currentTab === 1) && (
            <div className="embed-media-section">
              <Typography
                component="label"
                variant="smallprint"
                htmlFor="create-embed-text-input"
                style={{ marginBottom: '4px' }}
              >
                {t('create_build_assets_embed_label', {
                  accepted: acceptedEmbedTypes
                    .map((el) => el.charAt(0).toUpperCase() + el.slice(1))
                    .join(', ')
                })}
              </Typography>
              <div>
                <div className="embed-media-section-input-wrapper">
                  <TextInput
                    id="create-embed-text-input"
                    onChange={(e) => setEmbed(e.target.value)}
                    value={embed}
                    error={embedError}
                  />
                  <Button onClick={handleAddEmbed} variant="outlined" size="dense">
                    {t('create_build_embed_submit_label')}
                  </Button>
                </div>
                {embedError && (
                  <p className="embed-media-input-error">{t('create_build_invalid_embed_error')}</p>
                )}
              </div>
            </div>
          )}
          {Boolean(currentTab === 2) &&
            (userAssets.length < 1 && !assetsLoading ? (
              <div className="empty-library-section">
                <Typography component="h6" variant="headline-smallest">
                  {t('create_build_assets_section_my_assets_empty_title')}
                </Typography>
                <Typography component="p" variant="smallprint">
                  {t('create_build_assets_section_my_assets_empty_subtitle')}
                </Typography>
                {fileUpload()}
              </div>
            ) : (
              <FileLibrary
                fetchMore={fetchMore}
                files={userAssets}
                selected={blocks.map((block) => block.id)}
                onAddToSelection={onAddAssetFromLib}
                onRemoveFromSelection={onRemoveAssetFromBlocks}
                onDelete={onDeleteAssetFromLib}
                onPageChange={onPageChange}
                paginationData={paginationData}
                assetsLoaded={assetsLoaded}
                onSetLayoutTab={onSetLayoutTab}
              />
            ))}
          {/* TODO: Add better loading */}
          {Boolean(currentTab === 2 && assetsLoading) && <Progress size="medium" />}
        </TabSection>
      </TabsWrapper>
    </SectionWrapper>
  );
}

Assets.propTypes = {
  blocks: createPropTypes.blocks,
  fileUploadError: PropTypes.shape({
    error: PropTypes.string,
    isError: PropTypes.bool
  }),
  onFileUpload: PropTypes.func,
  onAddAssetFromLib: PropTypes.func,
  onDeleteAssetFromLib: PropTypes.func,
  onRemoveAssetFromBlocks: PropTypes.func,
  onAddEmbed: PropTypes.func,
  onSetLayoutTab: PropTypes.func,
  userAssets: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
      imageAlt: PropTypes.string,
      type: PropTypes.oneOf(assetType).isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  assetsLoading: PropTypes.bool,
  assetsLoaded: PropTypes.bool,
  onPageChange: PropTypes.func,
  paginationData: PropTypes.shape({
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
    totalPageCount: PropTypes.number,
    hasMorePages: PropTypes.bool
  }),
  fetchMore: PropTypes.func
};

Assets.defaultProps = {
  blocks: [],
  fileUploadError: {
    error: undefined,
    isError: false
  },
  onFileUpload: () => {},
  onAddEmbed: () => {},
  onAddAssetFromLib: () => {},
  onDeleteAssetFromLib: () => {},
  onRemoveAssetFromBlocks: () => {},
  onPageChange: () => {},
  onSetLayoutTab: () => {},
  userAssets: [],
  assetsLoaded: false,
  assetsLoading: true
};

export default Assets;
