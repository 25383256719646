import { SET_AUTH_STATUS, SET_AUTH_USER_INFO, AUTH_LOG_OUT } from '../actions/actionTypes';

export const initialState = {
  loading: false,
  isAuthenticated: false,
  hasLoggedIn: false
};

export default function authReducer(prevState = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case 'persist/REHYDRATE':
      return {
        ...prevState,
        ...payload?.auth
      };
    case SET_AUTH_STATUS:
      return {
        ...prevState,
        ...payload
      };
    case AUTH_LOG_OUT:
      return initialState;
    case SET_AUTH_USER_INFO:
      return {
        ...prevState,
        ...payload
      };

    default:
      return prevState;
  }
}
