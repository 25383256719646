import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@digital-hig/modal';
import Button from '@mui/material/Button';
import SvgIcon from '@digital-hig/svg-icon';
import SvgAlert from '@digital-hig/icon/lib/build/display-icons/alert';
import Typography from '@digital-hig/typography';
import { useMutation } from '@apollo/client';
import { t } from 'i18next';
import { ADMIN_UPDATE_PROJECT_STATUS, ADMIN_UPDATE_USER_STATUS } from '../../apollo/mutations';

const RejectActionsModal = ({
  projectId,
  showModal,
  handleCloseModal,
  userId,
  handleRefetchData
}) => {
  const [updateProject] = useMutation(ADMIN_UPDATE_PROJECT_STATUS, {
    context: { clientName: 'cms' }
  });

  const [updateUserStatus] = useMutation(ADMIN_UPDATE_USER_STATUS, {
    context: { clientName: 'cms' }
  });

  const handleRejectProject = () => {
    updateProject({
      variables: {
        ids: [parseInt(projectId)],
        action: 'reject'
      },
      onCompleted: () => {
        handleCloseModal();
        handleRefetchData();
      }
    });
  };

  const handleRejectAndBanUser = () => {
    handleRejectProject();
    updateUserStatus({
      variables: {
        user_ids: userId,
        status: 'banned'
      },
      onCompleted: () => {
        handleCloseModal();
        handleRefetchData();
      }
    });
  };

  const RejectActions = () => (
    <>
      <Button size="dense" layout="stretch" variant="outlined" onClick={handleRejectAndBanUser}>
        {t('create_detail_reject_ban_modal_button')}
      </Button>
      <Button size="dense" layout="stretch" variant="contained" onClick={handleRejectProject}>
        {t('create_detail_reject_modal_button')}
      </Button>
    </>
  );

  return (
    <Modal
      backdropVariant="default"
      closeButtonDisplay
      maxWidth="sm"
      title={`Reject Content`}
      type="modal"
      open={showModal}
      handleClose={handleCloseModal}
      actions={
        <>
          <Button size="dense" layout="stretch" variant="text" onClick={handleCloseModal}>
            Cancel
          </Button>
          <RejectActions />
        </>
      }
      content={
        <div style={{ display: 'flex' }}>
          <SvgIcon size="medium">
            <SvgAlert />
          </SvgIcon>
          <Typography colors="" style={{ marginLeft: '1rem' }} variant="body-copy-medium">
            {t('create_detail_reject_modal_description')}
          </Typography>
        </div>
      }
    />
  );
};

export default RejectActionsModal;

RejectActionsModal.propTypes = {
  modalType: PropTypes.string,
  projectId: PropTypes.array,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  userId: PropTypes.array,
  handleRefetchData: PropTypes.func
};
