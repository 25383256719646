import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  createFormCurrentStepChange,
  publishCreateActions,
  createChangeModalTypeAction,
  toggleEditModeAction
} from '../../store/actions/createActions';
import Modal from '@digital-hig/modal';
import { t } from 'i18next';
import Create from '../../common/Create';
import Actions from '../../common/Create/Actions';
import { createStatePropTypes } from '../../common/types/create';
import { useMutation } from '@apollo/client';
import { CREATE_PROJECT } from '../../apollo/mutations';
import { useOpenCreateModal } from '../../utils/hooks/useOpenCreateModal';
import { useNavigate } from 'react-router-dom';
import { StyledAvatar, TitleWrapper } from './CreateModal.styled';
import { useSelector } from 'react-redux';
import {
  trackCreateStep0,
  trackCreateStep1,
  trackCreateStep2,
  trackCreateStep3
} from '../../utils/trackingScripts';
import { emptyCreateBlocksStateAction } from '../../store/actions/createActions';
import { useDispatch } from 'react-redux';

function CreateModal({
  isEdit,
  status,
  showModal,
  currentCreateDetailsStep,
  createDetailsSteps,
  setCreateDetailsFormCurrentStep,
  publishCreateAction,
  stateToPublish,
  publishStatusData,
  slug,
  changeModalType,
  toggleEditMode,
  formComponentType,
  campaign
}) {
  const avatar = useSelector((state) => state.auth?.myProfile?.avatar_small);
  const [publishCreate] = useMutation(CREATE_PROJECT, { context: { clientName: 'authorized' } });
  const navigate = useNavigate();
  const { handleOpenCreateModal } = useOpenCreateModal();
  const dispatch = useDispatch();

  function handleTriggerTrack(step) {
    switch (step) {
      case 0:
        trackCreateStep0();
        break;

      case 1:
        trackCreateStep1();
        break;

      case 2:
        trackCreateStep2();
        break;

      case 3:
        trackCreateStep3();
        break;

      default:
        return;
    }
  }

  const handleClearBlocks = () => {
    if (formComponentType === 'project_build') {
      dispatch(emptyCreateBlocksStateAction());
    } else {
      handleOpenCreateModal();
    }
  };

  useEffect(() => {
    if (!isEdit) {
      handleTriggerTrack(currentCreateDetailsStep);
    }
  }, [currentCreateDetailsStep]);

  const publishState = campaign ? { ...stateToPublish, campaign } : stateToPublish;

  return (
    <Modal
      aria-describedby={t('create_modal_aria_description')}
      aria-labelledby={t('create_modal_aria_label')}
      backdropVariant="default"
      closeButtonDisplay
      maxWidth="xl"
      styleVariant="default"
      title={
        <TitleWrapper>
          <StyledAvatar profilePicture={avatar} size="medium" />
          {t(`create_modal_title_${isEdit ? `edit_${status}` : 'new'}`)}
        </TitleWrapper>
      }
      type="modal"
      open={showModal}
      handleClose={handleOpenCreateModal}
      content={<Create inModal campaign={campaign} />}
      actions={
        <Actions
          formComponentType={formComponentType}
          handleClearBlocks={handleClearBlocks}
          handleCancelClick={handleOpenCreateModal}
          showCancelButton={currentCreateDetailsStep < createDetailsSteps.length}
          showPrevButton={
            (currentCreateDetailsStep >= 1 &&
              currentCreateDetailsStep < createDetailsSteps.length) ||
            (currentCreateDetailsStep >= 1 && publishStatusData?.error?.isError)
          }
          setCreateDetailsFormCurrentStep={setCreateDetailsFormCurrentStep}
          nextButtonDisabled={!createDetailsSteps?.[currentCreateDetailsStep]?.validated}
          slug={slug}
          isEdit={isEdit}
          currentStep={currentCreateDetailsStep}
          stepsCount={createDetailsSteps.length - 1}
          publishCreate={() => publishCreateAction(publishState, publishCreate, navigate)}
          changeModalType={changeModalType}
          toggleEditMode={toggleEditMode}
        />
      }
    />
  );
}

CreateModal.propTypes = {
  isEdit: PropTypes.bool,
  status: PropTypes.oneOf(['draft', 'published']),
  showModal: PropTypes.bool.isRequired,
  currentCreateDetailsStep: PropTypes.number.isRequired,
  createDetailsSteps: createStatePropTypes.createDetailsSteps.isRequired,
  setCreateDetailsFormCurrentStep: PropTypes.func,
  formComponentType: PropTypes.oneOf(['project_details', 'project_build']).isRequired,
  publishCreateAction: PropTypes.func,
  publishStatusData: createStatePropTypes.publishStatusData,
  slug: PropTypes.string,
  campaign: PropTypes.string,
  changeModalType: PropTypes.func,
  toggleEditMode: PropTypes.func,
  stateToPublish: PropTypes.shape({
    title: createStatePropTypes.title,
    description: createStatePropTypes.description,
    industries: createStatePropTypes.industries,
    products: createStatePropTypes.products,
    tags: createStatePropTypes.tags,
    cover: createStatePropTypes.cover
  })
};

CreateModal.defaultProps = {
  isEdit: false,
  status: 'draft',
  slug: undefined,
  setCreateDetailsFormCurrentStep: () => {},
  publishCreate: () => {},
  publishStatusData: {},
  stateToPublish: {}
};

const mapStateToProps = ({ create }) => ({
  isEdit: create.isEdit,
  status: create.status,
  showModal: create.showModal,
  slug: create.slug,
  currentCreateDetailsStep: create.currentCreateDetailsStep,
  createDetailsSteps: create.createDetailsSteps,
  unsavedActions: create.unsavedActions,
  formComponentType: create.formComponentType,
  publishStatusData: create.publishStatusData,
  stateToPublish: {
    title: create.title,
    description: create.description,
    industries: create.industries,
    products: create.products,
    tags: create.tags,
    cover: create.cover
  }
});

const mapDispatchToProps = (dispatch) => ({
  setCreateDetailsFormCurrentStep: (payload) => dispatch(createFormCurrentStepChange(payload)),
  publishCreateAction: (payload, callback, navigate) =>
    dispatch(publishCreateActions(payload, callback, navigate)),
  changeModalType: (payload) => dispatch(createChangeModalTypeAction(payload)),
  toggleEditMode: (payload) => dispatch(toggleEditModeAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal);
