export const youtubeUrl =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

export const sketchFabUrl =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:sketchfab\.com\/(?:3d-models\/))((\w|-){11})(?:\S+)?$/;

export const vimeoUrl =
  /^(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/g;

export const wistiaUrl =
  /^(?:https?:\/\/)?(www\.)?(?:support\.|fast\.|home\.)?(?:wistia\.com\/(?:medias\/|embed\/))((\w|-){10})(?:\S+)?$/g;
