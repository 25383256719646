import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { document } from 'global';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant'
    });
  }, [pathname]);

  return null;
}
