import React, { useMemo } from 'react';

import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider, from } from '@apollo/client';
import { ENVIRONMENT_CONSTANTS } from '../common/constants';
import {
  // isErrorException,
  isSuccessException
} from '../providers/ClientNotificationProvider/clientNotificationExceptions';
import { useDispatch } from 'react-redux';
import { addClientNotificationAction } from '../store/actions/clientNotificationsActions';
import ClientNotificationProvider from '../providers/ClientNotificationProvider';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';
import { createUploadLink } from 'apollo-upload-client';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import window from 'global/window';
import { getEnv } from '../../app/utils/getEnv';
import { onError } from 'apollo-link-error';

import { getAccessTokenSilently } from '../utils/authHelper';

function ApolloProviderWithErrorHandler({ children }) {
  const dispatch = useDispatch();

  const env = getEnv();

  const httpLink = new createUploadLink({
    fetch,
    uri: ENVIRONMENT_CONSTANTS[env].API_GRAPHQL_URL
  });

  const httpLinkCMS = new createUploadLink({
    fetch,
    uri: ENVIRONMENT_CONSTANTS[env].API_GRAPHQL_URL + '/cms'
  });

  const authLink = setContext(async (_, { headers }) => {
    try {
      const token = await getAccessTokenSilently();
      if (token) {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`
          }
        };
      }
    } catch (e) {
      return {
        headers: {
          ...headers
        }
      };
    }
  });

  const apolloSplit = new RetryLink().split(
    (operation) => operation.getContext().clientName == 'public',
    httpLink,
    new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 2,
        retryIf: (error) => handleRetry(error)
      }
    })
      .split(
        (operation) => operation.getContext().clientName == 'authorized',
        from([authLink, httpLink])
      )
      .split(
        (operation) => operation.getContext().clientName === 'cms',
        from([authLink, httpLinkCMS])
      )
  );

  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      for (const { message, locations, path } of graphQLErrors) {
        dispatch(
          addClientNotificationAction({ text: "Operation could't be completed", type: 'error' })
        );
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      }
    }

    if (networkError) {
      dispatch(addClientNotificationAction({ text: 'Network connection error', type: 'error' }));
    }
  });

  const handleRetry = async (error) => {
    let requiresRetry = false;

    if (error.statusCode === 401) {
      requiresRetry = true;
    }

    return requiresRetry;
  };

  const successLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      if (response?.data && !response.errors) {
        isSuccessException(operation, (v) => dispatch(addClientNotificationAction(v)));
      }
      return response;
    });
  });

  const client = useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
      link: ApolloLink.from([errorLink, successLink, apolloSplit])
    });
  }, []);

  return (
    <ApolloProvider client={client}>
      <ClientNotificationProvider>{children}</ClientNotificationProvider>
    </ApolloProvider>
  );
}

export default ApolloProviderWithErrorHandler;

ApolloProviderWithErrorHandler.propTypes = {
  children: PropTypes.node.isRequired
};
