import React from 'react';
import Grid from '@digital-hig/grid';
import Divider from '@digital-hig/divider';
import Typography from '@digital-hig/typography';
import { t } from 'i18next';
import { Image } from './Unauthorized.styled';
import PageContainer from './../PageContainer';

import { ROUTER_BASENAME } from '../../common/constants';

const Unauthorized = () => {
  return (
    <PageContainer>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography component="h5" variant="headline-small" sx={{ mb: 4 }} className="wd-mv-">
            Unauthorized
          </Typography>
          <Divider />
          <Typography
            component="h1"
            variant="headline-large"
            sx={{ mb: 2, mt: 8 }}
            className="wd-mv-"
          >
            Access denied due to invalid credentials.
          </Typography>
          <Typography component="p" variant="body-copy-medium" sx={{ mb: 8 }} className="wd-mv-">
            {t('error_404_description')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className="dhig-text-right">
          <Image src={ROUTER_BASENAME + '/static/images/404.jpg'} alt="" />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Unauthorized;
