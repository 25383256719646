import React, { useEffect, useRef, useState } from 'react';
import { StyledNavBtn, NavWrapper, SliderWrapper, SliderContainer } from './Slider.styled';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Icon from '@digital-hig/icon';
import { useIsMobile } from '../../utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation]);

function Slider({ className, children, showNav, slidesPerView = 4, spaceBetween = 8, loop }) {
  const [showPrevState, setShowPrevState] = useState(false);
  const [showNextState, setShowNextState] = useState(false);

  useEffect(() => {
    if (children.length > slidesPerView) {
      setShowNextState(true);
    }
  }, []);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const isEnd = (swiper) => {
    setShowPrevState(!swiper.isBeginning);
    setShowNextState(!swiper.isEnd);
  };

  const isMobile = useIsMobile();

  return (
    <SliderWrapper className={className}>
      {showNav && (
        <NavWrapper>
          <StyledNavBtn hide={!showPrevState} ref={prevRef}>
            {t('slider-next-button-label')}
            <Icon id="dhig--icons--utility--caret-left" />
          </StyledNavBtn>
          <StyledNavBtn
            isNextAlone={!showPrevState && showNextState}
            isNext
            hide={!showNextState}
            ref={nextRef}
          >
            {'slider-prev-button-label'}
            <Icon id="dhig--icons--utility--caret-right" />
          </StyledNavBtn>
        </NavWrapper>
      )}
      <SliderContainer>
        <Swiper
          slidesPerView={isMobile ? 3 : slidesPerView}
          spaceBetween={spaceBetween}
          speed={600}
          grabCursor
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
            isEnd(swiper);
          }}
          onSlideChangeTransitionStart={(swiper) => isEnd(swiper)}
          onSlideChangeStart={(swiper) => isEnd(swiper)}
          onResize={(swiper) => isEnd(swiper)}
          onSlideChange={(swiper) => isEnd(swiper)}
          loop={loop}
        >
          {children.map((child) => {
            return (
              <SwiperSlide key={`slide-${child.key}`} style={{ display: 'flex' }}>
                {child}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SliderContainer>
    </SliderWrapper>
  );
}

Slider.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
  showNav: PropTypes.bool,
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  loop: PropTypes.bool
};

export default Slider;
