import { useEffect, useState } from 'react';

export function useIsMobile() {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
    );

    setMobile(mobile && window.screen.width <= 900);
  }, []);

  return isMobile;
}
