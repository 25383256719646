// SUCCESS EXCEPTIONS
export function isSuccessException(operation, callback) {
  const isMutation = operation.query.loc.source.body.includes('mutation');
  const isNotNotified = [
    'LikeProject',
    'BookmarkProject',
    'CreateAsset',
    'DeleteAssets',
    'FinaliseAsset',
    'CreateProject',
    'UpdateProject',
    'DeleteProject',
    'updateUser18Plus'
  ].includes(operation.operationName);

  if (isMutation && !isNotNotified) {
    return callback({
      text: `client_notification_${operation.operationName}_success`,
      type: 'success',
      autoDismiss: true,
      id: `notification-${new Date().getTime()}`
    });
  }
  return false;
}

// ERROR EXCEPTIONS
export function isErrorException(error, callback) {
  const isGraphQLError = error.graphQLErrors !== undefined;

  if (isGraphQLError) {
    const errors = error.graphQLErrors.map(({ message }) => message).join(',');
    const extras = error.graphQLErrors.map(({ exception }) => exception?.extra)?.[0];

    callback({
      text: `client_notification_${errors}_error`,
      type: 'error',
      autoDismiss: true,
      extras
    });
  }

  if (error.networkError) {
    callback({
      text: `client_notification_${error.networkError}_error`,
      type: 'error',
      autoDismiss: true
    });
  }
}
