import styled from 'styled-components';
import Alert from '@mui/material/Alert';

export const SectionWrapper = styled.div`
  display: flex;
`;

export const StyledAlert = styled(Alert)`
  color: #fff !important;
`;
