import { ADD_CLIENT_NOTIFICATION, DISMISS_CLIENT_NOTIFICATION } from '../actions/actionTypes';

import { generateUniqueId } from '../../utils';

export const initialState = [];

export default function notificationReducer(prevState = initialState, action) {
  const newState = [...prevState].filter((el) => el.id !== action.payload);
  switch (action.type) {
    case ADD_CLIENT_NOTIFICATION:
      return [
        ...prevState,
        {
          ...action.payload,
          id: `client_notification_${generateUniqueId()}`
        }
      ];
    case DISMISS_CLIENT_NOTIFICATION:
      return newState;
    default:
      return prevState;
  }
}
