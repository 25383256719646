import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import darkTheme from '@digital-hig/theme-data/lib/build/json/dark/design-tokens.json';
import { Link } from 'react-router-dom';

const wrapperStyle = css`
  background: ${lightTheme.tokens.colors['detail-1-40'].rgb};
  border-radius: 16px;
  color: #000;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 16px;
  white-space: nowrap;
  transition: all 250ms cubic-bezier(0.2, 0, 0.8, 1) 0s;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  vertical-align: middle;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0px;
  width: fit-content;
  padding: 0.5rem 0.75rem;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          // padding: ${lightTheme.primitives.spacings['1']}
          //   ${lightTheme.primitives.spacings['2']};

          span {
            font-size: ${lightTheme.primitives.fontSizes['1']} !important;
            padding: 10px 8px;
          }
        `;
      case 'medium':
        return css`
          padding: ${lightTheme.primitives.spacings['2']} ${lightTheme.primitives.spacings['3']};
          box-shadow: ${darkTheme.tokens.effects['divider-0-bottom-on-fill-primary'].boxShadow};
        `;
      case 'large':
        return css`
          padding: ${lightTheme.primitives.spacings['3']} ${lightTheme.primitives.spacings['4']};
          box-shadow: ${darkTheme.tokens.effects['divider-0-bottom-on-fill-primary'].boxShadow};
          span {
            font-size: ${lightTheme.primitives.fontSizes['3']} !important;
          }
        `;
    }
  }};
`;

export const TagWrapper = styled.div`
  ${wrapperStyle}
`;

export const TagWrapperWithLink = styled(Link)`
  ${wrapperStyle}
  text-decoration: none;
  color: #000000;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    background-color: ${lightTheme.tokens.colors['detail-1-20'].rgb};
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
`;

export const DeleteButton = styled.button`
  font-size: 0;
  padding: 0;
  margin-top: 0;
  border: none;
  border-radius: 50px;
  height: 14px;
  width: 14px;
  margin-right: ${lightTheme.primitives.spacings['1']};
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 2px;
    justify-self: center;
    transition: all 0.2s ease-in-out;
    background: black;
    align-self: center;
  }
  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }
`;
