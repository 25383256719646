import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StepWrapper } from './DetailsSteps.styled';
import ThumbnailCrop from '../../../../components/ThumbnailCrop';
import Typography from '@digital-hig/typography';
import { t } from 'i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function Cover({ isEdit, onChange, data }) {
  const [coverType, setCoverType] = useState('thumbnail');

  return (
    <StepWrapper>
      {!isEdit && (
        <Typography component="h5" variant="headline-medium">
          {t('create_cover_form_title')}
        </Typography>
      )}
      {isEdit && (
        <ToggleButtonGroup
          exclusive
          onChange={(e) => setCoverType(e.target.value)}
          size="medium"
          value={coverType}
          className="cover-segment-control"
        >
          <ToggleButton value={'thumbnail'} key={'thumbnail'}>
            {t('create_cover_thumbnail_title')}
          </ToggleButton>
          <ToggleButton value={'banner'} key={'banner'}>
            {t('create_cover_banner_title')}
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      <ThumbnailCrop
        onCropChange={(e) => onChange({ key: 'cover', value: e })}
        thumbnailProps={{
          title: data.title,
          avatarProps: data.creatorData
        }}
        image={data.cover}
        coverCropData={data.coverCropData}
        variant={coverType}
      />
    </StepWrapper>
  );
}

Cover.propTypes = {
  onChange: PropTypes.func,
  isEdit: PropTypes.bool,
  data: PropTypes.shape({
    cover: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
      // PropTypes.instanceOf(File),
      // PropTypes.arrayOf(PropTypes.instanceOf(File))
    ]),
    title: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    creatorData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      profilePicture: PropTypes.string.isRequired
    }),
    coverCropData: PropTypes.shape({})
  })
};

Cover.defaultProps = {
  onChange: () => {},
  isEdit: false,
  data: {
    cover: undefined,
    images: []
  },
  error: {},
  coverCropData: {}
};

export default Cover;
