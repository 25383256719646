import { useContext } from 'react';

import { loginWithRedirect, logoutWithRedirect } from './authHelper';
import { UserContext } from '../context/UserContext';
import { getEnv } from './getEnv';
import { getEnvironmentConstant } from '../utils/getEnvironmentConstant';
import { PKCE_ENV } from '../common/constants';
import window from 'global/window';

export function initAutodeskHeader() {
  const env = getEnv();
  const { state } = useContext(UserContext);

  var meMenuConfig = {
    onSignIn: function () {
      loginWithRedirect(location);
    },
    onSignOut: function () {
      logoutWithRedirect();
    }
  };

  var components = {
    meMenu: meMenuConfig
  };

  // var uhEventListener = function (response) {
  //   console.log(response.event);
  // };

  const Uhconfig = {
    property: 'forums',
    propertyPageWidth: '1248px',
    language: 'en-US',
    environment: getEnvironmentConstant({ env, key: PKCE_ENV }),
    accessToken: state.token ? state.token : null,
    components: components
    // eventListener: uhEventListener
  };
  if (window.adsk && window.adsk.components && window.adsk.components.universalHeader) {
    window.adsk.components.universalHeader.render('lo-uh-container', Uhconfig);
  }
}
