import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import Select from '@digital-hig/select';
import Button from '@digital-hig/button';
import ContentWrapper from '../ContentWrapper';

export const FilterBarWrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: ${lightTheme.primitives.spacings['4']};
  padding: 0 2rem;

  .active-filter {
    box-shadow: inset 0 0 0 1px ${lightTheme.primitives.colors.accent.plant};
    border-color: ${lightTheme.primitives.colors.accent.plant};
  }

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['4']}) {
    padding: 0 3rem;
  }
  @media screen and (min-width: ${lightTheme.primitives.grid.scale['7']}) {
    padding: 0 1rem;
  }

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    padding: 0;

    .filterbar--button {
      display: none;
    }
  }
`;

export const FilterSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${lightTheme.primitives.spacings['4']};

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    width: 100%;
    justify-content: center;
  }

  ${({ pageType }) =>
    pageType === 'search' &&
    css`
      width: 100%;
      justify-content: space-between;

      @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
        justify-content: space-between;
      }
    `};
`;

export const SubSection = styled.div`
  border-radius: 8px;
  background-color: ${lightTheme.primitives.colors.tint['slate-100']};
  margin-top: ${lightTheme.primitives.spacings['3']};
  padding: ${lightTheme.primitives.spacings['6']} ${lightTheme.primitives.spacings['9']};

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    padding: ${lightTheme.primitives.spacings['6']} ${lightTheme.primitives.spacings['4']};
  }
`;

export const StyledSelect = styled(Select)`
  background-color: white;
`;

export const SingleButton = styled(Button)`
  font-family: ${lightTheme.primitives.fontFamilies.default} !important;
  font-weight: 600 !important;
  font-size: ${lightTheme.primitives.fontSizes['2']} !important;
  padding: ${lightTheme.primitives.spacings['2']} ${lightTheme.primitives.spacings['4']} !important;
`;

export const ActionButton = styled(Button)`
  font-family: ${lightTheme.primitives.fontFamilies.default} !important;
  font-weight: 600 !important;
  font-size: ${lightTheme.primitives.fontSizes['2']} !important;
  padding: ${lightTheme.primitives.spacings['2']} ${lightTheme.primitives.spacings['4']} !important;
`;
