import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@digital-hig/typography';

import { BlockTextWrapper } from './Blocks.styled';

function BlockText(props) {
  const { text } = props;
  const str = /<p[^>]*>(&nbsp;|\s+|<br\s*\/?>)*<\/p>/g;
  const textWithoutLineBreaks = text?.replace(str, '');

  return (
    <BlockTextWrapper>
      <Typography component="div" variant="body-copy-medium">
        <p dangerouslySetInnerHTML={{ __html: `<p>${textWithoutLineBreaks}</p>` }}></p>
      </Typography>
    </BlockTextWrapper>
  );
}

BlockText.propTypes = {
  text: PropTypes.string
};

export default BlockText;
