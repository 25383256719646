function getIds(data, type) {
  return data?.[type]?.map((item) => parseInt(item.id, 10));
}

export const createBlockToViewModel = (block, isEdit) => {
  let newBlock = {
    order: block.order,
    id: block.id,
    type: block.type
  };

  switch (block.type) {
    case 'image':
      newBlock.imageSizes = block.image?.sizes;
      newBlock.imageUrl = block.image?.path;
      newBlock.imageAlt = block.image?.display_name;
      newBlock.height = block.image?.height;
      newBlock.width = block.image?.width;
      newBlock.title = block.image?.display_name;
      break;
    case 'raas_embed':
      newBlock.raasEmbed = block?.raas_embed;
      break;
    case 'embed':
      newBlock.embedUrl = block.embed?.link_url;
      newBlock.title = block.embed?.link_title;
      newBlock.imageUrl = block.embed?.link_image;
      newBlock.imageAlt = block.embed?.link_title;
      break;
    case '3D':
      newBlock.urn = block.resource.encoded_urn;
      newBlock.hasViewer = block.resource.has_viewer;
      newBlock.viewerSetting = block.resource.viewer_setting;
      newBlock.downloadable = isEdit
        ? block?.downloadable
        : block.resource?.has_viewer && block?.downloadable;
      newBlock.title = block.resource?.file_name;
      newBlock.imageUrl = block.image?.path;
      newBlock.imageAlt = block.resource?.file_name;
      newBlock.allowDownload = block.resource?.has_viewer;
      break;
  }
  return newBlock;
};

export const createDataToViewModel = (payload) => {
  const projectTagsIds = getIds(payload, 'tags');
  const projectIndustriesIds = getIds(payload, 'industries');
  const projectProductsIds = getIds(payload, 'products');

  return {
    id: payload.id,
    slug: payload.slug,
    title: payload.title,
    description: payload.description,
    // TODO: add block to view model function to remove useless data
    blocks: payload.assets.map((el) => createBlockToViewModel(el)),
    status: payload.status,
    cover: {
      File: payload.original?.path,
      banner: {
        x: payload.banner?.crop_x || 0.5,
        y: payload.banner?.crop_y || 0.5,
        z: payload.banner?.crop_z || 1
      },
      thumbnail: {
        x: payload.cover?.crop_x || 0.5,
        y: payload.cover?.crop_y || 0.5,
        z: payload.cover?.crop_z || 1
      }
    },
    tags: projectTagsIds,
    industries: projectIndustriesIds,
    products: projectProductsIds,
    creatorData: {
      firstName: payload.author.first_name,
      lastName: payload.author.last_name,
      userName: payload.author.display_name,
      title: payload.author.job_title,
      profilePicture: payload.author.avatar_big
    }
  };
};

export const createViewToDataModel = ({
  title,
  description,
  industries,
  products,
  tags,
  cover,
  campaign
}) => {
  return {
    input: {
      title,
      description,
      industries,
      products,
      tags,
      campaign,
      image: {
        image: cover.File,
        projectThumbnailCrop: {
          x: cover.thumbnail.x,
          y: cover.thumbnail.y,
          z: cover.thumbnail.z
        }
      }
    }
  };
};
