import { gql } from '@apollo/client';
import { ASSET_FRAGMENT } from './fragments';

export const PROJECT_LIKE_MUTATION = gql`
  mutation LikeProject($id: ID!, $action: String!) {
    LikeProject(id: $id, action: $action) {
      id
    }
  }
`;

export const PROJECT_BOOKMARK_MUTATION = gql`
  mutation BookmarkProject($id: ID!, $action: String!) {
    BookmarkProject(id: $id, action: $action) {
      id
    }
  }
`;

export const PROJECT_REPORT_MUTATION = gql`
  mutation reportProject($id: ID!, $type: String!) {
    reportProject(id: $id, type: $type) {
      id
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject($input: ProjectCreateInput) {
    CreateProject(createInput: $input) {
      id
      slug
      author {
        id
        slug
      }
      title
      description
      image {
        path
        width
        height
        sizes(formats: ["large", "cardXl"]) {
          path
          width
          height
          format
        }
      }
      industries {
        id
        name
      }
      products {
        id
        name
      }
      assets {
        id
        type
        order
        downloadable
        associated_at
        author {
          id
          slug
          avatar_big
          username
        }
      }
    }
  }
`;

export const ADMIN_UPDATE_PROJECT_STATUS = gql`
  mutation UpdateProjectsStatus($ids: [Int]!, $action: String) {
    updateProjectsStatus(ids: $ids, action: $action)
  }
`;

export const ADMIN_UPDATE_USER_STATUS = gql`
  mutation ($user_ids: [ID], $status: String) {
    updateUserStatus(user_ids: $user_ids, status: $status) {
      id
      status
    }
  }
`;

export const CREATE_UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $id: Int!
    $input: ProjectUpdateInput
    $assets: [ProjectAssetInput]
    $status: String
  ) {
    UpdateProject(id: $id, updateInput: $input, assets: $assets, status: $status) {
      id
      slug
      is_author
      can_edit
      author {
        id
        slug
      }
      title
      description
      campaign {
        id
        name
      }
      campaign_comment
      social_links {
        facebook
        twitter
        instagram
      }
      industries {
        id
        name
      }
      products {
        id
        name
      }
      tags {
        id
        name
      }
      assets {
        id
        type
        order
        downloadable
        associated_at
        author {
          slug
          id
          avatar_big
          username
        }
      }
      image(format: "pageHero") {
        path
        width
        height
        sizes(formats: ["mobile"]) {
          path
          width
          height
          format
        }
      }
      featured
      like_count
      status
      created_at
      updated_at
      deleted_at
      published_at
    }
  }
`;

export const CREATE_UPDATE_PROJECT_WITH_DETAILS = gql`
  mutation UpdateProjectWithDetails(
    $id: Int!
    $input: ProjectUpdateInput
    $assets: [ProjectAssetInput]
    $status: String
  ) {
    UpdateProject(id: $id, input: $input, assets: $assets, status: $status) {
      id
      slug
      status
      author {
        id
        slug
      }
      title
      description
      industries {
        id
        name
      }
      products {
        id
        name
      }
      tags {
        id
        name
      }
      assets {
        id
        type
        order
        downloadable
        associated_at
        author {
          slug
          id
          avatar_big
          username
        }
      }
      published_at
    }
  }
`;

export const CREATE_ASSET = gql`
  ${ASSET_FRAGMENT}
  mutation CreateAsset($input: AssetInput) {
    CreateAsset(input: $input) {
      ...AssetFragment
      resource {
        upload_url
      }
    }
  }
`;

export const FINALISE_ASSET = gql`
  ${ASSET_FRAGMENT}
  mutation FinaliseAsset($id: ID!) {
    FinaliseAsset(id: $id) {
      ...AssetFragment
      resource {
        encoded_urn
        file_name
        has_viewer
        size
        viewer_setting
      }
      author {
        id
        slug
        username
        email
        avatar_small
        avatar_big
      }
      projects {
        id
      }
      created_at
    }
  }
`;

export const DELETE_ASSETS = gql`
  mutation DeleteAssets($ids: [Int]!) {
    DeleteAssets(ids: $ids)
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: Int!) {
    DeleteProject(id: $id)
  }
`;

export const UPDATE_USER_18PLUS = gql`
  mutation updateUser18Plus($plus18: Boolean!) {
    updateUser18Plus(plus18: $plus18) {
      plus18
    }
  }
`;
