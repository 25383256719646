import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ChildrenWrapper,
  ImageCropWrapper,
  ZoomSliderWrapper,
  ZoomSliderWrapperInner
} from './ImageCrop.styled';
import AvatarEditor from 'react-avatar-editor';
import IconButton from '@digital-hig/icon-button';
import { t } from 'i18next';
import { cropDataPropTypes } from '../../common/types';

const MIN_ZOOM = 1;
const MAX_ZOOM = 2;

function ImageCrop({
  image,
  onChange = () => console.log(),
  className,
  showZoomSlider,
  children,
  isLoading,
  variant,
  border = 0,
  cropData = {
    width: 300,
    height: 470,
    x: 0.5,
    y: 0.5,
    z: MIN_ZOOM
  }
}) {
  const [crop, setCrop] = useState({ ...cropData });

  const [maxZoom, setMaxZoom] = useState(MAX_ZOOM);

  useEffect(() => {
    setCrop({ ...crop, ...cropData });
  }, [cropData.x, cropData.y, cropData.z, cropData.width, cropData.height]);

  function handlePositionChange(e) {
    const { x, y } = e;
    setCrop({ ...crop, x, y });
    onChange({ ...crop, x, y });
  }

  function handleSetZoom(direction) {
    let newZoom = crop.z;
    if (direction === 'in' && crop.z < maxZoom) {
      newZoom += 0.1;
    } else if (direction === 'out' && crop.z > MIN_ZOOM) {
      newZoom -= 0.1;
    }
    setCrop({ ...crop, z: newZoom });
    onChange({ ...crop, z: newZoom });
  }

  function handleImageChange() {
    const realImage = new Image();
    realImage.src = image;
    const { width, height } = realImage;

    const maxWidthZoom = width / crop.width;
    const maxHeightZoom = height / crop.height;
    const zoomValueWH = parseFloat((maxWidthZoom / maxHeightZoom).toFixed(1));
    const zoomValueHW = parseFloat((maxHeightZoom / maxWidthZoom).toFixed(1));

    const isPortrait = zoomValueHW > 1.5;
    const isSquare = zoomValueWH - zoomValueHW === 0;
    const isLandScape = zoomValueWH > 1.5;

    if (
      !isNaN(zoomValueWH) &&
      zoomValueWH !== maxZoom &&
      !isNaN(zoomValueHW) &&
      zoomValueWH === maxZoom
    ) {
      if (isPortrait) setMaxZoom(zoomValueHW);
      else if (isSquare) setMaxZoom(parseFloat(maxHeightZoom.toFixed(1)));
      else if (isLandScape) setMaxZoom(zoomValueWH);
    }
  }

  return (
    <ImageCropWrapper isLoading={isLoading} isBanner={variant === 'banner'}>
      {!!children && (
        <ChildrenWrapper
          width={crop.width}
          height={crop.height}
          // top={border[1] || border || 'unset'}
          isBanner={variant === 'banner'}
        >
          {children}
        </ChildrenWrapper>
      )}
      <AvatarEditor
        className={className}
        image={image}
        width={crop.width}
        position={{ x: crop.x, y: crop.y }}
        height={crop.height}
        scale={crop.z}
        border={border}
        borderRadius={8}
        style={
          isLoading
            ? {
                cursor: 'not-allowed'
              }
            : {}
        }
        color={[0, 0, 0, 0.05]}
        onPositionChange={!isLoading ? handlePositionChange : () => {}}
        onImageReady={
          !isLoading
            ? () => {
                handleImageChange();
                setCrop({ ...crop });
                onChange({ ...crop });
              }
            : () => {}
        }
      />
      {Boolean(showZoomSlider) && Boolean(maxZoom > MIN_ZOOM) && (
        <ZoomSliderWrapper>
          <ZoomSliderWrapperInner width={crop.width}>
            {/* <input
              type="range"
              min={MIN_ZOOM}
              max={maxZoom}
              step={0.1}
              value={crop.z}
              disabled={isLoading}
              onChange={(e) => {
                setCrop({ ...crop, z: parseFloat(e.target.value) });
                onChange({ ...crop, z: parseFloat(e.target.value) });
              }}
            /> */}
            <IconButton
              variant="text"
              onClick={() => handleSetZoom('in')}
              disabled={isLoading || crop.z >= maxZoom}
              iconId="dhig--icons--actions--zoom-in"
              aria-label={t('actions_zoom_in')}
              size="dense"
            />
            <IconButton
              variant="text"
              onClick={() => handleSetZoom('out')}
              disabled={isLoading || crop.z <= MIN_ZOOM}
              iconId="dhig--icons--actions--zoom-out"
              size="dense"
              aria-label={t('actions_zoom_out')}
            />
          </ZoomSliderWrapperInner>
        </ZoomSliderWrapper>
      )}
    </ImageCropWrapper>
  );
}

ImageCrop.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(['thumbnail', 'banner']),
  onChange: PropTypes.func,
  cropData: PropTypes.shape(cropDataPropTypes),
  className: PropTypes.string,
  border: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  showZoomSlider: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node
};

export default ImageCrop;
