import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  justify-content: center;
  width: 100%;
  span {
    display: inherit;
  }
  border-radius: ${lightTheme.primitives.borderRadii.large};
  // background-color: ${lightTheme.primitives.colors.tint['slate-100']};
  border: 2px dashed ${lightTheme.primitives.colors.tint['slate-200']};

  ${({ isError }) =>
    isError &&
    css`
      background-color: rgba(217, 85, 41, 0.1);
    `};

  ${({ isOver }) =>
    isOver &&
    css`
      background-color: ${lightTheme.primitives.colors.tint['slate-150']};
      border: 2px dashed ${lightTheme.primitives.colors.tint['slate-200']};
    `};

  ${({ variant }) => {
    switch (variant) {
      case 'narrow':
        return css`
          .file-upload-button {
            margin-top: ${lightTheme.primitives.spacings['1']};
            margin-bottom: ${lightTheme.primitives.spacings['2']};
          }
        `;
      case 'large':
        return css`
          min-height: 204px;
          background-color: ${lightTheme.primitives.colors.primary.white};
          border: 1px dashed ${lightTheme.primitives.colors.tint['slate-400']};
          min-height: 160px;
          .file-upload-button {
            margin-top: ${lightTheme.primitives.spacings['2']};
            margin-bottom: ${lightTheme.primitives.spacings['4']};
          }
          color: ${lightTheme.primitives.colors.primary.black};
          justify-content: center;
          border-radius: ${lightTheme.primitives.borderRadii.large};
          padding: 1rem 0;
          ${({ isError }) =>
            isError &&
            css`
              background-color: rgba(217, 85, 41, 0.1);
            `};
        `;
      case 'button':
        return css`
          width: fit-content;
        `;
    }
  }}
`;

export const FakeInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  flex-direction: column;
  label,
  p {
    pointer-events: none;
    color: ${({ isError }) => (isError ? 'red' : 'black')};

    max-width: 300px;
    text-align: center;
  }
`;

export const FilesWrapper = styled.div`
  padding: ${lightTheme.primitives.spacings['3']};
  display: flex;
  gap: ${lightTheme.primitives.spacings['3']};
  flex-wrap: wrap;
  justify-content: center;
  p {
    padding: ${lightTheme.primitives.spacings['3']};
    background: #f1f1f1;
    display: block;
    position: relative;
    width: fit-content;
    border-radius: 50px;
    border: 1px solid black;
    display: flex;
    button {
      font-size: 0;
      width: 24px;
      height: 24px;
      margin-right: ${lightTheme.primitives.spacings['2']};
      border-radius: 50%;
      border: 1px solid black;
      background: white;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
      &::after,
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 80%;
        height: 2px;
        background: black;
        align-self: center;
      }
      &::after {
        transform: rotate(45deg);
      }
      &::before {
        transform: rotate(-45deg);
      }
    }
  }
`;

export const RealInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: cell;
  position: absolute;
  pointer-events: all;
  z-index: 1;
  top: 0;
  left: 0;
  &:disabled {
    cursor: no-drop;
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${lightTheme.primitives.spacings['1']};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
`;
export const FormWrapper = styled.div`
  padding: 1rem 0px;
`;
