import React from 'react';
import PropTypes from 'prop-types';
import Button from '@digital-hig/button';
import Card from '@digital-hig/card';
import { CardActions, CardContent } from '@material-ui/core';
import Typography from '@digital-hig/typography';
import { ReactComponent as NewProjectImg } from '../../assets/images/newProject.svg';
import { ProfileCardWrapper } from './myProfile.styled';

const MyProfileCard = ({ handleOpenCreateModal }) => {
  return (
    <div className="dhig-grid dhig-grid-cols-1 md:dhig-grid-cols-2 lg:dhig-grid-cols-3 dhig-gap-4 dhig-py-2 dhig-auto-rows-fr">
      <ProfileCardWrapper>
        <Card className="cardComponent">
          <CardContent className="cardContent">
            <NewProjectImg className="cardImg" />
            <Typography className="cardTypography">
              Inspire with your story and let other designers, engineers, and inventors spark your
              creativity.
            </Typography>
          </CardContent>
          <CardActions className="cardButton">
            <Button className="filterbar--button" onClick={handleOpenCreateModal}>
              Create a Project
            </Button>
          </CardActions>
        </Card>
      </ProfileCardWrapper>
    </div>
  );
};

MyProfileCard.propTypes = {
  handleOpenCreateModal: PropTypes.func
};

export default MyProfileCard;
