import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  RealInput,
  UploadWrapper,
  FakeInputWrapper,
  FilesWrapper,
  TooltipWrapper,
  FormWrapper,
  InfoWrapper
} from './FileUpload.styled';
import Typography from '@digital-hig/typography';
import Button from '@digital-hig/button';
import Progress from '@digital-hig/progress';
import Icon from '@digital-hig/icon';
import { Trans, useTranslation } from 'react-i18next';
import { generateUniqueId } from '../../utils';
import Tooltip from '@mui/material/Tooltip';
import ErrorAlert from '../ErrorAlert/ErrorAlert';

function FileUpload({
  onChange,
  type,
  isLoading,
  files,
  multiple,
  showUploadedFiles,
  deletableFiles,
  accept,
  // isError,
  content,
  // error,
  variant,
  className,
  maxSizeKb,
  style,
  // showError,
  iconId,
  onSetLayoutTab
}) {
  const [openAlert, setOpenAlert] = useState(false);
  const [filesState, setFilesState] = useState([]);
  const [isOver, setIsOver] = useState(false);
  const [error, setError] = useState(false);

  const closeAlert = () => {
    setOpenAlert(false);
    setError(false);
    setFilesState([]);
  };

  function onInputChange(e) {
    const { filesValid, isErrorValidation, errorValidation } = filesValidation(
      e.target.files,
      accept,
      maxSizeKb
    );
    setIsOver(false);
    if (isErrorValidation) {
      setOpenAlert(true);
      setError(errorValidation);
    } else {
      onSetLayoutTab();
      setFilesState(filesValid);
      onChange(filesValid);
      setOpenAlert(false);
      setError(false);
    }

    // setFilesState(filesValid);
    // onChange(filesValid, { isError: isErrorValidation, error: errorValidation });
  }

  function handleDeleteFile(e) {
    const newFilesState = [...filesState].filter((el) => el.name !== e.name);
    setFilesState(newFilesState);
    onChange(newFilesState);
  }

  const { t } = useTranslation();

  function filesValidation(files, formats, maxSizeKb) {
    if (formats !== 'all') {
      const newFiles = [...files].filter(
        (f) => formats.includes(f.type) && f.size / 1000 < maxSizeKb
      );

      let error = undefined;
      if ([...files].some((f) => f.size / 1000 > maxSizeKb)) {
        // error = 'file_upload_error_invalid_file_size';
        error = (
          <Trans
            i18nKey="file_upload_error_invalid_file_size" // optional -> fallbacks to defaults if not provided
            values={{ extra: (maxSizeKb / 1000).toFixed() }}
            components={{ break: <br /> }}
          />
        );
      } else if ([...files].length > newFiles.length && newFiles.length > 0) {
        error = (
          <Trans
            i18nKey="file_upload_error_invalid_format_some" // optional -> fallbacks to defaults if not provided
            values={{
              allowedFiles:
                '.jpeg, .jpg, .png, .gif, .f3d, .ipt, .f3z, .3dm, .3ds, .asm, .catpart,' +
                ' .catproduct, .cgr, .collaboration, .dae, .ddx, .ddz, .dgk, .dwf,' +
                '.dwfx, .dwg, .dwt, .fbrd, .fbx, .fsch, .gbxml, .glb, .gltf, .idw, .ifc, .iges,' +
                '.igs, .ipt, .iwm, .jt, .max, .neu, .nwc, .nwd, .obj, .osb, .par, .pmlprjz, .prt,' +
                '.psm, .psmodel, .sab, .sat, .sch, .skp, .sldasm, .sldprt, .smb, .smt, .step,' +
                '.stl, .stp, .vpb, .vue, .wire, .x_b, .x_t'
            }}
            components={{ break: <br /> }}
          />
        );

        // error = 'file_upload_error_invalid_format_some';
      } else if ([...files].length > newFiles.length && newFiles.length === 0) {
        error = (
          <Trans
            i18nKey="file_upload_error_invalid_format_some" // optional -> fallbacks to defaults if not provided
            values={{
              allowedFiles:
                '.jpeg, .jpg, .png, .gif, .f3d, .ipt, .f3z, .3dm, .3ds, .asm, .catpart,' +
                '.catproduct, .cgr, .collaboration, .dae, .ddx, .ddz, .dgk, .dwf,' +
                '.dwfx, .dwg, .dwt, .fbrd, .fbx, .fsch, .gbxml, .glb, .gltf, .idw, .ifc, .iges,' +
                '.igs, .ipt, .iwm, .jt, .max, .neu, .nwc, .nwd, .obj, .osb, .par, .pmlprjz, .prt,' +
                '.psm, .psmodel, .sab, .sat, .sch, .skp, .sldasm, .sldprt, .smb, .smt, .step,' +
                '.stl, .stp, .vpb, .vue, .wire, .x_b, .x_t'
            }}
            components={{ break: <br /> }}
          />
        );
      }

      return {
        filesValid: newFiles,
        isErrorValidation: error !== undefined,
        errorValidation: error
      };
    } else {
      return {
        filesValid: [...files]
      };
    }
  }

  const id = generateUniqueId();
  const inputRef = useRef();

  // useEffect(() => {
  //   if (error !== undefined && error !== '' && isError && showError) {
  //     setShowAlert(true);
  //   } else {
  //     setShowAlert(false);
  //   }
  // }, [error, isError, showError]);

  useEffect(() => {
    if (files) {
      setFilesState(files);
    }
  }, [files]);

  // useEffect(() => {
  //   if (isOver) isError(false);
  // }, [isOver]);

  function buttonIcon() {
    if (iconId) {
      return <Icon id={iconId} />;
    }
    if (isLoading) {
      return <Progress size="tiny" />;
    }
    if (variant === 'narrow') {
      return null;
    }
    if (variant === 'large') {
      return <Icon id="dhig--icons--actions--upload" />;
    }
    if (variant === 'button') {
      return <Icon id="dhig--icons--utility--add" />;
    }
  }

  return (
    <>
      {openAlert && <ErrorAlert onClose={closeAlert} severity="error" error={error} />}
      <div style={{ width: '100%' }}>
        <UploadWrapper
          onDragOverCapture={() => (!isLoading ? setIsOver(true) : setIsOver(false))}
          onDragOver={() => (!isLoading ? setIsOver(true) : setIsOver(false))}
          onDragLeave={() => setIsOver(false)}
          isOver={isOver}
          isError={error}
          variant={variant}
          style={style}
          className={`${className}`}
        >
          <FormWrapper>
            <form onChange={onInputChange}>
              <RealInput
                ref={inputRef}
                type="file"
                id={id}
                multiple={multiple}
                disabled={isLoading}
                accept={accept}
              />
            </form>
            <FakeInputWrapper>
              {variant !== 'button' && (
                <Typography variant="smallprint" component="label" htmlFor={id}>
                  {t(
                    isOver
                      ? `file_upload_drag_over`
                      : `file_upload_label${typeof type === 'string' ? `_${type}` : ''}`
                  )}
                </Typography>
              )}
              {!isOver && (
                <>
                  <Button
                    variant="outlined"
                    aria-hidden="true"
                    disabled={isLoading}
                    className="file-upload-button"
                    onClick={() => {
                      inputRef.current.click();
                    }}
                    onDrop={(e) => {
                      inputRef.current.onDrop(e);
                    }}
                    size="small"
                    onDragOverCapture={() => setIsOver(true)}
                    onDragOver={() => setIsOver(true)}
                    onDragLeave={() => setIsOver(false)}
                    startIcon={buttonIcon()}
                  >
                    {content
                      ? content
                      : t(
                          !isLoading
                            ? `file_upload_button_label${
                                typeof type === 'string' ? `_${type}` : ''
                              }`
                            : 'file_upload_button_label_loading'
                        )}
                  </Button>
                </>
              )}
            </FakeInputWrapper>
          </FormWrapper>
          {variant !== 'button' && (
            <InfoWrapper>
              {accept !== 'all' && (
                <TooltipWrapper>
                  <Typography component="p" variant="smallprint">
                    {t('file_upload_formats')}
                  </Typography>
                  <Tooltip
                    TransitionProps={{
                      easing: {
                        enter: 'cubic-bezier(0, 0, 0.4, 1)',
                        exit: 'cubic-bezier(0.6, 0, 1, 1)'
                      },
                      timeout: {
                        enter: 125,
                        exit: 312
                      }
                    }}
                    arrow
                    placement="right"
                    title={accept
                      .split(',')
                      .map(
                        (f) =>
                          /(\/|\.)(.+)/.exec(f)?.[2].charAt(0) + /(\/|\.)(.+)/.exec(f)?.[2].slice(1)
                      )
                      .join(', ')}
                  >
                    <span>
                      <Icon
                        id="dhig--icons--utility--info-outline"
                        style={{ position: 'relative', zIndex: 2, pointerEvents: 'all' }}
                        size="small"
                      />
                    </span>
                  </Tooltip>
                </TooltipWrapper>
              )}
              {!!(maxSizeKb > 0) && (
                <Typography component="p" variant="smallprint">
                  {t('file_upload_max_size', { size: (maxSizeKb / 1000).toFixed() })}
                </Typography>
              )}
            </InfoWrapper>
          )}
        </UploadWrapper>

        {!!(showUploadedFiles && [...filesState].length >= 1) && (
          <FilesWrapper>
            {[...filesState].map((el) => {
              return (
                <Typography key={el.name} component="p">
                  {deletableFiles && (
                    <button disabled={isLoading} role="button" onClick={() => handleDeleteFile(el)}>
                      delete file
                    </button>
                  )}
                  {el.name}
                </Typography>
              );
            })}
          </FilesWrapper>
        )}
      </div>
    </>
  );
}

FileUpload.propTypes = {
  onChange: PropTypes.func,
  onSetLayoutTab: PropTypes.func,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  showUploadedFiles: PropTypes.bool,
  deletableFiles: PropTypes.bool,
  accept: PropTypes.object,
  variant: PropTypes.oneOf(['large', 'narrow', 'button']),
  className: PropTypes.string,
  maxSizeKb: PropTypes.number,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  style: PropTypes.object,
  content: PropTypes.string,
  iconId: PropTypes.string
};

FileUpload.defaultProps = {
  onChange: () => {},
  onSetLayoutTab: () => {},
  isLoading: false,
  type: 'files',
  multiple: true,
  showUploadedFiles: false,
  deletableFiles: false,
  accept: 'all',
  // isError: false,
  // error: undefined,
  variant: 'large',
  className: undefined,
  maxSizeKb: 10000
};

export default FileUpload;
