import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@digital-hig/modal';
import Button from '@mui/material/Button';
import { SvgIcon } from '@material-ui/core';
import Typography from '@digital-hig/typography';
import { t } from 'i18next';
import {
  CheckboxWrapper,
  TnCModalWrapper,
  ContentWrapper,
  SocialMediaWrapper,
  AddbuttonWrapper,
  MediaListWrapper,
  MediaListActions,
  SocialMediaRow,
  IconWrapper,
  CampaignWrapper,
  TermsAndConditionsWrapper
} from './Header.styled';
import InfoIcon from '@digital-hig/icon/lib/build/display-icons/info';
import Checkbox from '@digital-hig/checkbox';
import FormControl from '@digital-hig/form-control';
import Select from '@digital-hig/select';
import TextInput from '@digital-hig/text-input';
import Icon from '@digital-hig/icon';
import SvgFacebookCircle from '@digital-hig/icon/lib/build/icons/social/facebook-circle';
import SvgTwitterCircle from '@digital-hig/icon/lib/build/icons/social/twitter-circle';
import SvgInstagramCircle from '@digital-hig/icon/lib/build/icons/social/instagram-circle';
import SvgTrash from '@digital-hig/icon/lib/build/icons/actions/trash';
import SvgCross from '@digital-hig/icon/lib/build/icons/utility/cross';
import SvgEdit from '@digital-hig/icon/lib/build/icons/actions/edit';
import SvgCheckmark from '@digital-hig/icon/lib/build/icons/utility/checkmark';
import FormControlLabel from '@mui/material/FormControlLabel';

const TermsAndConditionModal = ({
  setAgreementModal,
  agreementModal,
  handlePublishClick,
  campaign
}) => {
  const [checked, setChecked] = useState(false);
  const [plus18checked, setPlus18Checked] = useState(false);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const mediaOptions = [
    { children: 'Facebook', value: 'facebook' },
    { children: 'Twitter', value: 'twitter' },
    { children: 'Instagram', value: 'instagram' }
  ];
  const [selectedOptions, setSelectedOptions] = useState(mediaOptions);
  const [socialMediaFields, setSocialMediaFields] = useState([
    { media: 'facebook', url: '', error: '' }
  ]);

  const [errors, setErrors] = useState({});
  const [plus18Error, setPlus18Error] = useState(false);
  const [aboutProject, setAboutProject] = useState('');

  const getSocialFields = () => {
    const newLinks = socialMediaFields.reduce((acc, key) => {
      if (key.media.length) {
        return { ...acc, [key.media]: key.url };
      } else {
        return { ...acc };
      }
    }, []);

    return newLinks;
  };

  const validateInput = (field) => {
    const fieldErrors = {};

    if (field.media === 'instagram' && !/^https?:\/\/(www\.)?instagram\.com\/.+$/.test(field.url)) {
      fieldErrors.instagram = 'Invalid Instagram URL';
    }

    if (field.media === 'twitter' && !/^https?:\/\/(www\.)?twitter\.com\/.+$/.test(field.url)) {
      fieldErrors.twitter = 'Invalid Twitter URL';
    }

    if (field.media === 'facebook' && !/^https?:\/\/(www\.)?facebook\.com\/.+$/.test(field.url)) {
      fieldErrors.facebook = 'Invalid Facebook URL';
    }

    setErrors(fieldErrors);

    return Object.keys(fieldErrors).length;
  };

  const handleSubmit = () => {
    if (campaign?.id) {
      let error;
      if (!plus18checked) {
        setPlus18Error(true);
      }

      if (checked) {
        socialMediaFields.map((field) => {
          error = validateInput(field);
        });
      }

      if (!error && plus18checked) {
        handlePublishClick(
          'publish',
          campaign?.name,
          plus18checked,
          aboutProject,
          getSocialFields()
        );
        setAgreementModal(false);
      }
    } else {
      handlePublishClick('publish', campaign?.name);
      setAgreementModal(false);
    }
  };

  const addNewField = (field) => {
    const error = validateInput(field);
    if (!error) {
      setSocialMediaFields([...socialMediaFields, { media: '', url: '' }]);
      //Removing the media from the dropdown
      const updatedOptions = selectedOptions.filter((option) => option.value !== field.media);
      setSelectedOptions(updatedOptions);
    }
  };

  const handleFieldValueChange = (index, event, key) => {
    const newTextFields = [...socialMediaFields];
    newTextFields[index] = { ...newTextFields[index], [key]: event.target.value };
    setSocialMediaFields(newTextFields);
  };

  const handleDeleteRow = (index, field) => {
    const newFields = socialMediaFields.filter((field, fieldIndex) => {
      return fieldIndex !== index;
    });
    setSocialMediaFields(newFields);

    //Adding the removed media back to the dropdown
    const optionToAddBack = mediaOptions.find((option) => option.value === field.media);
    setSelectedOptions((prevOptions) => [...prevOptions, optionToAddBack]);
    // if (optionToAddBack) {
    // }
  };

  const handleUrlUpdate = (field) => {
    const error = validateInput(field);
    if (!error) {
      setEditRowIndex(null);
    }
  };

  const handleCheck = () => {
    setChecked(!checked);
    setSocialMediaFields([{ media: 'facebook', url: '', error: '' }]);
    setErrors({});
  };

  const getMediaIcon = (field) => {
    switch (field.media) {
      case 'facebook':
        return <SvgFacebookCircle className="media_icon" />;
      case 'twitter':
        return <SvgTwitterCircle className="media_icon" />;
      case 'instagram':
        return <SvgInstagramCircle className="media_icon" />;
    }
  };

  return (
    <Modal
      aria-describedby={'Terms and Conditions'}
      aria-labelledby={'Terms and Conditions'}
      backdropVariant="default"
      closeButtonDisplay
      handleClose={() => setAgreementModal(false)}
      maxWidth="sm"
      maxLength="md"
      styleVariant="default"
      title={
        <TnCModalWrapper>
          <SvgIcon fontSize="large" className="svg_icon_margin">
            <InfoIcon />
          </SvgIcon>
          {/* Accept Autodesk terms and conditions */}
        </TnCModalWrapper>
      }
      open={agreementModal}
      content={
        <ContentWrapper>
          {campaign?.id && (
            <CampaignWrapper>
              <CheckboxWrapper>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={() => handleCheck()} size="small" />
                  }
                  label="If selected to be featured, would you like to be tagged on social?"
                />
              </CheckboxWrapper>

              {checked && (
                <>
                  <SocialMediaWrapper>
                    {socialMediaFields?.map((field, index) => {
                      const fieldError = errors[field.media]?.length > 0;

                      if (index !== 3) {
                        return (
                          <SocialMediaRow key={index} error={fieldError}>
                            {socialMediaFields.length === index + 1 || editRowIndex === index ? (
                              <>
                                {editRowIndex !== index ? (
                                  <FormControl
                                    id="select-id-default"
                                    variant="outlined"
                                    className="social_media_form"
                                  >
                                    <Select
                                      id="select-id-default"
                                      // placeholder="Select..."
                                      // label="Select..."
                                      defaultValue={0}
                                      className="social_media_select"
                                      onChange={(e) => handleFieldValueChange(index, e, 'media')}
                                      options={selectedOptions}
                                      value={field.media}
                                    />
                                  </FormControl>
                                ) : (
                                  <>
                                    {getMediaIcon(field)}
                                    <Typography
                                      variant="button-label-dense"
                                      sx={{ ml: 2 }}
                                      className="checkbox_title media_label"
                                    >
                                      {field?.media.charAt(0).toUpperCase() + field?.media.slice(1)}
                                    </Typography>
                                  </>
                                )}
                                <FormControl
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  className="social_media_input"
                                  required
                                  onChange={(e) => handleFieldValueChange(index, e, 'url')}
                                  error={errors[field.media]}
                                  helperText={errors[field.media]}
                                  hintText={`https://www.${field.media}.com`}
                                >
                                  <TextInput
                                    required
                                    value={field.url}
                                    error={errors[field.media]}
                                  />
                                </FormControl>
                                <AddbuttonWrapper>
                                  {socialMediaFields.length === index + 1 && !editRowIndex && (
                                    <Button
                                      size="medium"
                                      variant="outlined"
                                      onClick={() => addNewField(field)}
                                      disabled={field.url.length < 2}
                                      startIcon={
                                        <IconWrapper>
                                          <Icon id="dhig--icons--utility--add" />
                                        </IconWrapper>
                                      }
                                    >
                                      Add
                                    </Button>
                                  )}
                                  {editRowIndex === index && (
                                    <MediaListActions>
                                      <SvgCheckmark
                                        className="media_icon action-icons checkmark_action"
                                        // onClick={() => setEditRowIndex(null)}
                                        onClick={() => handleUrlUpdate(field)}
                                      />
                                      <SvgCross
                                        className="media_icon action-icons"
                                        onClick={() => setEditRowIndex(null)}
                                      />
                                    </MediaListActions>
                                  )}
                                </AddbuttonWrapper>
                              </>
                            ) : (
                              <MediaListWrapper>
                                {getMediaIcon(field)}
                                <Typography
                                  variant="button-label-dense"
                                  sx={{ ml: 2 }}
                                  className="checkbox_title media_label"
                                >
                                  {field?.media.charAt(0).toUpperCase() + field?.media.slice(1)}
                                </Typography>
                                <Typography
                                  variant="button-label-dense"
                                  sx={{ ml: 2 }}
                                  className="checkbox_title url_label"
                                >
                                  {field?.url}
                                </Typography>
                                <MediaListActions>
                                  <SvgEdit
                                    className="media_icon action-icons"
                                    onClick={() => setEditRowIndex(index)}
                                  />
                                  <SvgTrash
                                    className="media_icon action-icons"
                                    onClick={() => handleDeleteRow(index, field)}
                                  />
                                </MediaListActions>
                              </MediaListWrapper>
                            )}
                          </SocialMediaRow>
                        );
                      }
                    })}
                  </SocialMediaWrapper>
                  <div>
                    <FormControl
                      InputLabelProps={{
                        shrink: true
                      }}
                      className="about_project_input"
                      required
                      onChange={(e) => setAboutProject(e.target.value)}
                      label={'What did you enjoy or learn when building this project?'}
                      // error={errors[field.media]}
                      // helperText={errors[field.media]}
                    >
                      <TextInput
                        value={aboutProject}
                        inputProps={{
                          maxLength: 140
                        }}
                        required
                        multiline
                        rows={4}
                      />
                    </FormControl>
                  </div>
                </>
              )}

              <CheckboxWrapper>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={plus18checked}
                      onChange={() => setPlus18Checked(!plus18checked)}
                      size="small"
                      error={plus18Error}
                    />
                  }
                  label="I confirm that: I am a post-secondary (university) student or educator."
                />
              </CheckboxWrapper>
            </CampaignWrapper>
          )}
          <TermsAndConditionsWrapper>
            <Typography variant="smallprint" sx={{ mb: 4 }} className="terms_and_conditions">
              By clicking on the submit button, I agree to grant Autodesk the right to display,
              promote, re-publish and make minor edits for the purpose of publicizing my content
              throughout the world for a perpetual period of time in all media and by all means. I
              further represent that my content does not infringe upon, violate, or conflict with
              any rights of any third party.
            </Typography>
          </TermsAndConditionsWrapper>
        </ContentWrapper>
      }
      actions={
        <>
          <Button
            onClick={() => {
              setAgreementModal(false);
            }}
            variant="text"
          >
            {t('create_modal_unsaved_title_cancel_label')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </>
      }
    />
  );
};

export default TermsAndConditionModal;

TermsAndConditionModal.propTypes = {
  agreementModal: PropTypes.bool,
  setAgreementModal: PropTypes.func,
  handlePublishClick: PropTypes.func,
  campaign: PropTypes.object
};
