import i18n from '../../i18n';
import { SET_LANGUAGE } from '../actions/actionTypes';

export const initialState = {
  appLanguage: 'en'
};

export default function coreReducer(prevState = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_LANGUAGE:
      return {
        ...prevState,
        appLanguage: payload
      };

    case 'persist/REHYDRATE':
      if (payload && i18n.language !== payload.core.appLanguage) {
        i18n.changeLanguage(payload.core.appLanguage);
      }
      return prevState;

    default:
      return prevState;
  }
}
