/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  HeaderWrapper,
  HeaderWrapperInner,
  EditActionsWrapper,
  MoreActionsWrapper,
  LabelWrapper,
  IconWrapper,
  ButtonLabel,
  AlertWrapper,
  AlertInner,
  PublishWrapper,
  ReportOptionsWrapper
} from './Header.styled';
import { useTranslation } from 'react-i18next';
import Tag from '../Tag';
import Button from '@digital-hig/button';
import Icon from '@digital-hig/icon';
import OptionsButton from '../OptionsButton';
import { projectPropTypes } from '../../common/types';
import ThumbsUp from '@digital-hig/icon/lib/build/icons/actions/thumbs-up';
import ThumbsUpFilled from '@digital-hig/icon/lib/build/icons/actions/thumbs-up-filled';
import Bookmark from '@digital-hig/icon/lib/build/icons/actions/bookmark';
import ReportFlag from '@digital-hig/icon/lib/build/icons/actions/flag';
import BookmarkFilled from '@digital-hig/icon/lib/build/icons/actions/bookmark-filled';
import Alert from '@mui/material/Alert';
import { AlertIcon } from '@digital-hig/alert';
import Progress from '@digital-hig/progress';
import Modal from '@digital-hig/modal';
import { useIsMobile } from '../../utils';
import { useSelector } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { UserContext } from '../../context/UserContext';
import Typography from '@digital-hig/typography';
import TermsAndConditionModal from './Terms&ConditionModal';

function Header({
  handlePublishClick,
  handleDeleteClick,
  handleEditProjectClick,
  handleEditClick,
  handleCancelClick,
  handleLikeClick,
  handleBookmarkClick,
  handleReportClick,
  handleShareClick,
  handleApproveClick,
  isEdit,
  isLiked,
  isBookmarked,
  isReported,
  projectData,
  isLoading,
  alertData,
  unsavedActions,
  setShowModal,
  savingReport
}) {
  const { t } = useTranslation();
  const {
    totalBlocks,
    status,
    isAuthor,
    canEdit,
    likeCount = 0,
    bookmarkCount = 0,
    campaign
  } = projectData;
  const ownContent = isAuthor && canEdit;
  const isAdmin = (!isAuthor && canEdit) || ownContent;
  const isMobile = useIsMobile();
  const userRole = useSelector((state) => state?.auth?.myProfile?.role);
  const { state } = useContext(UserContext);

  const [showAlert, setShowAlert] = useState(alertData.showAlert);
  const [reportModal, setReportModal] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [reportReason, setReportReason] = useState('');

  useEffect(() => {
    setShowAlert(alertData.showAlert);
  }, [alertData]);

  const reportOptions = [
    { label: 'This is spam', id: 'spam' },
    { label: 'It contains violence', id: 'violence' },
    { label: 'It contains explicit imagery/nudity', id: 'adult-content' },
    { label: 'It contains racism', id: 'racism' },
    { label: 'It contains disinformation', id: 'disinformation' },
    { label: 'Other reason', id: 'other' }
  ];

  return (
    <>
      <Modal
        aria-describedby={t('create_delete_projecy_aria_description')}
        aria-labelledby={t('create_delete_projecy_aria_label')}
        backdropVariant="default"
        closeButtonDisplay
        maxWidth="xl"
        styleVariant="default"
        title={t(`create_delete_project_confirm_title`)}
        type="alert"
        iconName="alert"
        open={showConfirmDeleteModal}
        content={t(`create_delete_project_confirm_description`)}
        actions={
          <>
            <Button
              onClick={() => {
                setShowConfirmDeleteModal(false);
              }}
              variant="text"
            >
              {t('create_modal_unsaved_title_cancel_label')}
            </Button>
            <Button onClick={handleDeleteClick}>
              {t('create_modal_unsaved_title_confirm_label')}
            </Button>
          </>
        }
      />
      {showAlert && (
        <AlertWrapper color={isLoading ? 'info' : alertData.severity}>
          <AlertInner>
            <Alert
              severity={alertData.severity}
              onClose={!isLoading ? () => setShowAlert(false) : undefined}
              icon={
                isLoading ? (
                  <Progress className="inverted_white_loader" />
                ) : (
                  <AlertIcon
                    severity={alertData?.severity || 'info'}
                    variant="filled"
                    alt={`${alertData?.severity || 'info'} icon`}
                  />
                )
              }
              variant="filled"
            >
              {alertData.content
                ? alertData.content
                : t(`detail_header_alert_${alertData.severity}`)}
            </Alert>
          </AlertInner>
        </AlertWrapper>
      )}
      <HeaderWrapper
        data-wat-link-section="community gallery"
        className={isEdit && 'dhig-theme--dark'}
        isEdit={isEdit}
      >
        <HeaderWrapperInner isEdit={isEdit} rightAndStatus={Boolean(!isAdmin && !isEdit)}>
          {!!(isAdmin && !isEdit && projectData?.status !== 'deleted') && (
            <EditActionsWrapper>
              {status !== 'pending' && (
                <Button
                  size="dense"
                  variant="text"
                  onClick={handleEditClick}
                  startIcon={
                    <IconWrapper>
                      <Icon id="dhig--icons--actions--edit" size="medium" />
                    </IconWrapper>
                  }
                >
                  {!isMobile && t(`create_content_edit_project`)}
                </Button>
              )}
              <Button
                size="dense"
                variant="text"
                onClick={() => setShowConfirmDeleteModal(true)}
                startIcon={
                  <IconWrapper>
                    <Icon id="dhig--icons--actions--trash" size="medium" />
                  </IconWrapper>
                }
              >
                {!isMobile && t(`create_content_delete_project`)}
              </Button>
              {status === 'pending' && (userRole === 'moderator' || userRole === 'admin') && (
                <>
                  <Button size="dense" variant="outlined" onClick={handleApproveClick}>
                    {t(`create_detail_approve_button`)}
                  </Button>
                  <Button size="dense" variant="outlined" onClick={() => setShowModal(true)}>
                    {t(`create_detail_reject_button`)}
                  </Button>
                </>
              )}
            </EditActionsWrapper>
          )}
          {Boolean(isEdit) && (
            <EditActionsWrapper>
              <Button
                size="dense"
                variant="text"
                disabled={isLoading}
                onClick={() => handleEditProjectClick('project_details')}
                startIcon={
                  <IconWrapper>
                    <Icon id="dhig--icons--actions--settings" size="medium" />
                  </IconWrapper>
                }
              >
                {t(`create_content_edit_project_details_label`)}
              </Button>
              <Button
                size="dense"
                variant="text"
                disabled={isLoading}
                onClick={() => handleEditProjectClick('project_build')}
                startIcon={
                  <IconWrapper>
                    <Icon id="dhig--icons--miscellaneous--filter-tokens" size="medium" />
                  </IconWrapper>
                }
              >
                {t(`create_content_edit_project_build_label`)}
              </Button>
            </EditActionsWrapper>
          )}

          {Boolean(status && isAdmin && !isMobile) && (
            <LabelWrapper>
              <Tag
                label={t(`create_content_status_${status}`)}
                className={`create_content_tag create_content_status_${status}`}
                size="small"
              />
            </LabelWrapper>
          )}
          <MoreActionsWrapper>
            {Boolean(!isEdit) && (
              <>
                {!ownContent && (
                  <>
                    <Button
                      data-wat-val={isLiked ? 'liked' : 'unlike'}
                      size="dense"
                      variant="text"
                      onClick={handleLikeClick}
                      disabled={status === 'draft' || status === 'pending' || ownContent}
                      startIcon={
                        <IconWrapper showCount={likeCount > 0}>
                          {likeCount > 0 && likeCount}
                          <Icon
                            id={`dhig--icons--actions--thumbs-up${isLiked ? '-filled' : ''}`}
                            size="medium"
                          >
                            {isLiked ? <ThumbsUpFilled /> : <ThumbsUp />}
                          </Icon>
                        </IconWrapper>
                      }
                    >
                      <ButtonLabel>
                        {t(`create_content_like${isLiked ? 'd' : ''}_project`)}
                      </ButtonLabel>
                    </Button>
                    <Button
                      data-wat-val={isBookmarked ? 'bookmarked' : 'bookmark'}
                      size="dense"
                      variant="text"
                      onClick={handleBookmarkClick}
                      disabled={status === 'draft' || status === 'pending' || ownContent}
                      startIcon={
                        <IconWrapper showCount={bookmarkCount > 0}>
                          {bookmarkCount > 0 && bookmarkCount}
                          <Icon
                            id={`dhig--icons--actions--bookmark${isBookmarked ? '-filled' : ''}`}
                            size="medium"
                          >
                            {isBookmarked ? <BookmarkFilled /> : <Bookmark />}
                          </Icon>
                        </IconWrapper>
                      }
                    >
                      <ButtonLabel>
                        {t(`create_content_bookmark${isBookmarked ? 'ed' : ''}_project`)}
                      </ButtonLabel>
                    </Button>
                    {state.isAuthenticated && (
                      <Button
                        data-wat-val={'report'}
                        size="dense"
                        variant="text"
                        onClick={() => setReportModal(true)}
                        disabled={
                          status === 'draft' || status === 'pending' || ownContent || isReported
                        }
                        startIcon={
                          <IconWrapper showCount={bookmarkCount > 0}>
                            {bookmarkCount > 0 && bookmarkCount}
                            <Icon id={`dhig--icons--actions--flag`} size="medium">
                              {<ReportFlag />}
                            </Icon>
                          </IconWrapper>
                        }
                      >
                        <ButtonLabel>Report</ButtonLabel>
                      </Button>
                    )}
                  </>
                )}
                <Button
                  data-wat-val={'Share'}
                  size="dense"
                  variant="text"
                  onClick={handleShareClick}
                  disabled={status === 'draft' || status === 'pending'}
                  startIcon={
                    <IconWrapper>
                      <Icon id="dhig--icons--actions--share" size="medium"></Icon>
                    </IconWrapper>
                  }
                >
                  <ButtonLabel>{t('create_content_share_project')}</ButtonLabel>
                </Button>
              </>
            )}
            {isEdit && (
              <>
                <PublishWrapper isMobile={isMobile}>
                  <Button
                    size="dense"
                    variant="text"
                    onClick={handleCancelClick}
                    disabled={isLoading}
                  >
                    {t(`create_content_cancel_edit_project`)}
                  </Button>

                  <OptionsButton
                    isMobile={isMobile}
                    icon="dhig--icons--actions--publish"
                    label={t(
                      `create_content_publish${
                        isEdit && status === 'published' ? '_edited' : ''
                      }_project`
                    )}
                    disabled={
                      totalBlocks < 1 ||
                      isLoading ||
                      (status === 'published' && isEdit && !unsavedActions)
                    }
                    onClick={() =>
                      status === 'published'
                        ? handlePublishClick('publish')
                        : setAgreementModal(true)
                    }
                    otherActions={[
                      {
                        label: t('create_content_save_draft_project'),
                        onClick: () => handlePublishClick('draft'),
                        icon: 'dhig--icons--actions--save',
                        disabled: isLoading
                      },
                      {
                        label: t('create_content_delete_project'),
                        onClick: () => handleDeleteClick(),
                        icon: 'dhig--icons--actions--trash',
                        disabled: isLoading
                      }
                    ]}
                  />
                </PublishWrapper>
              </>
            )}
          </MoreActionsWrapper>
        </HeaderWrapperInner>
      </HeaderWrapper>
      {agreementModal && (
        <TermsAndConditionModal
          setAgreementModal={setAgreementModal}
          agreementModal={agreementModal}
          handlePublishClick={handlePublishClick}
          campaign={campaign}
        />
      )}
      {reportModal && (
        <Modal
          aria-describedby={'Report Project'}
          aria-labelledby={'Report Project'}
          backdropVariant="default"
          closeButtonDisplay
          handleClose={() => setReportModal(false)}
          maxWidth="sm"
          styleVariant="default"
          title={'Report Project'}
          open={reportModal}
          content={
            <>
              <Typography variant="button-label-dense" sx={{ mb: 4 }}>
                Why do you want to report this?
              </Typography>
              <ReportOptionsWrapper>
                <FormControl>
                  <RadioGroup
                    value={reportReason}
                    onChange={(e) => setReportReason(e.target.value)}
                  >
                    {reportOptions.map((option, i) => {
                      return (
                        <div key={i}>
                          <FormControlLabel
                            key={i}
                            control={<Radio />}
                            value={option.id}
                            label={option.label}
                          />
                        </div>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </ReportOptionsWrapper>
            </>
          }
          actions={
            <>
              <Button
                onClick={() => {
                  setReportModal(false);
                }}
                variant="text"
              >
                {t('create_modal_unsaved_title_cancel_label')}
              </Button>
              <Button
                startIcon={savingReport && <Progress size="tiny" />}
                disabled={!reportReason.length}
                onClick={() => handleReportClick(reportReason, setReportModal)}
              >
                Submit
              </Button>
            </>
          }
        />
      )}
    </>
  );
}

Header.propTypes = {
  handlePublishClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleEditProjectClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleCancelClick: PropTypes.func,
  handleShareClick: PropTypes.func,
  handleLikeClick: PropTypes.func,
  handleBookmarkClick: PropTypes.func,
  handleApproveClick: PropTypes.func,
  setShowModal: PropTypes.func,
  handleReportClick: PropTypes.func,
  isLiked: PropTypes.bool,
  isBookmarked: PropTypes.bool,
  isReported: PropTypes.number,
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  unsavedActions: PropTypes.bool,
  savingReport: PropTypes.bool,
  alertData: PropTypes.shape({
    severity: PropTypes.oneOf(['info', 'error', 'success', 'warning']),
    content: PropTypes.string,
    showAlert: PropTypes.bool
  }),
  projectData: PropTypes.shape({
    id: projectPropTypes.id,
    totalBlocks: PropTypes.number,
    status: projectPropTypes.status,
    isAuthor: projectPropTypes.isAuthor,
    canEdit: projectPropTypes.canEdit,
    isLiked: projectPropTypes.isLiked,
    isBookmarked: projectPropTypes.isBookmarked,
    likeCount: projectPropTypes.likeCount,
    bookmarkCount: projectPropTypes.bookmarkCount,
    campaign: projectPropTypes.campaign
  })
};

Header.defaultProps = {
  alertData: {
    severity: 'info',
    showAlert: false
  }
};

export default Header;
