import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const ThumbnailCropWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .thumbnail-crop-upload-mobile {
      border: none;
      position: absolute;
      button {
        color: white;
        border-color: white;
        svg {
          fill: white;
        }
      }
      ${({ hasImage }) =>
        hasImage
          ? css`
              top: 0px;

              button {
                border: none;
              }
            `
          : css`
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
    }
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${lightTheme.primitives.spacings['4']};
  width: 200px;
  background: ${lightTheme.primitives.colors.tint['slate-100']};
  @media screen and (max-width: 768px) {
    display: none;
  }
  border-left: 4px solid ${lightTheme.primitives.colors.primary.white};
`;

export const ImagesWrapper = styled.div`
  display: grid;
  gap: ${lightTheme.primitives.spacings['4']};
  grid-template-columns: repeat(2, 1fr);
  overflow-y: scroll;
  max-height: 308px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ImageSelectButton = styled.button`
  width: 72px;
  height: 72px;
  border-radius: ${lightTheme.primitives.borderRadii.medium};
  border: none;
  cursor: ${({ isLoading }) => (!isLoading ? 'pointer' : 'not-allowed')};
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  svg {
    color: #ffffff;
    z-index: 2;
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    align-self: center;
    opacity: 0;
    transition: opacity 0.4s ${lightTheme.primitives.transitions.easing.energetic};
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.4s ${lightTheme.primitives.transitions.easing.energetic};
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  }
  ${({ active }) =>
    active &&
    css`
      svg {
        opacity: 1;
      }
      &::after {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);
      }
    `}
`;
