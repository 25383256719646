import React, { useState } from 'react';
import ProjectListing from '../../components/ProjectListing';
import Banner from '../../components/Banner';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { SEARCH_QUERY } from '../../apollo/queryPages';

const SearchPage = () => {
  const [pageData, setPageData] = useState({
    heroData: null
  });

  const { data: pageDataAPI } = useQuery(SEARCH_QUERY, {
    context: { clientName: 'public' },
    variables: {
      id: 2,
      lang: 'en'
    },
    onCompleted: (pageDataAPI) => {
      const data = pageDataAPI.page;
      setPageData({
        heroData: {
          type: 'hero',
          title: data.hero_title,
          subtitle: data.hero_description,
          avatarProps: {
            name: `${data.hero_project.author.display_name}`,
            profilePicture: data.hero_project.author.avatar_big,
            profileUrl: `/profile/${data.hero_project.author.slug}`,
            slug: data.hero_project.author.slugg
          },
          image: {
            src: data.hero_project?.image.path,
            alt: data.hero_project?.title,
            srcSet: data.hero_project?.image.sizes
          },
          showSearchbar: true
        }
      });
    }
  });

  return (
    <>
      <Helmet
        title={`${pageDataAPI?.page.seo?.title} | Autodesk Community Gallery`}
        meta={[
          {
            property: 'og:image',
            content:
              pageDataAPI?.page?.seo?.image ||
              'https://dsuj2mkiosyd2.cloudfront.net/seo/en/000/001/og_image_original.jpg'
          },
          {
            property: 'og:title',
            content: `${pageDataAPI?.page.seo?.og_title} | Autodesk Community Gallery`
          },
          {
            property: 'og:url',
            content: `https://www.autodesk.com/community/gallery/search`
          },
          {
            property: 'og:description',
            content: `${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`
          },
          {
            property: 'twitter:title',
            content: `${pageDataAPI?.page.seo?.og_title} | Autodesk Community Gallery`
          }
        ]}
        description={`${pageDataAPI?.page.seo?.og_description} | Autodesk Community Gallery`}
      />
      {pageData.heroData && <Banner {...pageData.heroData} />}
      <ProjectListing name="search" pageType="search" />
    </>
  );
};

export default SearchPage;
