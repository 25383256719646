import React, { useState } from 'react';
import Typography from '@digital-hig/typography';
import Snackbar from '@material-ui/core/Snackbar';
// import { SigninAlertWrapper } from './SigninAlert.styled';
// import CloseIcon from '@material-ui/icons/Close';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { AlertIcon } from '@digital-hig/alert';
import { Container } from '@material-ui/core';
import Fade from '@mui/material/Fade';
import { loginWithRedirect } from '../../utils/authHelper';
import { SigninButton } from './SigninAlert.styled';
import IconButton from '@digital-hig/icon-button';
// import Button from '@digital-hig/button';
// import Grow, { GrowProps } from '@mui/material/Grow';
// import { TransitionProps } from '@mui/material/transitions';
// import { SvgIcon } from '@material-ui/core';
export default function SignInAlert(props) {
  const [state] = useState({
    Transition: Fade
  });

  return (
    <Box
      sx={{
        '& .MuiSnackbarContent-root': {
          '& .MuiSnackbarContent-action': {
            '& .MuiSvgIcon-root': {
              flexGrow: '1',
              order: '3'
            },
            '& .MuiTypography-root': {
              flexGrow: '3',
              order: '4',
              width: '100%'
            }
          },
          '& .MuiSnackbarContent-message': {
            '& .MuiSvgIcon-root': {
              flexGrow: '1',
              order: '1'
            },
            '& .MuiTypography-root': {
              flexGrow: '2',
              order: '2'
            },
            display: 'flex'
          },
          backgroundColor: 'white'
        }
      }}
    >
      <Snackbar
        open={true}
        autoHideDuration={4000}
        // onClose={handleCloseTopRight}
        TransitionComponent={state.Transition}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        message={
          <Container>
            <AlertIcon severity="warning" alt="info icon" />

            <Typography variant="short-copy-small">
              You must be signed in for this action.
            </Typography>

            <SigninButton onClick={() => loginWithRedirect(location)}>Sign in</SigninButton>
          </Container>
        }
        action={
          <IconButton
            layout="hug"
            size="dense"
            variant="text"
            iconId="dhig--icons--utility--cross"
            onClick={props.onClose}
          ></IconButton>
        }
      />
    </Box>
  );
}

SignInAlert.propTypes = {
  onClose: PropTypes.func
};
