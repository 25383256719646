import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const BoxContainer = styled.div`
  background-color: ${lightTheme.primitives.colors.primary.black};
  color: ${lightTheme.primitives.colors.primary.black};
  height: 200px;
  left: 0;

  @media all and (max-width: 990px) {
    position: relative;
  }
`;

export const GridContainer = styled.div`
  display: flex;
  max-width: 1248px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  align-items: flex-end;
  gap: 1em;

  .brandImage {
    position: relative;
  }

  .avatar-section {
    z-index: 1;
    margin-bottom: -40px;
    margin-left: 16px;
  }

  .profile-meta {
    position: relative;
    z-index: 1;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    p {
      padding-left: 18px;
    }
  }

  @media screen and (min-width: 991px) {
    .pattern {
      line-height: 0;
      margin-left: auto;
    }

    .avatar-section {
      z-index: 1;
    }
  }

  @media screen and (min-width: ${lightTheme.primitives.grid.scale['7']}) {
    .avatar-section {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .avatar-section {
      left: 0;
    }
  }

  .avatar-section {
    border: 3px solid white;
    box-sizing: border-box;
    width: 160px;
    height: 160px;
    border-radius: 999px;
  }

  .edit-profile {
    background: transparent;
    border-color: transparent;

    &:hover {
      background: transparent;
    }

    .icon-style {
      margin-right: 0;
      width: 20px;
      height: 20px;
    }
  }
  @media all and (max-width: 990px) {
    .brandImage {
      position: absolute;
      width: 507px;
      bottom: 0;
      right: -100px;
    }
    .avatar-section {
      width: 160px;
      height: 160px;
    }
  }
  .icon-style {
    margin-right: 8px;
    color: ${lightTheme.primitives.colors.primary.white};
  }
`;

export const BottomContainer = styled.div`
  max-width: 1248px;
  margin: 0 auto;

  .bottomBox {
    margin-top: 8px;
  }

  .wrap-name {
    word-break: break-all;
  }

  .sidebar {
    background-color: #f9f9f9;
  }

  .name-section {
    height: 58px;
    font-family: 'Artifakt Legend';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
  }

  .profile-section {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  .location-section {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    margin-top: 1.5em;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${lightTheme.primitives.spacings['9']} 0;
`;
