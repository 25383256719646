import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BuildSectionWrapper } from './buildSections/BuildSections.styled';
import { Assets, Layout } from './buildSections';
import { createStatePropTypes } from '../../types/create';
import { assetType } from '../../constants';
// import { t } from 'i18next';
import { useIsMobile } from '../../../utils';
import Tabs from '@digital-hig/tabs/lib/tabs';

import { TabsWrapper } from './ProjectBuild.styled';

const ProjectBuild = ({
  blocks,
  onReorderBlocks,
  onDeleteBlock,
  onAddEmbed,
  onFileUpload,
  onPageChange,
  userAssets,
  assetsLoaded,
  assetsLoading,
  onAddAssetFromLib,
  onDeleteAssetFromLib,
  onRemoveAssetFromBlocks,
  paginationData,
  onSelectDownload,
  fileUploadError,
  fetchMore
}) => {
  const isMobile = useIsMobile();
  const TABS = [
    {
      label: 'Layout'
    },
    {
      label: 'Add assets'
    }
  ];
  const [currentTab, setCurrentTab] = useState(blocks.length ? 0 : 1);

  // const TABS = Object.keys(typesIds).map((el, i) => ({
  //   'aria-controls': `tabpanel-${i}-default`,
  //   id: `${i}`,
  //   label: <div className="section-tab">{el}</div>
  // }));

  // const updateLayout = (addAssetType) => {
  //   setCurrentTab(0);
  //   addAssetType();
  // };

  return (
    <>
      {isMobile && (
        <TabsWrapper>
          <Tabs
            styleVariant="quiet"
            value={currentTab}
            ariaLabel="Build"
            tabProperties={TABS}
            onChange={(e, v) => setCurrentTab(v)}
          />
        </TabsWrapper>
      )}

      <BuildSectionWrapper>
        {(currentTab === 0 || (!isMobile && blocks.length > 0)) && (
          <Layout
            blocks={blocks}
            onSelectDownload={onSelectDownload}
            onReorder={onReorderBlocks}
            onDeleteBlock={onDeleteBlock}
          />
        )}
        {!!(currentTab === 1 || !isMobile) && (
          <Assets
            fetchMore={fetchMore}
            onAddEmbed={onAddEmbed}
            userAssets={userAssets}
            assetsLoaded={assetsLoaded}
            assetsLoading={assetsLoading}
            onAddAssetFromLib={onAddAssetFromLib}
            onDeleteAssetFromLib={onDeleteAssetFromLib}
            onRemoveAssetFromBlocks={onRemoveAssetFromBlocks}
            onFileUpload={onFileUpload}
            blocks={blocks}
            paginationData={paginationData}
            onPageChange={onPageChange}
            fileUploadError={fileUploadError}
            onSetLayoutTab={() => setCurrentTab(0)}
          />
        )}
      </BuildSectionWrapper>
    </>
  );
};

ProjectBuild.propTypes = {
  blocks: createStatePropTypes.blocks,
  onPageChange: PropTypes.func,
  onReorderBlocks: PropTypes.func,
  onDeleteBlock: PropTypes.func,
  onFileUpload: PropTypes.func,
  onAddEmbed: PropTypes.func,
  onAddAssetFromLib: PropTypes.func,
  onDeleteAssetFromLib: PropTypes.func,
  onRemoveAssetFromBlocks: PropTypes.func,
  onSelectDownload: PropTypes.func,
  userAssets: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      imageUrl: PropTypes.string,
      imageAlt: PropTypes.string,
      type: PropTypes.oneOf(assetType).isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  assetsLoaded: PropTypes.bool,
  assetsLoading: PropTypes.bool,
  fileUploadError: PropTypes.array,
  paginationData: PropTypes.shape({
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
    totalPageCount: PropTypes.number,
    hasMorePages: PropTypes.bool
  }),
  fetchMore: PropTypes.func
};

ProjectBuild.defaultProps = {
  blocks: [],
  onReorderBlocks: () => {},
  onDeleteBlock: () => {},
  onFileUpload: () => {},
  onAddEmbed: () => {},
  onAddAssetFromLib: () => {},
  onDeleteAssetFromLib: () => {},
  onRemoveAssetFromBlocks: () => {},
  onSelectDownload: () => {},
  userAssets: [],
  assetsLoaded: false,
  assetsLoading: false,
  paginationData: {}
};

export default ProjectBuild;
