import styled from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

import { Link } from 'react-router-dom';
export const TagListWrapper = styled.div`
  h4 {
    @media screen and (max-width: 728px) {
      text-align: left;
    }
  }
`;

export const Tags = styled.div`
  display: flex;
  max-width: 586px;
  gap: ${lightTheme.primitives.spacings['2']};
  flex-wrap: wrap;
  margin-top: ${({ hasTitle }) => (hasTitle ? lightTheme.primitives.spacings['4'] : 0)};
  @media screen and (max-width: 728px) {
    justify-content: flex-start;
  }
`;

export const LinkChip = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  & > div {
    pointer-events: none;
  }
`;
