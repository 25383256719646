import styled, { css } from 'styled-components';
import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

export const NavWrapper = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 10;
  justify-content: space-between;
  pointer-events: none;
`;

export const SliderWrapper = styled.div`
  position: relative;

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    margin: auto;
  }
`;

export const SliderContainer = styled.div`
  margin: 0px 50px;

  @media screen and (max-width: ${lightTheme.primitives.grid.scale['4']}) {
    margin: 0 25px;
  }
`;

export const StyledNavBtn = styled.button`
  font-size: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  line-height: 0;
  border: 0;
  background: white;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  pointer-events: auto;
  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
  }
  svg {
    width: 24px;
    height: 24px;
  }
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `};
  ${({ isNextAlone }) =>
    isNextAlone &&
    css`
      justify-self: flex-end;
      margin-left: auto;
    `};
  ${({ isNext }) =>
    isNext
      ? css`
          margin-right: 0px;
        `
      : css`
          margin-left: 0px;
        `};
`;
